import React, { useEffect, useState, useContext } from "react";
import { useTheme } from '@mui/material/styles';
import { Box, Typography, Button, Modal, TextField, IconButton, Tooltip, Switch } from '@mui/material';

export default props => {
    const theme = useTheme()
    function findEventoInModulo(modulos, modulo, evento) {
        const findModulo = modulos.findIndex((e) => e.modulo === modulo)
        if (findModulo === -1) return [false, 1]
        const findEvento = modulos[findModulo].eventos.findIndex((e) => e.id === evento)
        if (findEvento === -1) return [false, 2, findModulo]
        return [true, findModulo, findEvento]
    }
    function ifEventoActive(eventoId) {
        const init = findEventoInModulo(props.allUserModulos, props.modulo.id, eventoId)
        return init[0]
    }
    return (
        <Modal
            open={props.active}
            onClose={() => {
                props.desactive()
            }}
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                [theme.breakpoints.down('lg')]: {
                    alignItems: 'flex-start',
                }
            }}
        >
            <Box sx={{
                width: '45%',
                height: 500,
                overflowY: 'scroll',
                backgroundColor: theme.palette.background.default,
                border: 1,
                borderColor: theme.palette.secondary.main,
                position: 'absolute',
                [theme.breakpoints.down('lg')]: {
                    width: '70%'
                },
                [theme.breakpoints.down('md')]: {
                    width: '90%'
                },
            }}>
                <Box sx={{ backgroundColor: theme.palette.background.paper, padding: 5, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography sx={{ fontWeight: 'bold', fontSize: 30 }}>{'Eventos #' + props.modulo.nome}</Typography>
                    <Typography sx={{ fontSize: 20 }}>{props.aplicacao}</Typography>
                </Box >
                {props.data.length > 0 ? props.data.map((e, idx) => {
                    if (e.nome === 'Acesso') return
                    return (
                        <Box
                            key={idx}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                gap: 2,
                                padding: '16px',
                                border: '1px solid ' + theme.palette.background.paper,
                                borderRadius: '8px',
                                backgroundColor: theme.palette.background.default,
                                boxShadow: theme.palette.mode === 'dark' ? '0 4px 8px rgba(255, 255, 255, 0.1)' : '0 4px 8px rgba(0, 0, 0, 0.1)',
                            }}
                        >
                            <Tooltip title={e?.descricao} placement="top">
                                <TextField
                                    label="Evento"
                                    margin="none"
                                    value={e['nome']}
                                    disabled={true}
                                    id={e.nome}
                                    name={e.nome}
                                    sx={{
                                        width: '50%',
                                        '& input': {
                                            color: theme.palette.text.primary,
                                            WebkitTextFillColor: theme.palette.text.primary + ' !important' // Cor do valor (quando desativado)
                                            //-webkit-text-fill-color
                                        },
                                        '& .MuiInputLabel-root': {
                                            color: theme.palette.text.primary,
                                            WebkitTextFillColor: theme.palette.text.primary + ' !important', // Cor do texto do label
                                        },
                                        '& .MuiInputBase-root': {
                                            color: theme.palette.text.primary,
                                            WebkitTextFillColor: theme.palette.text.primary + ' !important', // Cor do valor do campo (quando desativado)
                                        },
                                    }}
                                    InputLabelProps={{
                                        style: {
                                            color: theme.palette.text.primary + ' !important',
                                        },
                                    }}
                                    inputProps={{
                                        style: {
                                            backgroundColor: theme.palette.background.default,
                                            borderRadius: '4px',
                                            border: '1px solid ' + theme.palette.background.paper,
                                            padding: '10px',
                                        },
                                    }}
                                />
                            </Tooltip>
                            <Switch
                                checked={ifEventoActive(e?.id) === true ? true : false}
                                onChange={(event) => props.updateEvento(props.modulo, e, event.target.checked)}
                                inputProps={{ 'aria-label': 'checkbox' }}
                            />
                        </Box>
                    )
                })
                    :
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '70%', width: '100%' }}>
                        <Box sx={{ padding: 2, backgroundColor: theme.palette.warning.main, borderRadius: 2 }}>
                            <Typography sx={{ color: theme.palette.text.whiteText, fontWeight: 'bold' }}>Não há eventos para esse módulo</Typography>
                        </Box>
                    </Box>
                }
            </Box>
        </Modal>
    )
}