import React, { useEffect, useState, useContext } from "react";
import { useTheme } from '@mui/material/styles';
import { Box, Typography, Button, Modal, Menu, MenuItem, Divider, TextField, IconButton, Icon } from '@mui/material';
import mainApi from "../../apis/mainApi";
import { User } from '../../contexts/user'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { getEvento } from "../../components/getEvento";
import { cpfMask, cepMask, celularMask } from "../../components/masks";
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
export default props => {
    const theme = useTheme()
    const user = useContext(User)
    let data = props?.data
    const newDataInitial = {
        editable: false,
        id: '',
        aplicacao_nome: '',
        email: '',
        cpf: '',
        nome: '',
        sobrenome: '',
        rua: '',
        numero: '',
        bairro: '',
        cidade: '',
        estado: '',
        celular: '',
        cep: '',
        complemento: '',
        data_cadastro: '',
        senha: ''
    }
    const [newData, setNewData] = useState(newDataInitial)

    async function updateESaveClient() {
        props.updateModalLoading(true)
        function FCallback(callback) {
            props.updateModalLoading(false)
            if (callback.status !== 200) {
                const x = {}
                x.title = 'Erro'
                x.subtitle = callback.apiReturn.message ? callback.apiReturn.message : 'Ocorreu um erro! Tente novamente mais tarde'
                x.body = <Button onClick={() => props.resetModalInfo()} sx={{ mb: 2, backgroundColor: theme.palette.secondary.main, color: theme.palette.text.primary, '&:hover': { backgroundColor: theme.palette.secondary.dark }, borderRadius: 0, padding: 1 }}>
                    <Typography fontWeight={'bold'} sx={{ color: theme.palette.text.whiteText, textAlign: 'center' }}>Voltar</Typography>
                </Button>
                x.active = true
                props.updateModalInfo(x)
            }
            const updateData = { ...props.mainData }
            if (props.type === 2) {
                const findIndex = updateData.content.findIndex((e) => e.id === newData.id)
                updateData.content[findIndex] = newData
            }
            if (props.type === 1) {
                callback.apiReturn.apiReturn.aplicacao_nome = 'Central de Negócios'
                updateData.content.push(callback.apiReturn.apiReturn)
            }
            props.updateData(updateData)
        }
        const body = { ...newData }
        delete body.aplicacao_nome
        delete body.editable
        if (props.type === 1) {
            body.primeiro_nome = body.nome
            delete body.nome
        }
        const method = props.type === 2 ? 'PUT' : 'POST'
        const endpoint = props.type === 2 ? 'clientes' : 'clientes/register'
        await mainApi(endpoint, method, body, FCallback, user.data.token)
    }


    useEffect(() => {
        if (props.active === true && props.type === 2) {
            const formatData = { ...newData }
            const getData = props.data
            formatData.aplicacao_nome = getData.aplicacao_nome
            formatData.bairro = getData.bairro
            formatData.celular = getData.celular
            formatData.cep = getData.cep
            formatData.cidade = getData.cidade
            formatData.complemento = getData.complemento ? getData.complemento : ''
            formatData.cpf = getData.cpf
            formatData.data_cadastro = getData.data_cadastro
            formatData.email = getData.email
            formatData.estado = getData.estado
            formatData.id = getData.id
            formatData.nome = getData.nome
            formatData.numero = getData.numero
            formatData.rua = getData.rua
            formatData.sobrenome = getData.sobrenome
            formatData.editable = true
            delete formatData.senha
            setNewData(formatData)
        }
    }, [props.active])
    return (
        <Modal
            open={props.active}
            onClose={() => {
                props.resetOpenData()
                setNewData(newDataInitial)
            }}
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                [theme.breakpoints.down('lg')]: {
                    alignItems: 'flex-start',
                },
            }}
        >
            <Box sx={{
                width: '45%',
                height: 'auto',
                backgroundColor: theme.palette.background.default,
                border: 1,
                borderColor: theme.palette.secondary.main,
                position: 'absolute',
                [theme.breakpoints.down('lg')]: {
                    width: '70%'
                },
                [theme.breakpoints.down('md')]: {
                    width: '90%'
                },
            }}>
                <Box sx={{ backgroundColor: theme.palette.background.paper, padding: 5, display: 'flex', justifyContent: 'space-between' }}>
                    <Typography sx={{ fontWeight: 'bold', fontSize: 30 }}>{props.type === 1 ? 'Novo Cliente' : 'Cliente #' + newData.nome}</Typography>
                    <Typography sx={{ fontWeight: 'bold', fontSize: 30 }}>{props.type === 1 ? 'CDN' : newData.aplicacao_nome}</Typography>
                </Box >
                <Box sx={{
                    padding: 5, paddingTop: 1
                }}>
                    <Box sx={{
                        display: 'flex', flexDirection: 'column', width: '100%', gap: 3,
                        [theme.breakpoints.down('md')]: {
                            width: '98%'
                        },
                    }}>
                        <Typography fontWeight={'bold'} fontSize={20}>Informações</Typography>
                        <Box sx={{ display: 'flex', gap: 1, width: '100%', flexWrap: 'wrap' }}>
                            {Object.keys(newData).map((e, idx) => {
                                if (['editable', 'aplicacao_nome'].includes(e)) return
                                return (
                                    <TextField
                                        key={idx}
                                        label={e}
                                        margin="none"
                                        value={newData[e]}
                                        disabled={['id', 'data_cadastro'].includes(e) ? true : newData.editable}
                                        id={e}
                                        name={e}
                                        onChange={(value) => {
                                            const x = { ...newData }
                                            x[e] = ['nome', 'sobrenome', 'cidade', 'estado'].includes(e) ? value.target.value.replace(/[^a-zA-Z]+/g, '') : e === 'numero' ? value.target.value.replace(/\D/g, "") : e === 'cpf' ? cpfMask(value.target.value) : e === 'cep' ? cepMask(value.target.value) : e === 'celular' ? celularMask(value.target.value) : value.target.value
                                            setNewData(x)
                                        }}
                                        sx={{
                                            width: '49%'
                                        }}
                                        InputLabelProps={{
                                            style: {
                                                color: theme.palette.text.secondary,
                                                borderColor: '#eee'
                                            }
                                        }}
                                        autoComplete=""
                                        inputProps={{
                                            maxLength: e === 'estado' ? 2 : 'unset',
                                            style: {
                                                backgroundColor: theme.palette.background.paper,
                                                borderRadius: 5,
                                                textTransform: e === 'estado' ? 'uppercase' : 'unset',
                                            }
                                        }}
                                    />
                                )
                            })}
                        </Box>
                        <Box sx={{ display: 'flex', gap: 2 }}>
                            {props.type === 2 ?
                                <IconButton
                                    onClick={() => {
                                        if (getEvento(props.eventos, 'Editar Cliente') === false) {
                                            const modalInfo = {
                                                active: true,
                                                title: 'Permissão',
                                                subtitle: 'Você não tem permissão para editar o cliente',
                                                body: <Button onClick={() => props.resetModalInfo()} sx={{ mb: 2, backgroundColor: theme.palette.secondary.main, color: theme.palette.text.primary, '&:hover': { backgroundColor: theme.palette.secondary.dark }, borderRadius: 0, padding: 1 }}>
                                                    <Typography fontWeight={'bold'} sx={{ color: theme.palette.text.whiteText, textAlign: 'center' }}>Voltar</Typography>
                                                </Button>
                                            }
                                            props.updateModalInfo(modalInfo)
                                            return
                                        }
                                        const x = { ...newData }
                                        x.editable = !x.editable
                                        setNewData(x)
                                    }}
                                    sx={{
                                        backgroundColor: theme.palette.primary.main,
                                        borderRadius: 0,
                                        '&:hover': {
                                            backgroundColor: theme.palette.secondary.main,
                                        }
                                    }}>
                                    <EditIcon sx={{ color: theme.palette.text.whiteText }} />
                                    {/* <Typography fontWeight={'bold'} sx={{ color: theme.palette.text.whiteText }}>Editar</Typography> */}
                                </IconButton>
                                : null}
                            <IconButton
                                onClick={() => {
                                    updateESaveClient()
                                }}
                                sx={{
                                    backgroundColor: newData.editable === true ? theme.palette.grey[600] : theme.palette.primary.main,
                                    borderRadius: 0,
                                    '&:hover': {
                                        backgroundColor: newData.editable === false ? theme.palette.secondary.main : theme.palette.grey[600]
                                    }
                                }}>
                                {props.type === 2 ?
                                    <SaveIcon sx={{ color: theme.palette.text.whiteText }} />
                                    :
                                    <Typography fontWeight={'bold'} sx={{ color: theme.palette.text.whiteText }}>Salvar</Typography>
                                }
                            </IconButton>
                        </Box>
                    </Box >
                </Box >
            </Box >
        </Modal >
    )
}