import React from "react";
import { Box, Typography, Modal, CircularProgress, useTheme } from "@mui/material"

export function ModalLoading({ative, updateModalLoading}) {
    const theme = useTheme()
    return (
        <Modal
            open={ative}
            onClose={() => {
                if (updateModalLoading) {
                    if (typeof updateModalLoading === 'function') updateModalLoading(false)
                }
            }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                display: "flex",
                justifyContent: 'space-between',
                alignItems: 'center',
                outline: 'none'
            }}>
                <CircularProgress sx={{ outline: 'none', color: '#fff' }} />
            </Box>
        </Modal>
    )
}

