import React, { useState, useContext } from 'react';
import { useTheme, Box, Modal, Typography, TextField } from '@mui/material';
import { ModalInfo } from '../../../../../components/modalInfoN';
import { User } from '../../../../../contexts/user';
import { ButtonSubmit } from '../../../../../components/buttons';
import mainApi from '../../../../../apis/mainApiN';

export function ModalRevisão({ visible, dismiss, produto, tipo, funcReturn }) {
    const theme = useTheme();
    const user = useContext(User);
    //estados
    const [MInfo, setMInfo] = useState({
        visible: false,
        title: '',
        subtitle: '',
        canDesactive: true,
        icon: '',
        funcS: () => null
    });
    const [descricao, setDescricao] = useState('')

    function updateModalInfo(visible, canDesactive, title, subtitle, icon, func) {
        const newMInfo = { ...MInfo };
        newMInfo.visible = visible;
        newMInfo.canDesactive = canDesactive;
        newMInfo.title = title;
        newMInfo.subtitle = subtitle;
        newMInfo.icon = icon;
        newMInfo.funcS = func;
        setMInfo(newMInfo);
    }
    function handleText(value) {
        setDescricao(value)
    }
    function handleButton(type) {
        if (type === 'Sair') {
            handleDismiss()
            return
        }
        saveToRevisao()
    }
    async function saveToRevisao() {
        if (descricao.length < 5) {
            updateModalInfo(true, true, 'Atenção', 'Revisão precisa ter no mínimo 5 caracteres.', 'exclamation')
            return
        }
        const body = {
            cod_barra: produto.cod_barra,
            descricao: descricao,
            tipo: tipo
        }
        updateModalInfo(true, false, 'Atenção', 'Salvando revisão.', 'loading')
        const save = await mainApi('produtosBase/pendencias/saveToRevision', 'POST', body, user?.data?.token)
        if (save?.status !== 200) {
            const message = save?.apiReturn?.message ? save?.apiReturn?.message : 'Ocorreu um erro ao salvar pendência'
            updateModalInfo(true, true, 'Atenção', message, 'exclamation')
            return
        }
        handleDismiss()
        funcReturn()
    }

    function handleDismiss() {
        if (dismiss) {
            updateModalInfo(false)
            dismiss()
        }
    }

    return (
        <Modal open={visible} onClose={handleDismiss} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 2 }} slotProps={{
            backdrop: {
                sx: {
                    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(250,255,255, 0.2)' : 'rgba(0,0,0, 0.4)'
                }
            }
        }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', height: 300, width: 400, background: theme.palette.background.default, outline: 'none' }}>
                <Box sx={{ background: theme.palette.background.paper, padding: 2 }}>
                    <Typography sx={{ color: theme.palette.text.primary, fontWeight: 'bold' }}>Justifique a revisão</Typography>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', padding: 2, width: '100%', justifyContent: 'space-between', height: '100%' }}>
                    <TextField
                        multiline
                        rows={5}
                        value={descricao}
                        onChange={(value) => handleText(value.target.value)}
                        sx={{
                            '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                borderWidth: '1',
                                borderColor: theme.palette.divider
                            },
                            width: '100%',
                            color: theme.palette.text.primary
                        }}
                    />
                    <Box sx={{ display: 'flex', gap: 2 }}>
                        {['Salvar', 'Sair'].map((e) => {
                            const background = e === 'Salvar' ? theme.palette.primary.main : theme.palette.background.paper
                            const color = ['Salvar'].includes(e) ? theme.palette.text.whiteText : theme.palette.text.primary
                            return (
                                <ButtonSubmit key={e} text={e} background={background} color={color} func={handleButton} funcParameters={e} />
                            )
                        })}
                    </Box>
                </Box>
                <ModalInfo hideModal={() => updateModalInfo(false)} canDesactive={MInfo.canDesactive} visible={MInfo.visible} title={MInfo.title} subtitle={MInfo.subtitle} icon={MInfo.icon} funcS={MInfo.funcS} />
            </Box>
        </Modal>
    );
}