import React, { useState, useEffect } from "react";
import { Box, TextField, Button, Grid, FormControl, InputLabel, Select, MenuItem, Typography } from "@mui/material";
import { realMask } from "../../components/masks";
import { useTheme } from "@mui/material";
import moment from 'moment';
import SearchIcon from '@mui/icons-material/Search';

export default props => {
    const initialFilterCriteria = {
        campo: "id",
        valor: "",
        status: "ativo", // Adicionado status inicial
    };
    const [filterCriteria, setFilterCriteria] = useState(initialFilterCriteria);
    const theme = useTheme()

    const handleFilterChange = () => {
        props.filterData(filterCriteria)
    };
    const filterStyle = {
        backgroundColor: theme.palette.background.paper,
        padding: 3,
        borderRadius: "8px"
    };

    const selectStyle = {
        backgroundColor: theme.palette.background.default,
        minWidth: "30%",
    };

    const buttonStyle = {
        marginTop: "16px",
    };



    const handleInputChange = (e) => {
        const { name, value } = e.target;
        let formattedValue = value;

        if (name === "valor") {
            if (filterCriteria.campo === "valor") {
                formattedValue = realMask(value);
            } else if (filterCriteria.campo === "validade") {
                formattedValue = value
                    .replace(/\D/g, "")
                    .replace(/(\d{2})(\d{2})(\d{4})/, "$1/$2/$3")
                    .substring(0, 10);
            } else if (filterCriteria.campo === "id") {
                if (!/^\d*$/.test(value)) {
                    return;
                }
            }
        }

        setFilterCriteria({
            ...filterCriteria,
            [name]: formattedValue,
        });
    };

    const isCouponVencido = (validade) => {
        const today = moment();
        const validadeDate = moment(validade, 'DD/MM/YYYY');
        return validadeDate.isBefore(today);
    };

    useEffect(() => {
        setFilterCriteria(initialFilterCriteria);
    }, []);

    return (
        <Box sx={filterStyle}>
            <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                <Box sx={{ display: 'flex', gap: 5, width: '50%' }}>
                    {filterCriteria.campo === "status" ? (
                        <FormControl variant="outlined" sx={selectStyle}>
                            <InputLabel>Status</InputLabel>
                            <Select
                                name="status"
                                value={filterCriteria.status}
                                onChange={handleInputChange}
                                label="Status"
                            >
                                <MenuItem value="ativo">Ativo</MenuItem>
                                <MenuItem value="inativo">Inativo</MenuItem>
                                <MenuItem value="vencido">Vencido</MenuItem>
                            </Select>
                        </FormControl>
                    ) : (
                        <TextField
                            sx={{ backgroundColor: theme.palette.background.default, }}
                            label={
                                filterCriteria.campo === "id"
                                    ? "ID"
                                    : filterCriteria.campo === "validade"
                                        ? "Data de Validade (DD/MM/YYYY)"
                                        : filterCriteria.campo === "valor"
                                            ? "Valor (Números com 2 casas decimais)"
                                            : "Valor do Filtro"
                            }
                            variant="outlined"
                            name="valor"
                            value={filterCriteria.valor}
                            onChange={handleInputChange}
                        />
                    )}
                    <FormControl variant="outlined" sx={selectStyle}>
                        <InputLabel>Campo de Filtro</InputLabel>
                        <Select
                            name="campo"
                            value={filterCriteria.campo}
                            onChange={(e) => {
                                setFilterCriteria({
                                    campo: e.target.value,
                                    valor: "",
                                    status: "ativo", // Reiniciar status ao mudar o campo
                                });
                            }}
                            label="Campo de Filtro"
                        >
                            <MenuItem value="id">ID</MenuItem>
                            <MenuItem value="código">Código</MenuItem>
                            <MenuItem value="parceiro">Parceiro</MenuItem>
                            <MenuItem value="valor">Valor</MenuItem>
                            <MenuItem value="quantidade">Quantidade</MenuItem>
                            <MenuItem value="tipo">Tipo</MenuItem>
                            <MenuItem value="status">Status</MenuItem>
                            <MenuItem value="validade">Validade</MenuItem>
                        </Select>
                    </FormControl>
                    <Button
                        onClick={() => handleFilterChange()}
                        sx={{
                            backgroundColor: theme.palette.primary.main,
                            padding: 2,
                            width: 70,
                            display: 'flex',
                            gap: 1
                        }}
                    >
                        <SearchIcon fontSize='medium' sx={{ color: theme.palette.text.whiteText }} />
                    </Button>
                </Box>
                <Box>
                    <Button
                        onClick={() => props.openInside(null, null, 'info', 'new')}
                        sx={{
                            backgroundColor: theme.palette.primary.main,
                            padding: 2,
                            width: 70,
                            display: 'flex',
                            gap: 1
                        }}
                    >
                        {/* <SearchIcon fontSize='medium' sx={{ color: theme.palette.text.whiteText }} /> */}
                        <Typography sx={{ color: theme.palette.text.whiteText, textTransform: 'none' }}>Adicionar</Typography>
                    </Button>
                </Box>
            </Box>
        </Box>
    );
}
