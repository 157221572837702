import React, { useState, useContext, useEffect, useRef } from 'react';
import { useTheme, Box, Modal, Typography, IconButton, Badge } from '@mui/material';
import { ModalInfo } from '../../../../components/modalInfoN';
import { User } from '../../../../contexts/user';
import { ButtonSubmit } from '../../../../components/buttons';
import mainApi from '../../../../apis/mainApiN';
import { getIcon } from '../../../../components/icons';
import { UpComp } from '../../../../components/upComp';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

export function Mais({ visible, campanha, dismiss, updateMais }) {
    const theme = useTheme();
    const user = useContext(User);
    const fileInputRef = useRef(null);
    const [MInfo, setMInfo] = useState({
        visible: false,
        title: '',
        subtitle: '',
        canDesactive: true,
        icon: '',
        funcS: () => null
    });
    const [data, setData] = useState([]);
    const [hoverIndex, setHoverIndex] = useState(null);

    function updateModalInfo(visible, canDesactive, title, subtitle, icon, func) {
        setMInfo({ visible, canDesactive, title, subtitle, icon, funcS: func });
    }

    function handleButton(type) {
        if (type === 'Salvar') {
            saveImages()
            return
        }
        handleDismiss();
    }

    function handleDismiss() {
        setData([])
        updateMais(false)
    }

    const handleImageChange = (e) => {
        const files = e.target.files;
        if (files.length > 0) {
            Array.from(files).forEach(file => {
                if (file.type === 'image/png' || file.type === 'image/jpeg') {
                    const reader = new FileReader();
                    reader.onload = () => {
                        const base64String = reader.result;
                        setData(prevData => {
                            const maxId = prevData.length > 0 ? Math.max(...prevData.map(item => item.id)) : 0;
                            return [...prevData, { id: maxId + 1, link: null, base64: base64String }];
                        });
                    };
                    reader.readAsDataURL(file);
                } else {
                    alert('Por favor, selecione apenas arquivos PNG ou JPEG.');
                }
            });
        } else {
            alert('Por favor, selecione ao menos um arquivo PNG ou JPEG.');
        }
    };

    async function saveImages() {
        if (data.length === 0) {
            updateModalInfo(true, true, 'Atenção', 'Ao menos uma imagem deve ser selecionada', 'exclamation')
            return
        }
        updateModalInfo(true, false, 'Salvando', 'Estamos salvando suas imagens', 'loading')
        const body = {
            campanha: campanha.id,
            imagens: data
        }
        const tryS = await mainApi('farmaTouch/saveCampFranquiaImg', 'POST', body, user?.data?.token)
        if (tryS?.status !== 200) {
            const message = tryS?.apiReturn?.message ? tryS?.apiReturn?.message : 'Ocorreu um erro ao salvar as imagens.'
            updateModalInfo(true, true, 'Atenção', message, 'exclamation')
            return
        }
        handleDismiss()
        updateModalInfo(false)
    }
    const onDragEnd = (result) => {
        if (!result.destination) return;
        const newData = Array.from(data);
        const [reorderedItem] = newData.splice(result.source.index, 1);
        newData.splice(result.destination.index, 0, reorderedItem);

        setData(newData);
    };
    function handleMove(index, direction) {
        const newData = Array.from(data);
        const targetIndex = index + direction;
        if (targetIndex >= 0 && targetIndex < newData.length) {
            const [movedItem] = newData.splice(index, 1);
            newData.splice(targetIndex, 0, movedItem);
            setData(newData);
        }
    }

    async function getImages() {
        updateModalInfo(true, false, 'Carregando', 'Buscando imagens.', 'loading')
        const get = await mainApi(`farmaTouch/getCampFranquiaImg/${campanha.id}`, 'GET', null, user?.data?.token)
        if (get?.status !== 200) {
            const message = get?.apiReturn?.message ? get?.apiReturn?.message : 'Ocorreu um erro ao buscar as imagens.'
            updateMais(true, true, 'Atenção', message, 'exclamation')
            return
        }
        updateModalInfo(false)
        setData(get?.apiReturn?.apiReturn)
    }
    function removeImage(image) {
        const newData = data.filter((e) => e.id !== image.id)
        setData(newData)
    }

    useEffect(() => {
        if (visible) {
            getImages()
        }
    }, [visible])

    return (
        <Modal open={visible} onClose={handleDismiss} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 2 }} slotProps={{
            backdrop: {
                sx: {
                    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(250,255,255, 0.2)' : 'rgba(0,0,0, 0.4)'
                }
            }
        }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', height: 600, maxWidth: 1100, width: '100%', background: theme.palette.background.default, outline: 'none' }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', background: theme.palette.background.default, padding: 5, paddingBottom: 2 }}>
                    <Box>
                        <Typography sx={{ fontWeight: 'bold', color: theme.palette.text.primary, fontSize: 30 }}>Conteúdo da Campanha</Typography>
                        <Typography sx={{ color: theme.palette.text.primary, fontSize: 18 }}>Adicione e edite as imagens da campanha</Typography>
                    </Box>
                    <UpComp tamanho={50}>
                        <IconButton onClick={() => fileInputRef.current.click()}>
                            {getIcon(55, theme.palette.primary.main, 30)}
                        </IconButton>
                    </UpComp>
                </Box>
                <Box sx={{
                    display: 'flex', flexDirection: 'column',
                    padding: 5,
                    paddingTop: 0,
                    gap: 3,
                    height: '80%',
                }}>
                    <Box sx={{ height: '80%', overflowY: 'scroll' }}>
                        <DragDropContext onDragEnd={onDragEnd}>
                            <Droppable droppableId="images" direction="horizontal">
                                {(provided) => (
                                    <Box ref={provided.innerRef} {...provided.droppableProps} sx={{}}>
                                        {data.map((e, index) => (
                                            <Draggable key={e.id} draggableId={String(e.id)} index={index}>
                                                {(provided) => (
                                                    <Box
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        sx={{
                                                            position: 'relative',
                                                            display: 'inline-block',
                                                            margin: 1,
                                                            ':hover .controls': { display: 'flex' }
                                                        }}
                                                        onMouseEnter={() => setHoverIndex(index)}
                                                        onMouseLeave={() => setHoverIndex(null)}
                                                    >
                                                        <Badge badgeContent={<IconButton onClick={() => removeImage(e)} sx={{ background: '#f44336', fontSize: 11, color: theme.palette.text.whiteText, padding: '3px 6px' }}>x</IconButton>}>
                                                            <img src={e.link || e.base64} alt={`Imagem ${index + 1}`} style={{ width: 150, height: 'auto' }} />
                                                        </Badge>
                                                        {hoverIndex === index && (
                                                            <Box
                                                                className="controls"
                                                                sx={{
                                                                    position: 'absolute',
                                                                    top: 0,
                                                                    left: 0,
                                                                    right: 0,
                                                                    bottom: 0,
                                                                    display: 'none',
                                                                    justifyContent: 'space-between',
                                                                    alignItems: 'center',
                                                                    background: 'rgba(0, 0, 0, 0.5)',
                                                                    padding: 1
                                                                }}
                                                            >
                                                                <IconButton sx={{ color: 'white' }} onClick={() => handleMove(index, -1)}>&#8592;</IconButton>
                                                                <IconButton sx={{ color: 'white' }} onClick={() => handleMove(index, 1)}>&#8594;</IconButton>
                                                            </Box>
                                                        )}
                                                    </Box>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </Box>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </Box>
                    <Box sx={{ display: 'flex', gap: 2 }}>
                        {['Salvar', 'Sair'].map((e, idx) => {
                            const background = e === 'Salvar' ? theme.palette.primary.main : theme.palette.background.paper;
                            const color = e === 'Salvar' ? theme.palette.text.whiteText : theme.palette.text.primary;
                            return (
                                <ButtonSubmit key={idx} text={e} background={background} color={color} func={handleButton} funcParameters={e} />
                            );
                        })}
                    </Box>
                </Box>
                <input
                    type="file"
                    accept=".png, .jpg, .jpeg"
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    onChange={handleImageChange}
                    multiple
                />
                <ModalInfo hideModal={() => updateModalInfo(false)} canDesactive={MInfo.canDesactive} visible={MInfo.visible} title={MInfo.title} subtitle={MInfo.subtitle} icon={MInfo.icon} funcS={MInfo.funcS} />
            </Box>
        </Modal >
    );
}
