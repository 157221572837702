import { useContext, useEffect, useState } from "react"
import { User } from "./contexts/user"
import Auth from "./views/auth/auth"
import { Box, Typography, useTheme } from "@mui/material"
import { Routes, Route, useLocation } from 'react-router-dom'
import Home from "./views/home/home"
import Error from "./views/404/error"
import { SidHeader } from "./components/sidHeader"
import Produtos from "./views/produtos/produtos"
import Clientes from "./views/clientes/clientes"
import Entregas from "./views/entregas/entregas"
import Cupons from "./views/cupons/cupons"
import Usuarios from "./views/usuarios/usuarios"
import Metas from "./views/metas/metas"
import { ProdBase } from "./views/produtosBase/produtosBase"
import { TabelaLideres } from "./views/tabelaLideres/tabelaLideres"
import { Vendas } from "./views/vendas/vendas"
import { Parceiros } from "./views/parceiros/parceiros"
import { Pedidos } from "./views/pedidos/pedidos"
import { CampanhasFranquia } from "./views/farmaTouch/campanhasFranquia/campanhasFranquia"


export default props => {
    const [loading, setLoading] = useState(true)
    const theme = useTheme()
    const user = useContext(User)
    const location = useLocation();
    useEffect(() => {
        setTimeout(() => {
            setLoading(false)
        }, 2000)
    }, [])

    if (location.pathname === '/metas') return <Metas />
    if (loading === true) {
        document.title = 'Carregando - Central De Negócios'
        return (
            <Box
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}
            >
                <img src={theme.palette.mode === 'dark' ? "../assets/images/loading/Infinity-black.gif" : "../assets/images/loading/Infinity.gif"} />
                <Box>
                    <Typography variant="h3" fontWeight={'bold'}>FRANCHISING BRASIL FARMA</Typography>
                    <Typography variant="h5" fontWeight={'bold'}>Central de negócio</Typography>
                </Box>
            </Box>
        )
    }
    if (user?.data?.token) {
        return (
            <Box display="flex" width="100%" height="100%" sx={{
                overflow: 'scroll',
                '&::-webkit-scrollbar': {
                    background: theme.palette.background.paper,
                    width: 20
                },
                '&::-webkit-scrollbar-thumb': {
                    background: theme.palette.primary.main
                }
            }} >
                <div className="app" >
                    <main className="content">
                        <SidHeader permissoes={user?.data?.permissoes}>
                            <Routes>
                                {user?.data?.permissoes ? user?.data?.permissoes.map((e) => {
                                    if (Array.isArray(e?.inside)) {
                                        return e?.inside.map((el) => (
                                            <Route permissoes={user?.data?.permissoes} key={el?.id} nome={el?.nome} path={el?.endpoint} element={EndPointToUser(el?.endpoint, el?.nome, el?.descricao, el?.eventos)} />
                                        ))
                                    }
                                    return (
                                        <Route permissoes={user?.data?.permissoes} key={e?.id} nome={e?.nome} path={e?.endpoint} element={EndPointToUser(e?.endpoint, e.nome, e?.descricao, e.eventos)} />
                                    )
                                }) : null}
                            </Routes>
                        </SidHeader>
                    </main>
                </div >
            </Box>
        )
    }
    function EndPointToUser(endpoint, nome, descricao, eventos) {
        if (endpoint === '/entregas') return <Entregas nome={nome} subtitle={descricao} eventos={eventos} />
        if (endpoint === '/cupons') return <Cupons nome={nome} subtitle={descricao} eventos={eventos} />
        if (endpoint === '/pedidos') return <Pedidos nome={nome} subtitle={descricao} eventos={eventos} />
        if (endpoint === '/produtos') return <Produtos nome={nome} subtitle={descricao} eventos={eventos} />
        if (endpoint === '/clientes') return <Clientes nome={nome} subtitle={descricao} eventos={eventos} />
        if (endpoint === '/usuarios') return <Usuarios nome={nome} subtitle={descricao} eventos={eventos} />
        if (endpoint === '/franquia-produtos') return <ProdBase nome={nome} subtitle={descricao} eventos={eventos} />
        if (endpoint === '/tabela-lideres') return <TabelaLideres nome={nome} subtitle={descricao} eventos={eventos} />
        if (endpoint === '/vendas') return <Vendas nome={nome} subtitle={descricao} eventos={eventos} />
        if (endpoint === '/parceiros') return <Parceiros nome={nome} subtitle={descricao} eventos={eventos} />
        if (endpoint === '/farma-touch/campanhas-franquia') return <CampanhasFranquia nome={nome} subtitle={descricao} eventos={eventos} />
        return <Home />
    }

    return (
        <Box display="flex" width="100%" height="100%">
            <div className="app">
                <main className="content">
                    <Auth />
                </main>
            </div >
        </Box>
    )
}