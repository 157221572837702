import * as React from 'react';
import Box from '@mui/material/Box';
import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
import useWindowDimensions from '../../components/useWindowDimensions';
import { Button, Grid, TextField, Typography, Divider } from '@mui/material';
import { ColorModeContext } from '../../theme/theme';
import { ModalLoading } from '../../components/modal';
import mainApi from '../../apis/mainApi';
import { User } from '../../contexts/user';


// TODO remove, this demo shouldn't need to reset the theme.


export default function SignInSide() {
    document.title = 'Login - Central de Negócios'
    const [modal, setModal] = React.useState({
        visible: false
    })
    const [error, setError] = React.useState({
        visible: false,
        error: ''
    })
    var CryptoJS = require("crypto-js");
    const user = React.useContext(User)
    const screenHeight = useWindowDimensions().height
    const screenWidth = useWindowDimensions().width
    const colorMode = React.useContext(ColorModeContext)
    const theme = useTheme();
    function closeModal() {
        const x = { ...modal }
        x.visible = false
        setModal(x)
    }
    const handleSubmit = async (event) => {
        const x = { ...modal }
        x.visible = true
        setModal(x)
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const body = {
            email: data.get('email'),
            senha: CryptoJS.MD5(data.get('password')).toString(),
            new: true
        }
        if (['', null, undefined].includes(body.email) || ['', null, undefined].includes(body.senha)) {
            const t = { ...modal }
            t.visible = false
            setModal(t)
            const x = { ...error }
            x.visible = true
            x.error = 'Preencha todos os campos para continuar'
            setError(x)
            return
        }
        function xxx(callback) {
            if (callback.status === 200) {
                user.changeData(callback.apiReturn.apiReturn)
            }
            else {
                const t = { ...modal }
                t.visible = false
                setModal(t)
                const x = { ...error }
                x.visible = true
                x.error = 'Acesso não autorizado'
                setError(x)
            }
        }
        await mainApi('login', 'POST', body, xxx, null)
    };

    return (
        <Box sx={{ backgroundColor: theme.palette.background.default, height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', overflow: 'hidden' }}>
            {/* <HeaderAuth /> */}
            <Grid container alignItems={'center'} justifyContent={'center'} height={'100%'}>
                <Grid item lg={9} md={10} sm={0} justifyContent={'center'} alignItems={'center'} display={'flex'} sx={{
                    [theme.breakpoints.down('lg')]: {
                        display: 'none',
                    },
                    width: '100%',
                    height: '100%'
                }}>
                    <img src='../assets/images/auth/bg.jpg' style={{ objectFit: 'cover' }} height={'100%'} width={'100%'} />
                </Grid>
                <Grid item lg={3} md sx={{ borderLeft: 1, borderColor: theme.palette.background.paper }} display={'flex'} justifyContent={'center'} alignItems={'center'} >
                    <Box component="form" noValidate onSubmit={handleSubmit} sx={{ maxWidth: 320, backgroundColor: theme.palette.background.default, minHeight: 300, padding: 1, borderRadius: 2, gap: 30 }}>
                        <Box mb={2} display={'flex'} justifyContent={'center'} alignItems={'center'} flexDirection={'column'}>
                            <img src={theme.palette.logo} style={{ maxWidth: 200 }} />
                            <Typography variant='h4' textAlign={'center'} fontWeight={'bold'}>Central de Negócios</Typography>
                        </Box>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="E-mail"
                            name="email"
                            InputLabelProps={{
                                style: {
                                    color: theme.palette.text.secondary,
                                    borderColor: '#eee'
                                }
                            }}
                            autoComplete="email"
                            autoFocus
                            inputProps={{
                                style: {
                                    backgroundColor: theme.palette.background.paper,
                                    borderRadius: 5
                                }
                            }}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Senha"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            InputLabelProps={{
                                style: {
                                    color: theme.palette.text.secondary
                                }
                            }}
                            inputProps={{
                                style: {
                                    backgroundColor: theme.palette.background.paper,
                                    borderRadius: 5
                                }
                            }}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                            onClick={() => {
                                const x = { ...modal }
                                x.visible = true
                                setModal(x)
                            }}
                        >
                            <Typography fontWeight={'bold'} textTransform={'none'}>Entrar</Typography>
                        </Button>
                        {error.visible === true ?
                            <Box bgcolor={theme.palette.info.light} padding={2} borderRadius={1}>
                                {error.error}
                            </Box>
                            : null}
                    </Box>
                </Grid>
            </Grid>
            <ModalLoading closeModal={closeModal} ative={modal.visible} />
        </Box>
    );
}