import * as React from 'react';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { Box, Button, Divider, Typography, useTheme } from '@mui/material';
import { CTextField } from './TextField';

export function MenuList({ options, param, selected, title, icon, width, handleChange, disabled }) {
    const theme = useTheme()
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [selectedIndex, setSelectedIndex] = React.useState(1);
    const open = Boolean(anchorEl);
    const handleClickListItem = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuItemClick = (event, index) => {
        setSelectedIndex(index);
        setAnchorEl(null);
        if (handleChange) {
            handleChange(index, title)
        }
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <Box sx={{ width: width ? width : '100%' }}>
            <Box>
                <Button
                    disabled={disabled ? disabled : false}
                    onClick={handleClickListItem}
                    sx={{
                        borderWidth: 0,
                        borderBottom: `0.1px solid ${theme.palette.inverseBackground.default}`,
                        borderRadius: 1,
                        color: theme.palette.text.primary,
                        width: width ? width : '100%',
                        minHeight: 0, minWidth: 0, padding: 0,
                        '&:hover': {
                            backgroundColor: theme.palette.action.hover
                        },
                        textTransform: 'none'
                    }}>
                    <Box sx={{ display: 'flex', gap: 1, flexDirection: 'column', width: width ? width : '100%', justifyContent: 'flex-start' }}>
                        <Typography sx={{ fontSize: 10, textAlign: 'left' }}>{title}</Typography>
                        <Box sx={{ display: 'flex', gap: 1 }}>
                            {icon ? icon : null}
                            <Typography>{selected[param] ? selected[param] : ''}</Typography>
                        </Box>
                    </Box>
                </Button>
            </Box>
            <Menu
                id="lock-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'lock-button',
                    role: 'listbox',
                }}
            >
                {Array.isArray(options) ? options.map((option, index) => (
                    <>
                        <MenuItem
                            key={option[param]}
                            selected={index === selectedIndex}
                            onClick={(event) => handleMenuItemClick(event, index)}
                        >
                            {option[param]}
                        </MenuItem>
                        <Divider />
                    </>
                )) : null}
            </Menu>
        </Box >
    );
}