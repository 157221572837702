import React, { useEffect, useState, useContext } from "react";
import { useTheme } from '@mui/material/styles';
import { Box, Typography, Button, List, TextField, Menu, MenuItem, ListItemIcon, ListItemText, MenuList, Divider, Select } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { cpfMask } from "../../components/masks";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import SearchIcon from '@mui/icons-material/Search';
import mainApi from "../../apis/mainApi";
import { User } from "../../contexts/user";
export default props => {
    const theme = useTheme()
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [dataFilter, setDataFilter] = useState({
        type: 'nome',
        name: 'Nome',
        field: '',
    })
    const user = useContext(User)
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const updateParameter = (parameter) => {
        const newDataFilter = { ...dataFilter }
        newDataFilter.type = parameter.key
        newDataFilter.name = parameter.nome
        newDataFilter.field = ''
        setDataFilter(newDataFilter)
        handleClose()
    }
    async function filterData() {
        const newData = { ...props.data }
        newData.contentFilter = newData.content.filter((f) => {
            if (![null, undefined, ''].includes(f[dataFilter.type])) {
                if (typeof f[dataFilter.type] === 'number') {
                    f[dataFilter.type] = f[dataFilter.type].toString()
                }
                let newF = /\d/.test(f[dataFilter.type]) ? f[dataFilter.type].toLowerCase() : f[dataFilter.type].toLowerCase()
                let newValue = /\d/.test(dataFilter.field) ? dataFilter.field.toLowerCase() : dataFilter.field.toLowerCase()
                return newF.includes(newValue)
            }
        })
        if (newData.contentFilter.length === 0) {
            const modalInfo = {
                active: true,
                title: 'Atenção',
                subtitle: 'Não foram encontrados dados para sua pesquisa.',
                body:
                    <Box sx={{ display: 'flex', gap: 2 }}>
                        <Button onClick={() => props.resetModalInfo()} sx={{ mb: 2, backgroundColor: theme.palette.secondary.main, color: theme.palette.text.primary, '&:hover': { backgroundColor: theme.palette.secondary.dark }, borderRadius: 0, padding: 1 }}>
                            <Typography fontWeight={'bold'} sx={{ color: theme.palette.text.whiteText, textAlign: 'center' }}>Voltar</Typography>
                        </Button>
                    </Box>
            }
            props.updateModalInfo(modalInfo)
            return
        }
        props.updateData(newData)
    }
    const handleSelectChange = (event) => {
        props.getData(event.target.value)
    };
    function validaSelect(value, options, parameter) {
        const findIndex = options.findIndex((e) => e[parameter] === value)
        return findIndex === -1 ? '' : value
    }
    return (
        <Box sx={{
            width: '100%', borderRadius: "8px", padding: 3, display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 5, backgroundColor: theme.palette.background.paper, [theme.breakpoints.down('md')]: {
                overflow: 'scroll', maxWidth: '100%'
            },
        }}>
            <Box sx={{
                display: 'flex',
                gap: 2
            }}>
                <Box>
                    <Select
                        value={validaSelect(props.data.activeBandeira, props.data.bandeiras, 'id')}
                        onChange={handleSelectChange}
                        sx={{
                            width: '200px',
                            backgroundColor: theme.palette.background.default,
                            borderRadius: '4px',
                        }}
                    >
                        {props.data.bandeiras.map((bandeira) => (
                            <MenuItem key={bandeira.id} value={bandeira.id}>
                                {bandeira.nome}
                            </MenuItem>
                        ))}
                    </Select>
                </Box>
                <Box>
                    <TextField
                        fullWidth
                        margin="none"
                        required
                        value={dataFilter.field}
                        id="pesquisa"
                        name="pesquisa"
                        label="Pesquisar"
                        onChange={(value) => {
                            const x = { ...dataFilter }
                            x.field = x.type === 'cpf' ? cpfMask(value.target.value) : value.target.value
                            setDataFilter(x)
                        }}
                        /* onKeyDown={(event) => {
                            if (event.key == 'Enter') {
                                props.filterData(filters.form)
                            }
                        }} */
                        sx={{ maxWidth: 200, backgroundColor: theme.palette.background.default, }}
                        InputLabelProps={{
                            style: {
                                color: theme.palette.text.secondary,
                                borderColor: '#eee'
                            }
                        }}
                        autoComplete=""
                    />
                </Box>
                {props.headers ?
                    <Box>
                        <Select
                            value={dataFilter.type} // Valor selecionado
                            sx={{
                                width: '200px',
                                backgroundColor: theme.palette.background.default,
                                borderRadius: '4px',
                            }} // Estilo do Select
                        >
                            {props.headers?.map((e, idx) => {
                                if (['compras', 'info', 'condicoes'].includes(e.key)) {
                                    return null;
                                }

                                return (
                                    <MenuItem key={idx} value={e.key} onClick={() => updateParameter(e)}>
                                        {e.nome}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </Box>
                    : null}
                <Button
                    onClick={() => filterData()}
                    sx={{
                        backgroundColor: theme.palette.primary.main,
                        padding: 2,
                        width: 50,
                        display: 'flex',
                        gap: 1
                    }}
                >
                    <SearchIcon fontSize='medium' sx={{ color: theme.palette.text.whiteText }} />
                    {/* <Typography sx={{ color: theme.palette.text.whiteText, textTransform: 'none' }}>Buscar</Typography> */}
                </Button>
            </Box>
            <Button
                onClick={() => props.openInside(null, null, 'inside', 'new')}
                sx={{
                    backgroundColor: theme.palette.primary.main,
                    padding: 2,
                    width: 70,
                    display: 'flex',
                    gap: 1
                }}
            >
                {/* <SearchIcon fontSize='medium' sx={{ color: theme.palette.text.whiteText }} /> */}
                <Typography sx={{ color: theme.palette.text.whiteText, textTransform: 'none' }}>Adicionar</Typography>
            </Button>
        </Box>
    )
}