import React from "react";
import { useTheme } from "@mui/material/styles";
import { Box, Typography, Badge, Button } from "@mui/material";
import moment from "moment";

export default props => {
    const theme = useTheme();
    const today = moment(); // Obter a data atual

    return (
        <Box
            sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                gap: 5,
            }}
        >
            <Box sx={{ display: "flex", gap: 10, flexWrap: "wrap" }}>
                {props.data.map((e, idx) => {
                    const isAtivo = e.status === 1;
                    const isVencido = moment(e.validade, "YYYY-MM-DD").isBefore(today);

                    let couponBorderColor = "";

                    if (isVencido) {
                        couponBorderColor = theme.palette.error.main; // Cupom inativo
                    } else if (!isAtivo) {
                        couponBorderColor = theme.palette.warning.main; // Cupom vencido
                    } else {
                        couponBorderColor = theme.palette.inverseBackground.default; // Cupom ativo
                    }

                    const badgeContent = isVencido ? "Vencido" : isAtivo ? "Ativo" : "Inativo";
                    const badgeColor = isVencido ? "error" : isAtivo ? "primary" : "warning";

                    const couponStyle = {
                        border: `2px dashed ${couponBorderColor}`,
                        padding: "20px",
                        width: "250px",
                        textAlign: "center",
                        backgroundColor: theme.palette.background.paper,
                        borderRadius: "10px",
                    };

                    const titleStyle = {
                        fontSize: "1.5rem",
                        fontWeight: "bold",
                        margin: "10px 0",
                    };

                    const detailStyle = {
                        fontSize: "1rem",
                        margin: "5px 0",
                    };

                    return (
                        <Badge key={idx} badgeContent={badgeContent} color={badgeColor}>
                            <Button className="removeDefault" onClick={() => props.openInside(e, null, 'inside')}>
                                <Box
                                    style={couponStyle}
                                    key={e.id}
                                    className={`coupon-card ${isAtivo ? "active" : isVencido ? "expired" : "inactive"}`}
                                >
                                    <Typography sx={titleStyle}>{e?.tipo?.nome}</Typography>
                                    <Box sx={detailStyle}>ID: {e?.id}</Box>
                                    <Box sx={detailStyle}>Aplicação: {e?.aplicacao_nome}</Box>
                                    <Box sx={detailStyle}>Código: {e?.codigo}</Box>
                                    {e?.parceiro?.id && (
                                        <Box sx={detailStyle}>Parceiro: {e?.parceiro.nome}</Box>
                                    )}
                                    <Box sx={detailStyle}>
                                        Validade: {moment(e?.validade, "YYYY-MM-DD").format("DD/MM/YYYY")}
                                    </Box>
                                    <Box sx={detailStyle}>Valor: {e?.valor}</Box>
                                    <Box sx={detailStyle}>Quantidade: {e?.quantidade}</Box>
                                    <Box sx={detailStyle}>Disponível: {e?.quantidade - e?.usados}</Box>
                                </Box>
                            </Button>
                        </Badge>
                    );
                })}
            </Box>
        </Box>
    );
}
