import React, { useState, useContext, useEffect } from 'react';
import { useTheme, Box, Modal, Typography, IconButton, Switch, Collapse, TextField, Tooltip } from '@mui/material';
import { ModalInfo } from '../../../components/modalInfoN';
import { User } from '../../../contexts/user';
import mainApi from '../../../apis/mainApiN';
import { CTextField, TextEditor } from '../../../components/TextField';
import { getIcon } from '../../../components/icons';
import { MenuList } from '../../../components/menu';
import { controladosClasse, controladosLista } from '../../../components/controlados';
import { ModalClass } from './ecomView/ModalClass';
import { UserAvatar } from '../../../components/avatar';
import { UpComp } from '../../../components/upComp';
import { ButtonSubmit } from '../../../components/buttons';

export function EcomView({ visible, prevData, handleEcomInfo, updateProduto, isPendEcom }) {
    const theme = useTheme();
    const user = useContext(User);
    //estados
    const [MInfo, setMInfo] = useState({
        visible: false,
        title: '',
        subtitle: '',
        canDesactive: true,
        icon: '',
        funcS: () => null
    });
    const [data, setData] = useState(dataInit)
    const [descricao, setDescricao] = useState({
        init: '',
        value: ''
    })
    const [modalClassParam, setModalClassParam] = useState({
        visible: false,
        tipo: null
    })

    function updateModalInfo(visible, canDesactive, title, subtitle, icon, func) {
        const newMInfo = { ...MInfo };
        newMInfo.visible = visible;
        newMInfo.canDesactive = canDesactive;
        newMInfo.title = title;
        newMInfo.subtitle = subtitle;
        newMInfo.icon = icon;
        newMInfo.funcS = func;
        setMInfo(newMInfo);
    }
    function handleDismiss() {
        updateDesc('', '')
        setData(dataInit)
        handleEcomInfo(false)
    }
    async function getData() {
        if (!prevData?.cod_barra) {
            updateModalInfo(true, true, 'Atenção', 'Código de barras não encontrado.', 'exclamation')
            return
        }
        const newData = { ...data }
        newData.cod_barra = prevData?.cod_barra
        newData.name = prevData?.name
        newData.nom_grupo = prevData?.nom_grupo
        newData.nom_categ = prevData?.nom_categ
        newData.image.base64 = null
        newData.image.link = null
        if (prevData?.image) {
            newData.image.link = prevData?.image
        }
        let produto = prevData?.ecomInfo ? prevData?.ecomInfo : null
        if (!produto) {
            updateModalInfo(true, false, 'Atenção', 'Buscando dados', 'loading')
            const get = await mainApi(`produtosBase/getEcomDataProducts/${prevData?.cod_barra}`, 'GET', null, user?.data?.token)
            if (get?.status !== 200) {
                const message = get?.apiReturn?.message ? get?.apiReturn?.message : 'Ocorreu um erro ao buscar os parâmetros.'
                updateModalInfo(true, true, 'Atençao', message, 'exclamation')
                setData(newData)
                updateDesc('', '')
                return
            }
            produto = get?.apiReturn?.apiReturn
        }
        updateModalInfo(false)
        newData.grupo = produto.grupo
        newData.categoria = produto.categoria
        newData.departamento = produto.departamento
        newData.marca = produto.marca
        newData.fabricante = produto.fabricante
        newData.princ_ativo = produto.princ_ativo
        newData.dosagem = produto.dosagem
        newData.peso = produto.peso
        newData.quantidade = produto.quantidade
        newData.ms = produto.ms
        newData.controlado.isControlado = produto.controlado.isControlado
        newData.controlado.classe = produto.controlado.classe ? produto.controlado.classe : newData.controlado.classe
        newData.controlado.lista = produto.controlado.lista ? produto.controlado.lista : newData.controlado.lista
        newData.descricao = produto.descricao
        const newPrevProduto = { ...prevData }
        newPrevProduto.ecomInfo = newData
        updateProduto(newPrevProduto)
        updateDesc(produto.descricao, produto.descricao)
        setData(newData)
    }
    function updateDesc(init, value) {
        const newDesc = { ...descricao }
        newDesc.init = init ? init : newDesc.init
        newDesc.value = value ? value : newDesc.value
        setDescricao(newDesc)
    }
    function changeMenuControl(index, title) {
        const newData = { ...data }
        const whatData = title === 'classe' ? controladosClasse : controladosLista
        newData.controlado[title] = whatData[index]
        if (newData.controlado.classe.cod === 1) {
            newData.controlado.lista = { cod: null, nome: '' }
        }
        setData(newData)
    }
    function handleChangeSwitch() {
        const newData = { ...data }
        newData.controlado.isControlado = !newData.controlado.isControlado
        setData(newData)
    }
    function updateModalClassParam(visible, tipo) {
        const newM = { ...modalClassParam }
        newM.visible = visible ? visible : false
        newM.tipo = tipo ? tipo : null
        setModalClassParam(newM)
    }
    function changeData(value, type) {
        if (modalClassParam.visible) {
            updateModalClassParam()
        }
        const newData = { ...data }
        newData[type] = value
        setData(newData)
    }
    function handleAvatar(base64) {
        const newData = { ...data }
        newData.image.base64 = base64
        setData(newData)
    }
    async function sincImage() {
        updateModalInfo(true, false, 'Carregando', 'Buscando imagem no servidor.', 'loading')
        const get = await mainApi(`produtosBase/buscarImagem/${prevData?.cod_barra}`, 'GET', null, user?.data?.token)
        const image = get?.apiReturn?.apiReturn?.base64
        if (get?.status !== 200 || !image) {
            const message = get?.apiReturn?.message ? get?.apiReturn?.message : 'Ocorreu um erro ao buscar a imagem.'
            updateModalInfo(true, true, 'Atenção', message, 'exclamation')
            return
        }
        const newImage = 'data:image/jpeg;base64,' + image
        updateModalInfo(false)
        const newData = { ...data }
        newData['image'].base64 = newImage
    }

    async function saveData() {
        let image = data.image.base64 ? data.image.base64 : data.image.link
        if (!image) {
            updateModalInfo(true, true, 'Atenção', 'O produto precisa ter uma imagem vinculada pra estar ativo no e-commerce.', 'exclamation')
            return
        }
        if (!data.name) {
            updateModalInfo(true, true, 'Atenção', 'O produto precisa ter um nome pra estar ativo no e-commerce.', 'exclamation')
            return
        }
        if (!data.grupo.id) {
            updateModalInfo(true, true, 'Atenção', 'O produto precisa ter um grupo pra estar ativo no e-commerce.', 'exclamation')
            return
        }
        const isBase64 = data.image.base64 ? true : false
        if (isBase64) {
            image = image.includes('data:image/jpeg;base64,') ? image.replace('data:image/jpeg;base64,', '') : image
        }
        const body = {
            cod_barra: data.cod_barra,
            image: {
                isBase64: isBase64,
                image: image
            },
            grupo: data.grupo.id,
            categ: data.categoria.id,
            depto: data.departamento.id,
            fabricante: data.fabricante.id,
            marca: data.marca.id,
            princ_ativo: data.princ_ativo.id,
            dosagem: data.dosagem,
            quantidade: data.quantidade,
            peso: data.peso,
            descricao: descricao.value,
            ms: data.ms,
            isControlado: data.controlado.isControlado,
        }
        if (isPendEcom) {
            body.isPendEcom = isPendEcom
        }
        if (data.controlado.isControlado) {
            body.classe = data.controlado.classe.nome
            body.lista = data.controlado.lista.nome
        }
        updateModalInfo(true, false, 'Carregando', 'Salvando dados no servidor.', 'loading')
        const trySave = await mainApi('produtosBase/saveEcomData', 'POST', body, user?.data?.token)
        if (trySave?.status !== 200) {
            const message = trySave?.apiReturn?.message ? trySave?.apiReturn?.message : 'Ocorreu um erro ao salvar o produto.'
            updateModalInfo(true, true, 'Atenção', message, 'exclamation')
            return
        }
        const newPrevData = { ...prevData }
        newPrevData.ecomStatus = 1
        newPrevData.image = trySave?.apiReturn?.apiReturn?.image
        updateProduto(newPrevData, true)
        updateModalInfo(true, true, 'Atenção', 'Dados salvos com sucesso', 'sucess')
    }
    function changeDescricao(value) {
        updateDesc(null, value)
    }
    function handleButton(type) {
        if (type === 'Sair') {
            handleDismiss()
            return
        }
        saveData()
    }

    useEffect(() => {
        if (visible === true) {
            getData()
        }
    }, [visible])

    return (
        <Modal open={visible} onClose={handleDismiss} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 2 }} slotProps={{
            backdrop: {
                sx: {
                    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(250,255,255, 0.2)' : 'rgba(0,0,0, 0.4)'
                }
            }
        }}>
            <Box sx={{
                display: 'flex', flexDirection: 'column', height: '95%', width: '80%', background: theme.palette.background.default, outline: 'none',
                [theme.breakpoints.down('lg')]: {
                    width: '98%'
                },
            }}>
                <Box sx={{ background: theme.palette.background.paper, padding: 5 }}>
                    <Typography sx={{ fontWeight: 'bold', color: theme.palette.text.primary, fontSize: 30 }}>Produto e-commerce</Typography>
                    <Typography sx={{ color: theme.palette.text.primary, fontSize: 18 }}>Preencha as informações a baixo para editar o produto.</Typography>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, padding: 5, overflowY: 'scroll', overflowX: 'hidden', height: '100%' }}>
                    <Box sx={{ display: 'flex', alignSelf: 'center', justifyContent: 'center', alignItems: 'center', gap: 2 }}>
                        <UserAvatar icon={getIcon(47, theme.palette.mode === 'dark' ? theme.palette.text.primary : theme.palette.primary.main, 80)} avatar={data?.image.link} avatarBase64={data.image.base64} editable={true} funcReturn={handleAvatar} />
                        <UpComp tamanho={40}>
                            <Tooltip title='Buscar Imagem' placement='right'>
                                <IconButton onClick={sincImage}>
                                    {getIcon(59)}
                                </IconButton>
                            </Tooltip>
                        </UpComp>
                    </Box>
                    <Box>
                        <Typography sx={{ fontWeight: 'bold', color: theme.palette.text.primary }}>Informações</Typography>
                        <Box sx={{ display: 'flex', width: '100%', flexWrap: 'wrap', columnGap: 2, rowGap: 2 }}>
                            {Object.keys(data).map((e) => {
                                if (!['cod_barra', 'name', 'nom_grupo', 'nom_categ'].includes(e)) return
                                return (
                                    <Box sx={{ display: 'flex', width: '30%', gap: 2, minWidth: 300, }}>
                                        <CTextField value={data[e]} label={nameToNome(e)} disabled={true} width={'92%'} />
                                    </Box>
                                )
                            })}
                        </Box>
                    </Box>
                    <Box>
                        <Typography sx={{ fontWeight: 'bold', color: theme.palette.text.primary }}>Classificação</Typography>
                        <Box sx={{ display: 'flex', width: '100%', flexWrap: 'wrap', columnGap: 2, rowGap: 2 }}>
                            {Object.keys(data).map((e) => {
                                if (!['grupo', 'categoria', 'departamento', 'fabricante', 'marca', 'princ_ativo'].includes(e)) return
                                return (
                                    <Box sx={{ display: 'flex', width: '30%', gap: 2, minWidth: 300, }}>
                                        <CTextField value={data[e].nome} label={nameToNome(e)} disabled={true} width={'92%'} />
                                        <IconButton sx={{ background: theme.palette.background.paper, borderRadius: 2, width: '10%' }} onClick={() => updateModalClassParam(true, e)}>
                                            {getIcon(53, theme.palette.text.primary)}
                                        </IconButton>
                                    </Box>
                                )
                            })}
                        </Box>
                    </Box>
                    <Box>
                        <Typography sx={{ fontWeight: 'bold', color: theme.palette.text.primary }}>Informações Adicionais</Typography>
                        <Box sx={{ display: 'flex', width: '100%', flexWrap: 'wrap', columnGap: 2, rowGap: 2 }}>
                            {Object.keys(data).map((e) => {
                                if (!['dosagem', 'quantidade', 'peso', 'ms'].includes(e)) return
                                return (
                                    <Box sx={{ display: 'flex', width: '30%', gap: 2, minWidth: 300, }}>
                                        <CTextField prevData={data} onChangeText={changeData} type={e} value={data[e]} label={nameToNome(e)} disabled={false} width={'92%'} />
                                    </Box>
                                )
                            })}
                        </Box>
                    </Box>
                    <Box>
                        <Box sx={{ display: 'flex', gap: 3, alignItems: 'center' }}>
                            <Typography sx={{ fontWeight: 'bold', color: theme.palette.text.primary }}>Controlado?</Typography>
                            <Switch onChange={handleChangeSwitch} checked={data.controlado.isControlado} />
                        </Box>
                        <Collapse in={data.controlado.isControlado}>
                            <Box sx={{ display: 'flex', width: '100%', flexWrap: 'wrap', columnGap: 2, rowGap: 2 }}>
                                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                                    <MenuList icon={getIcon(53)} handleChange={changeMenuControl} width={300} title={'classe'} options={controladosClasse} param={'nome'} selected={data.controlado.classe} />
                                </Box>
                                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                                    <MenuList icon={getIcon(53)} handleChange={changeMenuControl} disabled={data.controlado.classe.cod && data.controlado.classe.cod === 2 ? false : true} width={300} title={'lista'} options={controladosLista} param={'nome'} selected={data.controlado.lista} />
                                </Box>
                            </Box>
                        </Collapse>
                    </Box>
                    <Box>
                        <Typography sx={{ fontWeight: 'bold', color: theme.palette.text.primary }}>Descrição</Typography>
                        <TextEditor initialValue={descricao.init} changeDescricao={changeDescricao} />
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', gap: 2, padding: 5 }}>
                    {['Salvar', 'Sair'].map((e) => {
                        const background = e === 'Salvar' ? theme.palette.primary.main : theme.palette.background.paper
                        const color = e === 'Salvar' ? theme.palette.text.whiteText : theme.palette.text.primary
                        return (
                            <ButtonSubmit key={e} text={e} background={background} color={color} func={handleButton} funcParameters={e} />
                        )
                    })}
                </Box>
                <ModalClass grupo={data.grupo} categoria={data.categoria} changeData={changeData} visible={modalClassParam.visible} dismiss={updateModalClassParam} tipo={modalClassParam.tipo} nameToNome={nameToNome} />
                <ModalInfo hideModal={() => updateModalInfo(false)} canDesactive={MInfo.canDesactive} visible={MInfo.visible} title={MInfo.title} subtitle={MInfo.subtitle} icon={MInfo.icon} funcS={MInfo.funcS} />
            </Box>
        </Modal>
    );
}

const dataInit = {
    image: {
        link: '',
        base64: ''
    },
    cod_barra: '',
    name: '',
    nom_grupo: '',
    nom_categ: '',
    dosagem: '',
    quantidade: '',
    peso: '',
    ms: '',
    grupo: {
        id: '',
        nome: ''
    },
    categoria: {
        id: '',
        nome: ''
    },
    departamento: {
        id: '',
        nome: ''
    },
    fabricante: {
        id: '',
        nome: ''
    },
    marca: {
        id: '',
        nome: ''
    },
    princ_ativo: {
        id: '',
        nome: ''
    },
    descricao: '',
    controlado: {
        isControlado: false,
        classe: {
            cod: '',
            nome: ''
        },
        lista: {
            cod: '',
            nome: ''
        },
    }
}
const parametrosInit = {
    grupos: [],
    categorias: [],
    departamentos: [],
    fabricantes: [],
    marcas: [],
    princ_ativos: []
}

function nameToNome(type) {
    const nomes = {
        cod_barra: 'Código de Barras',
        name: 'Nome',
        nom_grupo: 'Grupo EDI',
        nom_categ: 'Categoria EDI',
        grupo: 'Grupo',
        categoria: 'Categoria',
        departamento: 'Departamento',
        fabricante: 'Fabricante',
        marca: 'Marca',
        princ_ativo: 'Princípio Ativo',
        dosagem: 'Dosagem',
        quantidade: 'Quantidade',
        peso: 'Peso',
        descricao: 'Descrição',
        nome: 'Nome',
        ediGrupo: 'Grupo EDI',
        ediCateg: 'Categoria EDI',
        ms: 'MS'
    }
    if (nomes.hasOwnProperty(type)) return nomes[type]
    return ''
}