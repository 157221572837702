import React, { useEffect, useState, useContext } from "react";
import { useTheme } from '@mui/material/styles';
import { Box, Typography, Button, List, TextField } from '@mui/material';
import mainApi from "../../apis/mainApi";
import { User } from "../../contexts/user";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css/navigation';
import 'swiper/css';
import 'swiper/css/pagination';
import useWindowDimensions from "../../components/useWindowDimensions";
export default props => {
    const user = useContext(User)
    const theme = useTheme()
    const width = useWindowDimensions().width
    const [control, setControl] = useState(0)
    useEffect(() => {
        setControl(control + 1)
    }, [props.data.content])
    return (
        <Box sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            gap: 5
        }}>
            {props?.data?.content ? props.data.content.map((e) => {
                return (
                    <Box key={e.aplicacao.id} sx={{
                        backgroundColor: theme.palette.background.paper,
                    }}>
                        <Box sx={{
                            display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 1, backgroundColor: theme.palette.background.paper, borderBottom: 1, borderColor: theme.palette.background.default,
                            [theme.breakpoints.down('md')]: {
                                padding: 0.4
                            },
                        }}>
                            <Box sx={{ display: 'flex', gap: 2 }}>
                                <Box sx={{ border: 2, borderColor: theme.palette.background.default, padding: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <img src={"../assets/images/aplicacoes/logo/" + e.aplicacao.id + ".png"} style={{ objectFit: 'contain', width: 25 }} />
                                </Box>
                                <Typography variant="h2" sx={{ fontWeight: 'bold', padding: 3 }}>{e.aplicacao.nome}</Typography>
                            </Box>
                            <Button
                                onClick={() => {
                                    const newData = { ...props.data }
                                    newData.openData.open = true
                                    newData.openData.edit = 1
                                    newData.openData.content.aplicacao = e.aplicacao.id
                                    props.updateData(newData)
                                }}
                                sx={{
                                    backgroundColor: theme.palette.primary.main, color: theme.palette.text.whiteText, '&:hover': { backgroundColor: theme.palette.secondary.rgba, color: theme.palette.text.primary }, borderRadius: 1, padding: 1, height: 60, marginRight: 5,
                                    [theme.breakpoints.down('md')]: {
                                        padding: 0.4
                                    },
                                }}
                            >
                                <Typography fontWeight={'bold'}>Adicionar</Typography>
                            </Button>
                        </Box>
                        <Box sx={{ padding: 5 }}>
                            <Swiper
                                resizeObserver
                                slidesPerView={1}
                                modules={{ Pagination, Navigation }}
                                style={{
                                    width: width / 1.5,
                                    backgroundColor: theme.palette.background.default,
                                }}
                                autoHeight
                                breakpoints={{
                                    700: {
                                        slidesPerView: 2,
                                    },
                                    1500: {
                                        slidesPerView: 4,
                                    }
                                }}
                            >
                                <Box sx={{
                                    padding: 2
                                }}>
                                    {e.produtos.map((p, idx) => (
                                        <SwiperSlide key={idx}>
                                            <Button
                                                sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%', padding: 0 }}
                                                onClick={() => {
                                                    const newData = { ...props.data }
                                                    newData.openData = {
                                                        open: true,
                                                        edit: 2,
                                                        content: p
                                                    }
                                                    props.updateData(newData)
                                                }}
                                            >
                                                <Box sx={{ width: '100%', borderRight: 1, padding: 3, borderColor: theme.palette.background.paper }}>
                                                    <Typography fontWeight={'bold'} sx={{ color: theme.palette.text.primary }}>{p.nome}</Typography>
                                                    <img src={p.image + '?t=' + new Date().getTime()} style={{ objectFit: 'contain', width: 100 }} />
                                                    <Box sx={{ background: theme.palette.primary.main, padding: 1 }}>
                                                        <Box sx={{ display: 'flex', gap: 1 }}>
                                                            <Typography fontWeight={'bold'} sx={{ color: theme.palette.text.whiteText }}>{'Quantidade:'}</Typography>
                                                            <Typography sx={{ color: theme.palette.text.whiteText }}>{p.quantidade}</Typography>
                                                        </Box>
                                                        <Box sx={{ display: 'flex', gap: 1 }}>
                                                            <Typography fontWeight={'bold'} sx={{ color: theme.palette.text.whiteText }}>{'Preço:'}</Typography>
                                                            <Typography sx={{ color: theme.palette.text.whiteText }}>{'R$' + p.preco}</Typography>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </Button>
                                        </SwiperSlide>
                                    ))}
                                </Box>
                            </Swiper>
                        </Box>
                    </Box>
                )
            }) : null}
        </Box>
    )
}