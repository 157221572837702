import React, { useState, useContext } from 'react';
import { useTheme, Box, Modal, Typography, Button, IconButton, Icon } from '@mui/material';
import { ModalInfo } from '../../../../components/modalInfoN';
import { User } from '../../../../contexts/user';
import { getIcon } from '../../../../components/icons';
import { CTextField } from '../../../../components/TextField';
import mainApi from '../../../../apis/mainApiN';
import { ButtonSubmit } from '../../../../components/buttons';

export function ModalClass({ visible, dismiss, tipo, nameToNome, changeData, grupo, categoria }) {
    const theme = useTheme();
    const user = useContext(User);
    //estados
    const [MInfo, setMInfo] = useState({
        visible: false,
        title: '',
        subtitle: '',
        canDesactive: true,
        icon: '',
        funcS: () => null
    });
    const [nome, setNome] = useState('')
    const [data, setData] = useState([])
    const [modalNew, setModalNew] = useState(false)
    const [newText, setNewText] = useState('')
    function updateModalInfo(visible, canDesactive, title, subtitle, icon, func) {
        const newMInfo = { ...MInfo };
        newMInfo.visible = visible;
        newMInfo.canDesactive = canDesactive;
        newMInfo.title = title;
        newMInfo.subtitle = subtitle;
        newMInfo.icon = icon;
        newMInfo.funcS = func;
        setMInfo(newMInfo);
    }
    function handleDismiss() {
        setNome('')
        setData([])
        dismiss()
    }
    function handleChangeText(value) {
        setNome(value)
    }
    function OnKeyPressTF(value) {
        if (value.key === 'Enter') {
            getDados()
        }
    }
    function handleButton(type) {
        if (type === 'Sair') {
            setModalNew(false)
            return
        }
        insertNewClass()
    }

    async function insertNewClass() {
        if (newText.length <= 3) {
            updateModalInfo(true, true, 'Atenção', 'Nome precisa ter no mínimo 3 caracteres.', 'exclamation')
            return
        }
        const body = {
            type: tipo,
            nome: newText
        }
        if (tipo === 'categoria' && grupo.id) {
            body.grupo = grupo.id
        }
        if (tipo === 'departamento' && categoria.id) {
            body.categoria = categoria.id
        }
        const trySave = await mainApi('produtosBase/newClass', 'POST', body, user?.data?.token)
        if (trySave?.status !== 200) {
            const message = trySave?.apiReturn?.message ? trySave?.apiReturn?.message : 'Ocorreu um erro na requisição.'
            updateModalInfo(true, true, 'Atenção', message, 'exclamation')
            return
        }
        const id = trySave?.apiReturn?.apiReturn
        const newData = [...data]
        const tipoNome = tipo === 'categoria' && grupo.nome ? grupo.nome : tipo === 'departamento' && categoria.nome ? categoria.nome : 'Novo'
        newData.push({
            main_nome: tipoNome,
            data: [{
                id: id,
                nome: newText,
                main_nome: ''
            }]
        }

        )
        setData(newData)
        setNewText('')
        setModalNew(false)
        updateModalInfo(true, true, 'Atenção', 'Classificação criada com sucesso.', 'sucess')
    }

    async function getDados() {
        const body = {
            type: tipo,
            nome: nome
        }
        updateModalInfo(true, false, 'Carregando', 'Buscando dados.', 'loading')
        const get = await mainApi('produtosBase/getEcomParametersSearch', 'POST', body, user?.data?.token)
        if (get?.status !== 200) {
            const message = get?.apiReturn?.message ? get?.apiReturn?.message : 'Ocorreu um erro ao buscar os dados.'
            updateModalInfo(true, true, 'Atenção', message, 'exclamation')
            return
        }
        const apiReturn = get?.apiReturn?.apiReturn
        const newD = []
        apiReturn.map((e) => {
            e.main_nome = e.main_nome ? e.main_nome : 'Todos'
            const findIndex = newD.findIndex((b) => b.nome === e.main_nome)
            if (findIndex === -1) {
                newD.push({
                    nome: e.main_nome,
                    data: [e]
                })
            }
            else {
                newD[findIndex].data.push(e)
            }
        })
        setData(newD)
        updateModalInfo(false)
    }
    function handleSelect(value) {
        handleDismiss()
        changeData(value, tipo)
    }
    function handleChangeNewText(value) {
        setNewText(value)
    }
    return (
        <Modal open={visible} onClose={handleDismiss} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 2 }} slotProps={{
            backdrop: {
                sx: {
                    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(250,255,255, 0.2)' : 'rgba(0,0,0, 0.4)'
                }
            }
        }}>
            <Box sx={{
                display: 'flex', flexDirection: 'column', height: 500, width: '95%', maxWidth: 600, background: theme.palette.background.default, outline: 'none',
                [theme.breakpoints.down('lg')]: {
                    width: '98%'
                },
            }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: 5, paddingBottom: 0 }}>
                    <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                        {getIcon(68, theme.palette.primary.main, 30)}
                        <Typography sx={{ fontWeight: 'bold', color: theme.palette.text.primary, fontSize: 30 }}>{nameToNome(tipo)}</Typography>
                    </Box>
                    <Box>
                        <IconButton onClick={() => setModalNew(true)}>
                            {getIcon(55, theme.palette.text.primary, 30)}
                        </IconButton>
                    </Box>
                </Box>
                <Box sx={{ padding: 5, paddingTop: 2 }}>
                    <CTextField onKeyPress={OnKeyPressTF} onChangeText={handleChangeText} value={nome} label={'Buscar'} width={'100%'} icon={getIcon(53)} iconPosition={'start'} />
                </Box>
                <Box sx={{ padding: 5, paddingTop: 2, display: 'flex', flexDirection: 'column', gap: 3, overflowY: 'scroll' }}>
                    {data.map((e) => {
                        if (grupo && tipo === 'categoria' && e.nome !== grupo.nome) return
                        if (categoria && tipo === 'departamento' && e.nome !== categoria.nome) return
                        return (
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                                <Typography sx={{ color: theme.palette.text.primary, fontWeight: 'bold' }}>{e?.nome}</Typography>
                                <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
                                    {e?.data.map((b) => (
                                        <Button sx={{ background: theme.palette.background.paper, padding: 1, textTransform: 'none', justifyContent: 'flex-start' }} onClick={() => handleSelect(b)}>
                                            <Typography sx={{ color: theme.palette.text.primary, textAlign: 'left' }}>{b?.nome}</Typography>
                                        </Button>
                                    ))}
                                </Box>
                            </Box>
                        )
                    })}
                </Box>
                <Modal open={modalNew} onClose={() => null} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 2 }} slotProps={{
                    backdrop: {
                        sx: {
                            backgroundColor: theme.palette.mode === 'dark' ? 'rgba(250,255,255, 0.2)' : 'rgba(0,0,0, 0.4)'
                        }
                    }
                }}>
                    <Box sx={{
                        display: 'flex', padding: 2, gap: 2, flexDirection: 'column', height: 200, width: '95%', maxWidth: 400, background: theme.palette.background.default, outline: 'none',
                        [theme.breakpoints.down('lg')]: {
                            width: '98%'
                        },
                    }}>
                        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                            {getIcon(68, theme.palette.primary.main, 30)}
                            <Typography sx={{ fontWeight: 'bold', color: theme.palette.text.primary, fontSize: 20 }}>{'Novo #' + nameToNome(tipo)}</Typography>
                        </Box>
                        <CTextField label={nameToNome(tipo)} width={'100%'} value={newText} onChangeText={handleChangeNewText} />
                        <Box sx={{ display: 'flex', marginTop: 2, gap: 2, justifyContent: 'flex-end' }}>
                            {['Salvar', 'Sair'].map((e, idx) => {
                                const background = e === 'Salvar' ? theme.palette.primary.main : theme.palette.background.paper
                                const color = e === 'Salvar' ? theme.palette.text.whiteText : theme.palette.text.primary
                                const icon = idx === 0 ? 57 : 43
                                return (
                                    <IconButton key={e} sx={{ background: background }} onClick={() => handleButton(e)}>
                                        {getIcon(icon, color)}
                                    </IconButton>
                                )
                            })}
                        </Box>
                    </Box>
                </Modal>
                <ModalInfo hideModal={() => updateModalInfo(false)} canDesactive={MInfo.canDesactive} visible={MInfo.visible} title={MInfo.title} subtitle={MInfo.subtitle} icon={MInfo.icon} funcS={MInfo.funcS} />
            </Box>
        </Modal>
    );
}