import React, { useEffect, useState, useContext } from "react";
import { useTheme } from '@mui/material/styles';
import { Box, Typography, Button, Modal, Menu, MenuItem, Divider, TextField, IconButton, Icon } from '@mui/material';
import mainApi from "../../apis/mainApi";
import { User } from '../../contexts/user'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { getEvento } from "../../components/getEvento";
import { cpfMask, cepMask, celularMask } from "../../components/masks";
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
export default props => {
    const theme = useTheme()
    const user = useContext(User)
    let data = props?.data
    const newDataInitial = {
        editable: true,
        id: '',
        aplicacao_nome: '',
        nome: '',
        vendas: []
    }
    const [newData, setNewData] = useState(newDataInitial)

    async function getCompras(id, formatData) {
        props.updateModalLoading(true)
        function FCallback(callback) {
            props.updateModalLoading(false)
            if (callback.status !== 200) {
                setNewData(formatData)
                const x = {}
                x.title = 'Erro'
                x.subtitle = callback.apiReturn.message ? callback.apiReturn.message : 'Ocorreu um erro! Tente novamente mais tarde'
                x.body = <Button onClick={() => props.resetModalInfo()} sx={{ mb: 2, backgroundColor: theme.palette.secondary.main, color: theme.palette.text.primary, '&:hover': { backgroundColor: theme.palette.secondary.dark }, borderRadius: 0, padding: 1 }}>
                    <Typography fontWeight={'bold'} sx={{ color: theme.palette.text.whiteText, textAlign: 'center' }}>Voltar</Typography>
                </Button>
                x.active = true
                props.updateModalInfo(x)
                return
            }
            formatData.vendas = callback.apiReturn.apiReturn
            setNewData(formatData)
        }
        const body = {
            cliente: id
        }
        await mainApi('pedidos/server/basic', 'POST', body, FCallback, user.data.token)
    }


    useEffect(() => {
        if (props.active === true) {
            const formatData = { ...newData }
            const getData = props.data
            formatData.aplicacao_nome = getData.aplicacao_nome
            formatData.id = getData.id
            formatData.nome = getData.nome
            getCompras(formatData.id, formatData)
        }
    }, [props.active])
    return (
        <Modal
            open={props.active}
            onClose={() => {
                props.resetOpenData()
                setNewData(newDataInitial)
            }}
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                overflow: 'scroll',
                [theme.breakpoints.down('lg')]: {
                    alignItems: 'flex-start',
                },
            }}
        >
            <Box sx={{
                width: '45%',
                height: '70%',
                backgroundColor: theme.palette.background.default,
                border: 1,
                borderColor: theme.palette.secondary.main,
                position: 'absolute',
                overflow: 'scroll',
                '&::-webkit-scrollbar': {
                    background: theme.palette.background.paper,
                    width: 20
                },
                '&::-webkit-scrollbar-thumb': {
                    background: theme.palette.primary.main
                },
                [theme.breakpoints.down('lg')]: {
                    width: '70%'
                },
                [theme.breakpoints.down('md')]: {
                    width: '90%'
                },
            }}>
                <Box sx={{ backgroundColor: theme.palette.background.paper, padding: 5, display: 'flex', justifyContent: 'space-between' }}>
                    <Typography sx={{ fontWeight: 'bold', fontSize: 30 }}>{'Vendas #' + newData.nome}</Typography>
                    <Typography sx={{ fontWeight: 'bold', fontSize: 30 }}>{newData.aplicacao_nome}</Typography>
                </Box >
                <Box sx={{
                    paddingTop: 1
                }}>
                    <Box sx={{
                        display: 'flex', flexDirection: 'column', width: '100%',
                        [theme.breakpoints.down('md')]: {
                            width: '98%'
                        },
                    }}>
                        {newData.vendas.map((e) => {
                            return (
                                <Box sx={{ display: 'flex' }}>
                                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 2, border: 1, width: '100%' }}>
                                        <Typography>{e.id}</Typography>
                                    </Box>
                                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 2, border: 1, width: '100%' }}>
                                        <Typography>{e.aplicacao_name}</Typography>
                                    </Box>
                                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 2, border: 1, width: '100%' }}>
                                        <Typography>{e.aplicacao_name}</Typography>
                                    </Box>
                                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 2, border: 1, width: '100%' }}>
                                        <Typography>{e.produto_name}</Typography>
                                    </Box>
                                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 2, border: 1, width: '100%' }}>
                                        <Typography>{e.amount}</Typography>
                                    </Box>
                                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 2, border: 1, width: '100%' }}>
                                        <Typography>{e.date_created}</Typography>
                                    </Box>
                                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 2, border: 1, width: '100%' }}>
                                        <Typography>{e.status}</Typography>
                                    </Box>
                                </Box>
                            )
                        })}
                    </Box >
                </Box >
            </Box >
        </Modal >
    )
}