import React, { useEffect, useState, useContext } from "react";
import { useTheme } from '@mui/material/styles';
import { Box, Typography, Button, Modal, TextField, IconButton, Tooltip, Switch } from '@mui/material';
import mainApi from "../../apis/mainApi";
import { User } from '../../contexts/user'
import { getEvento } from "../../components/getEvento";
import ModalEventos from "./modalEventos";



export default props => {
    const theme = useTheme()
    const user = useContext(User)
    let data = props?.data
    const newDataInitial = {
        modulos: [],
        aplicacaoAtive: {
            id: 2,
            nome: 'Central de Negócios'
        }
    }
    const [modalEventos, setModalEventos] = useState({
        active: false,
        modulo: 0,
        eventos: []
    })

    function verifyAcessoExist(modulo, eventos) {
        const findEventoAcesso = eventos.findIndex((evento) => evento.modulo === modulo && evento.nome === 'Acesso')
        return findEventoAcesso === -1 ? false : true
    }

    const handleSwitchChange = (e, item) => {
        const findModulo = newData.modulos.findIndex((modulo) => modulo.modulo === item.id)
        const getEventoAcesso = props.mainData.eventos.filter((evento) => evento.modulo === item.id && evento.nome === 'Acesso')
        const formatData = { ...newData }
        if (findModulo === -1) {
            formatData.modulos.push({
                modulo: item.id,
                eventos: [getEventoAcesso[0]]
            })
        }
        else {
            if (e.target.checked === false) {
                const newModulo = formatData.modulos[findModulo].eventos.filter((evento) => evento.nome !== 'Acesso')
                formatData.modulos[findModulo].eventos = newModulo
            }
            else {
                formatData.modulos[findModulo].eventos.push(getEventoAcesso[0])
            }
        }
        setNewData(formatData)
    };
    const [newData, setNewData] = useState(newDataInitial)
    function ifModuloActive(moduloID) {
        const findModulo = newData.modulos.findIndex((modulo) => modulo.modulo === moduloID)
        if (findModulo === -1) return false
        if (newData?.modulos[findModulo]?.eventos) {
            const findEventoAcesso = newData?.modulos[findModulo]?.eventos.findIndex((evento) => evento.nome === 'Acesso')
            if (findEventoAcesso !== -1) return true
        }
        return false
    }
    function updateModuloEventos(modulo, evento, target) {
        const formatData = { ...newData }
        const findModulo = formatData.modulos.findIndex((el) => el.modulo === modulo.id)
        if (findModulo === -1) {
            formatData.modulos.push({
                modulo: modulo,
                eventos: [evento]
            })
            setNewData(formatData)
            return
        }
        if (target === false) {
            const newEventos = formatData.modulos[findModulo].eventos.filter((el) => el.id !== evento.id)
            formatData.modulos[findModulo].eventos = newEventos
            setNewData(formatData)
            return
        }
        const findEvento = formatData.modulos[findModulo].eventos.findIndex((el) => el.id === evento.id)
        if (findEvento === -1) {
            formatData.modulos[findModulo].eventos.push(evento)
            setNewData(formatData)
            return
        }
        formatData.modulos[findModulo].eventos[findEvento] = evento
        setNewData(formatData)
        return

    }
    async function save() {
        props.updateModalLoading(true)
        function FCallback(callback) {
            props.updateModalLoading(false)
            if (callback.status !== 200) {
                const newModalInfo = {
                    active: true,
                    title: 'Erro',
                    subtitle: callback.apiReturn.message ? callback.apiReturn.message : 'Ocorreu um erro! Tente novamente mais tarde',
                    body: <Button onClick={() => props.resetModalInfo()} sx={{ mb: 2, backgroundColor: theme.palette.secondary.main, color: theme.palette.text.primary, '&:hover': { backgroundColor: theme.palette.secondary.dark }, borderRadius: 0, padding: 1 }}>
                        <Typography fontWeight={'bold'} sx={{ color: theme.palette.text.whiteText, textAlign: 'center' }}>Voltar</Typography>
                    </Button>
                }
                props.updateModalInfo(newModalInfo)
                return
            }
            const newModalInfo = {
                active: true,
                title: 'Sucesso',
                subtitle: callback.apiReturn.message ? callback.apiReturn.message : 'Módulos alterados com sucesso',
                body: <Button onClick={() => props.resetModalInfo()} sx={{ mb: 2, backgroundColor: theme.palette.secondary.main, color: theme.palette.text.primary, '&:hover': { backgroundColor: theme.palette.secondary.dark }, borderRadius: 0, padding: 1 }}>
                    <Typography fontWeight={'bold'} sx={{ color: theme.palette.text.whiteText, textAlign: 'center' }}>Voltar</Typography>
                </Button>
            }
            props.updateModalInfo(newModalInfo)
            return
        }
        const allEventos = []
        newData.modulos.map((modulo) => {
            if (modulo.eventos) {
                modulo.eventos.map((evento) => {
                    allEventos.push(evento.id)
                })
            }
        })
        const body = {
            usuario: props.data.id,
            eventos: allEventos
        }
        await mainApi('usuarios/modulos', 'POST', body, FCallback, user.data.token)
    }

    async function getModulosUser() {
        function FCallback(callback) {
            if (callback.status !== 200) {
                const newModalInfo = {
                    active: true,
                    title: 'Erro',
                    subtitle: callback.apiReturn.message ? callback.apiReturn.message : 'Ocorreu um erro! Tente novamente mais tarde',
                    body: <Button onClick={() => props.resetModalInfo()} sx={{ mb: 2, backgroundColor: theme.palette.secondary.main, color: theme.palette.text.primary, '&:hover': { backgroundColor: theme.palette.secondary.dark }, borderRadius: 0, padding: 1 }}>
                        <Typography fontWeight={'bold'} sx={{ color: theme.palette.text.whiteText, textAlign: 'center' }}>Voltar</Typography>
                    </Button>
                }
                props.updateModalInfo(newModalInfo)
                return
            }
            const formatData = { ...newData }
            formatData.modulos = callback.apiReturn.apiReturn
            setNewData(formatData)
        }
        await mainApi('usuarios/eventosUser/' + props.data.id, 'GET', null, FCallback, user.data.token)
    }
    function desactiveEventos() {
        const newModalEventos = { ...modalEventos }
        newModalEventos.active = false
        setModalEventos(newModalEventos)
    }
    useEffect(() => {
        if (props.active) {
            getModulosUser()
        }
    }, [props.active])
    return (
        <Modal
            open={props.active}
            onClose={() => {
                props.resetOpenData()
                setNewData(newDataInitial)
            }}
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                [theme.breakpoints.down('lg')]: {
                    alignItems: 'flex-start',
                },
            }}
        >
            <Box sx={{
                width: '45%',
                height: 'auto',
                backgroundColor: theme.palette.background.default,
                border: 1,
                borderColor: theme.palette.secondary.main,
                position: 'absolute',
                [theme.breakpoints.down('lg')]: {
                    width: '70%'
                },
                [theme.breakpoints.down('md')]: {
                    width: '90%'
                },
            }}>
                <Box sx={{ backgroundColor: theme.palette.background.paper, padding: 5, display: 'flex', justifyContent: 'space-between' }}>
                    <Typography sx={{ fontWeight: 'bold', fontSize: 30 }}>{'Módulos e Permissões'}</Typography>
                </Box >
                <Box sx={{
                    padding: 5, paddingTop: 1
                }}>
                    <Box sx={{
                        display: 'flex', flexDirection: 'column', width: '100%', gap: 3,
                        [theme.breakpoints.down('md')]: {
                            width: '98%'
                        },
                    }}>
                        <Typography fontWeight={'bold'} fontSize={20}>Informações</Typography>
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
                            {props.aplicacoes.map((e, idx) => {
                                if ([1].includes(e?.id)) return
                                return (
                                    <Button key={idx} sx={{
                                        backgroundColor: theme.palette.primary.main,
                                        '&:hover': {
                                            backgroundColor: theme.palette.primary.dark,
                                        }
                                    }}
                                        onClick={() => {
                                            const formatData = { ...newData }
                                            formatData.aplicacaoAtive.id = e?.id
                                            formatData.aplicacaoAtive.nome = e?.nome
                                            setNewData(formatData)
                                        }}
                                    >
                                        <Typography sx={{ textTransform: 'none', color: theme.palette.text.whiteText }}>{e?.nome}</Typography>
                                    </Button>
                                )
                            })}
                        </Box>
                        <Typography fontWeight={'bold'} fontSize={20}>{newData.aplicacaoAtive.nome}</Typography>
                        <Box sx={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 2,
                            justifyContent: 'flex-start',
                            overflowY: 'scroll',
                            height: '400px',
                            '&::-webkit-scrollbar': {
                                width: '12px',
                                height: '12px',
                            },
                            '&::-webkit-scrollbar-thumb': {
                                backgroundColor: 'rgba(0, 0, 0, 0.2)',
                                borderRadius: '10px',
                            },
                            '&::-webkit-scrollbar-thumb:hover': {
                                backgroundColor: 'rgba(0, 0, 0, 0.4)',
                            },
                            '&::-webkit-scrollbar-track': {
                                borderRadius: '10px',
                                boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.1)',
                                backgroundColor: '#f5f5f5',
                            },
                            '&::-webkit-scrollbar-track:hover': {
                                backgroundColor: '#f0f0f0',
                            },
                        }}>
                            {props.modulos.map((e, idx) => {
                                if (e?.aplicacao !== newData.aplicacaoAtive.id) return
                                if (!verifyAcessoExist(e.id, props.mainData.eventos)) return
                                return (
                                    <Box
                                        key={idx}
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                            gap: 2,
                                            padding: '16px',
                                            border: '1px solid ' + theme.palette.background.paper,
                                            borderRadius: '8px',
                                            backgroundColor: theme.palette.background.default,
                                            boxShadow: theme.palette.mode === 'dark' ? '0 4px 8px rgba(255, 255, 255, 0.1)' : '0 4px 8px rgba(0, 0, 0, 0.1)',
                                        }}
                                    >
                                        <Tooltip title={e?.descricao} placement="top">
                                            <TextField
                                                label="Módulo"
                                                margin="none"
                                                value={e['nome']}
                                                disabled={true}
                                                id={e.nome}
                                                name={e.nome}
                                                sx={{
                                                    width: '50%',
                                                    '& input': {
                                                        color: theme.palette.text.primary,
                                                        WebkitTextFillColor: theme.palette.text.primary + ' !important' // Cor do valor (quando desativado)
                                                        //-webkit-text-fill-color
                                                    },
                                                    '& .MuiInputLabel-root': {
                                                        color: theme.palette.text.primary,
                                                        WebkitTextFillColor: theme.palette.text.primary + ' !important', // Cor do texto do label
                                                    },
                                                    '& .MuiInputBase-root': {
                                                        color: theme.palette.text.primary,
                                                        WebkitTextFillColor: theme.palette.text.primary + ' !important', // Cor do valor do campo (quando desativado)
                                                    },
                                                }}
                                                InputLabelProps={{
                                                    style: {
                                                        color: theme.palette.text.primary + ' !important',
                                                    },
                                                }}
                                                inputProps={{
                                                    style: {
                                                        backgroundColor: theme.palette.background.default,
                                                        borderRadius: '4px',
                                                        border: '1px solid ' + theme.palette.background.paper,
                                                        padding: '10px',
                                                    },
                                                }}
                                            />
                                        </Tooltip>
                                        <Switch
                                            checked={ifModuloActive(e?.id) === true ? true : false}
                                            onChange={(event) => handleSwitchChange(event, e)}
                                            inputProps={{ 'aria-label': 'checkbox' }}
                                        />
                                        <Button variant="contained" color="primary" sx={{ borderRadius: '4px' }} onClick={() => {
                                            const getEventosModulo = props.mainData.eventos.filter((evento) => evento.modulo === e.id)
                                            const formatData = { ...modalEventos }
                                            formatData.active = true
                                            formatData.modulo = e
                                            formatData.eventos = getEventosModulo
                                            setModalEventos(formatData)
                                        }}>
                                            <Typography variant="body2">Eventos</Typography>
                                        </Button>
                                    </Box>
                                )
                            })}
                        </Box>
                        <Box sx={{ display: 'flex', gap: 2 }}>
                            <IconButton
                                onClick={() => {
                                    if (getEvento(props.eventos, 'Editar Módulos') === false) {
                                        const modalInfo = {
                                            active: true,
                                            title: 'Permissão',
                                            subtitle: 'Você não tem permissão para editar os módulos',
                                            body: <Button onClick={() => props.resetModalInfo()} sx={{ mb: 2, backgroundColor: theme.palette.secondary.main, color: theme.palette.text.primary, '&:hover': { backgroundColor: theme.palette.secondary.dark }, borderRadius: 0, padding: 1 }}>
                                                <Typography fontWeight={'bold'} sx={{ color: theme.palette.text.whiteText, textAlign: 'center' }}>Voltar</Typography>
                                            </Button>
                                        }
                                        props.updateModalInfo(modalInfo)
                                        return
                                    }
                                    save()
                                }}
                                sx={{
                                    backgroundColor: newData.editable === true ? theme.palette.grey[600] : theme.palette.primary.main,
                                    borderRadius: 0,
                                    '&:hover': {
                                        backgroundColor: newData.editable === false ? theme.palette.secondary.main : theme.palette.grey[600]
                                    }
                                }}>
                                <Typography fontWeight={'bold'} sx={{ color: theme.palette.text.whiteText }}>Salvar</Typography>
                            </IconButton>
                        </Box>
                    </Box >
                </Box >
                <ModalEventos active={modalEventos.active} aplicacao={newData.aplicacaoAtive.nome} modulo={modalEventos.modulo} allUserModulos={newData.modulos} data={modalEventos.eventos} desactive={desactiveEventos} updateEvento={updateModuloEventos} />
            </Box >
        </Modal >
    )
}