import React from "react";
import { useTheme, Box, Typography, IconButton, TableContainer, Table, TableBody, TableRow, TableCell, Paper } from "@mui/material";
import { getIcon } from "../../../../components/icons";

export function DetailsSection({ title, keys, data, updateNfeInfo, updatePostagemInfo }) {
    const theme = useTheme()
    const sectionData = keys.reduce((acc, { key, label }) => {
        if (data[key] !== undefined && data[key] !== '') {
            acc[label] = data[key];
        }
        return acc;
    }, {});

    const dataKeys = Object.keys(sectionData);
    function openBase64Pdf(base64) {
        const pdfWindow = window.open("");
        pdfWindow.document.write(
            `<iframe width='100%' height='100%' src='data:application/pdf;base64,${base64}'></iframe>`
        );
    }

    return (
        <Box mb={3}>
            {dataKeys.length > 0 && (
                <React.Fragment>
                    <Box sx={{ display: 'flex', gap: 1, alignItems: 'flex-end' }}>
                        <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1 }}>{`Detalhes ${title}`}</Typography>
                        {title === 'NFe' && data?.nfe_num ?
                            <IconButton sx={{ padding: 1 }} onClick={() => updateNfeInfo(true, data)}>
                                {
                                    getIcon(27, theme.palette.secondary.main, 15)
                                }
                            </IconButton> : title === 'Postagem' && data?.prePostagem ?
                                <IconButton sx={{ padding: 1 }} onClick={() => updatePostagemInfo(true, data)}>
                                    {
                                        getIcon(27, theme.palette.secondary.main, 15)
                                    }
                                </IconButton> : null
                        }
                    </Box>
                    <TableContainer component={Paper}>
                        <Table size="small">
                            <TableBody>
                                {dataKeys.map(label => (
                                    <TableRow key={label}>
                                        <TableCell component="th" scope="row">{label.replace(/_/g, ' ')}</TableCell>
                                        {label === 'Link' && sectionData[label] ?
                                            <IconButton onClick={() => window.open(sectionData[label], '_blank')} sx={{ padding: 0, marginLeft: 2 }}>
                                                {
                                                    getIcon(78)
                                                }
                                            </IconButton>
                                            : label === 'Etiqueta' && sectionData[label] ?
                                                <IconButton onClick={() => openBase64Pdf(sectionData[label])} sx={{ padding: 0, marginLeft: 2 }}>
                                                    {
                                                        getIcon(78)
                                                    }
                                                </IconButton>
                                                : <TableCell>{sectionData[label]}</TableCell>}

                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </React.Fragment>
            )}
        </Box>
    );
}