import { useTheme, Box,  Typography } from '@mui/material';

export function ProdutoComp({ data }) {
    const theme = useTheme()
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <img src={data.produto_image} style={{ objectFit: 'contain', width: 150 }} />
            <Box sx={{marginLeft: 2}}>
                <Typography><strong>Nome:</strong> {data.aplicacao_nome + ' ' + data.produto_nome}</Typography>
                <Typography><strong>Preço:</strong> R${data.produto_preco}</Typography>
                <Typography><strong>Quantidade:</strong> {data.produto_quantidade}</Typography>
            </Box>
        </Box>
    )
}