import React, { useState, useContext, useEffect } from 'react';
import { useTheme, Box, Typography, Modal, IconButton, Badge, Button } from '@mui/material';
import { ModalInfo } from '../../../components/modalInfoN';
import { User } from '../../../contexts/user';
import { getIcon } from '../../../components/icons';
import { CTextField } from '../../../components/TextField';
import { ModalIcones } from '../../../components/modalIcones';
import { ButtonSubmit } from '../../../components/buttons';
import mainApi from '../../../apis/mainApiN';

export function Links({ visible, parceiro, updateLinkMod }) {
    const theme = useTheme();
    const user = useContext(User);
    //estados
    const [MInfo, setMInfo] = useState({
        visible: false,
        title: '',
        subtitle: '',
        canDesactive: true,
        icon: '',
        funcS: () => null
    });
    const [link, setLinks] = useState([])
    const [modIcon, setModIcon] = useState({
        visible: false,
        parceiro: null
    })
    const [disabled, setDisabled] = useState(true)
    function updateModalInfo(visible, canDesactive, title, subtitle, icon, func) {
        const newMInfo = { ...MInfo };
        newMInfo.visible = visible;
        newMInfo.canDesactive = canDesactive;
        newMInfo.title = title;
        newMInfo.subtitle = subtitle;
        newMInfo.icon = icon;
        newMInfo.funcS = func;
        setMInfo(newMInfo);
    }
    function handleDismiss() {
        setLinks([])
        updateLinkMod(false)
        return
    }
    function pushLink() {
        const newL = [...link]
        newL.push({
            nome: '',
            icon: '',
            link: ''
        })
        setLinks(newL)
    }
    function handleIconClick(visible, control) {
        const newMIcon = { ...modIcon }
        newMIcon.visible = typeof visible === 'boolean' ? visible : false
        newMIcon.parceiro = ![undefined, null, ''].includes(control) ? control : false
        setModIcon(newMIcon)
    }
    function iconUpdate(icon, type, control) {
        const newData = [...link]
        const index = newData.findIndex((e, idx) => idx === control)
        if (index !== -1) {
            newData[index].icon = icon
        }
        setLinks(newData)
    }
    function removeLink(idx) {
        const newLink = link.filter((e, index) => index !== idx)
        setLinks(newLink)
    }
    function handleButton(type) {
        if (type === 'Sair') {
            handleDismiss()
            return
        }
        saveLinks()
    }
    function changeNome(value, idx) {
        const newData = [...link]
        newData[idx].nome = value.replace(' ', '')
        setLinks(newData)
    }
    async function handleNewLink(index) {
        if (!link[index].nome) {
            updateModalInfo(true, true, 'Atenção', 'Nome precisa ser preenchido pra gerar o link.', 'exclamation')
            return
        }
        const body = {
            parceiro: parceiro.id,
            nome: link[index].nome
        }
        updateModalInfo(true, false, 'Buscando', 'Estamos gerando seu link.', 'loading')
        const get = await mainApi('parceirosmp/gerarLink', 'POST', body, user?.data?.token)
        if (get?.status !== 200) {
            const message = get?.apiReturn?.message ? get?.apiReturn?.message : 'Ocorreu um erro ao solicitar um link'
            updateModalInfo(true, true, 'Atenção', message, 'exclamation')
            return
        }
        updateModalInfo(false)
        const newLinks = [...link]
        const verifyLink = newLinks.findIndex((e) => e.link === get?.apiReturn?.apiReturn)
        if (verifyLink === -1) {
            newLinks[index].link = get?.apiReturn?.apiReturn
            setLinks(newLinks)
            return
        }
        updateModalInfo(true, true, 'Atenção', 'Link gerado já se encontra na lista.', 'exclamation')
    }
    async function getData() {
        if (!parceiro?.id) {
            updateModalInfo(true, true, 'Atenção', 'Id do parceiro não foi encontrado. Tente recarregar a página.', 'exclamation')
            setDisabled(true)
            return
        }
        updateModalInfo(true, false, 'Buscando', 'Carregando links do parceiro.', 'loading')
        const get = await mainApi(`parceirosmp/getLinks/${parceiro.id}`, 'GET', null, user?.data?.token)
        if (get?.status !== 200) {
            const message = get?.apiReturn?.message ? get?.apiReturn?.message : 'Ocorreu um erro ao carregar os links.'
            updateModalInfo(true, true, 'Atenção', message, 'exclamation')
            setDisabled(true)
            return
        }
        updateModalInfo(false)
        setDisabled(false)
        setLinks(get?.apiReturn?.apiReturn)
    }

    async function saveLinks() {
        if (link.length === 0) {
            updateModalInfo(true, true, 'Atenção', 'Ao menos um link deve ser criado para salvar.', 'exclamation')
            return
        }
        const body = {
            parceiro: parceiro?.id,
            links: link
        }
        const save = await mainApi('parceirosmp/saveLinks', 'POST', body, user?.data?.token)
        if (save?.status !== 200) {
            const message = save?.apiReturn?.message ? save?.apiReturn?.message : 'Ocorreu um erro ao salvar os links'
            updateModalInfo(true, true, 'Atenção', message, 'exclamation')
            return
        }
        updateModalInfo(true, true, 'Atenção', 'Links salvos com sucesso', 'sucess')
    }

    useEffect(() => {
        if (visible) {
            getData()
        }
    }, [visible])

    return (
        <Modal open={visible} onClose={handleDismiss} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 2 }} slotProps={{
            backdrop: {
                sx: {
                    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(250,255,255, 0.2)' : 'rgba(0,0,0, 0.4)'
                }
            }
        }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', height: 550, width: 650, background: theme.palette.background.default, outline: 'none' }}>
                <Box sx={{ display: 'flex', background: theme.palette.background.default, padding: 5, paddingBottom: 2, justifyContent: 'space-between', alignItems: 'center' }}>
                    <Box>
                        <Typography sx={{ fontWeight: 'bold', color: theme.palette.text.primary, fontSize: 30 }}>Links</Typography>
                        <Typography sx={{ color: theme.palette.text.primary, fontSize: 18 }}>Adicione links personalizados para seu parceiro.</Typography>
                    </Box>
                    <IconButton onClick={pushLink}>
                        {getIcon(55, theme.palette.text.primary, 30)}
                    </IconButton>
                </Box>
                <Box sx={{
                    display: 'flex', flexDirection: 'column', flex: 1,
                    padding: 5,
                    gap: 3,
                    overflowY: 'scroll'
                }}>
                    {link.map((e, idx) => {
                        return (
                            <Badge badgeContent={<IconButton onClick={() => removeLink(idx)} sx={{ background: '#f44336', fontSize: 11, color: theme.palette.text.whiteText, padding: '3px 6px' }}>x</IconButton>}>
                                <Box sx={{ width: '100%', display: 'flex', gap: 2, boxShadow: theme.palette.mode === 'dark' ? '0px 4px 10px rgba(155, 155, 155, 0.2)' : '0px 4px 10px rgba(0, 0, 0, 0.1)', padding: 2, alignItems: 'center', justifyContent: 'space-between' }}>
                                    <IconButton onClick={() => handleIconClick(true, idx)} sx={{ alignItems: 'center', justifyContent: 'center' }}>
                                        {getIcon(e?.icon, theme.palette.secondary.main, 25)}
                                    </IconButton>
                                    <CTextField prevData={link} label={'Nome'} value={e.nome} onChangeText={changeNome} type={idx} />
                                    <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                        <CTextField label={'Link'} disabled={true} width={'95%'} value={e.link} />
                                        <IconButton onClick={() => handleNewLink(idx)}>
                                            {getIcon(59)}
                                        </IconButton>
                                    </Box>
                                </Box>
                            </Badge>
                        )
                    })}
                </Box>
                <Box sx={{ display: 'flex', gap: 2, padding: 2, paddingLeft: 5 }}>
                    {['Salvar', 'Sair'].map((e, idx) => {
                        const background = e === 'Salvar' ? theme.palette.primary.main : theme.palette.background.paper
                        const color = e === 'Salvar' ? theme.palette.text.whiteText : theme.palette.text.primary
                        const isDisabled = e === 'Salvar' && disabled ? true : false
                        return (
                            <ButtonSubmit disabled={isDisabled} key={idx} text={e} background={background} color={color} func={handleButton} funcParameters={e} />
                        )
                    })}
                </Box>
                <ModalIcones visible={modIcon.visible} onClose={handleIconClick} control={modIcon.parceiro} updateIcon={iconUpdate} />
                <ModalInfo hideModal={() => updateModalInfo(false)} canDesactive={MInfo.canDesactive} visible={MInfo.visible} title={MInfo.title} subtitle={MInfo.subtitle} icon={MInfo.icon} funcS={MInfo.funcS} />
            </Box>
        </Modal >
    );
}