import React, { useState, useContext, useEffect } from 'react';
import { useTheme, Box, Modal, Typography, IconButton, Button, Divider, Badge } from '@mui/material';
import { ModalInfo } from '../../../../components/modalInfoN';
import { User } from '../../../../contexts/user';
import { CTextField } from '../../../../components/TextField';
import moment from "moment";
import 'moment/locale/pt-br'
import { ButtonSubmit } from '../../../../components/buttons';
import mainApi from '../../../../apis/mainApiN';
import { UpComp } from '../../../../components/upComp';
import { getIcon } from '../../../../components/icons';
import { NovaCondicao } from './condicao/novaCondicao';

export function Condicao({ visible, campanha, dismiss, pushCampanha, editCampanha, updateCondicao }) {
    const theme = useTheme();
    const user = useContext(User);
    //estados

    const [data, setData] = useState([])
    const [novaCond, setNovaCond] = useState({
        visible: false,
        prevCondicao: null
    })
    function updateNovaCond(visible, prevCondicao) {
        const newC = { ...novaCond }
        newC.visible = visible ? visible : false
        newC.prevCondicao = prevCondicao ? prevCondicao : null
        setNovaCond(newC)
    }

    const [MInfo, setMInfo] = useState({
        visible: false,
        title: '',
        subtitle: '',
        canDesactive: true,
        icon: '',
        funcS: () => null
    });

    function pushData(value) {
        const newData = [...data]
        if (newData.findIndex((e) => e.type.id === value.type.id) !== -1) {
            updateModalInfo(true, true, 'Atenção', 'Adicione uma condição difente das condições da lista.', 'exclamation')
            return
        }
        newData.push(value)
        setData(newData)
    }
    function updateData(value) {
        const newData = [...data]
        const findIndex = newData.findIndex((e) => e.type.id === value.type.id)
        newData[findIndex] = value
        setData(newData)
    }
    function removeData(index) {
        const newData = data.filter((e, idx) => idx !== index)
        setData(newData)
    }

    function updateModalInfo(visible, canDesactive, title, subtitle, icon, func) {
        const newMInfo = { ...MInfo };
        newMInfo.visible = visible;
        newMInfo.canDesactive = canDesactive;
        newMInfo.title = title;
        newMInfo.subtitle = subtitle;
        newMInfo.icon = icon;
        newMInfo.funcS = func;
        setMInfo(newMInfo);
    }
    async function getData() {
        updateModalInfo(true, false, 'Carragando', 'Estamos buscando suas condições.', 'loading')
        const get = await mainApi(`farmaTouch/getCampFranquiaCondicoes/${campanha.id}`, 'GET', null, user?.data?.token)
        if (get?.status !== 200) {
            const message = get?.apiReturn?.message ? get?.apiReturn?.message : 'Ocorreu um erro ao savar as condições.'
            updateModalInfo(true, true, 'Atenção', message, 'exclamation')
            return
        }
        updateModalInfo(false)
        setData(get?.apiReturn?.apiReturn)
    }

    async function saveData() {
        updateModalInfo(true, false, 'Salvando', 'Estamos salvando suas condições.', 'loading')
        const body = {
            campanha: campanha.id,
            condicoes: data
        }
        const save = await mainApi('farmaTouch/saveCampFranquiaCondicoes', 'POST', body, user?.data?.token)
        if (save?.status !== 200) {
            const message = save?.apiReturn?.message ? save?.apiReturn?.message : 'Ocorreu um erro ao savar as condições.'
            updateModalInfo(true, true, 'Atenção', message, 'exclamation')
            return
        }
        updateModalInfo(true, true, 'Sucesso', 'Suas condições foram salvar.', 'sucess')
    }
    function handleButton(type) {
        if (type === 'Salvar') {
            saveData()
            return
        }
        handleDismiss()
    }
    function handleDismiss() {
        setData([])
        updateCondicao()
        return
    }
    function handleInteraction(type) {
        updateNovaCond(true, type)
        return
    }

    useEffect(() => {
        if (visible) {
            getData()
        }
    }, [visible])

    return (
        <Modal open={visible} onClose={handleDismiss} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 2 }} slotProps={{
            backdrop: {
                sx: {
                    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(250,255,255, 0.2)' : 'rgba(0,0,0, 0.4)'
                }
            }
        }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', height: 630, width: 650, background: theme.palette.background.default, outline: 'none' }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', background: theme.palette.background.default, padding: 5, paddingBottom: 2 }}>
                    <Box>
                        <Typography sx={{ fontWeight: 'bold', color: theme.palette.text.primary, fontSize: 30 }}>Condições</Typography>
                        <Typography sx={{ color: theme.palette.text.primary, fontSize: 18 }}>Adicione e ajuste condições para a campanha.</Typography>
                    </Box>
                    <UpComp tamanho={50}>
                        <IconButton onClick={() => updateNovaCond(true)}>
                            {getIcon(55, theme.palette.primary.main, 30)}
                        </IconButton>
                    </UpComp>
                </Box>
                <Box sx={{
                    display: 'flex', flexDirection: 'column', flex: 1,
                    padding: 5,
                    paddingTop: 0,
                    gap: 3,
                    justifyContent: 'space-between',
                    height: '75%'
                }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', overflowY: 'scroll', height: '65%' }}>
                        {data.map((e, index) => {
                            return (
                                <Button key={index} sx={{ width: '100%', padding: 2 }} onClick={() => handleInteraction(e)} >
                                    <Badge sx={{ width: '100%' }} badgeContent={<IconButton onClick={() => removeData(index)} sx={{ background: '#f44336', fontSize: 11, color: theme.palette.text.whiteText, padding: '3px 6px' }}>x</IconButton>}>
                                        <Box key={index} sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 2 }}>
                                            <Box sx={{ display: 'flex', background: theme.palette.background.default, boxShadow: theme.palette.mode === 'dark' ? '0px 4px 10px rgba(155, 155, 155, 0.3)' : '0px 4px 10px rgba(0, 0, 0, 0.1)', padding: 3, alignItems: 'center', gap: 3 }}>
                                                <UpComp>
                                                    {
                                                        getIcon(e.type.icon, theme.palette.mode === 'dark' ? theme.palette.text.primary : theme.palette.primary.main, 25)
                                                    }
                                                </UpComp>
                                                <Box sx={{ width: '70%' }}>
                                                    <Typography sx={{ color: theme.palette.text.primary, textTransform: 'none', fontWeight: 'bold', textAlign: 'left' }}>{'Condições por ' + e.type.nome}</Typography>
                                                    <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                                                        {e.condicoes.map((e) => {
                                                            return (
                                                                <Box key={e} sx={{ display: 'flex' }}>
                                                                    <Typography sx={{ color: theme.palette.text.primary, textTransform: 'none' }}>{e.nome}</Typography>
                                                                    <Typography sx={{ color: theme.palette.text.primary, textTransform: 'none', paddingLeft: 1, paddingRight: 1 }}> | </Typography>
                                                                </Box>
                                                            )
                                                        })}
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Badge>
                                </Button>
                            )
                        })}
                    </Box>
                    <Box sx={{ display: 'flex', gap: 2 }}>
                        {['Salvar', 'Sair'].map((e, idx) => {
                            const background = e === 'Salvar' ? theme.palette.primary.main : theme.palette.background.paper
                            const color = e === 'Salvar' ? theme.palette.text.whiteText : theme.palette.text.primary
                            return (
                                <ButtonSubmit key={idx} text={e} background={background} color={color} func={handleButton} funcParameters={e} />
                            )
                        })}
                    </Box>
                </Box>
                <NovaCondicao visible={novaCond.visible} campanha={campanha} updateNovaCond={updateNovaCond} pushData={pushData} prevCondicao={novaCond.prevCondicao} updateMainData={updateData} />
                <ModalInfo hideModal={() => updateModalInfo(false)} canDesactive={MInfo.canDesactive} visible={MInfo.visible} title={MInfo.title} subtitle={MInfo.subtitle} icon={MInfo.icon} funcS={MInfo.funcS} />
            </Box>
        </Modal>
    );
}