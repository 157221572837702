import React, { useState, useContext, useEffect } from 'react';
import { useTheme, Box, Modal, Typography } from '@mui/material';
import { ModalInfo } from '../../../components/modalInfoN';
import { User } from '../../../contexts/user';
import { CTextField } from '../../../components/TextField';
import { UserAvatar } from '../../../components/avatar';
import { getIcon } from '../../../components/icons';
import { ButtonSubmit } from '../../../components/buttons';
import { TestaEMAIL } from '../../../components/validaCampo';
import mainApi from '../../../apis/mainApiN';

export function Novo({ visible, updateModParc, pushParceiro, prevData, editParceiro }) {
    const theme = useTheme();
    const user = useContext(User);
    //estados
    const [MInfo, setMInfo] = useState({
        visible: false,
        title: '',
        subtitle: '',
        canDesactive: true,
        icon: '',
        funcS: () => null
    });
    const [data, setData] = useState(dataInit)

    function updateModalInfo(visible, canDesactive, title, subtitle, icon, func) {
        const newMInfo = { ...MInfo };
        newMInfo.visible = visible;
        newMInfo.canDesactive = canDesactive;
        newMInfo.title = title;
        newMInfo.subtitle = subtitle;
        newMInfo.icon = icon;
        newMInfo.funcS = func;
        setMInfo(newMInfo);
    }
    function handleAvatar(base64) {
        const newData = { ...data }
        newData.image.base64 = base64
        setData(newData)
    }
    function handleButton(type) {
        if (type === 'Sair') {
            handleDismiss()
            return
        }
        saveData()
    }
    function changeData(value, type) {
        const newData = { ...data }
        newData[type] = value
        setData(newData)
    }
    function handleDismiss() {
        updateModParc(false)
    }

    async function saveData() {
        if (data.nome.length <= 3) {
            updateModalInfo(true, true, 'Atenção', 'Campo nome precisa ter no mínimo 3 caracteres.', 'exclamation')
            return
        }
        if (!data.senha && !prevData) {
            updateModalInfo(true, true, 'Atenção', 'Campo senha precisa ser preenchido.', 'exclamation')
            return
        }
        if (data.senha && data.senha.length <= 5) {
            updateModalInfo(true, true, 'Atenção', 'Campo senha precisa ter no mínimo 5 caracteres.', 'exclamation')
            return
        }
        if (!TestaEMAIL(data.email)) {
            updateModalInfo(true, true, 'Atenção', 'O E-mail preenchido é inválido.', 'exclamation')
            return
        }
        updateModalInfo(true, false, 'Atenção', 'Tentando salvar os dados.', 'loading')
        const body = {
            nome: data.nome,
            email: data.email,
            senha: data.senha,
            image: !data.image.base64 ? null : data.image.base64.includes('data:image/jpeg;base64,') ? data.image.base64.replace('data:image/jpeg;base64,', '') : data.image.base64,
        }
        if (prevData) {
            body.id = prevData?.id
        }
        const endpoint = prevData ? 'editParceiro' : 'newParceiro'
        const method = prevData ? 'PUT' : 'POST'
        const trySave = await mainApi(`parceirosmp/${endpoint}`, method, body, user?.data?.token)
        if (trySave?.status !== 200) {
            const message = trySave?.apiReturn?.message ? trySave?.apiReturn?.message : 'Ocorreu um erro ao salvar o parceiro.'
            updateModalInfo(true, true, 'Atenção', message, 'exclamation')
            return
        }
        const dados = trySave?.apiReturn?.apiReturn
        body.id = dados.id
        if (dados?.image) {
            body.image = dados.image
        }
        if (!prevData) {
            body.status = 1
            pushParceiro(body)
            updateModalInfo(true, true, 'Sucesso', 'O parceiro foi inserido no servidor.', 'sucess')
            return
        }
        editParceiro(body)
        updateModalInfo(true, true, 'Sucesso', 'O parceiro foi editado.', 'sucess')
    }
    function updateData(nome, email, image) {
        const newData = { ...data }
        newData.nome = nome
        newData.email = email
        newData.image.link = image
        setData(newData)
    }
    useEffect(() => {
        if (prevData) {
            updateData(prevData?.nome, prevData?.email, prevData?.image)
        }
        else {
            setData(dataInit)
        }
    }, [visible])

    return (
        <Modal open={visible} onClose={handleDismiss} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 2 }} slotProps={{
            backdrop: {
                sx: {
                    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(250,255,255, 0.2)' : 'rgba(0,0,0, 0.4)'
                }
            }
        }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', height: 550, width: 650, background: theme.palette.background.default, outline: 'none' }}>
                <Box sx={{ background: theme.palette.background.default, padding: 5, paddingBottom: 2 }}>
                    <Typography sx={{ fontWeight: 'bold', color: theme.palette.text.primary, fontSize: 30 }}>Parceiro</Typography>
                    <Typography sx={{ color: theme.palette.text.primary, fontSize: 18 }}>Preencha as informações a baixo e salve para aplicar as alterações.</Typography>
                </Box>
                <Box sx={{
                    display: 'flex', flexDirection: 'column', flex: 1,
                    padding: 5,
                    gap: 3,
                    justifyContent: 'space-between'
                }}>
                    <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap' }}>
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                            <UserAvatar icon={getIcon(33, theme.palette.mode === 'dark' ? theme.palette.text.primary : theme.palette.primary.main, 80)} avatar={data?.image.link ? data?.image?.link + '?t=' + new Date().getTime() : data?.image.link} avatarBase64={data.image.base64} editable={true} funcReturn={handleAvatar} />
                        </Box>
                        {Object.keys(data).map((e) => {
                            if (['nome', 'email', 'senha'].includes(e)) {
                                const width = e === 'nome' ? '100%' : '48%'
                                const security = e === 'senha' ? true : false
                                return (
                                    <CTextField prevData={data} label={nameToNome[e]} width={width} security={security} value={data[e]} onChangeText={changeData} type={e} />
                                )
                            }
                        })}
                    </Box>
                    <Box sx={{ display: 'flex', gap: 2 }}>
                        {['Salvar', 'Sair'].map((e, idx) => {
                            const background = e === 'Salvar' ? theme.palette.primary.main : theme.palette.background.paper
                            const color = e === 'Salvar' ? theme.palette.text.whiteText : theme.palette.text.primary
                            return (
                                <ButtonSubmit key={idx} text={e} background={background} color={color} func={handleButton} funcParameters={e} />
                            )
                        })}
                    </Box>
                </Box>
                <ModalInfo hideModal={() => updateModalInfo(false)} canDesactive={MInfo.canDesactive} visible={MInfo.visible} title={MInfo.title} subtitle={MInfo.subtitle} icon={MInfo.icon} funcS={MInfo.funcS} />
            </Box>
        </Modal >
    );
}

const dataInit = {
    image: {
        link: null,
        base64: null
    },
    nome: '',
    email: '',
    senha: ''
}

const nameToNome = {
    nome: 'Nome',
    email: 'E-mail',
    senha: 'Senha'
}