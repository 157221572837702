import React, { useEffect, useState, useContext } from "react";
import { useTheme } from '@mui/material/styles';
import { Box, Typography, Button, Modal, Menu, MenuItem, Divider, TextField, InputLabel, FormControl, Select, Chip, IconButton } from '@mui/material';
import mainApi from "../../apis/mainApi";
import { User } from '../../contexts/user'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import OutlinedInput from '@mui/material/OutlinedInput';
import { getEvento } from "../../components/getEvento";
import { realMask } from "../../components/masks";
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DoNotDisturbOnIcon from '@mui/icons-material/DoNotDisturbOn';

export default props => {
    const theme = useTheme()
    const user = useContext(User)
    let data = props?.data?.openData?.content
    const newDataInitial = {
        editable: false,
        id: '',
        nome: '',
        quantidade: '',
        preco: '00,00',
        nVlPeso: '00,00',
        nVlAltura: '00,00',
        nVlLargura: '00,00',
        nVlComprimento: '00,00',
        nVlDiametro: '00,00',
        nCdFormato: '00,00',
        image: '',
        imageBase64: '',
        aplicacao: '',
        status: '',
    }
    const [newData, setNewData] = useState(newDataInitial)
    const [menuStatus, setMenuStatus] = useState({
        open: false,
        anchorEl: null
    })
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };
    async function handleUploadFile(event) {
        let selectedFile = event.target.files;
        async function resolveArquive() {
            return new Promise((resolve, reject) => {
                if (selectedFile.length > 0) {
                    let fileToLoad = selectedFile[0];
                    let fileName = fileToLoad.name;
                    let file = null
                    let fileReader = new FileReader();
                    fileReader.onload = (fileLoadedEvent) => {
                        file = fileLoadedEvent.target.result;
                        resolve([file, fileName])
                    };
                    fileReader.readAsDataURL(fileToLoad);
                }
            })
        }
        const getArquiveInfo = await resolveArquive()

        const x = { ...newData }
        x.imageBase64 = getArquiveInfo[0]
        setNewData(x)
    }

    async function changeData(value) {
        props.updateModalLoading(true)
        function FCallback(callback) {
            props.updateModalLoading(false)
            const x = {}
            if (callback.status !== 200) {
                x.title = 'Erro'
                x.subtitle = callback.apiReturn.message ? callback.apiReturn.message : 'Ocorreu um erro! Tente novamente mais tarde'
                x.body = <Button onClick={() => props.resetModalInfo()} sx={{ mb: 2, backgroundColor: theme.palette.secondary.main, color: theme.palette.text.primary, '&:hover': { backgroundColor: theme.palette.secondary.dark }, borderRadius: 0, padding: 1 }}>
                    <Typography fontWeight={'bold'} sx={{ color: theme.palette.text.whiteText, textAlign: 'center' }}>Voltar</Typography>
                </Button>
                x.active = true
                props.updateModalInfo(x)
                return
            }
            const formatData = { ...props.data }
            const findIndexAplicacao = formatData.content.findIndex((e) => e.aplicacao.id === newData.aplicacao)
            const findIndexProduto = formatData.content[findIndexAplicacao].produtos.findIndex((e) => e.id === newData.id)
            if (value) {
                const filterData = formatData.content[findIndexAplicacao].produtos.filter((e) => e.id !== newData.id)
                formatData.content[findIndexAplicacao].produtos = filterData
            }
            else if (props.data.openData.edit === 2) {
                const f = { ...newData }
                f.image = callback.apiReturn.apiReturn
                formatData.content[findIndexAplicacao].produtos[findIndexProduto] = f
            }
            else {
                const updateData = { ...newData }
                updateData.image = callback.apiReturn.apiReturn.image
                updateData.id = callback.apiReturn.apiReturn.id
                formatData.content[findIndexAplicacao].produtos.push(updateData)
                setNewData(updateData)
            }
            props.updateData(formatData)

            x.title = 'Sucesso'
            x.subtitle = callback.apiReturn.message ? callback.apiReturn.message : 'Os dados foram salvos com sucesso.'
            x.body = <Button onClick={() => {
                if (value) {
                    setNewData(newDataInitial)
                    props.resetOpenData()
                }
                props.resetModalInfo()
            }} sx={{ mb: 2, backgroundColor: theme.palette.secondary.main, color: theme.palette.text.primary, '&:hover': { backgroundColor: theme.palette.secondary.dark }, borderRadius: 0, padding: 1 }}>
                <Typography fontWeight={'bold'} sx={{ color: theme.palette.text.whiteText, textAlign: 'center' }}>Voltar</Typography>
            </Button>
            x.active = true
            props.updateModalInfo(x)
        }
        const uploadedData = { ...newData }
        delete uploadedData.editable
        uploadedData.nCdFormato = uploadedData.nCdFormato
        uploadedData.nVlAltura = uploadedData.nVlAltura
        uploadedData.nVlLargura = uploadedData.nVlLargura
        uploadedData.nVlComprimento = uploadedData.nVlComprimento
        uploadedData.nVlDiametro = uploadedData.nVlDiametro
        uploadedData.nVlPeso = uploadedData.nVlPeso
        uploadedData.aplicacao = parseInt(uploadedData.aplicacao)
        uploadedData.preco = uploadedData.preco
        uploadedData.quantidade = parseInt(uploadedData.quantidade)
        uploadedData.status = parseInt(uploadedData.status)
        uploadedData.imageBase64 = ['', null, undefined].includes(uploadedData.imageBase64) ? '' : uploadedData.imageBase64.replace('data:image/png;base64,', '')
        const method = value ? 'DELETE' : props.data.openData.edit === 2 ? 'PUT' : 'POST'
        const endpoint = value ? 'produtos/' + newData.id : 'produtos'
        await mainApi(endpoint, method, uploadedData, FCallback, user.data.token)
    }
    useEffect(() => {
        if (props.data.openData.edit === 2 && [null, undefined, ''].includes(newData.aplicacao)) {
            const x = { ...newData }
            x.editable = true
            x.id = props.data.openData.content.id
            x.image = props.data.openData.content.image
            x.nCdFormato = props.data.openData.content.nCdFormato
            x.nVlAltura = typeof props.data.openData.content.nVlAltura === 'number' ? realMask(props.data.openData.content.nVlAltura.toFixed(2).toString()) : props.data.openData.content.nVlAltura
            x.nVlComprimento = typeof props.data.openData.content.nVlComprimento === 'number' ? realMask(props.data.openData.content.nVlComprimento.toFixed(2).toString()) : props.data.openData.content.nVlComprimento
            x.nVlDiametro = typeof props.data.openData.content.nVlDiametro === 'number' ? realMask(props.data.openData.content.nVlDiametro.toFixed(2).toString()) : props.data.openData.content.nVlDiametro
            x.nVlLargura = typeof props.data.openData.content.nVlLargura === 'number' ? realMask(props.data.openData.content.nVlLargura.toFixed(2).toString()) : props.data.openData.content.nVlLargura
            x.nVlPeso = typeof props.data.openData.content.nVlPeso === 'number' ? realMask(props.data.openData.content.nVlPeso.toFixed(2).toString()) : props.data.openData.content.nVlPeso
            x.nome = props.data.openData.content.nome
            x.preco = typeof props.data.openData.content.preco === 'number' ? realMask(props.data.openData.content.preco.toFixed(2).toString()) : props.data.openData.content.preco
            x.quantidade = props.data.openData.content.quantidade
            x.aplicacao = props.data.openData.content.aplicacao
            x.status = props.data.openData.content.status
            setNewData(x)
        }
        else {
            if ([null, undefined, ''].includes(newData.aplicacao)) {
                const x = { ...newDataInitial }
                x.aplicacao = props.data.openData.content.aplicacao
                setNewData(x)
            }
        }
    }, [props.data])
    return (
        <Modal
            open={props.active}
            onClose={() => {
                setNewData(newDataInitial)
                props.resetOpenData()
            }}
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                [theme.breakpoints.down('sm')]: {
                    alignItems: 'flex-start',
                    paddingTop: 3
                },
            }}
        >
            <Box sx={{
                width: '45%',
                height: 'auto',
                backgroundColor: theme.palette.background.default,
                border: 1,
                borderColor: theme.palette.secondary.main,
                position: 'absolute',
                [theme.breakpoints.down('lg')]: {
                    width: '70%'
                },
                [theme.breakpoints.down('md')]: {
                    width: '90%'
                },
            }}>
                <Box sx={{ display: 'flex', justifyContent: 'flex-start', padding: 5, paddingBottom: 1 }}>
                    <Typography fontWeight={'bold'} sx={{ fontSize: 30 }}>{props.data.openData.edit === 1 ? 'Criar novo Produto' : 'Editar Produto'}</Typography>
                </Box>
                {props.data.openData.edit === 2 ?
                    <Box sx={{
                        position: 'absolute',
                        top: '5%',
                        right: '5%',
                        width: '25%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: 1,
                        padding: 1,
                        backgroundColor: newData.status === 1 ? theme.palette.primary.main : theme.palette.grey[700],
                        borderRadius: 1
                    }}>
                        {newData.status === 1 ?
                            <CheckCircleIcon sx={{ color: theme.palette.text.whiteText, fontSize: 20 }} />
                            : <DoNotDisturbOnIcon sx={{ color: theme.palette.text.whiteText, fontSize: 20 }} />}
                        <Typography fontWeight={'bold'} sx={{ fontSize: 20, color: theme.palette.text.whiteText }}>{newData.status === 1 ? 'Ativo' : 'Inativo'}</Typography>
                    </Box>
                    : null}
                <Box sx={{
                    padding: 5, paddingTop: 1
                }}>
                    <Box sx={{
                        display: 'flex', flexDirection: 'column', width: '50%', gap: 3,
                        [theme.breakpoints.down('md')]: {
                            width: '98%'
                        },
                    }}>
                        <Typography fontWeight={'bold'} fontSize={20}>Informações do Produto</Typography>
                        <Box sx={{ display: 'flex', width: '100%' }}>
                            <TextField
                                label="Nome"
                                margin="none"
                                value={newData.nome}
                                disabled={newData.editable}
                                id="nom_produto"
                                name="nom_produto"
                                onChange={(value) => {
                                    const x = { ...newData }
                                    x.nome = value.target.value
                                    setNewData(x)
                                }}
                                sx={{
                                    width: '100%'
                                }}
                                InputLabelProps={{
                                    style: {
                                        color: theme.palette.text.secondary,
                                        borderColor: '#eee'
                                    }
                                }}
                                autoComplete=""
                                inputProps={{
                                    style: {
                                        backgroundColor: theme.palette.background.paper,
                                        borderRadius: 5
                                    }
                                }}
                            />
                        </Box>
                        <Box sx={{ display: 'flex', gap: 1, width: '100%' }}>
                            <TextField
                                disabled={newData.editable}
                                label="Quantidade"
                                margin="none"
                                value={newData.quantidade}
                                /* disabled={errors.cep === true ? true : props.form.cep === '' ? true : false} */
                                id="quant_produto"
                                name="quant_produto"
                                onChange={(value) => {
                                    const x = { ...newData }
                                    x.quantidade = value.target.value.replace(/\D/g, "")
                                    setNewData(x)
                                }}
                                InputLabelProps={{
                                    style: {
                                        color: theme.palette.text.secondary,
                                        borderColor: '#eee'
                                    }
                                }}
                                autoComplete=""
                                inputProps={{
                                    style: {
                                        backgroundColor: theme.palette.background.paper,
                                        borderRadius: 5
                                    }
                                }}
                            />
                            <TextField
                                disabled={newData.editable}
                                label="Preço"
                                margin="none"
                                value={newData.preco}
                                /* disabled={errors.cep === true ? true : props.form.cep === '' ? true : false} */
                                id="preco_produto"
                                name="preco_produto"
                                onChange={(value) => {
                                    const x = { ...newData }
                                    x.preco = realMask(value.target.value)
                                    setNewData(x)
                                }}
                                InputLabelProps={{
                                    style: {
                                        color: theme.palette.text.secondary,
                                        borderColor: '#eee'
                                    }
                                }}
                                autoComplete=""
                                inputProps={{
                                    style: {
                                        backgroundColor: theme.palette.background.paper,
                                        borderRadius: 5
                                    }
                                }}
                            />
                        </Box>
                    </Box>
                    <Box sx={{
                        display: 'flex', flexDirection: 'column', width: '50%', gap: 3, marginTop: 5,
                        [theme.breakpoints.down('md')]: {
                            width: '98%'
                        },
                    }}>
                        <Typography fontWeight={'bold'} fontSize={20}>Informações de Entrega</Typography>
                        <Box sx={{ display: 'flex', gap: 1 }}>
                            <TextField
                                disabled={newData.editable}
                                label="Altura (cm)"
                                margin="none"
                                value={newData.nVlAltura}
                                /* disabled={errors.cep === true ? true : props.form.cep === '' ? true : false} */
                                id="altura_produto"
                                name="altura_produto"
                                onChange={(value) => {
                                    const x = { ...newData }
                                    x.nVlAltura = realMask(value.target.value)
                                    setNewData(x)
                                }}
                                InputLabelProps={{
                                    style: {
                                        color: theme.palette.text.secondary,
                                        borderColor: '#eee'
                                    }
                                }}
                                autoComplete=""
                                inputProps={{
                                    style: {
                                        backgroundColor: theme.palette.background.paper,
                                        borderRadius: 5
                                    }
                                }}
                            />
                            <TextField
                                disabled={newData.editable}
                                label="Largura (cm)"
                                margin="none"
                                value={newData.nVlLargura}
                                /* disabled={errors.cep === true ? true : props.form.cep === '' ? true : false} */
                                id="largura_produto"
                                name="largura_produto"
                                onChange={(value) => {
                                    const x = { ...newData }
                                    x.nVlLargura = realMask(value.target.value)
                                    setNewData(x)
                                }}
                                InputLabelProps={{
                                    style: {
                                        color: theme.palette.text.secondary,
                                        borderColor: '#eee'
                                    }
                                }}
                                autoComplete=""
                                inputProps={{
                                    style: {
                                        backgroundColor: theme.palette.background.paper,
                                        borderRadius: 5
                                    }
                                }}
                            />
                        </Box>
                        <Box sx={{
                            display: 'flex', gap: 1,
                            [theme.breakpoints.down('md')]: {
                                width: '98%'
                            },
                        }}>
                            <TextField
                                disabled={newData.editable}
                                label="Comprimento (cm)"
                                margin="none"
                                value={newData.nVlComprimento}
                                /* disabled={errors.cep === true ? true : props.form.cep === '' ? true : false} */
                                id="comprimento_produto"
                                name="comprimento_produto"
                                onChange={(value) => {
                                    const x = { ...newData }
                                    x.nVlComprimento = realMask(value.target.value)
                                    setNewData(x)
                                }}
                                InputLabelProps={{
                                    style: {
                                        color: theme.palette.text.secondary,
                                        borderColor: '#eee'
                                    }
                                }}
                                autoComplete=""
                                inputProps={{
                                    style: {
                                        backgroundColor: theme.palette.background.paper,
                                        borderRadius: 5
                                    }
                                }}
                            />
                            <TextField
                                disabled={newData.editable}
                                margin="none"
                                value={newData.nVlDiametro}
                                /* disabled={errors.cep === true ? true : props.form.cep === '' ? true : false} */
                                id="diametro_produto"
                                name="diametro_produto"
                                label="Diâmetro (cm)"
                                onChange={(value) => {
                                    const x = { ...newData }
                                    x.nVlDiametro = realMask(value.target.value)
                                    setNewData(x)
                                }}
                                InputLabelProps={{
                                    style: {
                                        color: theme.palette.text.secondary,
                                        borderColor: '#eee'
                                    }
                                }}
                                autoComplete=""
                                inputProps={{
                                    style: {
                                        backgroundColor: theme.palette.background.paper,
                                        borderRadius: 5
                                    }
                                }}
                            />
                        </Box>
                        <Box sx={{
                            display: 'flex', gap: 2
                        }}>
                            {props.data.openData.edit === 2 ?
                                <Box sx={{
                                    height: '100%',
                                    border: 1,
                                    borderColor: theme.palette.grey[400],
                                }}>
                                    <Button
                                        id="basic-button"
                                        aria-controls={menuStatus.open ? 'basic-menu' : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={menuStatus.open ? 'true' : undefined}
                                        disabled={newData.editable}
                                        onClick={(value) => {
                                            if (getEvento(props.eventos, 'Trocar Status') === false) {
                                                const modalInfo = {
                                                    active: true,
                                                    title: 'Permissão',
                                                    subtitle: 'Você não tem permissão para trocar status',
                                                    body: <Button onClick={() => props.resetModalInfo()} sx={{ mb: 2, backgroundColor: theme.palette.secondary.main, color: theme.palette.text.primary, '&:hover': { backgroundColor: theme.palette.secondary.dark }, borderRadius: 0, padding: 1 }}>
                                                        <Typography fontWeight={'bold'} sx={{ color: theme.palette.text.whiteText, textAlign: 'center' }}>Voltar</Typography>
                                                    </Button>
                                                }
                                                props.updateModalInfo(modalInfo)
                                                return
                                            }
                                            const newMenu = { ...menuStatus }
                                            newMenu.anchorEl = value.currentTarget
                                            newMenu.open = true
                                            setMenuStatus(newMenu)
                                        }}
                                        sx={{ padding: 2, borderRadius: 0 }}
                                    >
                                        <Typography sx={{ textAlign: 'center', color: newData.editable ? theme.palette.grey[500] : theme.palette.text.primary }}>{newData.status === 1 ? 'Ativo' : 'Inativo'}</Typography>
                                    </Button>
                                    <Menu
                                        id="basic-menu"
                                        anchorEl={menuStatus.anchorEl}
                                        open={menuStatus.open}
                                        onClose={() => {
                                            const newMenu = { ...menuStatus }
                                            newMenu.anchorEl = null
                                            newMenu.open = false
                                            setMenuStatus(newMenu)
                                        }}
                                        MenuListProps={{
                                            'aria-labelledby': 'basic-button',
                                        }}
                                    >
                                        <MenuItem sx={{ width: '100%' }} onClick={() => {
                                            const formatData = { ...newData }
                                            formatData.status = 1
                                            setNewData(formatData)
                                            const newMenu = { ...menuStatus }
                                            newMenu.anchorEl = null
                                            newMenu.open = false
                                            setMenuStatus(newMenu)
                                        }}>Ativo</MenuItem>
                                        <Divider />
                                        <MenuItem onClick={() => {
                                            const formatData = { ...newData }
                                            formatData.status = 0
                                            setNewData(formatData)
                                            const newMenu = { ...menuStatus }
                                            newMenu.anchorEl = null
                                            newMenu.open = false
                                            setMenuStatus(newMenu)
                                        }}>Inativo</MenuItem>
                                    </Menu>
                                </Box>
                                : null}
                        </Box>
                        <Box>
                            <Typography fontWeight={'bold'} fontSize={20}>Imagem do produto</Typography>
                            <Button component="label">
                                <img src={[null, undefined, ''].includes(newData.imageBase64) && [null, undefined, ''].includes(newData.image) ? '../assets/images/produtos/no-image.png' : ![null, undefined, ''].includes(newData.imageBase64) ? newData.imageBase64 : newData.image} style={{ objectFit: 'contain', width: '30%' }} />
                                {newData.editable === false ?
                                    <input
                                        accept=".png"
                                        type="file"
                                        hidden
                                        onChange={(value) => handleUploadFile(value)}
                                    />
                                    : null}
                            </Button>
                        </Box>
                        <Box sx={{
                            display: 'flex', gap: 2
                        }}>
                            {props.data.openData.edit === 2 ?
                                <IconButton
                                    onClick={() => {
                                        if (getEvento(props.eventos, 'Editar Produto') === false) {
                                            const modalInfo = {
                                                active: true,
                                                title: 'Permissão',
                                                subtitle: 'Você não tem permissão para editar o produto',
                                                body: <Button onClick={() => props.resetModalInfo()} sx={{ mb: 2, backgroundColor: theme.palette.secondary.main, color: theme.palette.text.primary, '&:hover': { backgroundColor: theme.palette.secondary.dark }, borderRadius: 0, padding: 1 }}>
                                                    <Typography fontWeight={'bold'} sx={{ color: theme.palette.text.whiteText, textAlign: 'center' }}>Voltar</Typography>
                                                </Button>
                                            }
                                            props.updateModalInfo(modalInfo)
                                            return
                                        }
                                        const x = { ...newData }
                                        x.editable = !x.editable
                                        setNewData(x)
                                    }}
                                    sx={{
                                        backgroundColor: theme.palette.primary.main,
                                        borderRadius: 0,
                                        '&:hover': {
                                            backgroundColor: theme.palette.secondary.main,
                                        }
                                    }}>
                                    <EditIcon sx={{ color: theme.palette.text.whiteText }} />
                                    {/* <Typography fontWeight={'bold'} sx={{ color: theme.palette.text.whiteText }}>Editar</Typography> */}
                                </IconButton>
                                : null}
                            <IconButton

                                onClick={() => {
                                    if (newData.editable === false) {
                                        changeData()
                                    }
                                }}
                                sx={{
                                    backgroundColor: newData.editable === true ? theme.palette.grey[600] : theme.palette.primary.main,
                                    borderRadius: 0,
                                    '&:hover': {
                                        backgroundColor: newData.editable === false ? theme.palette.secondary.main : theme.palette.grey[600]
                                    }
                                }}>
                                {props.data.openData.edit === 2 ?
                                    <SaveIcon sx={{ color: theme.palette.text.whiteText }} />
                                    :
                                    <Typography fontWeight={'bold'} sx={{ color: theme.palette.text.whiteText }}>Salvar</Typography>
                                }
                            </IconButton>
                            {props.data.openData.edit === 2 ?
                                <IconButton
                                    onClick={() => {
                                        if (newData.editable === true) return
                                        if (getEvento(props.eventos, 'Excluir Produto') === false) {
                                            const modalInfo = {
                                                active: true,
                                                title: 'Permissão',
                                                subtitle: 'Você não tem permissão para excluir o produto',
                                                body: <Button onClick={() => props.resetModalInfo()} sx={{ mb: 2, backgroundColor: theme.palette.secondary.main, color: theme.palette.text.primary, '&:hover': { backgroundColor: theme.palette.secondary.dark }, borderRadius: 0, padding: 1 }}>
                                                    <Typography fontWeight={'bold'} sx={{ color: theme.palette.text.whiteText, textAlign: 'center' }}>Voltar</Typography>
                                                </Button>
                                            }
                                            props.updateModalInfo(modalInfo)
                                            return
                                        }
                                        const modalInfo = {
                                            active: true,
                                            title: 'Exclusão',
                                            subtitle: 'Você tem certeza que deseja excluir o produto?',
                                            body:
                                                <Box sx={{ display: 'flex', gap: 2 }}>
                                                    <Button onClick={() => props.resetModalInfo()} sx={{ mb: 2, backgroundColor: theme.palette.secondary.main, color: theme.palette.text.primary, '&:hover': { backgroundColor: theme.palette.secondary.dark }, borderRadius: 0, padding: 1 }}>
                                                        <Typography fontWeight={'bold'} sx={{ color: theme.palette.text.whiteText, textAlign: 'center' }}>Voltar</Typography>
                                                    </Button>
                                                    <Button onClick={() => changeData('delete')} sx={{ mb: 2, backgroundColor: theme.palette.secondary.main, color: theme.palette.text.primary, '&:hover': { backgroundColor: theme.palette.secondary.dark }, borderRadius: 0, padding: 1 }}>
                                                        <Typography fontWeight={'bold'} sx={{ color: theme.palette.text.whiteText, textAlign: 'center' }}>Sim</Typography>
                                                    </Button>
                                                </Box>
                                        }
                                        props.updateModalInfo(modalInfo)
                                    }}
                                    sx={{
                                        backgroundColor: newData.editable === true ? theme.palette.grey[600] : theme.palette.primary.main,
                                        borderRadius: 0,
                                        '&:hover': {
                                            backgroundColor: newData.editable === true ? theme.palette.grey[600] : theme.palette.primary.main,
                                        }
                                    }}>
                                    <DeleteIcon sx={{ color: theme.palette.text.whiteText }} />
                                    {/* <Typography fontWeight={'bold'} sx={{ color: theme.palette.text.whiteText }}>Excluir</Typography> */}
                                </IconButton>
                                : null}
                        </Box>
                    </Box>
                </Box>
            </Box >
        </Modal >
    )
}