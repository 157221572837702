import { useEffect, useState, useRef, createRef, useMemo } from "react";
import { Box, Typography, CircularProgress, Modal, useTheme, Button, Avatar, Popover, Checkbox, LinearProgress, SliderThumb } from "@mui/material"
import useWindowDimensions from "../../components/useWindowDimensions";
import { useLocation } from 'react-router-dom'
import requestType from "../../apis/apiMetas";
import EmojiEvents from "@mui/icons-material/EmojiEvents";
import Refresh from "@mui/icons-material/Refresh";
import useMediaQuery from "@mui/material/useMediaQuery"
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectFade, Autoplay, Grid } from 'swiper/modules'
import { ResponsivePie } from "@nivo/pie";
import { BasicTooltip } from '@nivo/tooltip';
import moment from "moment";
import './index.css'
import 'swiper/css';
import 'swiper/css/navigation';
import "swiper/css/grid";
import Cookies from 'universal-cookie';
import HelpIcon from '@mui/icons-material/Help';

export default props => {
    //consts
    const theme = useTheme()
    const isNonMobile = useMediaQuery("(min-width:850px)")
    const userLocation = useLocation().search.substring(1)
    const screenHeight = useWindowDimensions().height
    const screenWidht = useWindowDimensions().width
    const cookies = new Cookies()
    document.title = 'Metas'
    const deviceInitialsValues = {
        open: false,
        key: '',
        title: 'Dispositivo',
        subtitle: 'Siga o procedimento abaixo para utilizar esse dispositivo',
        body: '',
        button: <Button sx={{
            background: getTheme().palette.background.default,
            border: '1px solid #000'
        }} onClick={(event) => {
            setDeviceInfo(deviceInitialsValues)
            getData()
        }}>
            <Refresh sx={{ color: 'orange', width: screenHeight / 20, height: 'auto' }} />
            <Typography sx={{ color: theme.palette.mode === 'dark' ? theme.palette.text.primary : '#000', fontWeight: 'bold' }}>Recarregar</Typography>
        </Button>
    }

    // Reffs
    const swiperRef = useRef()
    const swiperInsideRef = []
    const valueRef = useRef(1)
    // states
    const [data, setData] = useState({
        user: '',
        metas: []
    })
    const [boxShadow, setBoxShadow] = useState('')
    const [boxShadowOrange, setBoxShadowOrange] = useState('')
    const [boxShadowGreen, setBoxShadowGreen] = useState('')
    const [stopAnimations, setStopAnimations] = useState(false)
    const [dataLength, setDataLength] = useState({
        total: 0,
        atual: 0
    })
    const [start, setStart] = useState(false)
    const [deviceInfo, setDeviceInfo] = useState(deviceInitialsValues)
    const manutencao = false
    const [loading, setLoading] = useState(false)
    const [popover, setPopover] = useState({
        id: 0,
        text: {
            metaName: '',
            typeName: '',
            vendor: '',
            value: 0,
            meta_value: 0,
        },
        active: false,
        anchor: null
    })
    const [animationData, setAnimationData] = useState(null)
    //functions

    function changeModalVisible() {
        setLoading(false)
    }
    function getTheme() {
        return theme
    }
    function getCookies() {
        const device = cookies.get('device')
        const user = cookies.get('userVendors')
        const animation = cookies.get('animation')
        if (animation) {
            const newAnimation = animation === 'false' ? false : animation === 'true' ? true : animation
            setStopAnimations(newAnimation)
        }
        if (device) {
            const deviceAtive = cookies.get('ative')
            if (user != userLocation) {
                return [3, device]
            }
            if (deviceAtive == 'true') {
                return [1]
            }
            else {
                return [2, device]
            }
        }
        else {
            var key = []
            for (var i = 0; i < 5; i++) {
                const x = Math.floor(Math.random() * 10)
                key.push(x)
            }
            cookies.set('device', key.toString(), { path: '/metas', sameSite: 'lax', maxAge: 1000000000000 });
            return [3, key.toString()]
        }
    }

    async function apiMetas(index, newData, reload) {
        function callBackReturn(callback) {
            if (callback?.apiReturn?.index == 'false') {
                setData(newData)
                setStart(true)
                setLoading(false)
            }
            if (callback?.status != 200 || callback?.apiReturn?.status != '200') {
                setLoading(false)
            }
            else {
                if (index === 0) {
                    if (!reload) {
                        const newDataLength = { ...dataLength }
                        newDataLength.atual = callback?.apiReturn.index
                        newDataLength.total = callback?.apiReturn.length
                        setDataLength(newDataLength)
                        var x = callback?.apiReturn?.apiReturn
                        setTimeout(() => {
                            apiMetas(callback?.apiReturn.index + 1, x)
                        }, 3000)
                        return
                    }
                    else {
                        var x = callback?.apiReturn?.apiReturn
                        setTimeout(() => {
                            apiMetas(callback?.apiReturn.index + 1, x, reload)
                        }, 3000)
                        return
                    }
                }
                if (index != 'false') {
                    if (!reload) {
                        const newDataLength = { ...dataLength }
                        newDataLength.atual = callback?.apiReturn.index
                        newDataLength.total = callback?.apiReturn.length
                        setDataLength(newDataLength)
                        const x = { ...newData }
                        if (Array.isArray(callback?.apiReturn?.apiReturn.metas)) {
                            const j = x.metas.findIndex((e) => e.vendor.id == callback?.apiReturn?.apiReturn.metas[0].vendor.id)
                            if (j !== -1) {
                                x.metas[j].data.push(callback?.apiReturn?.apiReturn.metas[0].data[0])
                            }
                            else {
                                x.metas.push(callback?.apiReturn?.apiReturn.metas[0])
                            }
                        }
                        setTimeout(() => {
                            apiMetas(callback?.apiReturn.index + 1, x)
                        }, 3000)
                        return
                    }
                    else {
                        const x = { ...newData }
                        if (Array.isArray(callback?.apiReturn?.apiReturn.metas)) {
                            const j = x.metas.findIndex((e) => e.vendor.id == callback?.apiReturn?.apiReturn.metas[0].vendor.id)
                            if (j !== -1) {
                                x.metas[j].data.push(callback?.apiReturn?.apiReturn.metas[0].data[0])
                            }
                            else {
                                x.metas.push(callback?.apiReturn?.apiReturn.metas[0])
                            }
                        }
                        setTimeout(() => {
                            apiMetas(callback?.apiReturn.index + 1, x, reload)
                        }, 3000)
                    }
                }
            }
        }
        await requestType('metas/vendors/' + userLocation + '/' + index, 'Get', null, callBackReturn)
    }

    async function getData(ative) {
        if (ative) {
            apiMetas(0, null, 1)
            return
        }
        setLoading(true)
        const m = getCookies()
        if (m[0] == 1) {
            apiMetas(0)
        }
        if (m[0] == 2) {
            const body = {
                user: userLocation,
                device: m[1]
            }
            async function getDeviceAuth(callback) {
                if (callback?.status != 200 || callback?.apiReturn?.status != '200') {
                    var device = m[1]
                    var str = new RegExp(',', 'g')
                    var device = device.replace(str, '')
                    const deviceModal = { ...deviceInfo }
                    deviceModal.open = true
                    deviceModal.body = callback?.apiReturn?.message
                    deviceModal.key = device
                    setDeviceInfo(deviceModal)
                }
                else {
                    apiMetas(0)
                    cookies.set('ative', true, { path: '/metas' });
                    cookies.set('userVendors', userLocation, { path: '/metas' });
                }

            }
            await requestType('metas/device/', 'POST', body, getDeviceAuth)
        }
        if (m[0] == 3) {
            const body = {
                user: userLocation,
                device: m[1]
            }
            async function getDeviceAuth(callback) {
                if (callback?.status != 200 || callback?.apiReturn?.status != '200') {
                    var device = m[1]
                    var str = new RegExp(',', 'g')
                    var device = device.replace(str, '')
                    const deviceModal = { ...deviceInfo }
                    deviceModal.open = true
                    deviceModal.body = callback?.apiReturn?.message
                    deviceModal.key = device
                    setDeviceInfo(deviceModal)
                }
                else {
                    apiMetas(0)
                    cookies.set('ative', true, { path: '/metas' });
                    cookies.set('userVendors', userLocation, { path: '/metas' });
                }

            }
            await requestType('metas/device/', 'POST', body, getDeviceAuth)
        }
    }
    useEffect(() => {
        if (manutencao == true) {
            return
        }
        getData()
        const interval = setInterval(() => {
            if (navigator.onLine) {
                getData('ative');
            }
        }, 600000);
        return () => clearInterval(interval);
    }, [])
    function newInterval() {
        return valueRef.current
    }
    useEffect(() => {
        /* if (stopAnimations === true) {
            const otherInterval = setInterval(() => {
                setAnimationData(1)
                setTimeout(() => {
                    setAnimationData(null)
                }, 2000)
            }, 6000)
            return () => clearInterval(otherInterval);
        } */
    }, [stopAnimations])
    function setTimeInterval(newData) {
        var activeIndex = (swiperRef?.current?.swiper?.activeIndex + 1) == data.metas?.length ? 0 : swiperRef?.current?.swiper?.activeIndex + 1
        if (data.metas?.length == (swiperRef?.current?.swiper?.activeIndex + 1)) {
            let h = (swiperInsideRef[0]?.length / 6) * (swiperInsideRef[0]?.length / 6)
            let time = ((5500 * swiperInsideRef[0]?.length / 6) * Math.ceil(h)) + 1000
            time = time < 5500 ? 5500 : time
            swiperInsideRef[0].ref.current.swiper.autoplay.start()
            swiperRef?.current?.swiper.slideTo(0)
            swiperInsideRef[data.metas?.length - 1].ref.current.swiper.autoplay.stop()
            setTimeout(setTimeInterval, time)
            setTimeout(() => {
                swiperInsideRef[data.metas?.length - 1].ref.current.swiper.slideTo(0)
            }, 1000)
        }
        else {
            let h = (swiperInsideRef[activeIndex]?.length / 6) * (swiperInsideRef[activeIndex]?.length / 6)
            let time = ((5500 * swiperInsideRef[activeIndex]?.length / 6) * Math.ceil(h)) + 1000
            time = time < 5500 ? 5500 : time
            swiperInsideRef[activeIndex].ref.current.swiper.autoplay.start()
            swiperRef?.current?.swiper.slideNext()
            swiperInsideRef[activeIndex - 1].ref.current.swiper.autoplay.stop()
            setTimeout(() => {
                setTimeout(setTimeInterval, time)
                swiperInsideRef[activeIndex - 1].ref.current.swiper.slideTo(0)
            }, 1000)
        }
    }

    if (manutencao == true) {
        return (
            <Box style={{ display: 'flex', flexGrow: 1, backgroundColor: '#fff', justifyContent: 'center', alignItems: 'center' }}>
                <Typography color="#000" style={{ fontSize: '50px' }}>Módulo em manutenção</Typography>
            </Box>
        )
    }
    if (data.metas?.length > 0 && screenHeight > 500 & screenWidht > 900 && start == true) {
        const bandeira = data?.user.bandeira
        const colorAvatar = '#eee'
        return (
            <Swiper
                className="metas"
                ref={swiperRef}
                style={{ backgroundColor: theme.palette.background.default }}
                onInit={(value) => {
                    setTimeout(() => {
                        setTimeInterval()
                    }, 1000)
                }}
                modules={[EffectFade]}
                effect={'slide'}
            >
                {/* <Box style={{ position: 'absolute', left: 5, top: 5, display: 'flex', flexDirection: 'row', zIndex: 2, alignItems: 'center' }}>
                    <Checkbox
                        checked={stopAnimations}
                        onChange={() => changeAnimation()}
                        style={{ color: theme.palette.mode === 'dark' ? theme.palette.text.primary : '#000' }}
                    />
                    <Typography noWrap color={theme.palette.mode === 'dark' ? theme.palette.text.primary : "#474747"} fontWeight="bold" fontSize={screenHeight / 45}>Animações</Typography>
                </Box> */}
                {data.metas.map((element, index) => {
                    const newRef = createRef()
                    swiperInsideRef[index] = {
                        index: index,
                        length: element.data?.length,
                        ref: newRef
                    }
                    //Fidelidade
                    var filterFidelidade = element?.data.filter((e) => e?.metaList?.indicador === 1)
                    let progressFidelidadeTot = 0.00
                    let progressFidelidadeToday = 0.00
                    let metaFidelidade = 0.00
                    if (filterFidelidade.length > 0) {
                        let fidelidadeTot = 0.00
                        let fidelidade = 0.00
                        let todayFidelidade = 0.00
                        let todayFidelidadeTot = 0.00
                        metaFidelidade = filterFidelidade[0]?.metaList?.value
                        filterFidelidade[0].totalVendas.map((value) => {
                            if (value?.date === moment().format('YYYY-MM-DD')) {
                                todayFidelidadeTot = value?.totGer ? value?.totGer : 0.00
                                todayFidelidade = value?.total ? value?.total : 0.00
                            }
                            fidelidadeTot = fidelidadeTot + value?.totGer
                            fidelidade = fidelidade + value?.total
                        })
                        progressFidelidadeTot = [undefined, null].includes((fidelidade * 100) / fidelidadeTot) ? 0 : ((fidelidade * 100) / fidelidadeTot).toFixed(2)
                        progressFidelidadeToday = [undefined, null].includes((todayFidelidade * 100) / todayFidelidadeTot) ? 0 : ((todayFidelidade * 100) / todayFidelidadeTot).toFixed(2)
                    }
                    //Ticket Médio
                    const filterTicketMedio = element?.data.filter((e) => e?.metaList?.indicador === 2)
                    let atendimentosTotal = 0.00
                    let vlr_finalTotal = 0.00
                    let todayTM = 0.00
                    let todayAten = 0.00
                    let progressTMTot = 0.00
                    let progressTMToday = 0.00
                    let metaTicketMedio = 0.00
                    if (filterTicketMedio.length > 0) {
                        metaTicketMedio = filterTicketMedio[0]?.metaList?.value
                        filterTicketMedio[0].totalVendas.map((value) => {
                            if (value?.date === moment().format('YYYY-MM-DD')) {
                                todayTM = value?.vlr_final ? value?.vlr_final : 0.00
                                todayAten = value?.atendimentos ? value?.atendimentos : 0.00
                            }
                            atendimentosTotal = atendimentosTotal + value?.atendimentos
                            vlr_finalTotal = vlr_finalTotal + value?.vlr_final
                        })
                        progressTMTot = [undefined, null].includes(vlr_finalTotal / atendimentosTotal) ? 0.00 : (vlr_finalTotal / atendimentosTotal).toFixed(2)
                        progressTMToday = [undefined, null].includes(todayTM / todayAten) ? 0.00 : (todayTM / todayAten).toFixed(2)
                    }
                    //Rentabilidade
                    const filterRentabilidade = element?.data.filter((e) => e?.metaList?.indicador === 3)
                    let progressRentTot = 0.00
                    let progressRentToday = 0.00
                    let metaRentabilidade = 0.00
                    if (filterRentabilidade.length > 0) {
                        let vlr_custo = 0.00
                        let total = 0.00
                        metaRentabilidade = filterRentabilidade[0]?.metaList?.value
                        filterRentabilidade[0].totalVendas.map((value) => {
                            if (value?.date === moment().format('YYYY-MM-DD')) {
                                progressRentToday = value?.rentabilidade ? (value?.rentabilidade).toFixed(2) : 0.00
                            }
                            vlr_custo = vlr_custo + value?.vlr_custo
                            total = total + value?.total
                        })
                        progressRentTot = [undefined, null].includes(Math.abs((vlr_custo * 100 / total) - 100)) ? 0.00 : (Math.abs((vlr_custo * 100 / total) - 100)).toFixed(2)
                    }

                    return (
                        <SwiperSlide key={index} style={{
                            display: 'flex', alignItems: 'center',
                            justifyContent: 'center',
                            zIndex: 1
                        }}
                        >
                            <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'row', borderRadius: 3, width: screenWidht / 1.02, height: screenHeight / 1.05 }}>
                                <Box sx={{ backgroundColor: theme.palette.background.default, width: '20%', display: 'flex', flexDirection: 'column', height: '100%', gap: screenHeight / 500, justifyContent: 'center', marginRight: screenHeight / 300, paddingLeft: screenHeight / 150, paddingRight: screenHeight / 150 }}>
                                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: screenHeight / 300 }}>
                                        <Avatar className="avatar" sx={{ height: screenHeight / 4, width: screenWidht / 7, backgroundColor: theme.palette.background.default, borderRadius: 3, objectFit: 'contain' }} alt="profile-img" src={[undefined, null, ''].includes(element.vendor.avatar) ? "../../assets/images/profile/default.png" : element.vendor.avatar} />
                                        <Typography textAlign={'center'} color={theme.palette.mode === 'dark' ? theme.palette.text.primary : "#474747"} fontWeight="bold" fontSize={screenHeight / 30}>{element.vendor.name}</Typography>
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                        <img
                                            alt="Logo bandeira"
                                            width={screenHeight / 5}
                                            src={bandeira == 1 ? '../../assets/images/Logos/logo-brasil.png' : bandeira == 2 ? '../../assets/images/Logos/logo-farmae.png' : bandeira == 3 ? '../../assets/images/Logos/logo-acesso.png' : bandeira == 4 ? '../../assets/images/Logos/logo-cristal.png' : ''}
                                        />
                                        <Typography noWrap color={theme.palette.mode === 'dark' ? theme.palette.text.primary : "#474747"} fontWeight="bold" fontSize={screenHeight / 45}>{bandeira == 1 ? 'Farmácia Brasil Poupa Lar' : bandeira == 2 ? 'Farmácia Farmãe' : bandeira == 3 ? 'Farmácia Acesso Popular' : bandeira == 4 ? 'Farmácia Cristal Vida' : ''}</Typography>
                                        <Typography noWrap color={theme.palette.mode === 'dark' ? theme.palette.text.primary : "#474747"} fontWeight="bold" fontSize={screenHeight / 45}>{data?.user.name}</Typography>
                                    </Box>
                                    {filterFidelidade.length > 0 ?
                                        <Box>
                                            <Typography noWrap color={theme.palette.mode === 'dark' ? theme.palette.text.primary : "#474747"} fontSize={screenHeight / 60} fontWeight={'bold'}>{'Fidelidade / Meta: ' + metaFidelidade + '%'}</Typography>
                                            <Box>
                                                <Typography noWrap color={theme.palette.mode === 'dark' ? theme.palette.text.primary : "#474747"} fontSize={screenHeight / 80}>Hoje</Typography>
                                                <Box display={'flex'} justifyContent='space-between' alignItems="center" gap={screenHeight / 300}>
                                                    <LinearProgress sx={{ width: screenWidht / 7, height: screenHeight / 60, borderRadius: 5 }} color={progressFidelidadeToday >= metaFidelidade ? "success" : 'error'} value={progressFidelidadeToday} variant="determinate" />
                                                    <Typography noWrap color={theme.palette.mode === 'dark' ? theme.palette.text.primary : "#474747"} fontSize={screenHeight / 50}>{isNaN(progressFidelidadeToday) ? '0.00%' : (progressFidelidadeToday).toString() + '%'}</Typography>
                                                </Box>
                                                <Typography noWrap color={theme.palette.mode === 'dark' ? theme.palette.text.primary : "#474747"} fontSize={screenHeight / 80}>Total</Typography>
                                                <Box display={'flex'} justifyContent='space-between' alignItems="center" gap={screenHeight / 300}>
                                                    <LinearProgress sx={{ width: screenWidht / 7, height: screenHeight / 60, borderRadius: 5 }} color={progressFidelidadeTot >= metaFidelidade ? 'success' : 'error'} value={progressFidelidadeTot} variant="determinate" />
                                                    <Typography noWrap color={theme.palette.mode === 'dark' ? theme.palette.text.primary : "#474747"} fontSize={screenHeight / 50}>{isNaN(progressFidelidadeTot) ? '0.00%' : (progressFidelidadeTot).toString() + '%'}</Typography>
                                                </Box>
                                            </Box>
                                        </Box>
                                        : null}
                                    {filterTicketMedio.length > 0 ?
                                        <Box>
                                            <Typography noWrap color={theme.palette.mode === 'dark' ? theme.palette.text.primary : "#474747"} fontSize={screenHeight / 60} fontWeight={'bold'}>{'Ticket Médio / Meta: R$' + metaTicketMedio}</Typography>
                                            <Box>
                                                <Typography noWrap color={theme.palette.mode === 'dark' ? theme.palette.text.primary : "#474747"} fontSize={screenHeight / 80}>Hoje</Typography>
                                                <Box display={'flex'} justifyContent='space-between' alignItems="center" gap={screenHeight / 300}>
                                                    <LinearProgress sx={{ width: screenWidht / 7, height: screenHeight / 60, borderRadius: 5 }} color={progressTMToday >= metaTicketMedio ? "success" : 'error'} value={progressTMToday > 100 ? 100 : progressTMToday} variant="determinate" />
                                                    <Typography noWrap color={theme.palette.mode === 'dark' ? theme.palette.text.primary : "#474747"} fontSize={screenHeight / 50}>{isNaN(progressTMToday) ? 'R$0,00' : 'R$' + (progressTMToday).toString()}</Typography>
                                                </Box>
                                                <Typography noWrap color={theme.palette.mode === 'dark' ? theme.palette.text.primary : "#474747"} fontSize={screenHeight / 80}>Total</Typography>
                                                <Box display={'flex'} justifyContent='space-between' alignItems="center" gap={screenHeight / 300}>
                                                    <LinearProgress sx={{ width: screenWidht / 7, height: screenHeight / 60, borderRadius: 5 }} color={progressTMTot >= metaTicketMedio ? "success" : 'error'} value={progressTMTot > 100 ? 100 : progressTMTot} variant="determinate" />
                                                    <Typography noWrap color={theme.palette.mode === 'dark' ? theme.palette.text.primary : "#474747"} fontSize={screenHeight / 50}>{isNaN(progressTMTot) ? 'R$0,00' : 'R$' + (progressTMTot).toString()}</Typography>
                                                </Box>
                                            </Box>
                                        </Box>
                                        : null}
                                    {filterRentabilidade.length > 0 ?
                                        <Box>
                                            <Typography noWrap color={theme.palette.mode === 'dark' ? theme.palette.text.primary : "#474747"} fontSize={screenHeight / 60} fontWeight={'bold'}>{'Saúde da Venda'}</Typography>
                                            <Box>
                                                <Typography noWrap color={theme.palette.mode === 'dark' ? theme.palette.text.primary : "#474747"} fontSize={screenHeight / 80}>Hoje</Typography>
                                                <Box display={'flex'} justifyContent='space-between' alignItems="center" gap={screenHeight / 300}>
                                                    <LinearProgress sx={{ width: screenWidht / 7, height: screenHeight / 60, borderRadius: 5 }} color={progressRentToday >= metaRentabilidade ? "success" : progressRentToday >= metaRentabilidade * 0.8 ? 'warning' : 'error'} value={progressRentToday} variant="determinate" />
                                                    <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                                        <img src={progressRentToday >= metaRentabilidade ? "https://fbfadm.com.br/assets/images/emojis/6.png" : progressRentToday >= metaRentabilidade * 0.8 ? 'https://fbfadm.com.br/assets/images/emojis/20.png' : 'https://fbfadm.com.br/assets/images/emojis/11.png'} height={20} width={20} />
                                                        <Typography textAlign={'center'} noWrap color={theme.palette.mode === 'dark' ? theme.palette.text.primary : "#474747"} fontSize={screenHeight / 80}>{progressRentToday >= metaRentabilidade ? 'Bom' : progressRentToday >= metaRentabilidade * 0.8 ? 'Moderado' : 'Ruim'}</Typography>
                                                    </Box>
                                                </Box>
                                                <Typography noWrap color={theme.palette.mode === 'dark' ? theme.palette.text.primary : "#474747"} fontSize={screenHeight / 80}>Total</Typography>
                                                <Box display={'flex'} justifyContent='space-between' alignItems="center" gap={screenHeight / 300}>
                                                    <LinearProgress sx={{ width: screenWidht / 7, height: screenHeight / 60, borderRadius: 5 }} color={progressRentToday >= metaRentabilidade ? "success" : progressRentToday >= metaRentabilidade * 0.8 ? 'warning' : 'error'} value={progressRentTot} variant="determinate" />
                                                    <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                                        <img src={progressRentToday >= metaRentabilidade ? "https://fbfadm.com.br/assets/images/emojis/6.png" : progressRentToday >= metaRentabilidade * 0.8 ? 'https://fbfadm.com.br/assets/images/emojis/20.png' : 'https://fbfadm.com.br/assets/images/emojis/11.png'} height={20} width={20} />
                                                        <Typography noWrap color={theme.palette.mode === 'dark' ? theme.palette.text.primary : "#474747"} textAlign={'center'} fontSize={screenHeight / 80}>{progressRentToday >= metaRentabilidade ? 'Bom' : progressRentToday >= metaRentabilidade * 0.8 ? 'Moderado' : 'Ruim'}</Typography>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Box>
                                        : null}
                                </Box>
                                <Swiper
                                    ref={newRef}
                                    loop={false}
                                    slidesPerView={3}
                                    grid={
                                        {
                                            rows: 2,
                                            fill: 'row'
                                        }
                                    }
                                    modules={[Autoplay, Grid]}
                                    init={false}
                                    autoplay={{ delay: (20000 * element.data?.length) / 6, disableOnInteraction: true, pauseOnMouseEnter: true }}
                                    style={{ width: '80%', height: '100%' }}
                                    onReachEnd={() => {

                                    }}
                                >
                                    {element.data.map((el, ixd) => {
                                        if ([10, 11].includes(el?.meta?.type)) {
                                            return null
                                        }
                                        const init = moment()
                                        const end = moment(el.meta.end).add(2, 'days')
                                        const numberOfDays = end.diff(init, 'days')
                                        const total = el?.totalVendas?.total ? el?.totalVendas?.total : 0
                                        const metaFormat = el?.metaList?.unit == 1 ? (Math.round((el?.metaList?.value - el?.totalVendasMeta?.total) / numberOfDays)) < 1 ? 1 : (Math.round((el?.metaList?.value - el?.totalVendasMeta?.total) / numberOfDays)) : (Math.round((el?.metaList?.value - el?.totalVendasMeta?.total) / numberOfDays))
                                        const vendas = {
                                            valor: total,
                                            porcentagem: el?.metaList?.unit == 0 ? ((total * 100) / metaFormat).toFixed(2) : ((total * 100) / metaFormat).toFixed(0)
                                        }
                                        const metaDiaria = {
                                            valor: el?.metaList?.unit == 0 ? metaFormat.toFixed(2) : metaFormat.toFixed(0),
                                            porcentagem: (100 - vendas.porcentagem).toFixed(2) < 0 ? 0 : (100 - vendas.porcentagem).toFixed(2)
                                        }
                                        let labelFalta = 0
                                        const prefix = el?.metaList?.unit == 0 ? 'R$' : 'Qtd. '
                                        if (el?.metaList?.unit == 0) {
                                            if ((metaFormat.toFixed(2) - total).toFixed(2) < 0) {
                                                labelFalta = 'Sobra: ' + prefix + Math.abs((metaFormat.toFixed(2) - total).toFixed(2))
                                            }
                                            else {
                                                labelFalta = 'Falta: ' + prefix + (metaFormat.toFixed(2) - total).toFixed(2)
                                            }
                                        }
                                        else {
                                            if ((metaFormat.toFixed(2) - total).toFixed(2) < 0) {
                                                labelFalta = 'Sobra: ' + prefix + Math.abs((metaFormat.toFixed(2) - total).toFixed(0))
                                            }
                                            else {
                                                labelFalta = 'Falta: ' + prefix + (metaFormat.toFixed(2) - total).toFixed(0)
                                            }
                                        }
                                        const chartData = [
                                            {
                                                id: 'Meta',
                                                label: 'Meta: ' + prefix + metaDiaria.valor,
                                            },
                                            {
                                                id: 'Realizado',
                                                label: 'Realizado: ' + prefix + vendas.valor,
                                                value: vendas.porcentagem,
                                                value2: prefix + vendas.valor,
                                                color: theme.palette.mode === 'dark' ? theme.palette.text.primary : '#000'
                                            },
                                            {
                                                id: 'Falta',
                                                label: labelFalta,
                                                value: metaDiaria.porcentagem,
                                                value2: prefix + metaDiaria.valor,
                                                color: theme.palette.mode === 'dark' ? theme.palette.background.paper : "#eee"
                                            },
                                        ]
                                        const chartData2 = [
                                            {
                                                id: 'Meta',
                                                label: 'Meta: ' + prefix + metaDiaria.valor,
                                            },
                                            {
                                                id: 'Realizado',
                                                label: 'Realizado: ' + prefix + vendas.valor,
                                                value: 0,
                                                value2: prefix + vendas.valor,
                                                color: theme.palette.mode === 'dark' ? theme.palette.text.primary : '#000'
                                            },
                                            {
                                                id: 'Falta',
                                                label: labelFalta,
                                                value: metaDiaria.porcentagem,
                                                value2: prefix + metaDiaria.valor,
                                                color: theme.palette.mode === 'dark' ? theme.palette.background.paper : "#eee"
                                            },
                                        ]

                                        const metaName = el?.meta?.name
                                        const description = el?.metaList?.nom_agrupamento ? el?.metaList?.nom_agrupamento : el.mode.name
                                        function getColors() {
                                            var reaColor = vendas.porcentagem >= 100 ? 'green' : 'rgb(18, 45, 84)'
                                            var metaColor = vendas.porcentagem >= 100 || metaDiaria.valor <= 0 ? 'green' : 'red'
                                            return (['#000', reaColor, metaColor])
                                        }
                                        return (
                                            <SwiperSlide key={ixd} style={{ backgroundColor: theme.palette.background.default, borderWidth: 1, bordercolor: theme.palette.mode === 'dark' ? theme.palette.text.primary : '#000', borderTop: 0, borderBottom: 0, height: screenHeight / 2 }}>
                                                <Box style={{ height: '100%' }}>
                                                    <Box style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'center' }}>
                                                        <Typography noWrap color={theme.palette.mode === 'dark' ? theme.palette.text.primary : "#474747"} fontWeight="bold" fontSize={screenHeight / 30}>{metaName}</Typography>
                                                    </Box>
                                                    <Box style={{ height: '80%' }}>
                                                        {vendas.porcentagem >= 100 || metaDiaria.valor <= 0 ?
                                                            <Box style={{ position: 'absolute', marginLeft: 'auto', marginRight: 'auto', left: 0, right: 0, flex: 1, height: '65%', width: '100%', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                                                                <EmojiEvents sx={{ color: 'orange', width: screenHeight / 15, height: 'auto' }} />
                                                            </Box>
                                                            : null}
                                                        <ResponsivePie
                                                            animate={true}
                                                            data={animationData !== null && swiperRef?.current?.swiper?.activeIndex == index && vendas.porcentagem > 0 && vendas.porcentagem < 100 ? chartData2 : chartData}
                                                            margin={{ top: 20, right: 0, bottom: 80, left: 0 }}
                                                            innerRadius={0.5}
                                                            padAngle={0.7}
                                                            cornerRadius={3}
                                                            activeOuterRadiusOffset={8}
                                                            borderWidth={1}
                                                            colors={getColors()}
                                                            arcLabelsTextColor={(value) => {
                                                                if (value.value == undefined) {
                                                                    return 'transparent'
                                                                }
                                                                else return '#fff'
                                                            }}
                                                            borderColor={{
                                                                from: 'color',
                                                                modifiers: [
                                                                    [
                                                                        'darker',
                                                                        0.2
                                                                    ]
                                                                ]
                                                            }}
                                                            theme={{
                                                                fontSize: screenHeight / 60,
                                                                fontFamily: theme.typography.fontFamily,
                                                                textColor: theme.palette.text.primary,
                                                                tooltip: {
                                                                    basic: {
                                                                        color: theme.palette.mode === 'dark' ? theme.palette.text.primary : '#000',
                                                                    },

                                                                },
                                                            }}
                                                            arcLabel={(value) => value.data.value + '%'}
                                                            arcLinkLabel={false}
                                                            arcLinkLabelsThickness={0}
                                                            defs={[
                                                                {
                                                                    id: 'dots',
                                                                    type: 'patternDots',
                                                                    background: 'inherit',
                                                                    color: 'rgba(255, 255, 255, 0.3)',
                                                                    size: 4,
                                                                    padding: 1,
                                                                    stagger: true
                                                                },
                                                                {
                                                                    id: 'lines',
                                                                    type: 'patternLines',
                                                                    background: 'inherit',
                                                                    color: 'rgba(255, 255, 255, 0.3)',
                                                                    rotation: -45,
                                                                    lineWidth: 6,
                                                                    spacing: 100
                                                                }
                                                            ]}
                                                            tooltip={(value) => {
                                                                return (
                                                                    <BasicTooltip title color={value.datum.color} value={value.datum.data.value2} enableChip />
                                                                )
                                                            }}
                                                            fill={[
                                                                {
                                                                    match: {
                                                                        id: vendas.porcentagem >= 100 ? 'Realizado' : 'Falta'
                                                                    },
                                                                    id: 'dots'
                                                                },
                                                            ]}
                                                            legends={[
                                                                {
                                                                    anchor: 'bottom-left',
                                                                    direction: 'column',
                                                                    justify: false,
                                                                    translateX: 0,
                                                                    translateY: 56,
                                                                    itemsSpacing: 10,
                                                                    itemWidth: 100,
                                                                    itemHeight: 18,
                                                                    itemTextcolor: theme.palette.mode === 'dark' ? theme.palette.text.primary : '#000',
                                                                    itemDirection: 'left-to-right',
                                                                    itemOpacity: 1,
                                                                    symbolSize: 18,
                                                                    symbolShape: 'circle',
                                                                    effects: [
                                                                        {
                                                                            on: 'hover',
                                                                            style: {
                                                                                itemTextcolor: theme.palette.mode === 'dark' ? theme.palette.text.primary : '#000'
                                                                            }
                                                                        }
                                                                    ]
                                                                }
                                                            ]}
                                                        />
                                                    </Box>

                                                </Box>
                                            </SwiperSlide>
                                        )
                                    })}
                                </Swiper>
                            </Box>
                        </SwiperSlide>
                    )
                })
                }

                <Modal
                    open={loading}
                    onClose={changeModalVisible}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    sx={{
                        border: 'none',
                        borderColor: 'transparent',
                        "&:focus": {
                            outline: 'none'
                        }
                    }}
                >
                    <Box sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: isNonMobile ? 400 : '80%',
                        border: 'none',
                        display: "flex",
                        flexDirection: "column",
                        "&:focus": {
                            outline: 'none'
                        },
                        justifyContent: 'space-between'
                    }}>
                        <Box alignSelf='center' mt='50px'><CircularProgress size='30px' color="inherit" /></Box>
                    </Box>
                </Modal>
            </Swiper >
        )
    }
    else {
        const bandeira = data?.user.bandeira
        return (
            <Box style={{ height: screenHeight, width: screenWidht }}>
                {data.metas?.length == 0 && loading == false ?
                    <Box style={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                        <Box sx={{ padding: 10, background: theme.palette.background.default, borderRadius: 3, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', border: 0, borderColor: theme.palette.primary.main, boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.4)' }}>
                            <Box
                                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                            >
                                <img src={theme.palette.mode === 'dark' ? "../assets/images/loading/Infinity-black.gif" : "../assets/images/loading/Infinity.gif"} />
                                <Box>
                                    <Typography variant="h3" fontWeight={'bold'}>FRANCHISING BRASIL FARMA</Typography>
                                    <Typography variant="h1" fontWeight={'bold'}>Metas</Typography>
                                </Box>
                            </Box>
                            <HelpIcon sx={{ fontSize: 80, color: theme.palette.mode === 'dark' ? theme.palette.text.primary : theme.palette.primary.main }} />
                            <Typography sx={{ color: theme.palette.text.primary, fontSize: 30, fontWeight: 'bold' }}>Você não possui metas ativas</Typography>
                        </Box>
                    </Box>
                    : null}
                <Modal
                    open={deviceInfo.open === true ? false : loading}
                    /* onClose={changeModalVisible} */
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    sx={{
                        border: 'none',
                        borderColor: 'transparent',
                        "&:focus": {
                            outline: 'none'
                        }
                    }}
                >
                    <Box sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 600,
                        border: 'none',
                        display: "flex",
                        flexDirection: "column",
                        "&:focus": {
                            outline: 'none'
                        },
                        height: 400,
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRadius: 3,
                        backgroundColor: theme.palette.background.default
                    }}>
                        <Box
                            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                        >
                            <img src={theme.palette.mode === 'dark' ? "../assets/images/loading/Infinity-black.gif" : "../assets/images/loading/Infinity.gif"} />
                            <Box>
                                <Typography variant="h3" fontWeight={'bold'}>FRANCHISING BRASIL FARMA</Typography>
                                <Typography variant="h1" fontWeight={'bold'}>Metas</Typography>
                            </Box>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                            <Typography sx={{ color: theme.palette.text.primary, fontWeight: 'bold' }}>Aguarde! Estamos montando suas metas.</Typography>
                            <Typography sx={{ color: theme.palette.text.primary, fontWeight: 'bold', fontSize: 30 }}>{dataLength.atual + '/' + dataLength.total}</Typography>
                        </Box>
                    </Box>
                </Modal>
                <Modal
                    open={deviceInfo.open}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 600,
                        border: 'none',
                        display: "flex",
                        flexDirection: "column",
                        "&:focus": {
                            outline: 'none'
                        },
                        height: 500,
                        backgroundColor: theme.palette.background.default,
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        borderRadius: 3,
                        padding: 5
                    }}>
                        <Typography variant="h2" sx={{ color: theme.palette.text.primary, fontWeight: 'bold' }}>{deviceInfo.title}</Typography>
                        <Box sx={{ background: 'orange', padding: 2, borderRadius: 3 }}>
                            <Typography variant="h1" color='#fff'>{deviceInfo.key}</Typography>
                        </Box>
                        <Typography variant="h4" sx={{ textAlign: 'center', color: theme.palette.text.primary }} >{deviceInfo.subtitle}</Typography>
                        <Typography variant="h3" sx={{ textAlign: 'center', color: theme.palette.text.primary, fontWeight: 'bold' }} >{deviceInfo.body}</Typography>
                        {deviceInfo.button}
                    </Box>
                </Modal>
            </Box>
        )
    }
}