import React, { useState, useContext, useEffect, useRef } from 'react';
import { useTheme, Box, Modal, Typography, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Divider, Button, IconButton } from '@mui/material';
import { ModalInfo } from '../../../components/modalInfoN';
import { User } from '../../../contexts/user';
import mainApi from '../../../apis/mainApiN';
import { ButtonSubmit } from '../../../components/buttons';
import { getIcon } from '../../../components/icons';
import { CTextField } from '../../../components/TextField';
import { DetailsSection } from './inside/DetailsSection';
import { ModalNfe } from './inside/ModalNfe';
import { ButtonOnList } from './inside/ButtonOnList';
import { ProdutoComp } from './inside/ProdutoComp';
import { ActionNeed } from './inside/ActionNeed';
import { AplicacaoComp } from './inside/AplicacaoComp';
import { ModalPostagem } from './inside/ModalPostagem';

export function Inside({ visible, venda, updateInside }) {
    const theme = useTheme();
    const user = useContext(User);
    const [modalInfo, setModalInfo] = useState({
        visible: false,
        title: '',
        subtitle: '',
        canDesactive: true,
        icon: '',
        funcS: () => null
    });
    const [data, setData] = useState(dataInit);
    const [nfeInfo, setNfeInfo] = useState({
        visible: false,
        nfe: null
    })
    const [modalPostagem, setModalPostagem] = useState({
        visible: false,
        postagem: null
    })

    function updateModalInfo(visible, canDesactive, title, subtitle, icon, func) {
        setModalInfo({
            visible,
            canDesactive,
            title,
            subtitle,
            icon,
            funcS: func
        });
    }

    function handleDismiss() {
        setData(dataInit)
        updateInside(false)
    }

    async function getData() {
        if (!venda.id) {
            updateModalInfo(true, true, 'Atenção', 'Número do pedido não foi encontrado', 'exclamation')
            return
        }
        updateModalInfo(true, false, 'Carregando', 'Buscando dados da venda.', 'loading')
        const get = await mainApi(`pedidosecom/infoPedido/${venda.id}`, 'GET', null, user?.data?.token)
        if (get?.status !== 200) {
            const message = get?.apiReturn?.message ? get?.apiReturn?.message : 'Ocorreu um erro ao buscar os dados da venda.'
            updateModalInfo(true, true, 'Atenção', message, 'exclamation')
            return
        }
        updateModalInfo(false)
        setData(get?.apiReturn?.apiReturn)
    }
    function handleAction(type) {
        if (type === 'Pagamento Aprovado') {
            updateNfeInfo(true, null)
        }
        if (type === 'Pedido Faturado') {
            updatePostagemInfo(true, null)
        }
        if (type === 'Pedido em Separação') {
            enviarPedido()
        }
        if (type === 'Devolução Solicitada') {
            finalizarDevolucao()
        }
        return
    }

    async function finalizarDevolucao() {
        updateModalInfo(true, false, 'Salvando', 'Finalizando devolução.', 'loading')
        const get = await mainApi('pedidosecom/finalizarDevolucao', 'POST', { pedido: venda?.id }, user?.data?.token)
        console.log(get, '<<<<<<<<<<<<<<<<<<<<<<<')
        if (get?.status !== 200) {
            const message = get?.apiReturn?.message ? get?.apiReturn?.message : 'Ocorreu um erro ao enviar o pedido'
            updateModalInfo(true, true, 'Atenção', message, 'exclamation')
            return
        }
        const status = get?.apiReturn?.apiReturn
        console.log(status)
        updateModalInfo(true, true, 'Sucesso', 'Status do pedido foi trocado para "Enviado"', 'sucess')
        const newData = { ...data }
        newData.status = status.status
        newData.status_nome = status.status_nome
        setData(newData)
    }
    async function enviarPedido() {
        updateModalInfo(true, false, 'Separação', 'Enviando Pedido', 'loading')
        const get = await mainApi('pedidosecom/enviarPedido', 'POST', { pedido: venda?.id }, user?.data?.token)
        if (get?.status !== 200) {
            const message = get?.apiReturn?.message ? get?.apiReturn?.message : 'Ocorreu um erro ao enviar o pedido'
            updateModalInfo(true, true, 'Atenção', message, 'exclamation')
            return
        }
        const status = get?.apiReturn?.apiReturn
        updateModalInfo(true, true, 'Sucesso', 'Status do pedido foi trocado para "Enviado"', 'sucess')
        const newData = { ...data }
        newData.status = status.status
        newData.status_nome = status.status_nome
        setData(newData)
    }
    function updatedata(newData) {
        setData(newData)
    }
    function updateNfeInfo(visible, nfe) {
        const newNfe = { ...nfeInfo }
        newNfe.visible = visible ? visible : false
        newNfe.nfe = nfe ? nfe : null
        setNfeInfo(newNfe)
    }
    function updatePostagemInfo(visible, postagem) {
        const newNfe = { ...modalPostagem }
        newNfe.visible = visible ? visible : false
        newNfe.postagem = postagem ? postagem : null
        setModalPostagem(newNfe)
    }

    useEffect(() => {
        if (visible) {
            getData()
        }
    }, [visible])

    return (
        <Modal open={visible} onClose={handleDismiss} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 2 }}>
            <Box>
                {data.aplicacao_id ?
                    <Paper sx={{ width: 1200, maxWidth: 1200, maxHeight: '90vh', p: 4 }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 2 }}>Detalhes do Pedido</Typography>
                            <ActionNeed data={data} />
                            <AplicacaoComp data={data} />
                        </Box>
                        <Divider sx={{ mb: 4 }} />
                        <Box sx={{ overflowY: 'scroll', overflowX: 'hidden', maxHeight: '65vh', paddingRight: 3 }}>
                            <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1 }}>Detalhes do Produto</Typography>
                            <ProdutoComp data={data} />
                            <Divider sx={{ mt: 4, mb: 4 }} />
                            <DetailsSection data={data} title="Informações da Venda" keys={[
                                { key: 'id', label: 'ID do Pedido' },
                                { key: 'order_id', label: 'ID do Pagamento' },
                                { key: 'forma_pagamento', label: 'Forma de Pagamento' },
                                { key: 'total', label: 'Total' },
                            ]} />
                            <DetailsSection data={data} title="Status da Venda" keys={[
                                { key: 'status', label: 'Status' },
                                { key: 'status_nome', label: 'Status (Nome)' },
                                { key: 'data_pedido', label: 'Data do Pedido' },
                                { key: 'data_aprovado', label: 'Data Aprovado' },
                                { key: 'data_atualizado', label: 'Data Atualizado' },
                                { key: 'data_expirado', label: 'Data Expirado' },
                                { key: 'data_reembolso', label: 'Data Reembolso' },
                            ]} />
                            <DetailsSection title="Entrega" data={data} keys={[
                                { key: 'entrega_cod', label: 'Código' },
                                { key: 'entrega_nome', label: 'Nome' },
                                { key: 'entrega_custo', label: 'Custo' },
                                { key: 'entrega_prazo', label: 'Prazo' },
                                { key: 'entrega_peso', label: 'Peso' },
                                { key: 'entrega_comprimento', label: 'Comprimento' },
                                { key: 'entrega_altura', label: 'Altura' },
                                { key: 'entrega_largura', label: 'Largura' },
                                { key: 'entrega_diametros', label: 'Diâmetros' },
                                { key: 'entrega_formato', label: 'Formato' }
                            ]} />
                            <DetailsSection updatePostagemInfo={updatePostagemInfo} title="Postagem" data={data} keys={[
                                { key: 'prePostagem', label: 'ID Pré-postagem' },
                                { key: 'entrega_rastreio', label: 'Rastreio' },
                                { key: 'recibo', label: 'Recibo' },
                                { key: 'etiqueta', label: 'Etiqueta' },
                            ]} />
                            <DetailsSection updateNfeInfo={updateNfeInfo} data={data} title="NFe" keys={[
                                { key: 'nfe_num', label: 'Número' },
                                { key: 'nfe_chave', label: 'Chave' },
                                { key: 'nfe_link', label: 'Link' },
                                { key: 'nfe_created', label: 'Criada em' },
                                { key: 'nfe_updated', label: 'Atualizada em' }
                            ]} />
                            <DetailsSection data={data} title="Parceiro" keys={[
                                { key: 'parceiro_id', label: 'ID' },
                                { key: 'parceiro_nome', label: 'Nome' },
                                { key: 'parceiro_link', label: 'Link' }
                            ]} />
                            <DetailsSection data={data} title="Cliente" keys={[
                                { key: 'cliente_id', label: 'ID' },
                                { key: 'cliente_nome', label: 'Nome' },
                                { key: 'cliente_cpf', label: 'CPF' },
                                { key: 'cliente_rua', label: 'Rua' },
                                { key: 'cliente_numero', label: 'Número' },
                                { key: 'cliente_bairro', label: 'Bairro' },
                                { key: 'cliente_cidade', label: 'Cidade' },
                                { key: 'cliente_estado', label: 'Estado' },
                                { key: 'cliente_cep', label: 'CEP' }
                            ]} />
                        </Box>
                        <Divider sx={{ mb: 4 }} />
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <ButtonOnList status={data.status_nome} funcReturn={handleAction} />
                        </Box>
                    </Paper>
                    : null}
                <ModalPostagem visible={modalPostagem.visible} dismissPostagem={updatePostagemInfo} pedido={venda?.id} prevData={data} allData={data} updatedata={updatedata} />
                <ModalNfe visible={nfeInfo.visible} dismissNf={updateNfeInfo} pedido={venda?.id} updatedata={updatedata} allData={data} prevData={nfeInfo.nfe} />
                <ModalInfo hideModal={() => updateModalInfo(false)} canDesactive={modalInfo.canDesactive} visible={modalInfo.visible} title={modalInfo.title} subtitle={modalInfo.subtitle} icon={modalInfo.icon} funcS={modalInfo.funcS} />
            </Box>
        </Modal>
    );
}

const dataInit = {
    id: '',
    order_id: '',
    aplicacao_id: '',
    aplicacao_nome: '',
    aplicacao_image: '',
    produto_id: '',
    produto_nome: '',
    produto_quantidade: '',
    produto_preco: '',
    produto_image: '',
    forma_pagamento: '',
    total: '',
    data_pedido: '',
    data_aprovado: '',
    data_atualizado: '',
    data_expirado: '',
    data_reembolso: '',
    status: '',
    status_nome: '',
    cliente_id: '',
    cliente_nome: '',
    cliente_cpf: '',
    cliente_rua: '',
    cliente_numero: '',
    cliente_bairro: '',
    cliente_cidade: '',
    cliente_estado: '',
    cliente_cep: '',
    nfe_num: '',
    nfe_chave: '',
    nfe_link: '',
    nfe_created: '',
    nfe_updated: '',
    parceiro_id: '',
    parceiro_nome: '',
    parceiro_link: '',
    entrega_cod: '',
    entrega_nome: '',
    entrega_custo: '',
    entrega_prazo: '',
    entrega_rastreio: '',
    entrega_peso: '',
    entrega_comprimento: '',
    entrega_altura: '',
    entrega_largura: '',
    entrega_diametros: '',
    entrega_formato: '',
    prePostagem: '',
    etiqueta: '',
    recibo: ''
}