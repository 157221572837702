import { Modal, useTheme, Box, Button } from "@mui/material"
import { getIcon } from "./icons"


export function ModalIcones({ visible, onClose, updateIcon, control }) {
    const theme = useTheme()
    const icons = getIcon(null, theme.palette.text.primary, 30, true)
    return (
        <Modal open={visible} onClose={onClose} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} slotProps={{
            backdrop: {
                sx: {
                    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(250,255,255, 0.2)' : 'rgba(0,0,0, 0.4)'
                }
            }
        }}>
            <Box sx={{
                display: 'flex', gap: 2, height: 300, width: 330, background: theme.palette.background.default, flexWrap: 'wrap', outline: 'none', overflowY: 'scroll', '&::-webkit-scrollbar': {
                    background: theme.palette.background.paper,
                    width: 20
                },
                '&::-webkit-scrollbar-thumb': {
                    background: theme.palette.primary.main
                }
            }}>
                {Object.keys(icons).map((e) => {
                    return (
                        <Box key={e} sx={{ display: 'flex', height: '20%' }}>
                            <Button onClick={() => updateIcon(e, 'icon', control)}>
                                {
                                    icons[e]
                                }
                            </Button>
                        </Box>
                    )
                })}
            </Box>
        </Modal>
    )
}