import React, { useState, useContext, useEffect } from 'react';
import { useTheme, Box, Typography } from '@mui/material';
import { ModalInfo } from '../../../components/modalInfoN';
import { User } from '../../../contexts/user';
import mainApi from '../../../apis/mainApiN';
import { List } from './components/list';
import { getEvento } from '../../../components/getEvento';
import { Filter } from './components/filter';
import { New } from './components/new';
import { Mais } from './components/mais';
import { Condicao } from './components/condicao';

export function CampanhasFranquia(props) {
    const theme = useTheme();
    const user = useContext(User);
    //estados
    const [MInfo, setMInfo] = useState({
        visible: false,
        title: '',
        subtitle: '',
        canDesactive: true,
        icon: '',
        funcS: () => null
    });
    const [campanhas, setCampanhas] = useState([])
    const [newCampanha, setNewCampanha] = useState({
        visible: '',
        campanha: null
    })
    const [mais, setMais] = useState({
        visible: false,
        campanha: null
    })
    const [condicao, setCondicao] = useState({
        visible: false,
        campanha: null
    })

    function updateCondicao(visible, campanha) {
        const newCond = { ...condicao }
        newCond.visible = visible ? visible : false
        newCond.campanha = campanha ? campanha : false
        setCondicao(newCond)
    }
    function updateMais(visible, campanha) {
        const newMais = { ...mais }
        newMais.visible = visible ? visible : false
        newMais.campanha = campanha ? campanha : false
        setMais(newMais)
    }
    function pushCampanha(campanha) {
        const newC = [...campanhas]
        newC.push(campanha)
        setCampanhas(newC)
    }
    function editCampanha(campanha) {
        const newC = [...campanhas]
        const findIndex = newC.findIndex((e) => e.id === campanha.id)
        if (findIndex !== -1) {
            newC[findIndex] = campanha
            setCampanhas(newC)
        }
    }
    function excludeCampanha(campanha) {
        const newC = campanhas.filter((e) => e.id !== campanha.id)
        setCampanhas(newC)
    }
    function updateNewCampanha(visible, campanha) {
        const NNewCampanha = { ...newCampanha }
        NNewCampanha.visible = visible ? visible : false
        NNewCampanha.campanha = campanha ? campanha : null
        setNewCampanha(NNewCampanha)
    }

    function updateModalInfo(visible, canDesactive, title, subtitle, icon, func) {
        const newMInfo = { ...MInfo };
        newMInfo.visible = visible;
        newMInfo.canDesactive = canDesactive;
        newMInfo.title = title;
        newMInfo.subtitle = subtitle;
        newMInfo.icon = icon;
        newMInfo.funcS = func;
        setMInfo(newMInfo);
    }

    async function getCampanhas() {
        updateModalInfo(true, false, 'Carregando', 'Buscando suas campanhas', 'loading')
        const get = await mainApi('farmaTouch/getAllCampFranquia', 'GET', null, user?.data?.token)
        if (get?.status !== 200) {
            const message = get?.apiReturn?.message ? get?.apiReturn?.message : 'Ocorreu um erro ao buscar as campanhas.'
            updateModalInfo(true, true, 'Atenção', message, 'exclamation')
            return
        }
        setCampanhas(get?.apiReturn?.apiReturn)
        updateModalInfo()
    }

    useEffect(() => {
        getCampanhas()
    }, [])

    return (
        <Box sx={{
            width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 5, [theme.breakpoints.down('md')]: {
                overflow: 'scroll'
            },
        }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '90%' }}>
                <Typography variant="h2" fontWeight={'bold'}>{props?.nome}</Typography>
                <Typography fontSize={20}>{props?.subtitle}</Typography>
            </Box>
            <Box sx={{ width: '90%', display: 'flex', flexDirection: 'column', gap: 2 }}>
                {!getEvento(props.eventos, 'Acesso') ?
                    <Box sx={{ padding: 2, background: theme.palette.warning.main }}>
                        <Typography sx={{ color: theme.palette.text.whiteText, fontWeight: 'bold' }}>Você não possui acesso a esse módulo</Typography>
                    </Box>
                    :
                    <>
                        <Filter newCampanha={updateNewCampanha} />
                        <List campanhas={campanhas} newCampanha={updateNewCampanha} excludeCampanha={excludeCampanha} updateNewCampanha={updateNewCampanha} editCampanha={editCampanha} updateMais={updateMais} updateCondicao={updateCondicao} />
                        <New visible={newCampanha.visible} campanha={newCampanha.campanha} dismiss={updateNewCampanha} pushCampanha={pushCampanha} editCampanha={editCampanha} />
                        <Mais visible={mais.visible} campanha={mais.campanha} updateMais={updateMais} />
                        <Condicao visible={condicao.visible} campanha={condicao.campanha} updateCondicao={updateCondicao} />
                    </>
                }
            </Box>
            <ModalInfo hideModal={() => updateModalInfo(false)} canDesactive={MInfo.canDesactive} visible={MInfo.visible} title={MInfo.title} subtitle={MInfo.subtitle} icon={MInfo.icon} funcS={MInfo.funcS} />
        </Box>
    );
}