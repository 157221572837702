export const gruposNames = [
    { id: 800, name: 'Referência' },
    { id: 801, name: 'Genérico' },
    { id: 802, name: 'Similar' },
    { id: 803, name: 'Perfumaria' },
    { id: 804, name: 'Hospitalar' },
    { id: 805, name: 'Ortopédico' },
    { id: 806, name: 'Fitoterápico' },
    { id: 807, name: 'Leite' },
    { id: 808, name: 'Fralda' },
    { id: 809, name: 'Conveniência' },
    { id: 810, name: 'Suplemento' },
    { id: 811, name: 'Manipulado' },
    { id: 812, name: 'Acessório' },
]

export const categToGroups = (categ) => {
    if ([800, 801, 802, 803, 804, 805, 830].includes(categ)) return 'Referência'
    if ([806, 807, 808, 809, 810, 811, 831].includes(categ)) return 'Genérico'
    if ([812, 813, 814, 815, 816, 817, 818, 832].includes(categ)) return 'Similar'
    if ([819, 820, 821, 822, 823].includes(categ)) return 'Perfumaria'
    if (categ === 824) return 'Hospitalar'
    if (categ === 825) return 'Ortopédico'
    if (categ === 826) return 'Fitoterápico'
    if (categ === 827) return 'Leite'
    if ([828, 835].includes(categ)) return 'Fralda'
    if (categ === 829) return 'Conveniência'
    if ([833, 834].includes(categ)) return 'Suplemento'
    if (categ === 836) return 'Manipulado'
    if (categ === 837) return 'Acessório'
    else return 'Organizar'
}
