import React, { useEffect, useState, useContext } from "react";
import { useTheme } from '@mui/material/styles';
import { Box, Typography, Button } from '@mui/material';
import mainApi from "../../apis/mainApi";
import { ModalLoading } from "../../components/modal";
import { User } from "../../contexts/user";
import { ModalInfo } from "../../components/modalInfo";
import moment from "moment";
import { realMask } from "../../components/masks";
import ListUsuarios from "./listUsuarios";
import UsuariosInside from "./usuariosInside";
import Modulos from "./modulos";
import FilterUsuarios from "./filterUsuarios";
import ModalEventos from "./modalEventos";
export default props => {
    document.title = 'Usuários - Central de Negócio'
    const theme = useTheme()
    const modalInfoInitial = {
        active: false,
        title: '',
        subtitle: '',
        body: ''
    }
    const user = useContext(User)
    const [modalLoading, setModalLoading] = useState(false)
    const [modalInfo, setModalInfo] = useState(modalInfoInitial)
    //'id', 'email', 'cpf', 'nome', 'sobrenome', 'rua', 'bairro', 'cidade', 'estado', 'celular', 'cep'
    const [data, setData] = useState({
        headers: [
            {
                key: 'id',
                nome: 'ID',
                type: 'int'
            },
            {
                key: 'nome',
                nome: 'Nome',
                type: 'string'
            },
        ],
        content: [],
        contentFilter: [],
        modalEventos: {
            active: false,
            eventos: []
        },
        aplicacoes: [],
        bandeiras: [],
        activeBandeira: 5,
        modulos: [],
        eventos: [],
        openDataInside: {
            open: false,
            content: {},
            edit: 1
        },
        openModulos: {
            open: false,
            content: {},
            edit: 1
        },
    })
    function updateModalInfo(value) { setModalInfo(value) }
    function updateData(value) { setData(value) }
    function updateModalLoading(value) { setModalLoading(value) }
    function resetOpenData(value) {
        const newData = { ...data }
        newData.openDataInside = {
            open: false,
            content: {}
        }
        newData.openModulos = {
            open: false,
            content: {},
            edit: 1
        }
        setData(newData)
    }
    function resetModalEventos() {
        const newData = { ...data }
        newData.modalEventos.active = false
        setData(newData)
    }

    function resetModalInfo() {
        setModalInfo(modalInfoInitial)
    }
    function openInside(value, idx, type, newClient) {
        const newData = { ...data }
        if (type === 'modulos') {
            newData.openModulos.open = true
            newData.openModulos.content = value
            newData.openDataInside.open = false
        }
        if (type === 'inside') {
            newData.openModulos.open = false
            newData.openDataInside.open = true
            newData.openDataInside.content = value
            newData.openDataInside.edit = newClient ? 1 : 2
        }
        setData(newData)
    }
    async function getData(value, value2) {
        if (modalLoading === false) updateModalLoading(true)
        function FCallback(callback) {
            updateModalLoading(false)
            if (callback.status !== 200) {
                const x = { ...modalInfo }
                x.title = 'Erro'
                x.subtitle = callback.apiReturn.message ? callback.apiReturn.message : 'Ocorreu um erro! Tente novamente mais tarde'
                x.body = <Button onClick={() => setModalInfo(modalInfoInitial)} sx={{ mb: 2, backgroundColor: theme.palette.secondary.main, color: theme.palette.text.primary, '&:hover': { backgroundColor: theme.palette.secondary.dark }, borderRadius: 0, padding: 1 }}>
                    <Typography fontWeight={'bold'} sx={{ color: theme.palette.text.whiteText, textAlign: 'center' }}>Voltar</Typography>
                </Button>
                x.active = true
                setModalInfo(x)
                return
            }
            const newData = { ...data }
            newData.activeBandeira = value
            newData.content = callback.apiReturn.apiReturn.usuarios ? callback.apiReturn.apiReturn.usuarios : newData.content
            newData.eventos = callback.apiReturn.apiReturn.eventos ? callback.apiReturn.apiReturn.eventos : newData.eventos
            newData.aplicacoes = callback.apiReturn.apiReturn.aplicacoes ? callback.apiReturn.apiReturn.aplicacoes : newData.aplicacoes
            newData.modulos = callback.apiReturn.apiReturn.modulos ? callback.apiReturn.apiReturn.modulos : newData.modulos
            newData.bandeiras = callback.apiReturn.apiReturn.bandeiras ? callback.apiReturn.apiReturn.bandeiras : newData.bandeiras
            newData.contentFilter = []
            setData(newData)
        }
        const parameter = value2 ? value + '/initial' : value
        await mainApi('usuarios/list/' + parameter, 'GET', null, FCallback, user?.data?.token)
    }
    useEffect(() => {
        getData(5, 1)
    }, [])
    return (
        <Box sx={{
            width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 5, [theme.breakpoints.down('md')]: {
                overflow: 'scroll', maxWidth: '100%'
            },
        }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '90%' }}>
                <Typography variant="h2" fontWeight={'bold'}>{props?.nome}</Typography>
                <Typography fontSize={20}>{props?.subtitle}</Typography>
            </Box>
            <Box sx={{
                width: '90%',
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                [theme.breakpoints.down('md')]: {
                    width: '98%'
                },
            }}>
                {/* //content */}
                <FilterUsuarios getData={getData} headers={data.headers} updateData={updateData} data={data} openInside={openInside} resetModalInfo={resetModalInfo} updateModalInfo={updateModalInfo} />
                <ListUsuarios data={data} listData={data.contentFilter.length > 0 ? data.contentFilter : data.content} openInside={openInside} />
            </Box>
            <Modulos type={data.openModulos.edit} data={data.openModulos.content} resetModalInfo={resetModalInfo} eventos={props?.eventos} active={data.openModulos.open} updateModalInfo={updateModalInfo} ative={modalLoading} updateModalLoading={updateModalLoading} mainData={data} updateData={updateData} resetOpenData={resetOpenData} aplicacoes={data.aplicacoes} modulos={data.modulos} />
            <ModalLoading ative={modalLoading} updateModalLoading={updateModalLoading} />
            <UsuariosInside type={data.openDataInside.edit} resetModalInfo={resetModalInfo} eventos={props?.eventos} active={data.openDataInside.open} updateModalInfo={updateModalInfo} ative={modalLoading} updateModalLoading={updateModalLoading} data={data.openDataInside.content} mainData={data} updateData={updateData} resetOpenData={resetOpenData} />
            <ModalInfo height={modalInfo?.height} ative={modalInfo.active} title={modalInfo.title} subtitle={modalInfo.subtitle} body={modalInfo.body} closeModal={resetModalInfo} />
        </Box>
    )
}