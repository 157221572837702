import React, { useEffect, useState, useContext } from "react";
import { useTheme } from '@mui/material/styles';
import { Box, Typography, Button, Modal, MenuItem, TextField, IconButton, FormControl, Select, InputLabel, Avatar, Chip } from '@mui/material';
import mainApi from "../../apis/mainApi";
import { User } from '../../contexts/user'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { getEvento } from "../../components/getEvento";
import { cpfMask, cepMask, celularMask, realMask, onlyInteger, onlyString } from "../../components/masks";
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import { estadosBr } from "../../components/estadosBr";
import DeleteIcon from '@mui/icons-material/Delete';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';

//value.target.value.replace(/[^a-zA-Z]+/g, '')
export default props => {
    const theme = useTheme()
    const user = useContext(User)
    let data = props?.data
    const newDataInitial = {
        id: '',
        nome: '',
        email: '',
        avatar: '',
        avatarBase64: '',
        status: '',
        nova_senha: '',
        token: '',
        bandeira: {
            id: '',
            nome: '',
        },
        cod_operador: '',
        lojaToken: '',
        editable: false
    }
    const [newData, setNewData] = useState(newDataInitial)
    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            if (file.type === 'image/png' && file.size <= 5 * 1024 * 1024) {
                const reader = new FileReader();
                reader.onload = () => {
                    const base64String = reader.result;
                    // Utilize base64String conforme necessário
                    const formatedData = { ...newData }
                    formatedData.avatarBase64 = base64String
                    setNewData(formatedData)
                };
                reader.readAsDataURL(file);
            }
            else {
                alert('Por favor, selecione um arquivo PNG dentro do limite de tamanho (5MB)');
            }
        } else {
            alert('Por favor, selecione um arquivo PNG');
        }
    };

    async function updateESaveEDeleteClient(value) {
        props.updateModalLoading(true)
        function FCallback(callback) {
            props.updateModalLoading(false)
            if (callback.status !== 200) {
                const x = {}
                x.title = 'Erro'
                x.subtitle = callback.apiReturn.message ? callback.apiReturn.message : 'Ocorreu um erro! Tente novamente mais tarde'
                x.body = <Button onClick={() => props.resetModalInfo()} sx={{ mb: 2, backgroundColor: theme.palette.secondary.main, color: theme.palette.text.primary, '&:hover': { backgroundColor: theme.palette.secondary.dark }, borderRadius: 0, padding: 1 }}>
                    <Typography fontWeight={'bold'} sx={{ color: theme.palette.text.whiteText, textAlign: 'center' }}>Voltar</Typography>
                </Button>
                x.active = true
                props.updateModalInfo(x)
                return
            }
            const updateData = { ...props.mainData }
            const findIndexEntrega = updateData.content.findIndex((e) => e.id === newData.id)
            if (value) {
                const filterData = updateData.content.filter((e) => e.id !== newData.id)
                updateData.content = filterData
                updateData.openDataInside.open = false
                setNewData(newDataInitial)
                props.updateData(updateData)
                props.resetModalInfo()
                return
            }
            else if (props.type === 2) {
                const findIndex = updateData.content.findIndex((e) => e.id === newData.id)
                if (updateData.content[findIndex].bandeira_id === newData.bandeira.id) {
                    const formatedData = { ...newData }
                    formatedData.avatar = callback.apiReturn.apiReturn === null ? formatedData.avatar : callback.apiReturn.apiReturn
                    formatedData['bandeira_id'] = formatedData.bandeira.id
                    formatedData['bandeira_nome'] = formatedData.bandeira.nome
                    delete formatedData.bandeira
                    updateData.content[findIndex] = formatedData
                    updateData.openDataInside.open = false
                }
                else {
                    delete updateData.content[findIndex]
                }
                props.updateData(updateData)
                const x = {}
                x.title = 'Sucesso'
                x.subtitle = callback.apiReturn.message ? callback.apiReturn.message : 'Entrega inserida com sucesso'
                x.body = <Button onClick={() => props.resetModalInfo()} sx={{ mb: 2, backgroundColor: theme.palette.secondary.main, color: theme.palette.text.primary, '&:hover': { backgroundColor: theme.palette.secondary.dark }, borderRadius: 0, padding: 1 }}>
                    <Typography fontWeight={'bold'} sx={{ color: theme.palette.text.whiteText, textAlign: 'center' }}>Voltar</Typography>
                </Button>
                x.active = true
                setNewData(newDataInitial)
                props.updateModalInfo(x)
                return
            }
            else if (props.type === 1) {
                newData.id = callback?.apiReturn?.apiReturn?.id
                newData.token = callback?.apiReturn?.apiReturn?.token
                newData.status = 1
                updateData.openDataInside.open = false
                updateData.content.push(newData)
                props.updateData(updateData)
                const x = {}
                x.title = 'Sucesso'
                x.subtitle = callback.apiReturn.message ? callback.apiReturn.message : 'Entrega inserida com sucesso'
                x.body = <Button onClick={() => props.resetModalInfo()} sx={{ mb: 2, backgroundColor: theme.palette.secondary.main, color: theme.palette.text.primary, '&:hover': { backgroundColor: theme.palette.secondary.dark }, borderRadius: 0, padding: 1 }}>
                    <Typography fontWeight={'bold'} sx={{ color: theme.palette.text.whiteText, textAlign: 'center' }}>Voltar</Typography>
                </Button>
                x.active = true
                props.updateModalInfo(x)
                return
            }
        }
        const body = { ...newData }
        delete body.editable
        const method = value ? 'DELETE' : props.type === 2 ? 'PUT' : 'POST'
        let endpoint = value ? 'usuarios/' + newData.id : props.type === 2 ? 'usuarios/info' : 'usuarios/register'
        const newBody = {
            id: body.id,
            nome: body.nome,
            email: body.email,
            senha: body.nova_senha,
            base64: ![null, undefined, ''].includes(body.avatarBase64) ? body.avatarBase64.replace('data:image/png;base64,', '') : null,
            bandeira: body.bandeira.id,
            status: body.status,
        }
        if ([undefined, null, ''].includes(body.nova_senha)) {
            delete newData.nova_senha
        }
        if (props.type === 1) {
            delete newBody.id
        }
        const token = body.bandeira.id === 7 ? body.lojaToken : user?.data?.token
        if (body.bandeira.id === 7) {
            newBody.codigo = body.cod_operador
            newBody.avatar = ![null, undefined, ''].includes(body.avatarBase64) ? body.avatarBase64.replace('data:image/png;base64,', '') : null
            endpoint = value ? 'operadores/delete/' + body.cod_operador : props.type === 2 ? 'operadores/update' : 'operadores/register'
        }
        await mainApi(endpoint, method, newBody, FCallback, token)
    }

    const fileInputRef = React.useRef(null);
    const handleIconClick = () => {
        fileInputRef.current.click();
    };


    useEffect(() => {
        if (props.active === true && props.type === 2) {
            const formatData = { ...newData }
            const getData = props.data
            formatData.bandeira.id = getData.bandeira_id ? getData.bandeira_id : ''
            formatData.bandeira.nome = getData.bandeira_nome ? getData.bandeira_nome : ''
            formatData.editable = true
            formatData.avatar = getData.avatar ? getData.avatar : ''
            formatData.email = getData.email ? getData.email : ''
            formatData.id = getData.id ? getData.id : ''
            formatData.nome = getData.nome ? getData.nome : ''
            formatData.status = getData.status ? getData.status : ''
            formatData.token = getData.token ? getData.token : ''
            if (getData.bandeira_id === 7) {
                formatData.lojaToken = getData?.lToken
                formatData.cod_operador = getData?.cod_operador
            }
            setNewData(formatData)
        }
    }, [props.active])
    return (
        <Modal
            open={props.active}
            onClose={() => {
                props.resetOpenData()
                setNewData(newDataInitial)
            }}
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                [theme.breakpoints.down('lg')]: {
                    alignItems: 'flex-start',
                },
            }}
        >
            <Box sx={{
                width: '45%',
                height: 'auto',
                backgroundColor: theme.palette.background.default,
                border: 1,
                borderColor: theme.palette.secondary.main,
                position: 'absolute',
                [theme.breakpoints.down('lg')]: {
                    width: '70%'
                },
                [theme.breakpoints.down('md')]: {
                    width: '90%'
                },
            }}>
                <Box sx={{ backgroundColor: theme.palette.background.paper, padding: 5, display: 'flex', justifyContent: 'space-between' }}>
                    <Typography sx={{ fontWeight: 'bold', fontSize: 30 }}>{props.type === 1 ? 'Novo Usuário' : 'Usuário #' + newData.id}</Typography>
                    <Typography sx={{ fontWeight: 'bold', fontSize: 30 }}>{props.type === 1 ? '' : newData.status === 1 ? 'Ativo' : 'Inativo'}</Typography>
                </Box >
                <Box sx={{
                    padding: 5, paddingTop: 1
                }}>
                    <Box sx={{
                        display: 'flex', flexDirection: 'column', width: '100%', gap: 3,
                        [theme.breakpoints.down('md')]: {
                            width: '98%'
                        },
                    }}>
                        <Typography fontWeight={'bold'} fontSize={20}>Informações</Typography>
                        <Box sx={{ display: 'flex', gap: 1, width: '100%', flexWrap: 'wrap' }}>
                            {Object.keys(newData).map((e, idx) => {
                                if (['editable', 'status', 'avatarBase64', 'avatar', 'bandeira'].includes(e)) return
                                if (e === 'lojaToken' && newData.bandeira.id !== 7) return
                                if (e === 'cod_operador' && newData.bandeira.id !== 7) return
                                return (
                                    <TextField
                                        key={idx}
                                        label={e}
                                        margin="none"
                                        value={newData[e]}
                                        disabled={props.type === 2 && e === 'cod_operador' ? true : ['id', 'data_cadastro', 'token'].includes(e) ? true : newData.editable}
                                        id={e}
                                        name={e}
                                        onChange={(value) => {
                                            const x = { ...newData }
                                            x[e] = ['preco'].includes(e) ? realMask(value.target.value) : ['servico', 'prazo', 'cod_operador'].includes(e) ? onlyInteger(value.target.value) : value.target.value
                                            setNewData(x)
                                        }}
                                        sx={{
                                            width: '49%'
                                        }}
                                        InputLabelProps={{
                                            style: {
                                                color: theme.palette.text.secondary,
                                                borderColor: '#eee'
                                            }
                                        }}
                                        autoComplete=""
                                        inputProps={{
                                            maxLength: e === 'estado' ? 2 : 'unset',
                                            style: {
                                                backgroundColor: theme.palette.background.paper,
                                                borderRadius: 5,
                                                textTransform: e === 'estado' ? 'uppercase' : 'unset',
                                            }
                                        }}
                                    />
                                )
                            })}
                            <Box>
                                <FormControl>
                                    <InputLabel id="bandeira">Bandeira</InputLabel>
                                    <Select
                                        value={newData.bandeira.id}
                                        disabled={newData.editable}
                                        onChange={(value) => {
                                            const filterBandeira = props.mainData.bandeiras.filter((bandeira) => bandeira.id === value.target.value)
                                            const formatData = { ...newData }
                                            formatData.bandeira = filterBandeira[0]
                                            setNewData(formatData)
                                        }}
                                        sx={{
                                            width: '200px',
                                            backgroundColor: theme.palette.background.paper,
                                            borderRadius: '4px',
                                        }}
                                    >
                                        {props.mainData.bandeiras.map((bandeira) => (
                                            <MenuItem key={bandeira.id} value={bandeira.id}>
                                                {bandeira.nome}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'flex-start',
                                padding: '20px',
                                gap: '10px',
                            }}
                        >
                            <label htmlFor="upload-image">
                                <Box
                                    component="div"
                                    sx={{
                                        position: 'relative',
                                        width: 'fit-content',
                                        '&:hover .hover-icon': { display: 'block' },
                                    }}
                                >
                                    <Avatar
                                        src={newData.avatarBase64 ? newData.avatarBase64 : newData.avatar + '?t=' + new Date().getTime()}
                                        sx={{
                                            width: 100,
                                            height: 100,
                                            fontSize: '2.5rem',
                                            '&:hover ~ .hover-icon': { display: 'block' },
                                        }}
                                    />
                                    {newData.editable === false ?
                                        <input
                                            type="file"
                                            accept="image/png"
                                            id="upload-image"
                                            ref={fileInputRef}
                                            onChange={handleImageChange}
                                            style={{ display: 'none' }}
                                        />
                                        : null}
                                    {newData.editable === false ?
                                        <IconButton
                                            className="hover-icon"
                                            sx={{
                                                position: 'absolute',
                                                top: '50%',
                                                left: '50%',
                                                transform: 'translate(-50%, -50%)',
                                                backgroundColor: 'rgba(255, 255, 255, 0.8)',
                                                display: 'none',
                                            }}
                                            onClick={() => {
                                                handleIconClick()
                                            }}
                                        >
                                            <PhotoCameraIcon />
                                        </IconButton>
                                        : null}
                                </Box>
                            </label>
                        </Box>
                        <Box sx={{ display: 'flex', gap: 2 }}>
                            {props.type === 2 ?
                                <IconButton
                                    onClick={() => {
                                        if (getEvento(props.eventos, 'Editar Usuário') === false) {
                                            const modalInfo = {
                                                active: true,
                                                title: 'Permissão',
                                                subtitle: 'Você não tem permissão para editar o usuário',
                                                body: <Button onClick={() => props.resetModalInfo()} sx={{ mb: 2, backgroundColor: theme.palette.secondary.main, color: theme.palette.text.primary, '&:hover': { backgroundColor: theme.palette.secondary.dark }, borderRadius: 0, padding: 1 }}>
                                                    <Typography fontWeight={'bold'} sx={{ color: theme.palette.text.whiteText, textAlign: 'center' }}>Voltar</Typography>
                                                </Button>
                                            }
                                            props.updateModalInfo(modalInfo)
                                            return
                                        }
                                        const x = { ...newData }
                                        x.editable = !x.editable
                                        setNewData(x)
                                    }}
                                    sx={{
                                        backgroundColor: theme.palette.primary.main,
                                        borderRadius: 0,
                                        '&:hover': {
                                            backgroundColor: theme.palette.secondary.main,
                                        }
                                    }}>
                                    {props.type === 2 ?
                                        <EditIcon sx={{ color: theme.palette.text.whiteText }} />
                                        : <Typography fontWeight={'bold'} sx={{ color: theme.palette.text.whiteText }}>Salvar</Typography>
                                    }
                                </IconButton>
                                : null
                            }
                            <IconButton
                                onClick={() => {
                                    if (newData.editable === true) return
                                    updateESaveEDeleteClient()
                                }}
                                sx={{
                                    backgroundColor: newData.editable === true ? theme.palette.grey[600] : theme.palette.primary.main,
                                    borderRadius: 0,
                                    '&:hover': {
                                        backgroundColor: newData.editable === false ? theme.palette.secondary.main : theme.palette.grey[600]
                                    }
                                }}>
                                {props.type === 2 ?
                                    <SaveIcon sx={{ color: theme.palette.text.whiteText }} />
                                    : <Typography fontWeight={'bold'} sx={{ color: theme.palette.text.whiteText }}>Salvar</Typography>
                                }
                            </IconButton>
                            {props.type === 2 ?
                                <IconButton
                                    onClick={() => {
                                        if (newData.editable === true) return
                                        if (getEvento(props.eventos, 'Excluir Usuário') === false) {
                                            const modalInfo = {
                                                active: true,
                                                title: 'Permissão',
                                                subtitle: 'Você não tem permissão para excluir usuário',
                                                body: <Button onClick={() => props.resetModalInfo()} sx={{ mb: 2, backgroundColor: theme.palette.secondary.main, color: theme.palette.text.primary, '&:hover': { backgroundColor: theme.palette.secondary.dark }, borderRadius: 0, padding: 1 }}>
                                                    <Typography fontWeight={'bold'} sx={{ color: theme.palette.text.whiteText, textAlign: 'center' }}>Voltar</Typography>
                                                </Button>
                                            }
                                            props.updateModalInfo(modalInfo)
                                            return
                                        }
                                        const modalInfo = {
                                            active: true,
                                            title: 'Exclusão',
                                            subtitle: 'Você tem certeza que deseja excluir o usuário?',
                                            body:
                                                <Box sx={{ display: 'flex', gap: 2 }}>
                                                    <Button onClick={() => props.resetModalInfo()} sx={{ mb: 2, backgroundColor: theme.palette.secondary.main, color: theme.palette.text.primary, '&:hover': { backgroundColor: theme.palette.secondary.dark }, borderRadius: 0, padding: 1 }}>
                                                        <Typography fontWeight={'bold'} sx={{ color: theme.palette.text.whiteText, textAlign: 'center' }}>Voltar</Typography>
                                                    </Button>
                                                    <Button onClick={() => {
                                                        updateESaveEDeleteClient('delete')
                                                    }} sx={{ mb: 2, backgroundColor: theme.palette.secondary.main, color: theme.palette.text.primary, '&:hover': { backgroundColor: theme.palette.secondary.dark }, borderRadius: 0, padding: 1 }}>
                                                        <Typography fontWeight={'bold'} sx={{ color: theme.palette.text.whiteText, textAlign: 'center' }}>Sim</Typography>
                                                    </Button>
                                                </Box>
                                        }
                                        props.updateModalInfo(modalInfo)
                                    }}
                                    sx={{
                                        backgroundColor: newData.editable === true ? theme.palette.grey[600] : theme.palette.primary.main,
                                        borderRadius: 0,
                                        '&:hover': {
                                            backgroundColor: newData.editable === false ? theme.palette.secondary.main : theme.palette.grey[600]
                                        }
                                    }}>
                                    <DeleteIcon sx={{ color: theme.palette.text.whiteText }} />
                                </IconButton>
                                : null}
                            {props.type === 2 ?
                                <IconButton
                                    onClick={() => {
                                        if (newData.editable === true) return
                                        if (getEvento(props.eventos, 'Trocar Status') === false) {
                                            const modalInfo = {
                                                active: true,
                                                title: 'Permissão',
                                                subtitle: 'Você não tem permissão para trocar status',
                                                body: <Button onClick={() => props.resetModalInfo()} sx={{ mb: 2, backgroundColor: theme.palette.secondary.main, color: theme.palette.text.primary, '&:hover': { backgroundColor: theme.palette.secondary.dark }, borderRadius: 0, padding: 1 }}>
                                                    <Typography fontWeight={'bold'} sx={{ color: theme.palette.text.whiteText, textAlign: 'center' }}>Voltar</Typography>
                                                </Button>
                                            }
                                            props.updateModalInfo(modalInfo)
                                            return
                                        }
                                        const formatData = { ...newData }
                                        formatData.status = formatData.status === 1 ? 0 : 1
                                        setNewData(formatData)
                                    }}
                                    sx={{
                                        backgroundColor: newData.editable === true ? theme.palette.grey[600] : theme.palette.primary.main,
                                        borderRadius: 0,
                                        '&:hover': {
                                            backgroundColor: newData.editable === false ? theme.palette.secondary.main : theme.palette.grey[600]
                                        }
                                    }}>
                                    <AutorenewIcon sx={{ color: theme.palette.text.whiteText }} />
                                </IconButton>
                                : null}
                        </Box>
                    </Box >
                </Box >
            </Box >
        </Modal >
    )
}