import { useContext, useState } from "react";
import { Box, useTheme, IconButton, Typography, Badge, Tooltip } from "@mui/material";
import { getIcon } from "../../../../components/icons";
import { ModalInfo } from '../../../../components/modalInfoN';
import { BoxHover } from "../../../../components/boxHover";
import { UpComp } from "../../../../components/upComp";
import moment from 'moment'
import mainApi from "../../../../apis/mainApiN";
import { User } from "../../../../contexts/user";


export function List({ campanhas, excludeCampanha, updateNewCampanha, editCampanha, updateMais, updateCondicao }) {
    const theme = useTheme()
    const user = useContext(User)
    const respStyle = {
        color: theme.palette.text.primary,
        fontSize: 12,
        textAlign: 'center'
    }
    //estados
    const [MInfo, setMInfo] = useState({
        visible: false,
        title: '',
        subtitle: '',
        canDesactive: true,
        icon: '',
        funcS: () => null
    });
    function updateModalInfo(visible, canDesactive, title, subtitle, icon, func) {
        const newMInfo = { ...MInfo };
        newMInfo.visible = visible;
        newMInfo.canDesactive = canDesactive;
        newMInfo.title = title;
        newMInfo.subtitle = subtitle;
        newMInfo.icon = icon;
        newMInfo.funcS = func;
        setMInfo(newMInfo);
    }

    async function deleteCampanha(campanha, control) {
        if (!control) {
            updateModalInfo(true, true, 'Atenção', 'Você tem certeza que deseja excluir a campanha?', 'question', () => deleteCampanha(campanha, true))
            return
        }
        updateModalInfo(true, false, 'Excluindo', 'Estamos excluindo a campanha.', 'loading')
        const tryD = await mainApi(`farmaTouch/excludeCampFranquia/${campanha.id}`, 'DELETE', null, user?.data?.token)
        if (tryD?.status !== 200) {
            const message = tryD?.apiReturn?.message ? tryD?.apiReturn?.message : 'Ocorreu um erro ao excluir a campanha.'
            updateModalInfo(true, true, 'Atenção', message, 'exclamation')
            return
        }
        excludeCampanha(campanha)
        updateModalInfo(false)
    }
    async function changeStatus(campanha) {
        updateModalInfo(true, false, 'Atualizando', 'Estamos trocando o status da campanha', 'loading')
        const status = campanha.status === 1 ? 0 : 1
        const body = {
            id: campanha.id,
            status: status
        }
        const tryD = await mainApi(`farmaTouch/statusCampFranquia`, 'PUT', body, user?.data?.token)
        if (tryD?.status !== 200) {
            const message = tryD?.apiReturn?.message ? tryD?.apiReturn?.message : 'Ocorreu um erro ao excluir a campanha.'
            updateModalInfo(true, true, 'Atenção', message, 'exclamation')
            return
        }
        campanha.status = status
        editCampanha(campanha)
        updateModalInfo(false)
    }

    function handleButton(type, campanha) {
        if (type === 'Excluir') { deleteCampanha(campanha) }
        if (type === 'Editar') { updateNewCampanha(true, campanha) }
        if (type === 'Status') { changeStatus(campanha) }
        if (type === 'Mais') { updateMais(true, campanha) }
        if (type === 'Condições') { updateCondicao(true, campanha) }
        return
    }
    return (
        <Box sx={{ display: 'flex', flexGrow: 1, flexWrap: 'wrap' }}>
            {campanhas.map((e) => {
                return (
                    <BoxHover key={e?.id} width={300}>
                        <Badge badgeContent={e?.status === 1 ? 'Ativo' : 'Inativo'} color={e?.status === 1 ? 'secondary' : 'primary'} sx={{ width: '100%' }}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', height: 200, width: '100%', gap: 5, overflow: 'hidden' }}>
                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
                                    <Typography sx={{ fontWeight: 'bold', fontSize: 20 }}>{e?.nome}</Typography>
                                    <Typography sx={respStyle}>{e?.descricao}</Typography>
                                    <Typography sx={respStyle}>{`Início: ${moment(e?.init).format('DD/MM/YYYY')}`}</Typography>
                                    <Typography sx={respStyle}>{`Fim: ${moment(e?.end).format('DD/MM/YYYY')}`}</Typography>
                                </Box>
                                <Box sx={{ display: 'flex', width: '100%', gap: 5, justifyContent: 'center' }}>
                                    {['Editar', 'Status', 'Condições', 'Mais', 'Excluir'].map((el, idx) => {
                                        const icon = idx === 0 ? 27 : idx === 2 ? 20 : idx === 4 ? 28 : idx === 1 ? 59 : 55
                                        const background = idx < 3 ? theme.palette.secondary.main : idx === 4 ? 'red' : 'green'
                                        return (
                                            <UpComp tamanho={10} key={el}>
                                                <Tooltip title={el} >
                                                    <IconButton onClick={() => handleButton(el, e)} sx={{ background: background }}>
                                                        {getIcon(icon, theme.palette.text.whiteText)}
                                                    </IconButton>
                                                </Tooltip>
                                            </UpComp>
                                        )
                                    })}
                                </Box>
                            </Box>
                        </Badge>
                    </BoxHover>
                )
            })}
            < ModalInfo hideModal={() => updateModalInfo(false)} canDesactive={MInfo.canDesactive} visible={MInfo.visible} title={MInfo.title} subtitle={MInfo.subtitle} icon={MInfo.icon} funcS={MInfo.funcS} />
        </Box>
    )
}
