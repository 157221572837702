import React, { useState, useMemo, useEffect } from "react";
import { useTheme } from '@mui/material/styles';
import { Box, Typography, Button, Avatar, Pagination } from '@mui/material';


export default props => {
    const theme = useTheme()
    const boxStyle = {
        width: '250px',
        backgroundColor: 'rgba(255, 255, 255, 0.1)',
        padding: '20px',
        display: 'flex',
        gap: 1,
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '10px',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
        transition: 'transform 0.2s ease-in-out',
        position: 'relative',
        '&:hover': {
            transform: 'scale(1.05)',
        },
    };
    const [page, setPage] = useState(1);
    const itemsPerPage = 10;
    const paginatedProdutos = props.listData.slice((page - 1) * itemsPerPage, page * itemsPerPage);
    const handleChangePage = (event, value) => {
        setPage(value);
    };
    useEffect(() => {
        setPage(1)
    }, [props.listData])
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 5 }}>
                {useMemo(() => {
                    return paginatedProdutos.map((e, idx) => {
                        return (
                            <Box key={idx} sx={boxStyle}>
                                <Avatar src={e?.avatar ? e.avatar + '?t=' + new Date().getTime() : null} sx={{ width: 64, height: 64, marginBottom: '10px' }} />
                                <Typography variant="h6" sx={{ marginBottom: '5px', fontSize: 20 }}>{e?.nome}</Typography>
                                <Typography variant="body1" sx={{ color: 'text.secondary', marginBottom: '5px' }}>{e?.email}</Typography>
                                <Typography variant="body1" sx={{ color: 'text.secondary', marginBottom: '5px' }}>{e?.bandeira_nome}</Typography>
                                <Typography variant="body2">{e?.status === 1 ? 'Ativo' : 'Inativo'}</Typography>
                                {e?.bandeira_id === 7 ?
                                    <>
                                        <Typography sx={{ fontSize: 17, color: 'text.secondary', marginBottom: '5px', fontWeight: 'bold', textAlign: 'center' }}>{'Loja:'}</Typography>
                                        <Typography variant="body1" sx={{ color: 'text.secondary', marginBottom: '5px' }}>{e?.lNome}</Typography>
                                        <Typography variant="body1" sx={{ color: 'text.secondary', marginBottom: '5px' }}>{e?.lBandeira}</Typography>
                                    </>
                                    : null
                                }
                                <Button className="removeDefault" onClick={() => props.openInside(e, null, 'inside')}
                                    sx={{
                                        background: theme.palette.primary.main,
                                        padding: '5px !important',
                                        width: '100%',
                                        '&:hover': {
                                            background: theme.palette.primary.dark
                                        }
                                    }}
                                >
                                    <Typography sx={{ color: theme.palette.text.whiteText }}>Editar</Typography>
                                </Button>
                                <Button
                                    className="removeDefault"
                                    sx={{
                                        background: theme.palette.primary.main,
                                        padding: '5px !important',
                                        width: '100%',
                                        '&:hover': {
                                            background: theme.palette.primary.dark
                                        }
                                    }}
                                    onClick={() => props.openInside(e, null, 'modulos')}
                                >
                                    <Typography sx={{ color: theme.palette.text.whiteText }}>Módulos</Typography>
                                </Button>
                            </Box>
                        )
                    })
                }, [paginatedProdutos])}
            </Box>
            <Pagination
                count={Math.ceil(props.listData.length / itemsPerPage)}
                page={page}
                onChange={handleChangePage}
                color="primary"
                sx={{ marginTop: 2, alignSelf: 'center' }}
            />
        </Box>
    );
};