import { CssBaseline, ThemeProvider } from "@mui/material";
import { useMode, ColorModeContext } from "./theme/theme";
import React from "react"
import User from "./contexts/user"
import Route from "./route"
import './index.css'

export default props => {
    const [theme, colorMode] = useMode()

    return (
        <User>
            <ColorModeContext.Provider value={colorMode}>
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <Route></Route>
                </ThemeProvider>
            </ColorModeContext.Provider>
        </User>
    )
}