import { useTheme, Button } from "@mui/material"

export function ButtonTitle({ text, func, funcParameters, background }) {
    const theme = useTheme()
    const style = {
        background: background ? background : theme.palette.primary.main,
        fontWeight: 'bold',
        color: theme.palette.text.onPrimary,
        textTransform: 'none',
        padding: 1.5,
        '&:hover': {
            background: theme.palette.tertiary.main,
            color: theme.palette.text.onPrimary
        }
    }
    return (
        <Button sx={style} type='submit' onClick={() => func(funcParameters)}>{text}</Button>
    )
}
export function ButtonSubmit({ text, func, funcParameters, background, backgroundHover, color, colorHover, width, fontWeight, disabled }) {
    const theme = useTheme()
    const style = {
        background: disabled ? theme.palette.background.paper : background ? background : theme.palette.primary.main,
        fontWeight: fontWeight ? 'bold' : 'regular',
        color: color ? color : theme.palette.text.whiteText,
        textTransform: 'none',
        padding: 1.5,
        width: width ? width : 90,
        '&:hover': {
            background: backgroundHover ? backgroundHover : theme.palette.secondary.main,
            color: colorHover ? colorHover : theme.palette.text.whiteText
        }
    }
    return (
        <Button disabled={disabled ? disabled : false} sx={style} type='submit' onClick={() => func(funcParameters)}>{text}</Button>
    )
}