import React, { useState, useContext, useEffect } from 'react';
import { useTheme, Box, Modal, Typography, Button } from '@mui/material';
import { ModalInfo } from '../../../components/modalInfo';
import { User } from '../../../contexts/user';
import { UpComp } from '../../../components/upComp';
import { getIcon } from '../../../components/icons';
import { ButtonSubmit } from '../../../components/buttons';
import { PendImagem } from './pendencias/imagem';
import mainApi from '../../../apis/mainApiN';
import { PendProdSNome } from './pendencias/produtosSNome';
import { PendProdSImg } from './pendencias/produtoSImagem';
import { PendPreClass } from './pendencias/preClass';

export function Pendencias({ visible, visiblePendencias, getEcomPend }) {
    const theme = useTheme();
    const user = useContext(User);
    //estados
    const [MInfo, setMInfo] = useState({
        visible: false,
        title: '',
        subtitle: '',
        canDesactive: true,
        icon: '',
        funcS: () => null
    });
    const [selected, setSelected] = useState(null)
    const [modalPend, setModalPend] = useState(null)
    const [pendTipos, setPendTipos] = useState([])

    function updateModalInfo(visible, canDesactive, title, subtitle, icon, func) {
        const newMInfo = { ...MInfo };
        newMInfo.visible = visible;
        newMInfo.canDesactive = canDesactive;
        newMInfo.title = title;
        newMInfo.subtitle = subtitle;
        newMInfo.icon = icon;
        newMInfo.funcS = func;
        setMInfo(newMInfo);
    }
    function handleButton(type) {
        if (type === 'Sair') {
            handleDismiss()
            return
        }
        if (selected === 'Produtos Ecom') {
            getEcomPend()
            return
        }
        setModalPend(selected)
    }
    function handleDismiss() {
        visiblePendencias(false)
    }

    async function getPendenciasTipo() {
        if (Array.isArray(pendTipos) && pendTipos.length > 0) return
        updateModalInfo(true, false, 'Atenção', 'Buscando tipos de pendências.', 'loading')
        const get = await mainApi('produtosBase/pendencias/tipo', 'GET', null, user?.data?.token)
        if (get?.status !== 200) {
            const message = get?.apiReturn?.message ? get?.apiReturn?.message : 'Ocorreu um erro ao buscar os tipos.'
            updateModalInfo(true, true, 'Atenção', message, 'exclamation')
            return
        }
        setPendTipos(get?.apiReturn?.apiReturn)
    }

    useEffect(() => {
        getPendenciasTipo()
    }, [])
    return (
        <Modal open={visible} onClose={handleDismiss} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 2 }} slotProps={{
            backdrop: {
                sx: {
                    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(250,255,255, 0.2)' : 'rgba(0,0,0, 0.4)'
                }
            }
        }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', height: 420, width: 650, background: theme.palette.background.default, outline: 'none' }}>
                <Box sx={{ background: theme.palette.background.default, padding: 5, paddingBottom: 1 }}>
                    <Typography sx={{ fontWeight: 'bold', color: theme.palette.text.primary, fontSize: 30 }}>Pendências</Typography>
                    <Typography sx={{ color: theme.palette.text.primary, fontSize: 18 }}>Escolha a baixo qual pendência você irá verificar.</Typography>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, padding: 5, justifyContent: 'space-between', height: '100%', overflowY: 'scroll' }}>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, justifyContent: 'center', alignItems: 'center', }}>
                        {pendTipos.map((e, idx) => {
                            const color = selected === e?.nome || theme.palette.mode === 'dark' ? theme.palette.text.whiteText : theme.palette.primary.main
                            const icon = getIcon(e?.icon, color, 50)
                            return (
                                <Button key={e.id} sx={{ textTransform: 'none', flexDirection: 'column', gap: 1 }} onClick={() => setSelected(e?.nome)}>
                                    <UpComp tamanho={100} background={selected === e?.nome ? theme.palette.secondary.main : 'transparent'}>
                                        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', }}>
                                            {icon}
                                            <Typography sx={{ color: color, fontWeight: 'bold' }}>{e?.nome}</Typography>
                                        </Box>
                                    </UpComp>
                                    <Box sx={{ display: 'flex', gap: 1 }}>
                                        <Typography sx={{ color: theme.palette.mode === 'dark' ? theme.palette.text.primary : theme.palette.secondary.main, fontWeight: 'bold' }}>{'Pontos:'}</Typography>
                                        <Typography sx={{ color: theme.palette.mode === 'dark' ? theme.palette.text.primary : theme.palette.secondary.main, fontWeight: 'bold' }}>{e?.pontos}</Typography>
                                    </Box>
                                </Button>
                            )
                        })}
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', gap: 2, padding: 2 }}>
                    {['Próximo', 'Sair'].map((e) => {
                        const background = e === 'Próximo' ? theme.palette.primary.main : theme.palette.background.paper
                        const color = e === 'Próximo' ? theme.palette.text.whiteText : theme.palette.text.primary
                        return (
                            <ButtonSubmit key={e} text={e} background={background} color={color} func={handleButton} funcParameters={e} />
                        )
                    })}
                </Box>
                <PendImagem visible={modalPend === 'Imagem S/ Produto' ? true : false} dismiss={() => setModalPend(null)} />
                <PendProdSNome visible={modalPend === 'Produtos S/ Nome' ? true : false} dismiss={() => setModalPend(null)} />
                <PendProdSImg visible={modalPend === 'Produto S/ Imagem' ? true : false} dismiss={() => setModalPend(null)} />
                <PendPreClass visible={modalPend === 'Pré - class' ? true : false} dismiss={() => setModalPend(null)} />
                <ModalInfo hideModal={() => updateModalInfo(false)} canDesactive={MInfo.canDesactive} visible={MInfo.visible} title={MInfo.title} subtitle={MInfo.subtitle} icon={MInfo.icon} funcS={MInfo.funcS} />
            </Box>
        </Modal>
    );
}