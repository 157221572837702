import React, { useState, useContext, useEffect } from 'react';
import { useTheme, Box, Typography } from '@mui/material';
import { ModalInfo } from '../../components/modalInfoN';
import { User } from '../../contexts/user';
import { getEvento } from '../../components/getEvento';
import { Filter } from './components/filter';
import mainApi from '../../apis/mainApiN';
import { List } from './components/list';
import { Novo } from './components/novo';
import { Links } from './components/links';

export function Parceiros(props) {
    document.title = 'Parceiros - Central de Negócios'
    const theme = useTheme();
    const user = useContext(User);
    //estados
    const [MInfo, setMInfo] = useState({
        visible: false,
        title: '',
        subtitle: '',
        canDesactive: true,
        icon: '',
        funcS: () => null
    });
    const [data, setData] = useState([])
    const [modalNew, setModalNew] = useState({
        visible: false,
        parceiro: null
    })
    const [linkMod, setLinkMod] = useState({
        visible: false,
        parceiro: null
    })

    function updateLinkMod(visible, parceiro) {
        const newLinkMod = { ...linkMod }
        newLinkMod.visible = visible ? visible : false
        newLinkMod.parceiro = parceiro ? parceiro : null
        setLinkMod(newLinkMod)
    }

    function updateModalInfo(visible, canDesactive, title, subtitle, icon, func) {
        const newMInfo = { ...MInfo };
        newMInfo.visible = visible;
        newMInfo.canDesactive = canDesactive;
        newMInfo.title = title;
        newMInfo.subtitle = subtitle;
        newMInfo.icon = icon;
        newMInfo.funcS = func;
        setMInfo(newMInfo);
    }
    function updateModParc(visible, parceiro) {
        const newModNew = { ...modalNew }
        newModNew.visible = visible ? visible : false
        newModNew.parceiro = parceiro ? parceiro : null
        setModalNew(newModNew)
    }

    function pushParceiro(parceiro) {
        const newData = [...data]
        newData.push(parceiro)
        setData(newData)
    }
    function editParceiro(parceiro) {
        const newData = [...data]
        const index = newData.findIndex((e) => e.id === parceiro.id)
        if (index !== -1) {
            newData[index] = parceiro
            setData(newData)
        }
    }
    function deleteParceiro(parceiro) {
        const newData = data.filter((e) => e.id !== parceiro.id)
        setData(newData)
    }

    async function getParceiros() {
        updateModalInfo(true, false, 'Carregando', 'Buscando parceiros.', 'loading')
        const get = await mainApi('parceirosmp/getParceiros', 'GET', null, user?.data?.token)
        if (get?.status !== 200) {
            const message = get?.apiReturn?.message ? get?.apiReturn?.message : 'Ocorreu um erro ao buscar os parceiros.'
            updateModalInfo(true, true, 'Atenção', message, 'exclamation')
            return
        }
        updateModalInfo(false)
        setData(get?.apiReturn?.apiReturn)
    }
    useEffect(() => {
        getParceiros()
    }, [])

    return (
        <Box sx={{
            width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 5, [theme.breakpoints.down('md')]: {
                overflow: 'scroll'
            },
        }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '90%' }}>
                <Typography variant="h2" fontWeight={'bold'}>{props?.nome}</Typography>
                <Typography fontSize={20}>{props?.subtitle}</Typography>
            </Box>
            <Box sx={{ width: '90%', display: 'flex', flexDirection: 'column', gap: 2 }}>
                {!getEvento(props.eventos, 'Acesso') ?
                    <Box sx={{ padding: 2, background: theme.palette.warning.main }}>
                        <Typography sx={{ color: theme.palette.text.whiteText, fontWeight: 'bold' }}>Você não possui acesso a esse módulo</Typography>
                    </Box>
                    :
                    <>
                        <Filter updateModParc={updateModParc} />
                        <List data={data} updateModParc={updateModParc} editParceiro={editParceiro} deleteParceiro={deleteParceiro} updateLinkMod={updateLinkMod} />
                        <Links visible={linkMod.visible} parceiro={linkMod.parceiro} updateLinkMod={updateLinkMod} />
                        <Novo visible={modalNew.visible} updateModParc={updateModParc} pushParceiro={pushParceiro} prevData={modalNew.parceiro} editParceiro={editParceiro} />
                    </>}
            </Box>
            <ModalInfo hideModal={() => updateModalInfo(false)} canDesactive={MInfo.canDesactive} visible={MInfo.visible} title={MInfo.title} subtitle={MInfo.subtitle} icon={MInfo.icon} funcS={MInfo.funcS} />
        </Box >
    );
}