import React, { useState, useContext, useEffect } from 'react';
import { useTheme, Box, Typography, Button, Badge } from '@mui/material';
import { ModalInfo } from '../../../../../components/modalInfoN';
import { User } from '../../../../../contexts/user';
import mainApi from '../../../../../apis/mainApiN';
import { UpComp } from '../../../../../components/upComp';
import { getIcon } from '../../../../../components/icons';

export function List({ prevData, updateData }) {
    const theme = useTheme();
    const user = useContext(User);
    //estados
    const [data, setData] = useState([])
    const [select, setSelect] = useState({
        type: null,

    })
    const [MInfo, setMInfo] = useState({
        visible: false,
        title: '',
        subtitle: '',
        canDesactive: true,
        icon: '',
        funcS: () => null
    });
    function updateModalInfo(visible, canDesactive, title, subtitle, icon, func) {
        const newMInfo = { ...MInfo };
        newMInfo.visible = visible;
        newMInfo.canDesactive = canDesactive;
        newMInfo.title = title;
        newMInfo.subtitle = subtitle;
        newMInfo.icon = icon;
        newMInfo.funcS = func;
        setMInfo(newMInfo);
    }
    async function getData() {
        updateModalInfo(true, false, 'Carregando', 'Buscando tipos de condição.', 'loading')
        const get = await mainApi('farmaTouch/getCampFranquiaTipos', 'GET', null, user?.data?.token)
        if (get?.status !== 200) {
            const message = get?.apiReturn?.message ? get?.apiReturn?.message : 'Ocorreu um erro ao buscar os tipos.'
            updateModalInfo(true, true, 'Atenção', message, 'exclamation')
            return
        }
        const getCond = get?.apiReturn?.apiReturn
        if (!Array.isArray(getCond) || getCond.length === 0) {
            updateModalInfo(true, true, 'Atenção', 'Não foram encontrados tipos.', 'exclamation')
            return
        }
        updateModalInfo(false)
        setData(getCond)
    }
    function handleSubmit(type) {
        const newData = prevData?.type?.id === type?.id ? null : type
        updateData(newData, 'type')
        return
    }
    useEffect(() => {
        getData()
    }, [])
    return (
        <Box sx={{
            flex: 1,
            padding: 2
        }}>
            {data.map((e, index) => {
                return (
                    <Button key={index} sx={{ width: '100%' }} onClick={() => handleSubmit(e)} >
                        <Badge sx={{ width: '100%' }} badgeContent={prevData?.type?.id === e.id ? <Box sx={{ background: theme.palette.secondary.main, borderRadius: 5 }}>{getIcon(82, theme.palette.text.whiteText)}</Box> : null} anchorOrigin={{ vertical: 'top', horizontal: 'left' }}>
                            <Box key={index} sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 2 }}>
                                <Box sx={{ display: 'flex', background: theme.palette.background.default, boxShadow: theme.palette.mode === 'dark' ? '0px 4px 10px rgba(155, 155, 155, 0.3)' : '0px 4px 10px rgba(0, 0, 0, 0.1)', padding: 3, alignItems: 'center', gap: 3 }}>
                                    <UpComp>
                                        {
                                            getIcon(e.icon, theme.palette.mode === 'dark' ? theme.palette.text.primary : theme.palette.primary.main, 25)
                                        }
                                    </UpComp>
                                    <Box>
                                        <Typography sx={{ color: theme.palette.text.primary, textTransform: 'none', fontWeight: 'bold', textAlign: 'left' }}>{e.nome}</Typography>
                                        <Typography sx={{ color: theme.palette.text.primary, textTransform: 'none' }}>{e.descricao}</Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Badge>
                    </Button>
                )
            })}
            <ModalInfo hideModal={() => updateModalInfo(false)} canDesactive={MInfo.canDesactive} visible={MInfo.visible} title={MInfo.title} subtitle={MInfo.subtitle} icon={MInfo.icon} funcS={MInfo.funcS} />
        </Box>
    );
}