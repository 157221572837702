import React, { useEffect, useState, useContext } from "react";
import { useTheme } from '@mui/material/styles';
import { Box, Typography, Button, Modal, MenuItem, TextField, IconButton, FormControl, Select, InputLabel, OutlinedInput, Chip } from '@mui/material';
import mainApi from "../../apis/mainApi";
import { User } from '../../contexts/user'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { getEvento } from "../../components/getEvento";
import { cpfMask, cepMask, celularMask, realMask, onlyInteger, onlyString } from "../../components/masks";
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import { estadosBr } from "../../components/estadosBr";
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

//value.target.value.replace(/[^a-zA-Z]+/g, '')
export default props => {
    const theme = useTheme()
    const user = useContext(User)
    let data = props?.data
    const newDataInitial = {
        editable: false,
        id: '',
        nome: '',
        content: [{
            condicao_id: '',
            tipo_condicao: '',
            tipo_condicao_name: '',
            condicao_preco: '',
            condicao_estado: [],
            condicao_quantidade: '',
            condicao_preco_maior: '',
        }]
    }
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };
    const [newData, setNewData] = useState(newDataInitial)

    function removeAddData(idx, value) {
        const x = { ...newData }
        if (value === 1) {
            const filter = x.content.filter((e, index) => index !== idx)
            x.content = filter.length === 0 ? newDataInitial.content : filter
        }
        else {
            newDataInitial.content[0].metodo_id = newDataInitial.id
            x.content.push(newDataInitial.content[0])
        }
        setNewData(x)
    }
    async function updateESaveClient() {
        props.updateModalLoading(true)
        function FCallback(callback) {
            props.updateModalLoading(false)
            if (callback.status !== 200) {
                const x = {}
                x.title = 'Erro'
                x.subtitle = callback.apiReturn.message ? callback.apiReturn.message : 'Ocorreu um erro! Tente novamente mais tarde'
                x.body = <Button onClick={() => props.resetModalInfo()} sx={{ mb: 2, backgroundColor: theme.palette.secondary.main, color: theme.palette.text.primary, '&:hover': { backgroundColor: theme.palette.secondary.dark }, borderRadius: 0, padding: 1 }}>
                    <Typography fontWeight={'bold'} sx={{ color: theme.palette.text.whiteText, textAlign: 'center' }}>Voltar</Typography>
                </Button>
                x.active = true
                props.updateModalInfo(x)
            }
            props.resetModalInfo()
            const updateData = { ...props.mainData }
            const filter = updateData.condicoes.filter((e) => e.metodo_id !== newData.id)
            body.condicoes.map((e) => {
                e.metodo_id = newData.id
                filter.push(e)
            })
            updateData.condicoes = filter
            updateData.openDataCondicao.open = false
            props.updateData(updateData)
        }
        const formatData = newData.content.filter((e) => ![null, undefined, ''].includes(e.condicao_preco) && ![null, undefined, ''].includes(e.tipo_condicao) && ((![null, undefined, ''].includes(e.condicao_estado) && !Array.isArray(e.condicao_estado)) || ![null, undefined, ''].includes(e.condicao_quantidade) || ![null, undefined, ''].includes(e.condicao_preco_maior)))
        const body = {
            id: newData.id,
            condicoes: formatData
        }
        if (newData.content.length !== formatData.length) {
            const modalInfo = {
                active: true,
                title: 'Atenção',
                subtitle: 'Existem dados não preenchidos que não serão inseridos. Confirma a alteração?',
                body:
                    <Box sx={{ display: 'flex', gap: 2 }}>
                        <Button onClick={() => props.resetModalInfo()} sx={{ mb: 2, backgroundColor: theme.palette.secondary.main, color: theme.palette.text.primary, '&:hover': { backgroundColor: theme.palette.secondary.dark }, borderRadius: 0, padding: 1 }}>
                            <Typography fontWeight={'bold'} sx={{ color: theme.palette.text.whiteText, textAlign: 'center' }}>Voltar</Typography>
                        </Button>
                        <Button onClick={async () => {
                            await mainApi('entregas/condicoes', 'POST', body, FCallback, user.data.token)
                        }} sx={{ mb: 2, backgroundColor: theme.palette.secondary.main, color: theme.palette.text.primary, '&:hover': { backgroundColor: theme.palette.secondary.dark }, borderRadius: 0, padding: 1 }}>
                            <Typography fontWeight={'bold'} sx={{ color: theme.palette.text.whiteText, textAlign: 'center' }}>Sim</Typography>
                        </Button>
                    </Box>
            }
            props.updateModalInfo(modalInfo)
            return
        }
        await mainApi('entregas/condicoes', 'POST', body, FCallback, user.data.token)
    }


    useEffect(() => {
        if (props.active === true && props.type === 2) {
            const formatData = { ...newData }
            const getData = props.data
            const dataSelect = props.dataSelect
            formatData.id = dataSelect.id ? dataSelect.id : ''
            formatData.nome = dataSelect.nome ? dataSelect.nome : ''
            formatData.editable = true
            if (getData.length > 0) {
                formatData.content = getData
            }
            setNewData(formatData)
        }
    }, [props.active])
    return (
        <Modal
            open={props.active}
            onClose={() => {
                props.resetOpenData()
                setNewData(newDataInitial)
            }}
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                [theme.breakpoints.down('lg')]: {
                    alignItems: 'flex-start',
                },
            }}
        >
            <Box sx={{
                width: '55%',
                height: 500,
                backgroundColor: theme.palette.background.default,
                border: 1,
                borderColor: theme.palette.secondary.main,
                position: 'absolute',
                [theme.breakpoints.down('lg')]: {
                    width: '70%'
                },
                [theme.breakpoints.down('md')]: {
                    width: '90%'
                },
            }}>
                <Box sx={{ backgroundColor: theme.palette.background.paper, padding: 5, display: 'flex', justifyContent: 'space-between' }}>
                    <Typography sx={{ fontWeight: 'bold', fontSize: 30 }}>{props.type === 1 ? 'Nova Entrega' : 'Entrega #' + newData.nome}</Typography>
                    <IconButton
                        onClick={() => {
                            if (newData.editable) {
                                return
                            }
                            removeAddData(null, 2)
                        }}
                        sx={{
                            backgroundColor: newData.editable === true ? theme.palette.grey[600] : theme.palette.primary.main,
                            borderRadius: 0,
                            '&:hover': {
                                backgroundColor: newData.editable === false ? theme.palette.secondary.main : theme.palette.grey[600]
                            }
                        }}>
                        <AddIcon sx={{ color: theme.palette.text.whiteText }} />
                    </IconButton>
                </Box >
                <Box sx={{
                    padding: 5, paddingTop: 1
                }}>
                    <Box sx={{
                        display: 'flex', flexDirection: 'column', width: '100%', gap: 3,
                        [theme.breakpoints.down('md')]: {
                            width: '98%'
                        },
                    }}>
                        <Typography fontWeight={'bold'} fontSize={20}>Informações</Typography>
                        <Box sx={{
                            display: 'flex', paddingTop: 2, flexDirection: 'column', gap: 2, maxHeight: 220, overflow: 'scroll', '&::-webkit-scrollbar': {
                                display: 'none'
                            },
                        }}>
                            {newData.content.map((forData, dataidx) => (
                                <Box key={dataidx} sx={{ display: 'flex', gap: 1, width: '100%' }}>
                                    {
                                        Object.keys(forData).map((e, idx) => {
                                            if (['editable', 'aplicacao_nome', 'condicao_id', 'aplicacao_id', 'tipo_condicao', 'id', 'nome', 'metodo_id'].includes(e)) return
                                            if (e === 'condicao_estado') {
                                                return (
                                                    <FormControl key={idx} sx={{
                                                        width: '18%',
                                                        backgroundColor: theme.palette.background.paper,
                                                        [theme.breakpoints.down('md')]: {
                                                            width: '18%'
                                                        },
                                                    }}>
                                                        <InputLabel id="demo-multiple-chip-label">{e}</InputLabel>
                                                        <Select
                                                            disabled={newData.content[dataidx].tipo_condicao !== 1 ? true : newData.editable}
                                                            labelId="demo-multiple-chip-label"
                                                            id="demo-multiple-chip"
                                                            multiple
                                                            value={typeof newData.content[dataidx].condicao_estado === 'string' ? newData.content[dataidx].condicao_estado.split(',') : []}
                                                            onChange={(value) => {
                                                                const x = { ...newData }
                                                                const newServico = value.target.value.filter((e) => e !== '')
                                                                x.content[dataidx].condicao_estado = newServico.toString()
                                                                setNewData(x)
                                                            }}
                                                            input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                                                            renderValue={(selected) => (
                                                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                                    {selected.map((value) => (
                                                                        <Chip key={value} label={value} />
                                                                    ))}
                                                                </Box>
                                                            )}
                                                            MenuProps={MenuProps}
                                                            sx={{ maxHeight: 53, overflow: 'hidden' }}
                                                        >
                                                            {estadosBr.map((name) => (
                                                                <MenuItem
                                                                    key={name.value}
                                                                    value={name.value}
                                                                >
                                                                    {name.label}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                )
                                            }
                                            if (e === 'tipo_condicao_name') {
                                                return (
                                                    <FormControl disabled={newData.editable} key={idx} sx={{
                                                        width: '18%'
                                                    }}>
                                                        <InputLabel id="demo-simple-select-label">{e}</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            value={newData.content[dataidx].tipo_condicao}
                                                            label={e}
                                                            onChange={(event) => {
                                                                const formatData = { ...newData }
                                                                formatData.content[dataidx].tipo_condicao = event.target.value
                                                                const findIndex = props.entregasType.findIndex((e) => e.id === parseInt(event.target.value))
                                                                formatData.content[dataidx].tipo_condicao_name = props.entregasType[findIndex].name
                                                                formatData.content[dataidx].condicao_estado = []
                                                                formatData.content[dataidx].condicao_quantidade = ''
                                                                formatData.content[dataidx].condicao_preco_maior = ''
                                                                setNewData(formatData)
                                                            }}
                                                            sx={{ backgroundColor: theme.palette.background.paper, }}
                                                        >
                                                            {props.entregasType.map((e, eidx) => (
                                                                <MenuItem key={eidx} value={e.id}>{e.name}</MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                )
                                            }
                                            return (
                                                <TextField
                                                    key={idx}
                                                    label={e}
                                                    margin="none"
                                                    value={[null, undefined].includes(newData.content[dataidx][e]) ? '' : newData.content[dataidx][e]}
                                                    disabled={e === 'condicao_preco_maior' && newData.content[dataidx].tipo_condicao !== 2 ? true : e === 'condicao_quantidade' && newData.content[dataidx].tipo_condicao !== 3 ? true : ['id', 'data_cadastro'].includes(e) ? true : newData.editable}
                                                    id={e}
                                                    name={e}
                                                    onChange={(value) => {
                                                        const x = { ...newData }
                                                        x.content[dataidx][e] = ['condicao_preco_maior', 'preco', 'condicao_preco'].includes(e) ? realMask(value.target.value) : ['servico', 'prazo', 'condicao_quantidade'].includes(e) ? onlyInteger(value.target.value) : value.target.value
                                                        setNewData(x)
                                                    }}
                                                    sx={{
                                                        width: '18%'
                                                    }}
                                                    InputLabelProps={{
                                                        style: {
                                                            color: theme.palette.text.secondary,
                                                            borderColor: '#eee'
                                                        }
                                                    }}
                                                    autoComplete=""
                                                    inputProps={{
                                                        maxLength: e === 'estado' ? 2 : 'unset',
                                                        style: {
                                                            backgroundColor: theme.palette.background.paper,
                                                            borderRadius: 5,
                                                            textTransform: e === 'estado' ? 'uppercase' : 'unset',
                                                        }
                                                    }}
                                                />
                                            )
                                        })
                                    }
                                    <IconButton
                                        onClick={() => {
                                            if (newData.editable) {
                                                return
                                            }
                                            removeAddData(dataidx, 1)
                                        }}
                                        sx={{
                                            backgroundColor: newData.editable === true ? theme.palette.grey[600] : theme.palette.error.main,
                                            borderRadius: 0,
                                            '&:hover': {
                                                backgroundColor: newData.editable === false ? theme.palette.secondary.main : theme.palette.grey[600]
                                            }
                                        }}>
                                        <DeleteIcon sx={{ color: theme.palette.text.whiteText }} />
                                    </IconButton>
                                </Box>

                            ))}
                        </Box>
                        <Box sx={{ display: 'flex', gap: 2 }}>
                            {props.type === 2 ?
                                <IconButton
                                    onClick={() => {
                                        if (getEvento(props.eventos, 'Editar Entrega') === false) {
                                            const modalInfo = {
                                                active: true,
                                                title: 'Permissão',
                                                subtitle: 'Você não tem permissão para editar o cliente',
                                                body: <Button onClick={() => props.resetModalInfo()} sx={{ mb: 2, backgroundColor: theme.palette.secondary.main, color: theme.palette.text.primary, '&:hover': { backgroundColor: theme.palette.secondary.dark }, borderRadius: 0, padding: 1 }}>
                                                    <Typography fontWeight={'bold'} sx={{ color: theme.palette.text.whiteText, textAlign: 'center' }}>Voltar</Typography>
                                                </Button>
                                            }
                                            props.updateModalInfo(modalInfo)
                                            return
                                        }
                                        const x = { ...newData }
                                        x.editable = !x.editable
                                        setNewData(x)
                                    }}
                                    sx={{
                                        backgroundColor: theme.palette.primary.main,
                                        borderRadius: 0,
                                        '&:hover': {
                                            backgroundColor: theme.palette.secondary.main,
                                        }
                                    }}>
                                    <EditIcon sx={{ color: theme.palette.text.whiteText }} />
                                    {/* <Typography fontWeight={'bold'} sx={{ color: theme.palette.text.whiteText }}>Editar</Typography> */}
                                </IconButton>
                                : null}
                            <IconButton
                                onClick={() => {
                                    updateESaveClient()
                                }}
                                sx={{
                                    backgroundColor: newData.editable === true ? theme.palette.grey[600] : theme.palette.primary.main,
                                    borderRadius: 0,
                                    '&:hover': {
                                        backgroundColor: newData.editable === false ? theme.palette.secondary.main : theme.palette.grey[600]
                                    }
                                }}>
                                {props.type === 2 ?
                                    <SaveIcon sx={{ color: theme.palette.text.whiteText }} />
                                    :
                                    <Typography fontWeight={'bold'} sx={{ color: theme.palette.text.whiteText }}>Salvar</Typography>
                                }
                            </IconButton>
                        </Box>
                    </Box >
                </Box >
            </Box >
        </Modal >
    )
}