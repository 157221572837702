import { useRef } from "react";
import { Box, IconButton, Avatar, useTheme } from "@mui/material";
import { getIcon } from "./icons";


export function UserAvatar({ avatar, editable, funcReturn, avatarBase64, icon }) {
    const theme = useTheme()
    const fileInputRef = useRef(null);

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            if (file.type === 'image/jpeg' && file.size <= 20 * 1024 * 1024) {
                const reader = new FileReader();
                reader.onload = () => {
                    const base64String = reader.result;
                    funcReturn(base64String)
                };
                reader.readAsDataURL(file);
            }
            else {
                alert('Por favor, selecione um arquivo jpeg dentro do limite de tamanho (5MB)');
            }
        } else {
            alert('Por favor, selecione um arquivo jpeg');
        }
    };
    const handleIconClick = () => {
        fileInputRef.current.click();
    };
    return (
        <Box >
            <label>
                <Box
                    component="div"
                    sx={{
                        position: 'relative',
                        width: 'fit-content',
                        '&:hover .hover-icon': { display: 'block' },
                    }}
                >
                    {avatarBase64 || avatar ?
                        <Box sx={{ boxShadow: theme.palette.mode === 'dark' ? '0px 4px 10px rgba(0, 0, 0, 0.3)' : '0px 4px 10px rgba(0, 0, 0, 0.1)', overflow: 'hidden', height: 120, width: 120 }}>
                            <img src={avatarBase64 ? avatarBase64 : avatar ? avatar + '?t=' + new Date().getTime() : ''} style={{ objectFit: 'contain', width: '100%' }} />
                        </Box>
                        :
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 120, width: 120, boxShadow: theme.palette.mode === 'dark' ? '0px 4px 10px rgba(0, 0, 0, 0.3)' : '0px 4px 10px rgba(0, 0, 0, 0.1)' }}>
                            {
                                icon
                            }
                        </Box>
                    }

                    {editable ?
                        <>
                            <input
                                type="file"
                                accept="image/jpeg"
                                id="upload-image"
                                ref={fileInputRef}
                                onChange={handleImageChange}
                                style={{ display: 'none' }}
                            />
                            <IconButton
                                className="hover-icon"
                                sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    backgroundColor: 'rgba(255, 255, 255, 0.8)',
                                    display: 'none',
                                }}
                                onClick={() => {
                                    handleIconClick()
                                }}
                            >
                                {getIcon(34)}
                            </IconButton>
                        </>
                        : null}
                </Box>
            </label>
        </Box>
    )
}