import React, { useState, useContext } from 'react';
import { useTheme, Box, Typography, Tooltip, IconButton, Badge } from '@mui/material';
import { ModalInfo } from '../../../components/modalInfoN';
import { User } from '../../../contexts/user';
import { BoxHover } from '../../../components/boxHover';
import { getIcon } from '../../../components/icons';
import { UpComp } from '../../../components/upComp';
import mainApi from '../../../apis/mainApiN';

export function List({ data, updateModParc, editParceiro, deleteParceiro, updateLinkMod }) {
    const theme = useTheme();
    const user = useContext(User);
    //estados
    const [MInfo, setMInfo] = useState({
        visible: false,
        title: '',
        subtitle: '',
        canDesactive: true,
        icon: '',
        funcS: () => null
    });

    function updateModalInfo(visible, canDesactive, title, subtitle, icon, func) {
        const newMInfo = { ...MInfo };
        newMInfo.visible = visible;
        newMInfo.canDesactive = canDesactive;
        newMInfo.title = title;
        newMInfo.subtitle = subtitle;
        newMInfo.icon = icon;
        newMInfo.funcS = func;
        setMInfo(newMInfo);
    }
    const titleStyle = {
        color: theme.palette.text.primary,
        fontWeight: 'bold'
    }
    const respStyle = {
        color: theme.palette.text.primary,
        fontSize: 12,
        textAlign: 'center'
    }
    function handleButton(type, parceiro) {
        if (type === 'Editar') {
            updateModParc(true, parceiro)
        }
        if (type === 'Status') {
            updateStatus(parceiro)
        }
        if (type === 'Excluir') {
            delParceiro(parceiro)
        }
        if (type === 'links') {
            updateLinkMod(true, parceiro)
        }
        return
    }
    async function delParceiro(parceiro, control) {
        if (!control) {
            updateModalInfo(true, true, 'Atenção', 'Você tem certeza que deseja remover o parceiro?', 'question', () => delParceiro(parceiro, true))
            return
        }
        const newParceiro = { ...parceiro }
        newParceiro.status = newParceiro.status === 1 ? 0 : 1
        updateModalInfo(true, false, 'Salvando', 'Trocando o status do parceiro.', 'loading')
        const trySave = await mainApi(`parceirosmp/deleteParceiro/${parceiro.id}`, 'DELETE', null, user?.data?.token)
        if (trySave?.status !== 200) {
            const message = trySave?.apiReturn?.message ? trySave?.apiReturn?.message : 'Ocorreu um erro ao excluir o parceiro.'
            updateModalInfo(true, true, 'Atenção', message, 'exclamation')
            return
        }
        updateModalInfo(false)
        deleteParceiro(newParceiro)
    }
    async function updateStatus(parceiro) {
        const newParceiro = { ...parceiro }
        newParceiro.status = newParceiro.status === 1 ? 0 : 1
        updateModalInfo(true, false, 'Salvando', 'Trocando o status do parceiro.', 'loading')
        const trySave = await mainApi(`parceirosmp/changeStatus`, 'PUT', { id: newParceiro.id, status: newParceiro.status }, user?.data?.token)
        if (trySave?.status !== 200) {
            const message = trySave?.apiReturn?.message ? trySave?.apiReturn?.message : 'Ocorreu um erro ao editar o status.'
            updateModalInfo(true, true, 'Atenção', message, 'exclamation')
            return
        }
        updateModalInfo(false)
        editParceiro(newParceiro)
    }
    return (
        <Box sx={{
            flex: 1
        }}>
            <Box sx={{ display: 'flex' }}>
                {Array.isArray(data) ? data.map((e) => {
                    return (
                        <BoxHover key={e?.id}>
                            <Badge badgeContent={e?.status === 1 ? 'Ativo' : 'Inativo'} color={e?.status === 1 ? 'secondary' : 'primary'} sx={{ width: '100%' }}>
                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', height: e?.pendencia ? 280 : 200, maxWidth: 200, width: '100%', gap: 3, overflow: 'hidden' }}>
                                    <Box sx={{ display: 'flex', flexDirection: 'column', alignSelf: 'center' }}>
                                        {e?.image ?
                                            <Box sx={{ borderRadius: 25, boxShadow: theme.palette.mode === 'dark' ? '0px 4px 10px rgba(0, 0, 0, 0.3)' : '0px 4px 10px rgba(0, 0, 0, 0.1)', overflow: 'hidden', height: 70, width: 70 }}>
                                                <img src={e?.image} style={{ objectFit: 'contain', width: '100%', borderRadius: 90 }} />
                                            </Box>
                                            :
                                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 75, width: 75, borderRadius: 25, boxShadow: theme.palette.mode === 'dark' ? '0px 4px 10px rgba(0, 0, 0, 0.3)' : '0px 4px 10px rgba(0, 0, 0, 0.1)' }}>
                                                {
                                                    getIcon(33, theme.palette.mode === 'dark' ? theme.palette.text.primary : theme.palette.primary.main, 50)
                                                }
                                            </Box>
                                        }
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
                                        <Typography sx={respStyle}>{e?.nome}</Typography>
                                        <Typography sx={respStyle}>{e?.email}</Typography>
                                    </Box>
                                    <Box sx={{ display: 'flex', width: '100%', gap: 5, justifyContent: 'center' }}>
                                        {['Editar', 'Status', 'links', 'Excluir'].map((el, idx) => {
                                            const icon = idx === 0 ? 27 : idx === 3 ? 28 : idx === 1 ? 59 : 8
                                            const background = idx < 2 ? theme.palette.secondary.main : idx === 3 ? 'red' : 'green'
                                            return (
                                                <UpComp tamanho={10}>
                                                    <Tooltip title={el} >
                                                        <IconButton onClick={() => handleButton(el, e)} sx={{ background: background }}>
                                                            {getIcon(icon, theme.palette.text.whiteText)}
                                                        </IconButton>
                                                    </Tooltip>
                                                </UpComp>
                                            )
                                        })}
                                    </Box>
                                </Box>
                            </Badge>
                        </BoxHover>
                    )
                }) : null}
            </Box>
            <ModalInfo hideModal={() => updateModalInfo(false)} canDesactive={MInfo.canDesactive} visible={MInfo.visible} title={MInfo.title} subtitle={MInfo.subtitle} icon={MInfo.icon} funcS={MInfo.funcS} />
        </Box >
    );
}