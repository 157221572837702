import React, { useState, useContext, useEffect } from 'react';
import { useTheme, Box, Typography } from '@mui/material';
import { ModalInfo } from '../../components/modalInfoN';
import { User } from '../../contexts/user';
import { getEvento } from '../../components/getEvento';
import mainApi from '../../apis/mainApiN';
import { Filter } from './components/filter';
import { ListLideres } from './components/listLideres';

export function TabelaLideres(props) {
    document.title = 'Tabela de Líderes - Central de Negócios'
    const theme = useTheme();
    const user = useContext(User);
    //estados
    const [MInfo, setMInfo] = useState({
        visible: false,
        title: '',
        subtitle: '',
        canDesactive: true,
        icon: '',
        funcS: () => null
    });
    const [lideres, setLideres] = useState([])
    const [filterText, setFilterText] = useState('')

    function updateModalInfo(visible, canDesactive, title, subtitle, icon, func) {
        const newMInfo = { ...MInfo };
        newMInfo.visible = visible;
        newMInfo.canDesactive = canDesactive;
        newMInfo.title = title;
        newMInfo.subtitle = subtitle;
        newMInfo.icon = icon;
        newMInfo.funcS = func;
        setMInfo(newMInfo);
    }
    function handleChangeFilter(value) {
        setFilterText(value)
    }

    async function getData(tipo) {
        updateModalInfo(true, false, 'Atenção', 'Buscando dados.', 'loading')
        const get = await mainApi(`liderBoard/${tipo}`, 'GET', null, user?.data?.token)
        if (get?.status !== 200) {
            const message = get?.apiReturn?.message ? get?.apiReturn?.message : 'Ocorreu um erro ao buscar os tipos.'
            updateModalInfo(true, true, 'Atenção', message, 'exclamation')
            return
        }
        console.log(get)
        updateModalInfo(false)
        setLideres(get?.apiReturn?.apiReturn)
    }

    useEffect(() => {
        getData('buscarAtual')
    }, [])

    return (
        <Box sx={{
            width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 5, [theme.breakpoints.down('md')]: {
                overflow: 'scroll'
            },
        }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '90%' }}>
                <Typography variant="h2" fontWeight={'bold'}>{props?.nome}</Typography>
                <Typography fontSize={20}>{props?.subtitle}</Typography>
            </Box>
            <Box sx={{ width: '90%', display: 'flex', flexDirection: 'column', gap: 2 }}>
                {!getEvento(props.eventos, 'Acesso') ?
                    <Box sx={{ padding: 2, background: theme.palette.warning.main }}>
                        <Typography sx={{ color: theme.palette.text.whiteText, fontWeight: 'bold' }}>Você não possui acesso a esse módulo</Typography>
                    </Box>
                    :
                    <>
                        <Filter getData={getData} handleChangeFilter={handleChangeFilter} />
                        <ListLideres lideres={lideres} filterText={filterText} />
                    </>
                }
            </Box>
            <ModalInfo hideModal={() => updateModalInfo(false)} canDesactive={MInfo.canDesactive} visible={MInfo.visible} title={MInfo.title} subtitle={MInfo.subtitle} icon={MInfo.icon} funcS={MInfo.funcS} />
        </Box>
    );
}