import { createContext, useState, useEffect } from 'react'
import Cookies from 'universal-cookie';
import { decrypt, encrypt } from '../functions';
import mainApi from '../apis/mainApi';


export const User = createContext()

export default props => {
    const dataInit = {
        token: null
    }
    const [data, setData] = useState(dataInit)
    const cookies = new Cookies()
    async function getCookies() {
        var user = sessionStorage.getItem('user')
        if (user) {
            var decryptedData = decrypt(user)
            function apiCallBack(callback) {
                if (callback.status === 200) {
                    setData(callback.apiReturn.apiReturn[0])
                    var ciphertext = encrypt(callback.apiReturn.apiReturn[0])
                    cookies.set('user', ciphertext, { path: '/', maxAge: 30 });
                    sessionStorage.setItem('user', ciphertext)
                    return
                }
                setData(decryptedData)
            }
            await mainApi('validadeToken', 'GET', null, apiCallBack, decryptedData.token)
            return
        }
        var user = cookies.get('user')
        if (user) {
            var decryptedData = decrypt(user)
            function apiCallBack(callback) {
                if (callback.status === 200) {
                    setData(callback.apiReturn.apiReturn[0])
                    var ciphertext = encrypt(callback.apiReturn.apiReturn[0])
                    cookies.set('user', ciphertext, { path: '/', maxAge: 30 });
                    sessionStorage.setItem('user', ciphertext)
                }
            }
            await mainApi('validadeToken', 'GET', null, apiCallBack, decryptedData.token)
        }
    }
    const changeData = (value) => {
        var ciphertext = encrypt(value)
        cookies.set('user', ciphertext, { path: '/', maxAge: 30 });
        sessionStorage.setItem('user', ciphertext)
        setData(value)
    }
    const logOff = () => {
        cookies.remove('user')
        sessionStorage.removeItem('user')
        setData(dataInit)
    }
    useEffect(() => {
        getCookies()
    }, [])

    return (
        <User.Provider value={{ data, changeData, logOff }}>
            {props.children}
        </User.Provider>
    )
}
