import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { Link } from 'react-router-dom';
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import { ColorModeContext } from '../theme/theme';
import LogoutIcon from '@mui/icons-material/Logout';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import WcIcon from '@mui/icons-material/Wc';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import HomeIcon from '@mui/icons-material/Home';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import { User } from '../contexts/user';
import { ModalInfo } from './modalInfo';
import { Avatar, Button, Collapse } from '@mui/material';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import mainApi from '../apis/mainApi';
import Cookies from 'universal-cookie';
import { getIcon } from './icons';

const drawerWidth = 240;

const openedMixin = (theme) => ({
    width: drawerWidth,
    backgroundColor: theme.palette.background.default,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    backgroundColor: theme.palette.background.default,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    backgroundColor: theme.palette.background.default,
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

export function SidHeader(props) {
    const theme = useTheme();
    const [open, setOpen] = React.useState(true);
    const fileInputRef = React.useRef(null);
    const cookies = new Cookies()
    const handleIconClick = () => {
        fileInputRef.current.click();
    };
    const modalInfoInitial = {
        open: false,
        title: '',
        subtitle: '',
        body: ''
    }
    const [modalInfo, setModalInfo] = React.useState(modalInfoInitial)
    const changeTheme = React.useContext(ColorModeContext)
    const user = React.useContext(User)
    const [isCollapse, setIsCollapse] = React.useState(null)

    const handleDrawerOpen = () => {
        cookies.set('isOpen', !open)
        setOpen(!open);
    };

    const handleDrawerClose = () => {
        cookies.set('isOpen', !open)
        setOpen(!open);
    };
    const closeModalInfo = () => {
        setModalInfo(modalInfoInitial)
    }
    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            if (file.type === 'image/png' && file.size <= 5 * 1024 * 1024) {
                const reader = new FileReader();
                reader.onload = () => {
                    const base64String = reader.result;
                    // Utilize base64String conforme necessário
                    function FCallback(callback) {
                        if (callback.status === 200) {
                            const newUser = { ...user.data }
                            newUser.avatar = callback.apiReturn.apiReturn
                            user.changeData(newUser)
                            return
                        }
                        const newModalInfo = { ...modalInfo }
                        newModalInfo.open = true
                        newModalInfo.title = 'Atenção'
                        newModalInfo.subtitle = 'Não foi possível alterar o avatar. Tente novamente mais tarde.'
                        newModalInfo.body = (<Box sx={{ display: 'flex', gap: 2 }}>
                            <Button onClick={() => closeModalInfo()} sx={{ mb: 2, backgroundColor: theme.palette.secondary.main, color: theme.palette.text.primary, '&:hover': { backgroundColor: theme.palette.secondary.dark }, borderRadius: 0, padding: 1 }}>
                                <Typography fontWeight={'bold'} sx={{ color: theme.palette.text.whiteText, textAlign: 'center' }}>Voltar</Typography>
                            </Button>
                        </Box>)
                        setModalInfo(newModalInfo)
                    }
                    const base64Formated = base64String.replace('data:image/png;base64,', '')
                    mainApi('usuarios/changeAvatar', 'PUT', { base64: base64Formated, id: user.data.id }, FCallback, user?.data?.token)
                };
                reader.readAsDataURL(file);
            }
            else {
                alert('Por favor, selecione um arquivo PNG dentro do limite de tamanho (5MB)');
            }
        } else {
            alert('Por favor, selecione um arquivo PNG');
        }
    };

    function handleChangeCollapse(index) {
        if (isCollapse === null || index !== isCollapse) {
            setIsCollapse(index)
        }
        else {
            setIsCollapse(null)
        }
    }
    React.useEffect(() => {
        const isOpen = cookies.get('isOpen')
        if (![null, undefined].includes(isOpen)) {
            setOpen(isOpen === 'false' ? false : true)
        }
    }, [])

    return (
        <Box sx={{ display: 'flex' }}>
            <AppBar position="fixed" open={false}>
                <Toolbar sx={{
                    display: 'flex',
                    justifyContent: 'space-between'
                }}>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center'
                    }}>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            edge="start"
                            sx={{
                                marginRight: 5,
                                /* s */
                            }}
                        >
                            <MenuIcon sx={{ color: theme.palette.text.primary }} />
                        </IconButton>
                        <img src={theme.palette.logo} style={{ maxWidth: 100 }} />
                    </Box>
                    <Box sx={{ display: 'flex', gap: 3 }}>
                        <Box sx={{
                        }}>
                            <IconButton
                                sx={{ backgroundColor: theme.palette.background.default }}
                                onClick={() => {
                                    changeTheme.toggleColorMode()
                                }}
                            >
                                {theme.palette.mode === 'dark' ?
                                    <LightModeIcon sx={{ color: theme.palette.text.primary }} />
                                    : <DarkModeIcon sx={{ color: theme.palette.text.primary }} />}
                            </IconButton>
                        </Box>
                        <Box sx={{
                        }}>
                            <IconButton
                                sx={{ backgroundColor: theme.palette.background.default }}
                                onClick={() => {
                                    const newModalInfo = { ...modalInfo }
                                    newModalInfo.open = true
                                    newModalInfo.title = 'Sair'
                                    newModalInfo.subtitle = 'Você tem certeza que deseja sair?'
                                    newModalInfo.body = (<Box sx={{ display: 'flex', gap: 2 }}>
                                        <Button onClick={() => closeModalInfo()} sx={{ mb: 2, backgroundColor: theme.palette.secondary.main, color: theme.palette.text.primary, '&:hover': { backgroundColor: theme.palette.secondary.dark }, borderRadius: 0, padding: 1 }}>
                                            <Typography fontWeight={'bold'} sx={{ color: theme.palette.text.whiteText, textAlign: 'center' }}>Voltar</Typography>
                                        </Button>
                                        <Button onClick={() => user.logOff()} sx={{ mb: 2, backgroundColor: theme.palette.secondary.main, color: theme.palette.text.primary, '&:hover': { backgroundColor: theme.palette.secondary.dark }, borderRadius: 0, padding: 1 }}>
                                            <Typography fontWeight={'bold'} sx={{ color: theme.palette.text.whiteText, textAlign: 'center' }}>Sim</Typography>
                                        </Button>
                                    </Box>)
                                    setModalInfo(newModalInfo)

                                }}

                            >
                                <LogoutIcon sx={{ color: theme.palette.text.primary }} />
                            </IconButton>
                        </Box>
                    </Box>
                </Toolbar>
            </AppBar>
            <Drawer variant="permanent" open={open}>
                <DrawerHeader>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'rtl' ? <ChevronRightIcon color={theme.palette.text.primary} /> : <ChevronLeftIcon color={theme.palette.text.primary} />}
                    </IconButton>
                </DrawerHeader>
                <Divider />
                {open ?
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            padding: '20px',
                            gap: '10px',
                        }}
                    >
                        <label htmlFor="upload-image">
                            <Box
                                component="div"
                                sx={{
                                    position: 'relative',
                                    width: 'fit-content',
                                    '&:hover .hover-icon': { display: 'block' },
                                }}
                            >
                                <Avatar
                                    src={user.data.avatar ? user.data.avatar + '?t=' + new Date().getTime() : null}
                                    sx={{
                                        width: 100,
                                        height: 100,
                                        fontSize: '2.5rem',
                                        '&:hover ~ .hover-icon': { display: 'block' },
                                    }}
                                />
                                <input
                                    type="file"
                                    accept="image/png"
                                    id="upload-image"
                                    ref={fileInputRef}
                                    onChange={handleImageChange}
                                    style={{ display: 'none' }}
                                />
                                <IconButton
                                    className="hover-icon"
                                    sx={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                        backgroundColor: 'rgba(255, 255, 255, 0.8)',
                                        display: 'none',
                                    }}
                                    onClick={handleIconClick}
                                >
                                    <PhotoCameraIcon />
                                </IconButton>
                            </Box>
                        </label>
                        <Typography variant="h5">{user?.data?.name}</Typography>
                    </Box>
                    : null}
                <Divider key={'key12345'} />
                <List>
                    <ListItem disablePadding sx={{ display: 'block' }}>
                        <ListItemButton
                            component={Link}
                            to={'/'}
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? 'initial' : 'center',
                                px: 2.5,
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : 'auto',
                                    justifyContent: 'center',
                                }}
                            >
                                {<HomeIcon sx={{ color: theme.palette.mode === 'dark' ? theme.palette.text.secondary : theme.palette.secondary.main }} />}
                            </ListItemIcon>
                            <ListItemText primary={'Início'} sx={{ opacity: open ? 1 : 0 }} />
                        </ListItemButton>
                        <Divider key={'key123321sssaa'} />
                    </ListItem>
                    {Array.isArray(props?.permissoes) ? props?.permissoes.map((text, index) => (
                        <Box key={index}>
                            <ListItem key={index} disablePadding sx={{ width: '100%' }} >
                                <ListItemButton
                                    component={!text?.inside ? Link : null}
                                    to={text?.endpoint}
                                    sx={{
                                        minHeight: 60,
                                        justifyContent: open ? 'initial' : 'center',
                                        px: 2.5,
                                    }}
                                    onClick={() => {
                                        if (text?.inside) {
                                            handleChangeCollapse(index)
                                        }
                                    }}
                                >
                                    <ListItemIcon
                                        sx={{
                                            minWidth: 0,
                                            mr: open ? 3 : 'auto',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        {getIcon(text.icon, theme.palette.mode === 'dark' ? theme.palette.text.secondary : theme.palette.secondary.main)}
                                    </ListItemIcon>
                                    <ListItemText primary={text?.nome} sx={{ opacity: open ? 1 : 0 }} />
                                </ListItemButton>
                            </ListItem>
                            <Divider />
                            {Array.isArray(text?.inside) ? text.inside.map((e) => {
                                return (
                                    <Collapse key={e?.endpoint} in={isCollapse === index ? true : false} sx={{ background: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : theme.palette.background.paper }}>
                                        <ListItem key={index} disablePadding sx={{ display: 'block' }} >
                                            <ListItemButton
                                                component={Link}
                                                to={e?.endpoint}
                                                sx={{
                                                    minHeight: 60,
                                                    justifyContent: open ? 'initial' : 'center',
                                                    px: 2.5,
                                                }}
                                            >
                                                <ListItemIcon
                                                    sx={{
                                                        minWidth: 0,
                                                        mr: open ? 3 : 'auto',
                                                        justifyContent: 'center',
                                                    }}
                                                >
                                                    {getIcon(e.icon, theme.palette.mode === 'dark' ? theme.palette.text.secondary : theme.palette.text.primary)}
                                                </ListItemIcon>
                                                <ListItemText primary={e?.nome} sx={{ opacity: open ? 1 : 0, color: theme.palette.mode === 'dark' ? theme.palette.text.secondary : theme.palette.text.primary }} />
                                            </ListItemButton>
                                            <Divider sx={{ background: 'rgba(250,250,250, 0.1)' }} />
                                        </ListItem>
                                    </Collapse>
                                )
                            }) : null}
                        </Box>
                    )) : null}
                </List>
            </Drawer>
            <Box component="main" sx={{
                flexGrow: 1, p: 3,
                [theme.breakpoints.down('md')]: {
                    p: 0.5
                },
            }}>
                <DrawerHeader />
                {props.children}
            </Box>
            <ModalInfo ative={modalInfo.open} closeModal={closeModalInfo} title={modalInfo.title} subtitle={modalInfo.subtitle} body={modalInfo.body} />
        </Box>
    );
}