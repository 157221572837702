import React, { useState, useContext, useEffect } from 'react';
import { useTheme, Box, Typography, Pagination, Button, Tooltip, IconButton } from '@mui/material';
import { ModalInfo } from '../../../components/modalInfoN';
import { User } from '../../../contexts/user';
import { BoxHover } from '../../../components/boxHover';
import { getIcon } from '../../../components/icons';
import { UpComp } from '../../../components/upComp';

export function ListProdutos({ produtos, updateEditProduto, handleEcomInfo, isPendEcom }) {
    const theme = useTheme();
    const user = useContext(User);
    //estados
    const [MInfo, setMInfo] = useState({
        visible: false,
        title: '',
        subtitle: '',
        canDesactive: true,
        icon: '',
        funcS: () => null
    });
    const [page, setPage] = useState(1);
    const itemsPerPage = 10;

    function updateModalInfo(visible, canDesactive, title, subtitle, icon, func) {
        const newMInfo = { ...MInfo };
        newMInfo.visible = visible;
        newMInfo.canDesactive = canDesactive;
        newMInfo.title = title;
        newMInfo.subtitle = subtitle;
        newMInfo.icon = icon;
        newMInfo.funcS = func;
        setMInfo(newMInfo);
    }
    const handleChangePage = (event, value) => {
        setPage(value);
    };
    const titleStyle = {
        color: theme.palette.text.primary,
        fontWeight: 'bold'
    }
    const respStyle = {
        color: theme.palette.text.primary,
        textAlign: 'left',
        textTransform: 'uppercase',
        fontSize: 12
    }
    const oneLine = {
        pointerEvents: 'none',
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 1, // Número de linhas
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    }
    function handleButton(type, produto) {
        if (type === 'EDI') {
            updateEditProduto(true, produto)
        }
        if (type === 'Ecommerce') {
            handleEcomInfo(true, produto)
        }
    }
    const boxStyle = { display: 'flex', gap: 1, alignItems: 'center' }
    const paginatedProdutos = produtos.slice((page - 1) * itemsPerPage, page * itemsPerPage);
    useEffect(() => {
        setPage(1)
    }, [produtos])
    return (
        <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
            <Box sx={{
                flex: 1,
                display: 'flex',
                flexWrap: 'wrap'
            }}>
                {Array.isArray(paginatedProdutos) ? paginatedProdutos.map((e) => {
                    if (e?.ecomStatus && isPendEcom) return
                    return (
                        <BoxHover key={e?.cod_barra}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', height: e?.pendencia ? 280 : 200, maxWidth: 200, width: '100%', gap: 3, overflow: 'hidden' }}>
                                <Box sx={{ display: 'flex', flexDirection: 'column', alignSelf: 'center' }}>
                                    {e?.image ?
                                        <Box sx={{ borderRadius: 25, boxShadow: theme.palette.mode === 'dark' ? '0px 4px 10px rgba(0, 0, 0, 0.3)' : '0px 4px 10px rgba(0, 0, 0, 0.1)', overflow: 'hidden', height: 70, width: 70 }}>
                                            <img src={e?.image} style={{ objectFit: 'contain', width: '100%', borderRadius: 90 }} />
                                        </Box>
                                        :
                                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 75, width: 75, borderRadius: 25, boxShadow: theme.palette.mode === 'dark' ? '0px 4px 10px rgba(0, 0, 0, 0.3)' : '0px 4px 10px rgba(0, 0, 0, 0.1)' }}>
                                            {
                                                getIcon(47, theme.palette.mode === 'dark' ? theme.palette.text.primary : theme.palette.primary.main, 50)
                                            }
                                        </Box>
                                    }
                                </Box>
                                <Box>
                                    <Box sx={boxStyle}>
                                        <Typography sx={titleStyle}>Código: </Typography>
                                        <Typography sx={respStyle}>{e?.cod_barra}</Typography>
                                    </Box>
                                    <Box sx={boxStyle}>
                                        <Typography sx={titleStyle}>Nome: </Typography>
                                        <Typography sx={[respStyle, oneLine]}>{e?.name}</Typography>
                                    </Box>
                                </Box>
                                {e?.pendencia ?
                                    <>
                                        <Box sx={boxStyle}>
                                            <Typography sx={titleStyle}>Usuário: </Typography>
                                            <Typography sx={[respStyle, oneLine]}>{e?.userName}</Typography>
                                        </Box>
                                        <Box sx={boxStyle}>
                                            <Typography sx={titleStyle}>Pendência: </Typography>
                                            <Typography sx={[respStyle, oneLine]}>{e?.pendNome}</Typography>
                                        </Box>
                                    </>
                                    : null
                                }
                                <Box sx={{ display: 'flex', width: '100%', gap: 5, justifyContent: 'center' }}>
                                    {['EDI', 'Ecommerce'].map((el, idx) => {
                                        const icon = idx === 0 ? 15 : 50
                                        const background = idx === 0 ? theme.palette.secondary.main : 'green'
                                        return (
                                            <UpComp tamanho={10}>
                                                <Tooltip title={el} >
                                                    <IconButton onClick={() => handleButton(el, e)} sx={{ background: background }}>
                                                        {getIcon(icon, theme.palette.text.whiteText)}
                                                    </IconButton>
                                                </Tooltip>
                                            </UpComp>
                                        )
                                    })}
                                </Box>
                            </Box>
                        </BoxHover>
                    )
                }) : null}
            </Box>
            <Pagination
                count={Math.ceil(produtos.length / itemsPerPage)}
                page={page}
                onChange={handleChangePage}
                color="primary"
                sx={{ marginTop: 2, alignSelf: 'center' }}
            />
            <ModalInfo hideModal={() => updateModalInfo(false)} canDesactive={MInfo.canDesactive} visible={MInfo.visible} title={MInfo.title} subtitle={MInfo.subtitle} icon={MInfo.icon} funcS={MInfo.funcS} />
        </Box>
    );
}