import React, { useState, useContext, useEffect } from 'react';
import { useTheme, Box, Modal, Typography, Stepper, Step, StepLabel } from '@mui/material';
import { ModalInfo } from '../../../../../components/modalInfoN';
import { User } from '../../../../../contexts/user';
import { ButtonSubmit } from '../../../../../components/buttons';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay, EffectFade } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import "swiper/css/grid";
import 'swiper/swiper-bundle.css';
import { List } from './list';
import { ListCond } from './listCondicoes';

export function NovaCondicao({ visible, campanha, updateNovaCond, pushData, prevCondicao, updateMainData }) {
    const theme = useTheme();
    const user = useContext(User);
    const [swiper, setSwiper] = useState(null);
    const params = {
        loop: true,
        modules: [Pagination, Navigation, Autoplay, EffectFade],
        effects: 'slide',
        allowTouchMove: false
    };
    const steps = ['Tipo da condição', 'Selecione as condições'];
    const [activeSlideIndex, setActiveSlideIndex] = useState(0);
    const [MInfo, setMInfo] = useState({
        visible: false,
        title: '',
        subtitle: '',
        canDesactive: true,
        icon: '',
        funcS: () => null
    });
    const dataInit = {
        type: null,
        condicoes: []
    }
    const [data, setData] = useState(dataInit)
    function updateModalInfo(visible, canDesactive, title, subtitle, icon, func) {
        const newMInfo = { ...MInfo };
        newMInfo.visible = visible;
        newMInfo.canDesactive = canDesactive;
        newMInfo.title = title;
        newMInfo.subtitle = subtitle;
        newMInfo.icon = icon;
        newMInfo.funcS = func;
        setMInfo(newMInfo);
    }
    function handleSubmit(type) {
        if (type === 'Sair') {
            hideModal()
        }
        if (type === 'Próximo') {
            if (!data.type?.id) {
                updateModalInfo(true, true, 'Atenção', 'Nenhum tipo selecionado.', 'exclamation')
                return
            }
            nextSlide()
        }
        if (type === 'Voltar') {
            prevSlide()
        }
        if (type === 'Salvar') {
            if (prevCondicao) {
                updateMainData(data)
            }
            else {
                pushData(data)
            }
            hideModal()
        }
    }
    function hideModal() {
        updateNovaCond()
        return
    }
    function updateData(value, type) {
        const newData = { ...data }
        newData[type] = value
        setData(newData)
    }

    function nextSlide() {
        swiper.slideTo(activeSlideIndex + 1)
    }
    function prevSlide() {
        swiper.slideTo(activeSlideIndex - 1)
    }

    useEffect(() => {
        setActiveSlideIndex(0)
        const x = prevCondicao ? prevCondicao : dataInit
        console.log(x)
        setData(x)
    }, [visible])


    return (
        <Modal open={visible} onClose={() => hideModal()} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} slotProps={{
            backdrop: {
                sx: {
                    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(250,255,255, 0.2)' : 'rgba(0,0,0, 0.4)'
                }
            }
        }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, height: 850, width: '95%', maxWidth: 700, background: theme.palette.background.default, outline: 'none', }}>
                <Box sx={{ background: theme.palette.background.paper, padding: 5 }}>
                    <Typography sx={{ fontWeight: 'bold', color: theme.palette.text.primary, fontSize: 30 }}>{'Nova Condição'}</Typography>
                    <Typography sx={{ color: theme.palette.text.primary, fontSize: 18 }}>Percorra os passos para adicionar uma nova condição.</Typography>
                </Box>
                <Stepper activeStep={activeSlideIndex} alternativeLabel>
                    {steps.map((label, index) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
                <Box sx={{ display: 'flex', flex: 1, padding: 5, paddingTop: 0 }}>
                    <Swiper style={{ width: '100%' }} {...params} onSlideChange={(swiper) => setActiveSlideIndex(swiper.activeIndex)} onSwiper={setSwiper}>
                        <SwiperSlide><List prevData={data} updateData={updateData} activeIndex={activeSlideIndex} /></SwiperSlide>
                        <SwiperSlide><ListCond prevData={data} updateData={updateData} activeIndex={activeSlideIndex} /></SwiperSlide>
                    </Swiper>
                </Box>
                <Box sx={{ display: 'flex', padding: 5, paddingTop: 0, gap: 2 }}>
                    {['Voltar', 'Próximo', 'Salvar', 'Sair'].map((e, idx) => {
                        if (e === 'Voltar' && activeSlideIndex === 0) return
                        if (e === 'Salvar' && activeSlideIndex !== 1) return
                        if (e === 'Próximo' && activeSlideIndex === 1) return
                        return (
                            <ButtonSubmit key={e} text={e} background={[0, 3].includes(idx) ? theme.palette.background.paper : idx === 2 ? theme.palette.secondary.main : theme.palette.primary.main} color={[0, 3].includes(idx) ? theme.palette.text.primary : theme.palette.text.onPrimary} func={handleSubmit} funcParameters={e} />
                        )
                    })}
                </Box>
                <ModalInfo hideModal={() => updateModalInfo(false)} canDesactive={MInfo.canDesactive} visible={MInfo.visible} title={MInfo.title} subtitle={MInfo.subtitle} icon={MInfo.icon} funcS={MInfo.funcS} />
            </Box>
        </Modal >
    );
}
