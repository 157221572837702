import React, { useEffect, useState, useContext } from "react";
import { useTheme } from '@mui/material/styles';
import { Box, Typography, Button } from '@mui/material';
import mainApi from "../../apis/mainApi";
import { ModalLoading } from "../../components/modal";
import { User } from "../../contexts/user";
import { ModalInfo } from "../../components/modalInfo";
import ListTable from "../../components/listTable";
import EntregasInside from "./entregasInside";
import EntregasCondicao from "./entregasCondicao";
import FilterEntregas from "./filterEntregas";
import { getEvento } from "../../components/getEvento";
export default props => {
    document.title = 'Entregas - Central de Negócios'
    const theme = useTheme()
    const modalInfoInitial = {
        active: false,
        title: '',
        subtitle: '',
        body: ''
    }
    const user = useContext(User)
    const [modalLoading, setModalLoading] = useState(false)
    const [modalInfo, setModalInfo] = useState(modalInfoInitial)
    //'id', 'email', 'cpf', 'nome', 'sobrenome', 'rua', 'bairro', 'cidade', 'estado', 'celular', 'cep'
    const [data, setData] = useState({
        headers: [{
            key: 'id',
            nome: 'ID',
            type: 'int'
        },
        {
            key: 'aplicacao_nome',
            nome: 'Aplicação',
            type: 'string'
        },
        {
            key: 'nome',
            nome: 'Entrega',
            type: 'string'
        },
        {
            key: 'servico',
            nome: 'Serviço',
            type: 'int'
        },
        {
            key: 'preco',
            nome: 'Preço',
            type: 'int'
        },
        {
            key: 'prazo',
            nome: 'Prazo',
            type: 'int'
        },
        {
            key: 'status',
            nome: 'Status',
            type: 'int'
        },
        {
            key: 'info',
            nome: 'Informações',
            type: ''
        },
        {
            key: 'condicao',
            nome: 'Condições',
            type: ''
        },
        ],
        content: [],
        contentFilter: [],
        entregasType: [],
        aplicacoes: [],
        condicoes: [],
        openDataInside: {
            open: false,
            content: [],
            edit: 1
        },
        openDataCondicao: {
            open: false,
            content: [],
            mainSelect: {},
            edit: 1
        }
    })
    function updateModalInfo(value) { setModalInfo(value) }
    function updateData(value) { setData(value) }
    function updateModalLoading(value) { setModalLoading(value) }
    function resetOpenData(value) {
        const newData = { ...data }
        newData.openDataInside = {
            open: false,
            content: {}
        }
        newData.openDataCondicao = {
            open: false,
            content: {}
        }
        setData(newData)
    }

    function resetModalInfo() {
        setModalInfo(modalInfoInitial)
    }
    function openInside(value, idx, type, newClient) {
        const newData = { ...data }
        if (type === 'info') {
            newData.openDataInside.open = true
            newData.openDataInside.content = newClient ? newData.openDataInside.content : value
            newData.openDataInside.edit = newClient ? 1 : 2
        }
        if (type === 'condicao') {
            const condicaoFilter = data.condicoes.filter((e) => e.metodo_id === value.id)
            newData.openDataCondicao.open = true
            newData.openDataCondicao.content = condicaoFilter
            newData.openDataCondicao.edit = newClient ? 1 : 2
            newData.openDataCondicao.mainSelect = value
        }
        setData(newData)
    }
    async function getData() {
        if (modalLoading === false) updateModalLoading(true)
        function FCallback(callback) {
            updateModalLoading(false)
            if (callback.status !== 200) {
                const x = { ...modalInfo }
                x.title = 'Erro'
                x.subtitle = callback.apiReturn.message ? callback.apiReturn.message : 'Ocorreu um erro! Tente novamente mais tarde'
                x.body = <Button onClick={() => setModalInfo(modalInfoInitial)} sx={{ mb: 2, backgroundColor: theme.palette.secondary.main, color: theme.palette.text.primary, '&:hover': { backgroundColor: theme.palette.secondary.dark }, borderRadius: 0, padding: 1 }}>
                    <Typography fontWeight={'bold'} sx={{ color: theme.palette.text.whiteText, textAlign: 'center' }}>Voltar</Typography>
                </Button>
                x.active = true
                setModalInfo(x)
                return
            }
            const newData = { ...data }
            newData.content = callback.apiReturn.apiReturn.entregas
            newData.entregasType = callback.apiReturn.apiReturn.entregas_tipo
            newData.aplicacoes = callback.apiReturn.apiReturn.aplicacoes
            newData.condicoes = callback.apiReturn.apiReturn.condicoes
            setData(newData)
        }
        await mainApi('entregas', 'GET', null, FCallback, user?.data?.token)
    }
    useEffect(() => {
        if (!getEvento(props.eventos, 'Acesso')) return
        getData()
    }, [])
    return (
        <Box sx={{
            width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 5, [theme.breakpoints.down('md')]: {
                overflow: 'scroll', maxWidth: '100%'
            },
        }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '90%' }}>
                <Typography variant="h2" fontWeight={'bold'}>{props?.nome}</Typography>
                <Typography fontSize={20}>{props?.subtitle}</Typography>
            </Box>
            <Box sx={{
                width: '90%',
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                [theme.breakpoints.down('md')]: {
                    width: '98%'
                },
            }}>
                {/* //content */}
                {!getEvento(props.eventos, 'Acesso') ?
                    <Box sx={{ padding: 2, background: theme.palette.warning.main }}>
                        <Typography sx={{ color: theme.palette.text.whiteText, fontWeight: 'bold' }}>Você não possui acesso a esse módulo</Typography>
                    </Box>
                    :
                    <>
                        <FilterEntregas headers={data.headers} updateData={updateData} data={data} openInside={openInside} resetModalInfo={resetModalInfo} updateModalInfo={updateModalInfo} />
                        <ListTable data={{ headers: data.headers, content: data.contentFilter.length > 0 ? data.contentFilter : data.content }} openButton={['info', 'condicao']} open={openInside} />
                    </>
                }
            </Box>
            <EntregasInside aplicacoes={data.aplicacoes} entregasType={data.entregasType} updateModalLoading={updateModalLoading} eventos={props.eventos} resetModalInfo={resetModalInfo} updateModalInfo={updateModalInfo} active={data.openDataInside.open} data={data.openDataInside.content} resetOpenData={resetOpenData} mainData={data} type={data.openDataInside.edit} updateData={updateData} />
            <EntregasCondicao aplicacoes={data.aplicacoes} entregasType={data.entregasType} updateModalLoading={updateModalLoading} eventos={props.eventos} resetModalInfo={resetModalInfo} updateModalInfo={updateModalInfo} active={data.openDataCondicao.open} data={data.openDataCondicao.content} resetOpenData={resetOpenData} mainData={data} type={data.openDataCondicao.edit} dataSelect={data.openDataCondicao.mainSelect} updateData={updateData} />
            <ModalLoading ative={modalLoading} updateModalLoading={updateModalLoading} />
            <ModalInfo height={modalInfo?.height} ative={modalInfo.active} title={modalInfo.title} subtitle={modalInfo.subtitle} body={modalInfo.body} closeModal={resetModalInfo} />
        </Box>
    )
}