import { createContext, useState, useMemo, useEffect } from "react";
import { createTheme } from "@mui/material/styles";
import { color, typography } from "@mui/system";
import Cookies from 'universal-cookie';

//color design tokens

// mui theme settings

export const themeSettings = (mode) => {
    return {
        palette: {
            logo: '../assets/images/logos/franchising-logo.png',
            mode: mode,
            ...(mode === 'light'
                ? {
                    primary: {
                        main: '#212b36',
                        light: '#E6F4F1',
                        dark: '#469088'
                    },
                    secondary: {
                        main: '#103778',
                        light: 'rgb(63, 95, 147)',
                        dark: 'rgb(11, 38, 84)'
                    },
                    background: {
                        default: '#fff',
                        paper: '#eee',
                        light: '#eee'
                    },
                    inverseBackground: {
                        default: '#121212',
                        paper: '#191919'
                    },
                    warning: {
                        main: '#ed6c02',
                        light: '#ff9800',
                        dark: '#e65100'
                    },
                    sucess: {
                        main: '#2e7d32',
                        light: '#4caf50',
                        dark: '#1b5e20',
                    },
                    error: {
                        main: '#f44336',
                        light: '#e57373',
                        dark: '#d32f2f'
                    },
                    text: {
                        primary: 'rgba(0, 0, 0, 0.87)',
                        invert: '#fff',
                        secondary: 'rgba(0, 0, 0, 0.6)',
                        disabled: 'rgba(0, 0, 0, 0.38)',
                        inverse: '#fff',
                        whiteText: '#fff'
                    }
                } : {
                    primary: {
                        main: '#212b36',
                        light: 'rgb(101, 115, 195)',
                        dark: 'rgb(44, 56, 126)'
                    },
                    secondary: {
                        main: '#103778',
                        light: 'rgb(63, 95, 147)',
                        dark: 'rgb(11, 38, 84)'
                    },
                    background: {
                        default: '#121212',
                        paper: '#191919',
                        light: '#262626',
                    },
                    inverseBackground: {
                        default: '#fff',
                        paper: '#eee',
                    },
                    warning: {
                        main: '#ed6c02',
                        light: '#ff9800',
                        dark: '#e65100'
                    },
                    sucess: {
                        main: '#2e7d32',
                        light: '#4caf50',
                        dark: '#1b5e20',
                    },
                    error: {
                        main: '#f44336',
                        light: '#e57373',
                        dark: '#d32f2f'
                    },
                    text: {
                        primary: '#fff',
                        secondary: 'rgba(255, 255, 255, 0.7)',
                        disabled: 'rgba(255, 255, 255, 0.5)',
                        inverse: 'rgba(0, 0, 0, 0.87)',
                        whiteText: '#fff'
                    }
                }
            )
        },
        typography: {
            fontFamily: ["Public Sans", "sans-serif"].join(","),
            fontSize: 12,
            h1: {
                fontFamily: ["Public Sans", "sans-serif"].join(","),
                fontSize: 40,
            },
            h2: {
                fontFamily: ["Public Sans", "sans-serif"].join(","),
                fontSize: 32,
            },
            h3: {
                fontFamily: ["Public Sans", "sans-serif"].join(","),
                fontSize: 24,
            },
            h4: {
                fontFamily: ["Public Sans", "sans-serif"].join(","),
                fontSize: 20,
            },
            h5: {
                fontFamily: ["Public Sans", "sans-serif"].join(","),
                fontSize: 16,
            },
            h6: {
                fontFamily: ["Public Sans", "sans-serif"].join(","),
                fontSize: 14,
            }
        }
    }
}

// context

export const ColorModeContext = createContext({
    toggleColorMode: () => { }
})

export const useMode = () => {
    const [mode, setMode] = useState("light")
    const cookies = new Cookies()

    const colorMode = useMemo(
        () => ({
            toggleColorMode: () => {
                cookies.set('mode', mode === 'dark' ? 'light' : 'dark', { path: '/', maxAge: 999999999999999 });
                setMode((prev) => (prev === 'dark' ? 'light' : 'dark'))
            }
        })
    )
    useEffect(() => {
        const getMode = cookies.get('mode')
        if (getMode) {
            setMode(getMode)
        }
    }, [])

    const theme = useMemo(() => createTheme(themeSettings(mode)), [mode])

    return [theme, colorMode, mode]
}