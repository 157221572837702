import React, { useState, useEffect, forwardRef, useMemo, useRef } from "react";
import { TextField, useTheme, InputAdornment, Box, Typography, Paper } from "@mui/material";
import { styled } from '@mui/material/styles';
import 'quill/dist/quill.snow.css';
import ReactQuill from 'quill';
import DOMPurify from 'dompurify';




export const CTextField = forwardRef(({ value, type, onChangeText, label, security, icon, iconPosition, autoFocus, onKeyPress, prevData, variant, width, disabled }, ref) => {
    const theme = useTheme()
    return (
        useMemo(() => (
            <TextField
                inputRef={ref ? ref : null}
                disabled={disabled ? disabled : false}
                autoFocus={autoFocus ? autoFocus : false}
                variant={variant ? variant : 'standard'}
                onKeyDown={(value) => {
                    if (onKeyPress) {
                        onKeyPress(value, type)
                    }
                }}
                InputLabelProps={{
                    style: { color: theme.palette.text.primary },
                }}
                sx={{
                    width: width ? width : '30%',
                    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderWidth: '1',
                        borderColor: theme.palette.divider
                    },
                    color: theme.palette.text.primary
                }}
                type={security ? 'password' : 'text'}
                InputProps={icon ? {
                    startAdornment: iconPosition === 'start' ? <InputAdornment position={'start'} sx={{ marginRight: '8px' }}>{icon}</InputAdornment> : null,
                    endAdornment: iconPosition === 'end' ? icon : null
                } : null}
                label={label ? label : ' '}
                value={value}
                onChange={(value) => {
                    if (onChangeText) {
                        onChangeText(value.target.value, type)
                    }
                }}
            />
        ), [value, type, prevData, width])
    )
})

// Estilizando o contêiner do editor
const EditorContainer = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    background: theme.palette.background.default
}));

export const TextEditor = ({ initialValue, changeDescricao }) => {
    const [editorContent, setEditorContent] = useState('');
    const editorRef = useRef(null);
    const quillRef = useRef(null);

    useEffect(() => {
        if (!quillRef.current) {
            quillRef.current = new ReactQuill(editorRef.current, {
                theme: 'snow',
                modules: {
                    table: true,
                    toolbar: [
                        [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                        [{ size: [] }],
                        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                        [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
                        ['link', 'image'],
                        ['clean']
                    ],
                },
                formats: [
                    'header', 'font', 'size',
                    'bold', 'italic', 'underline', 'strike', 'blockquote',
                    'list', 'indent',
                    'link', 'image'
                ]
            });

            // Adicionar ouvinte para o evento de mudança de texto do Quill Editor
            quillRef.current.on('text-change', handleEditorChange);
        }

        // Setar o conteúdo inicial
        if (initialValue && quillRef.current) {
            quillRef.current.setContents(convertHtmlToDelta(initialValue))
        }
    }, [initialValue]);
    const convertHtmlToDelta = (html) => {
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = html;
        return quillRef.current.clipboard.convert({ html: tempDiv.innerHTML });
    };

    // Função para lidar com a mudança de texto do editor
    const handleEditorChange = () => {
        const quill = document.querySelector('.ql-editor');
        if (quill) {
            changeDescricao(quill.innerHTML)
        }
    };

    return (
        <EditorContainer ref={editorRef} />
    );
}
