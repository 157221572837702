import React, { useEffect, useState, useContext } from "react";
import { useTheme } from '@mui/material/styles';
import { Box, Typography, TableHead, TableContainer, Table, TableRow, TableCell, TableBody, TableSortLabel, TablePagination, IconButton } from '@mui/material';
import moment from 'moment'
import 'moment/locale/pt-br'
import OpenInFullIcon from '@mui/icons-material/OpenInFull';

export default props => {
    const theme = useTheme()
    const [page, setPage] = useState(0)
    const [data, setData] = useState({
        headers: [],
        content: []
    })
    const [reverse, setReverse] = React.useState({
        type: '',
        active: false
    })
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    function sortedData(key, data, type) {
        const newReverse = { ...reverse }
        newReverse.active = !newReverse.active
        newReverse.type = key
        setReverse(newReverse)
        if (type === 'int') {
            if (reverse.type === key && reverse.active) {
                const x = data.sort((a, b) => (a[key] - b[key])).reverse()
                return x
            }
            const x = data.sort((a, b) => (a[key] - b[key]))
            return x
        }
        if (type === 'string') {
            if (reverse.type === key && reverse.active) {
                const x = data.sort((a, b) => {
                    let fa = a[key].toLowerCase()
                    let fb = b[key].toLowerCase();

                    if (fa < fb) {
                        return -1;
                    }
                    if (fa > fb) {
                        return 1;
                    }
                    return 0
                }).reverse()
                return x
            }
            const x = data.sort((a, b) => {
                let fa = a[key].toLowerCase()
                let fb = b[key].toLowerCase();

                if (fa < fb) {
                    return -1;
                }
                if (fa > fb) {
                    return 1;
                }
                return 0
            })
            return x
        }
        if (type === 'date') {
            if (reverse.type === key && reverse.active) {
                const x = data.sort((a, b) => {
                    return moment(a[key]).diff(moment(b[key]))
                }).reverse()
                return x
            }
            const x = data.sort((a, b) => {
                return moment(a[key]).diff(moment(b[key]))
            })
            return x
        }
    }


    useEffect(() => {
        if (Array.isArray(props?.data?.content)) {
            setData({
                headers: Array.isArray(props?.data?.headers) ? props.data.headers : [],
                content: props.data.content.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            })
        }
    }, [props?.data, rowsPerPage, page])

    return (
        <Box sx={{
            width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center'
        }}>
            <TableContainer sx={{
                width: '100%'
            }}>
                <Table stickyHeader aria-label="sticky table" sx={{ border: 1, borderColor: theme.palette.secondary.main }}>
                    <TableHead >
                        <TableRow sx={{ backgroundColor: theme.palette.primary.main }}>
                            {data.headers.map((e, idx) => (
                                <TableCell key={idx} sx={{
                                    backgroundColor: theme.palette.primary.main, color: theme.palette.text.whiteText, '&.Mui-active': {
                                        color: theme.palette.text.whiteText
                                    }, '&:hover': { color: theme.palette.text.whiteText }
                                }}>
                                    <TableSortLabel
                                        sx={{
                                            '&:hover': { color: theme.palette.text.whiteText }, '&.Mui-active': {
                                                color: theme.palette.text.whiteText
                                            },
                                            fontWeight: 'bold'
                                        }}
                                        active={reverse.type === e.key}
                                        direction={!reverse.active ? 'asc' : 'desc'}
                                        onClick={() => {
                                            const sortData = sortedData(e.key, data.content, e.type)
                                            if (Array.isArray(sortData)) {
                                                const newData = { ...data }
                                                newData.content = sortData
                                                setData(newData)
                                            }
                                        }}>
                                        {e.nome}
                                    </TableSortLabel>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            data.content.map((e, idx) => {
                                const keys = Object.keys(e)
                                return (
                                    <TableRow key={idx} sx={{ backgroundColor: idx & 1 ? theme.palette.background.light : 'transparent' }}>
                                        {keys.map((key) => {
                                            const filter = data.headers.findIndex((index) => index.key === key)
                                            if (filter !== -1) {
                                                return (
                                                    <TableCell key={key}>{e[key]}</TableCell>
                                                )
                                            }
                                        })}
                                        {props.openButton ? props.openButton.map((b) => (
                                            <TableCell key={b}>
                                                <IconButton sx={{ backgroundColor: theme.palette.primary.main, borderRadius: 1 }} onClick={() => {

                                                    props.open(e, idx, b)
                                                }}>
                                                    <OpenInFullIcon sx={{ color: theme.palette.text.primary }} />
                                                </IconButton>
                                            </TableCell>
                                        ))
                                            : null}
                                    </TableRow>
                                )
                            })
                        }
                    </TableBody>
                </Table>
                <TablePagination
                    aria-valuetext="Páginas"
                    rowsPerPageOptions={[5, 10, 15]}
                    component="div"
                    count={props?.data?.content ? props.data.content.length : 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage={"Páginas"}
                />
            </TableContainer>
        </Box >
    )
}