import React, { useEffect, useState, useContext } from "react";
import { useTheme } from '@mui/material/styles';
import { Box, Typography, Button, List, TextField } from '@mui/material';
import mainApi from "../../apis/mainApi";
import Modal from "@mui/material";
import { ModalLoading } from "../../components/modal";
import { User } from "../../contexts/user";
import { ModalInfo } from "../../components/modalInfo";
import moment from 'moment'
import ListTable from "../../components/listTable";
import FilterClientes from "./filterClientes";
import ClientesInside from "./clientesInside";
import ClientesVendas from "./clientesVendas";
import { getEvento } from "../../components/getEvento";
export default props => {
    document.title = 'Clientes - Central de Negócios'
    const theme = useTheme()
    const modalInfoInitial = {
        active: false,
        title: '',
        subtitle: '',
        body: ''
    }
    const user = useContext(User)
    const [modalLoading, setModalLoading] = useState(false)
    const [modalInfo, setModalInfo] = useState(modalInfoInitial)
    //'id', 'email', 'cpf', 'nome', 'sobrenome', 'rua', 'bairro', 'cidade', 'estado', 'celular', 'cep'
    const [data, setData] = useState({
        headers: [
            {
                key: 'id',
                nome: 'ID',
                type: 'int'
            },
            {
                key: 'aplicacao_nome',
                nome: 'Aplicação',
                type: 'string'
            },
            {
                key: 'email',
                nome: 'E-mail',
                type: 'string',
            },
            {
                key: 'cpf',
                nome: 'CPF',
                type: 'string',
            },
            {
                key: 'nome',
                nome: 'Nome',
                type: 'string'
            },
            {
                key: 'sobrenome',
                nome: 'Sobrenome',
                type: 'string'
            },
            {
                key: 'info',
                nome: 'Informações',
                type: ''
            },
            {
                key: 'compras',
                nome: 'Compras',
                type: ''
            },
        ],
        content: [],
        openDataInside: {
            open: false,
            content: {},
            edit: 1
        },
        openDataVendas: {
            open: false,
            content: {}
        }

    })
    function openInside(value, idx, type, newClient) {
        const newData = { ...data }
        if (type === 'inside') {
            newData.openDataInside.open = true
            newData.openDataInside.content = newClient ? newData.openDataInside.content : value
            newData.openDataInside.edit = newClient ? 1 : 2
        }
        if (type === 'compras') {
            newData.openDataVendas.open = true
            newData.openDataVendas.content = value
        }
        setData(newData)
    }
    function updateModalInfo(value) { setModalInfo(value) }
    function updateData(value) { setData(value) }
    function updateModalLoading(value) { setModalLoading(value) }
    function resetOpenData(value) {
        const newData = { ...data }
        newData.openDataInside = {
            open: false,
            content: {}
        }
        newData.openDataVendas = {
            open: false,
            content: {}
        }
        setData(newData)
    }

    function resetModalInfo() {
        setModalInfo(modalInfoInitial)
    }
    async function getData() {
        if (modalLoading === false) updateModalLoading(true)
        function FCallback(callback) {
            updateModalLoading(false)
            if (callback.status !== 200) {
                const x = { ...modalInfo }
                x.title = 'Erro'
                x.subtitle = callback.apiReturn.message ? callback.apiReturn.message : 'Ocorreu um erro! Tente novamente mais tarde'
                x.body = <Button onClick={() => setModalInfo(modalInfoInitial)} sx={{ mb: 2, backgroundColor: theme.palette.secondary.main, color: theme.palette.text.primary, '&:hover': { backgroundColor: theme.palette.secondary.dark }, borderRadius: 0, padding: 1 }}>
                    <Typography fontWeight={'bold'} sx={{ color: theme.palette.text.whiteText, textAlign: 'center' }}>Voltar</Typography>
                </Button>
                x.active = true
                setModalInfo(x)
            }
            const newData = { ...data }
            newData.content = callback.apiReturn.apiReturn
            setData(newData)
        }
        await mainApi('produtos/byaplicacao', 'GET', null, FCallback, user?.data?.token)
    }
    useEffect(() => {
        //getData()
    }, [])
    return (
        <Box sx={{
            width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 5, [theme.breakpoints.down('md')]: {
                overflow: 'scroll', maxWidth: '100%'
            },
        }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '90%' }}>
                <Typography variant="h2" fontWeight={'bold'}>{props?.nome}</Typography>
                <Typography fontSize={20}>{props?.subtitle}</Typography>
            </Box>
            <Box sx={{
                width: '90%',
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                [theme.breakpoints.down('md')]: {
                    width: '98%'
                },
            }}>
                {/* //content */}
                {!getEvento(props.eventos, 'Acesso') ?
                    <Box sx={{ padding: 2, background: theme.palette.warning.main }}>
                        <Typography sx={{ color: theme.palette.text.whiteText, fontWeight: 'bold' }}>Você não possui acesso a esse módulo</Typography>
                    </Box>
                    :
                    <>
                        <FilterClientes headers={data.headers} updateData={updateData} data={data} openInside={openInside} />
                        <ListTable data={{ headers: data.headers, content: data.content }} openButton={['inside', 'compras']} open={openInside} />
                    </>
                }
                {/* <ListProdutos data={data} updateData={updateData} /> */}
            </Box>
            <ClientesInside updateModalLoading={updateModalLoading} eventos={props.eventos} resetModalInfo={resetModalInfo} updateModalInfo={updateModalInfo} active={data.openDataInside.open} data={data.openDataInside.content} resetOpenData={resetOpenData} mainData={data} type={data.openDataInside.edit} updateData={updateData} />
            <ClientesVendas updateModalLoading={updateModalLoading} eventos={props.eventos} resetModalInfo={resetModalInfo} updateModalInfo={updateModalInfo} active={data.openDataVendas.open} data={data.openDataVendas.content} resetOpenData={resetOpenData} mainData={data} type={data.openDataVendas.edit} updateData={updateData} />
            <ModalLoading ative={modalLoading} updateModalLoading={updateModalLoading} />
            <ModalInfo height={modalInfo?.height} ative={modalInfo.active} title={modalInfo.title} subtitle={modalInfo.subtitle} body={modalInfo.body} closeModal={resetModalInfo} />
        </Box>
    )
}