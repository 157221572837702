import React, { useState, useContext, useEffect } from 'react';
import { useTheme, Box, Modal, Typography, Button, IconButton, Tooltip } from '@mui/material';
import { ModalInfo } from '../../../components/modalInfoN';
import { User } from '../../../contexts/user';
import mainApi from '../../../apis/mainApiN';
import { CTextField } from '../../../components/TextField';
import { getIcon } from '../../../components/icons';
import { MenuList } from '../../../components/menu';
import { categToGroups, gruposNames } from '../../../components/gruposNames';
import { categoriasOBJ } from '../../../components/categorias';
import { UserAvatar } from '../../../components/avatar';
import { ButtonSubmit } from '../../../components/buttons';
import { onlyInteger } from '../../../components/masks';
import { UpComp } from '../../../components/upComp';

export function NovoProduto({ visible, dismiss, pushProdutos }) {
    const theme = useTheme();
    const user = useContext(User);
    //estados
    const [MInfo, setMInfo] = useState({
        visible: false,
        title: '',
        subtitle: '',
        canDesactive: true,
        icon: '',
        funcS: () => null
    });

    const [data, setData] = useState(dataInit)
    function handleChangeMenu(index, type) {
        const newData = { ...data }
        newData.Grupos = gruposNames[index]
        newData.Categorias = categoriasOBJ[index]
        const getGrupos = gruposNames.filter((e) => e.name === categToGroups(categoriasOBJ[index].id))
        newData.Grupos = getGrupos[0]
        setData(newData)
    }
    function onChangeText(value, type) {
        const newData = { ...data }
        newData[type] = type === 'Código de Barras' ? onlyInteger(value) : value.toUpperCase()
        setData(newData)
    }
    function updateModalInfo(visible, canDesactive, title, subtitle, icon, func) {
        const newMInfo = { ...MInfo };
        newMInfo.visible = visible;
        newMInfo.canDesactive = canDesactive;
        newMInfo.title = title;
        newMInfo.subtitle = subtitle;
        newMInfo.icon = icon;
        newMInfo.funcS = func;
        setMInfo(newMInfo);
    }
    function handleAvatar(base64) {
        const newData = { ...data }
        newData.image.base64 = base64
        setData(newData)
    }
    async function handleButton(type) {
        if (type === 'Sair') {
            handleDismiss()
            return
        }
        if ([data['Nome'], data['Código de Barras'], data['Grupos'].id, data['Categorias'].id].includes('')) {
            updateModalInfo(true, true, 'Atenção', 'Todos os campos devem ser preenchidos', 'exclamation')
            return
        }
        const body = {
            cod_barra: data['Código de Barras'],
            nome: data['Nome'],
            grupo: data['Grupos'].id,
            categ: data['Categorias'].id
        }
        if (data.image.base64) {
            body.image = data['image'].base64.includes('data:image/jpeg;base64,') ? data['image'].base64.replace('data:image/jpeg;base64,', '') : data['image'].base64
        }
        const TRYSave = await mainApi('produtosBase/novo', 'POST', body, user?.data?.token)
        if (TRYSave?.status !== 200) {
            const message = TRYSave?.apiReturn?.message ? TRYSave?.apiReturn?.message : 'Ocorreu um erro ao salvar o produto.'
            updateModalInfo(true, true, 'Atenção', message, 'exclamation')
            return
        }
        const newProduto = {
            cod_barra: body.cod_barra,
            name: body.nome,
            cod_grupo: data['Grupos'].id,
            nom_grupo: data['Grupos'].name,
            cod_categoria: data['Categorias'].id,
            nom_categ: data['Categorias'].name
        }
        if (TRYSave?.apiReturn?.apiReturn?.imgLink) {
            newProduto.image = TRYSave?.apiReturn?.apiReturn?.imgLink
        }
        pushProdutos(newProduto)
        updateModalInfo(true, false, 'Sucesso', 'Produto inserido com sucesso.', 'sucess')
        setTimeout(() => {
            handleDismiss()
        }, 2000)
    }

    async function sincImage() {
        if (!data['Código de Barras']) {
            updateModalInfo(true, true, 'Atenção', 'Código de barras precisa ser preenchido.', 'exclamation')
            return
        }
        updateModalInfo(true, false, 'Carregando', 'Buscando imagem no servidor.', 'loading')
        const get = await mainApi(`produtosBase/buscarImagem/${data['Código de Barras']}`, 'GET', null, user?.data?.token)
        const image = get?.apiReturn?.apiReturn?.base64
        if (get?.status !== 200 || !image) {
            const message = get?.apiReturn?.message ? get?.apiReturn?.message : 'Ocorreu um erro ao buscar a imagem.'
            updateModalInfo(true, true, 'Atenção', message, 'exclamation')
            return
        }
        const newImage = 'data:image/jpeg;base64,' + image
        updateModalInfo(false)
        const newData = { ...data }
        newData['image'].base64 = newImage
    }
    function handleDismiss() {
        updateModalInfo(false)
        dismiss(false)
    }
    useEffect(() => {
        setData(dataInit)
    }, [visible])

    return (
        <Modal open={visible} onClose={handleDismiss} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 2 }} slotProps={{
            backdrop: {
                sx: {
                    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(250,255,255, 0.2)' : 'rgba(0,0,0, 0.4)'
                }
            }
        }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', height: 750, width: 650, background: theme.palette.background.default, outline: 'none' }}>
                <Box sx={{ background: theme.palette.background.paper, padding: 5 }}>
                    <Typography sx={{ fontWeight: 'bold', color: theme.palette.text.primary, fontSize: 30 }}>Novo Produto</Typography>
                    <Typography sx={{ color: theme.palette.text.primary, fontSize: 18 }}>Preencha as informações a baixo para adicionar um novo produto.</Typography>
                </Box>
                <Box sx={{
                    display: 'flex', flexDirection: 'column', flex: 1,
                    padding: 5, overflowY: 'scroll', '&::-webkit-scrollbar': {
                        background: theme.palette.background.paper,
                        width: 20
                    },
                    gap: 3,
                    '&::-webkit-scrollbar-thumb': {
                        background: theme.palette.primary.main
                    }
                }}>
                    {Object.keys(data).map((e, idx) => {
                        if (e === 'image') {
                            return (
                                <Box key={idx} sx={{ display: 'flex', alignSelf: 'center', justifyContent: 'center', alignItems: 'center', gap: 2 }}>
                                    <UserAvatar icon={getIcon(47, theme.palette.mode === 'dark' ? theme.palette.text.primary : theme.palette.primary.main, 80)} avatar={data?.image.link ? data?.image?.link + '?t=' + new Date().getTime() : data?.image.link} avatarBase64={data.image.base64} editable={true} funcReturn={handleAvatar} />
                                    <UpComp tamanho={40}>
                                        <Tooltip title='Buscar Imagem' placement='right'>
                                            <IconButton onClick={sincImage}>
                                                {getIcon(59)}
                                            </IconButton>
                                        </Tooltip>
                                    </UpComp>
                                </Box>
                            )
                        }
                        if (e === 'Grupos') return <MenuList disabled={true} key={idx} options={gruposNames} param={'name'} selected={data.Grupos} title={e} icon={getIcon(56)} handleChange={handleChangeMenu} />
                        if (e === 'Categorias') return <MenuList key={idx} options={categoriasOBJ} param={'name'} selected={data.Categorias} title={e} icon={getIcon(56)} handleChange={handleChangeMenu} />
                        return (
                            <CTextField key={idx} prevData={data} value={data[e]} onChangeText={onChangeText} type={e} label={e} icon={getIcon(56)} iconPosition={'start'} width={'100%'} />
                        )
                    })}
                </Box>
                <Box sx={{ display: 'flex', gap: 2, padding: 3, paddingLeft: 5 }}>
                    {['Salvar', 'Sair'].map((e, idx) => {
                        const background = e === 'Salvar' ? theme.palette.primary.main : theme.palette.background.paper
                        const color = e === 'Salvar' ? theme.palette.text.whiteText : theme.palette.text.primary
                        return (
                            <ButtonSubmit key={idx} text={e} background={background} color={color} func={handleButton} funcParameters={e} />
                        )
                    })}
                </Box>
                <ModalInfo hideModal={() => updateModalInfo(false)} canDesactive={MInfo.canDesactive} visible={MInfo.visible} title={MInfo.title} subtitle={MInfo.subtitle} icon={MInfo.icon} funcS={MInfo.funcS} />
            </Box>
        </Modal>
    );
}
const dataInit = {
    'image': {
        link: null,
        base64: null
    },
    'Código de Barras': '',
    'Nome': '',
    'Grupos': {
        id: '',
        name: ''
    },
    'Categorias': {
        id: '',
        name: ''
    },
}