import React, { useState, useContext, useEffect } from 'react';
import { useTheme, Box, Typography, Pagination, Button } from '@mui/material';
import { ModalInfo } from '../../../components/modalInfoN';
import { User } from '../../../contexts/user';
import { BoxHover } from '../../../components/boxHover';
import { getIcon } from '../../../components/icons';
import { removerCaracteresEspeciais } from '../../../components/masks';

export function ListLideres({ lideres, filterText }) {
    const theme = useTheme();
    const user = useContext(User);
    //estados
    const [MInfo, setMInfo] = useState({
        visible: false,
        title: '',
        subtitle: '',
        canDesactive: true,
        icon: '',
        funcS: () => null
    });
    const [page, setPage] = useState(1);
    const itemsPerPage = 10;

    function updateModalInfo(visible, canDesactive, title, subtitle, icon, func) {
        const newMInfo = { ...MInfo };
        newMInfo.visible = visible;
        newMInfo.canDesactive = canDesactive;
        newMInfo.title = title;
        newMInfo.subtitle = subtitle;
        newMInfo.icon = icon;
        newMInfo.funcS = func;
        setMInfo(newMInfo);
    }
    const handleChangePage = (event, value) => {
        setPage(value);
    };
    const titleStyle = {
        color: theme.palette.text.primary,
        fontWeight: 'bold'
    }
    const respStyle = {
        color: theme.palette.text.primary,
        textAlign: 'left',
        textTransform: 'uppercase',
        fontSize: 12
    }
    const oneLine = {
        pointerEvents: 'none',
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 1, // Número de linhas
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    }
    const boxStyle = { display: 'flex', gap: 1, alignItems: 'center' }
    const paginatedLideres = lideres.slice((page - 1) * itemsPerPage, page * itemsPerPage);
    useEffect(() => {
        setPage(1)
    }, [lideres])
    return (
        <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
            <Box sx={{
                flex: 1,
                display: 'flex',
                flexWrap: 'wrap'
            }}>
                {Array.isArray(paginatedLideres) ? paginatedLideres.map((e, idx) => {
                    if (validaFilter(e, filterText))
                        return (
                            <BoxHover key={e?.cod_barra}>
                                <Button sx={{ width: '100%', textTransform: 'none' }} onClick={() => null}>
                                    <Box sx={{ display: 'flex', flexGrow: 1, flexDirection: 'column', alignItems: 'flex-start', height: e?.pendencia ? 250 : 180, maxWidth: 200, width: '100%', gap: 2, overflow: 'hidden' }}>
                                        <Box sx={{ display: 'flex', flexDirection: 'column', alignSelf: 'center' }}>
                                            {e?.avatar ?
                                                <Box sx={{ borderRadius: 25, boxShadow: theme.palette.mode === 'dark' ? '0px 4px 10px rgba(0, 0, 0, 0.3)' : '0px 4px 10px rgba(0, 0, 0, 0.1)', overflow: 'hidden', height: 70, width: 70 }}>
                                                    <img src={e?.avatar} style={{ objectFit: 'contain', width: '100%', borderRadius: 90 }} />
                                                </Box>
                                                :
                                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 75, width: 75, borderRadius: 25, boxShadow: theme.palette.mode === 'dark' ? '0px 4px 10px rgba(0, 0, 0, 0.3)' : '0px 4px 10px rgba(0, 0, 0, 0.1)' }}>
                                                    {
                                                        getIcon(47, theme.palette.mode === 'dark' ? theme.palette.text.primary : theme.palette.primary.main, 50)
                                                    }
                                                </Box>
                                            }
                                        </Box>
                                        <Box sx={boxStyle}>
                                            <Typography sx={titleStyle}>Nome: </Typography>
                                            <Typography sx={respStyle}>{e?.name}</Typography>
                                        </Box>
                                        <Box sx={boxStyle}>
                                            <Typography sx={titleStyle}>Pontos: </Typography>
                                            <Typography sx={[respStyle, oneLine]}>{e?.total_pontos}</Typography>
                                        </Box>
                                        <Box sx={boxStyle}>
                                            <Typography sx={titleStyle}>Realizado: </Typography>
                                            <Typography sx={[respStyle, oneLine]}>{e?.total_pendencias}</Typography>
                                        </Box>
                                        {idx === 0 ?
                                            <Box sx={{ position: 'absolute', right: 0, top: 0 }}>
                                                {getIcon(65, 'orange', 50)}
                                            </Box>
                                            : null}
                                    </Box>
                                </Button>
                            </BoxHover>
                        )
                }) : null}
            </Box>
            <Pagination
                count={Math.ceil(lideres.length / itemsPerPage)}
                page={page}
                onChange={handleChangePage}
                color="primary"
                sx={{ marginTop: 2, alignSelf: 'center' }}
            />
            <ModalInfo hideModal={() => updateModalInfo(false)} canDesactive={MInfo.canDesactive} visible={MInfo.visible} title={MInfo.title} subtitle={MInfo.subtitle} icon={MInfo.icon} funcS={MInfo.funcS} />
        </Box>
    );
}

function validaFilter(value, filter) {
    if (!filter) return true
    let nome = !value?.name ? '' : typeof value?.name !== 'string' ? value?.name.toString() : value?.name
    let pontos = !value?.total_pontos ? '' : typeof value?.total_pontos !== 'string' ? value?.total_pontos.toString() : value?.total_pontos
    let pendencias = !value?.total_pendencias ? '' : typeof value?.total_pendencias !== 'string' ? value?.total_pendencias.toString() : value?.total_pendencias
    nome = removerCaracteresEspeciais(nome).toLowerCase()
    pontos = removerCaracteresEspeciais(pontos).toLowerCase()
    pendencias = removerCaracteresEspeciais(pendencias).toLowerCase()
    filter = removerCaracteresEspeciais(filter).toLowerCase()
    if (nome.includes(filter) || pontos.includes(filter) || pendencias.includes(filter)) return true
    return false
}