import React, { useEffect, useState, useContext } from "react";
import { useTheme } from '@mui/material/styles';
import { Box, Typography, Button, List, TextField, Menu, MenuItem, ListItemIcon, ListItemText, MenuList, Divider } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { cpfMask } from "../../components/masks";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import SearchIcon from '@mui/icons-material/Search';
import mainApi from "../../apis/mainApi";
import { User } from "../../contexts/user";
export default props => {
    const theme = useTheme()
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [dataFilter, setDataFilter] = useState({
        type: 'nome',
        name: 'Nome',
        field: '',
    })
    const user = useContext(User)
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const updateParameter = (parameter) => {
        const newDataFilter = { ...dataFilter }
        newDataFilter.type = parameter.key
        newDataFilter.name = parameter.nome
        newDataFilter.field = ''
        setDataFilter(newDataFilter)
        handleClose()
    }
    async function getClientes() {

        function FCallback(callback) {
            if (callback.status === 200) {
                const newData = { ...props.data }
                newData.content = callback.apiReturn.apiReturn
                props.updateData(newData)
            }
        }

        const body = {
            type: dataFilter.type,
            value: dataFilter.field
        }
        await mainApi('clientes/allClient', 'POST', body, FCallback, user.data.token)
    }
    return (
        <Box sx={{
            width: '100%', borderRadius: "8px", padding: 3, display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 5, backgroundColor: theme.palette.background.paper, [theme.breakpoints.down('md')]: {
                overflow: 'scroll', maxWidth: '100%'
            },
        }}>
            <Box sx={{
                display: 'flex',
                gap: 2
            }}>
                <Box>
                    <TextField
                        fullWidth
                        margin="none"
                        required
                        value={dataFilter.field}
                        id="pesquisa"
                        name="pesquisa"
                        label="Pesquisar"
                        onChange={(value) => {
                            const x = { ...dataFilter }
                            x.field = x.type === 'cpf' ? cpfMask(value.target.value) : value.target.value
                            setDataFilter(x)
                        }}
                        /* onKeyDown={(event) => {
                            if (event.key == 'Enter') {
                                props.filterData(filters.form)
                            }
                        }} */
                        sx={{ maxWidth: 200, backgroundColor: theme.palette.background.default, }}
                        InputLabelProps={{
                            style: {
                                color: theme.palette.text.secondary,
                                borderColor: '#eee'
                            }
                        }}
                        autoComplete=""
                    />
                </Box>
                <Button
                    id="basic-button"
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                    sx={{
                        border: 1,
                        borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : theme.palette.grey[400],
                        padding: 2,
                        width: 150,
                        display: 'flex',
                        gap: 1,
                        backgroundColor: theme.palette.background.default,
                    }}
                >
                    <Typography sx={{ color: theme.palette.mode === 'dark' ? theme.palette.grey[400] : theme.palette.grey[600], textTransform: 'none' }}>{dataFilter?.name}</Typography>
                    {open ?
                        <KeyboardArrowUpIcon fontSize="small" sx={{ color: theme.palette.grey[600] }} />
                        : <KeyboardArrowDownIcon fontSize="small" sx={{ color: theme.palette.grey[600] }} />
                    }
                </Button>
                {props.headers ?
                    <Menu
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                    >
                        <MenuList sx={{ width: 150 }}>
                            {props.headers.map((e, idx) => {
                                if (['aplicacao_nome', 'compras', 'info'].includes(e.key)) {
                                    return
                                }
                                if (dataFilter.type === e?.key) {
                                    return (
                                        <Box key={idx}>
                                            <MenuItem onClick={() => updateParameter(e)}>
                                                <ListItemIcon>
                                                    <CheckIcon fontSize="small" />
                                                </ListItemIcon>
                                                <ListItemText sx={{ color: theme.palette.text.primary }}>{dataFilter?.name}</ListItemText>
                                            </MenuItem>
                                            <Divider />
                                        </Box>
                                    )
                                }
                                else {
                                    return (
                                        <Box key={idx + 90}>
                                            <MenuItem onClick={() => updateParameter(e)}>
                                                <ListItemText sx={{ color: theme.palette.text.primary }}>{e?.nome}</ListItemText>
                                                <Divider />
                                            </MenuItem>
                                            <Divider />
                                        </Box>
                                    )
                                }
                            })}
                        </MenuList>
                    </Menu>
                    : null}
                <Button
                    onClick={() => getClientes()}
                    sx={{
                        backgroundColor: theme.palette.primary.main,
                        padding: 2,
                        width: 50,
                        display: 'flex',
                        gap: 1
                    }}
                >
                    <SearchIcon fontSize='medium' sx={{ color: theme.palette.text.whiteText }} />
                    {/* <Typography sx={{ color: theme.palette.text.whiteText, textTransform: 'none' }}>Buscar</Typography> */}
                </Button>
            </Box>
            <Button
                onClick={() => props.openInside(null, null, 'inside', 'new')}
                sx={{
                    backgroundColor: theme.palette.primary.main,
                    padding: 2,
                    width: 70,
                    display: 'flex',
                    gap: 1
                }}
            >
                {/* <SearchIcon fontSize='medium' sx={{ color: theme.palette.text.whiteText }} /> */}
                <Typography sx={{ color: theme.palette.text.whiteText, textTransform: 'none' }}>Adicionar</Typography>
            </Button>
        </Box>
    )
}