export const categorias = {
    800: 'REFERÊNCIA CONTROLADO NÃO CONTÍNUO',
    801: 'REFERÊNCIA CONTROLADO CONTÍNUO',
    802: 'REFERÊNCIA CONTÍNUO',
    803: 'REFERÊNCIA NÃO CONTÍNUO',
    804: 'REFERÊNCIA ANTICONCEPCIONAL',
    805: 'REFERÊNCIA FITOTERÁPICO',
    806: 'GENÉRICO CONTROLADO NÃO CONTÍNUO',
    807: 'GENÉRICO CONTROLADO CONTÍNUO',
    808: 'GENÉRICO CONTÍNUO',
    809: 'GENÉRICO NÃO CONTÍNUO',
    810: 'GENÉRICO ANTICONCEPCIONAL',
    811: 'GENÉRICO DESCONTO REDUZIDO',
    812: 'SIMILAR CONTROLADO NÃO CONTÍNUO',
    813: 'SIMILAR CONTROLADO CONTÍNUO',
    814: 'SIMILAR CONTÍNUO',
    815: 'SIMILAR NÃO CONTÍNUO',
    816: 'SIMILAR ANTICONCEPCIONAL',
    817: 'SIMILAR DESCONTO REDUZIDO',
    818: 'SIMILAR FITOTERÁPICO',
    819: 'PERFUMARIA GERAL',
    820: 'PERFUMARIA DERMO',
    821: 'PERFUMARIA FEMININA',
    822: 'PERFUMARIA MASCULINA',
    823: 'PERFUMARIA INFANTIL',
    824: 'HOSPITALAR',
    825: 'ORTOPÉDICO',
    826: 'FITOTERÁPICO',
    827: 'LEITE',
    828: 'FRALDA INFANTIL',
    829: 'CONVENIÊNCIA',
    830: 'REFERÊNCIA LIBERADO',
    831: 'GENÉRICO LIBERADO',
    832: 'SIMILAR LIBERADO',
    833: 'SUPLEMENTO GERAL',
    834: 'SUPLEMENTO INFANTIL',
    835: 'FRALDA ADULTA',
    836: 'MANIPULADO',
    837: 'ACESSÓRIO'
}
export const categoriasOBJ = [
    { id: 800, name: 'REFERÊNCIA CONTROLADO NÃO CONTÍNUO' },
    { id: 801, name: 'REFERÊNCIA CONTROLADO CONTÍNUO' },
    { id: 802, name: 'REFERÊNCIA CONTÍNUO' },
    { id: 803, name: 'REFERÊNCIA NÃO CONTÍNUO' },
    { id: 804, name: 'REFERÊNCIA ANTICONCEPCIONAL' },
    { id: 805, name: 'REFERÊNCIA FITOTERÁPICO' },
    { id: 806, name: 'GENÉRICO CONTROLADO NÃO CONTÍNUO' },
    { id: 807, name: 'GENÉRICO CONTROLADO CONTÍNUO' },
    { id: 808, name: 'GENÉRICO CONTÍNUO' },
    { id: 809, name: 'GENÉRICO NÃO CONTÍNUO' },
    { id: 810, name: 'GENÉRICO ANTICONCEPCIONAL' },
    { id: 811, name: 'GENÉRICO DESCONTO REDUZIDO' },
    { id: 812, name: 'SIMILAR CONTROLADO NÃO CONTÍNUO' },
    { id: 813, name: 'SIMILAR CONTROLADO CONTÍNUO' },
    { id: 814, name: 'SIMILAR CONTÍNUO' },
    { id: 815, name: 'SIMILAR NÃO CONTÍNUO' },
    { id: 816, name: 'SIMILAR ANTICONCEPCIONAL' },
    { id: 817, name: 'SIMILAR DESCONTO REDUZIDO' },
    { id: 818, name: 'SIMILAR FITOTERÁPICO' },
    { id: 819, name: 'PERFUMARIA GERAL' },
    { id: 820, name: 'PERFUMARIA DERMO' },
    { id: 821, name: 'PERFUMARIA FEMININA' },
    { id: 822, name: 'PERFUMARIA MASCULINA' },
    { id: 823, name: 'PERFUMARIA INFANTIL' },
    { id: 824, name: 'HOSPITALAR' },
    { id: 825, name: 'ORTOPÉDICO' },
    { id: 826, name: 'FITOTERÁPICO' },
    { id: 827, name: 'LEITE' },
    { id: 828, name: 'FRALDA INFANTIL' },
    { id: 829, name: 'CONVENIÊNCIA' },
    { id: 830, name: 'REFERÊNCIA LIBERADO' },
    { id: 831, name: 'GENÉRICO LIBERADO' },
    { id: 832, name: 'SIMILAR LIBERADO' },
    { id: 833, name: 'SUPLEMENTO GERAL' },
    { id: 834, name: 'SUPLEMENTO INFANTIL' },
    { id: 835, name: 'FRALDA ADULTA' },
    { id: 836, name: 'MANIPULADO' },
    { id: 837, name: 'ACESSÓRIO' }
]