import { useState } from "react";
import { Box, IconButton, TextField, Tooltip, useTheme, InputAdornment } from "@mui/material";
import { getIcon } from "../../../components/icons";
import { UpComp } from "../../../components/upComp";
import AccountCircle from '@mui/icons-material/AccountCircle';


export function Filter({ getData, handleChangeFilter }) {
    const theme = useTheme()
    const [buscar, setBuscar] = useState('')
    const [selected, setSelected] = useState(0)
    function handleTextfield(value) {
        setBuscar(value)
    }
    function handleClick(index) {
        if (selected === index) return
        setSelected(index)
        getData(index === 0 ? 'buscarAtual' : 'buscarTotal')
    }
    function OnKeyPressTF(value) {
        if (value.key === 'Enter') {
            handleChangeFilter(buscar)
        }
    }
    return (
        <Box sx={{ padding: 3, boxShadow: theme.palette.mode === 'dark' ? '0px 4px 10px rgba(155, 155, 155, 0.1)' : '0px 4px 10px rgba(0, 0, 0, 0.1)' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap', rowGap: 2 }}>
                <TextField
                    variant='standard'
                    id="input-with-icon-textfield"
                    value={buscar}
                    onChange={(value) => handleTextfield(value.target.value)}
                    label={'Buscar'}
                    sx={{
                        width: '30%',
                        [theme.breakpoints.down('lg')]: {
                            width: '100%'
                        },
                        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderWidth: '1',
                            borderColor: theme.palette.divider
                        },
                        color: theme.palette.text.primary
                    }}
                    onKeyDown={(value) => OnKeyPressTF(value)}
                    InputLabelProps={{
                        style: { color: theme.palette.text.primary },
                    }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                {getIcon(53, theme.palette.mode === 'dark' ? theme.palette.text.primary : theme.palette.secondary.main)}
                            </InputAdornment>
                        ),
                    }}
                />
                <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                    {['Atual', 'Total'].map((e, idx) => {
                        return (
                            <UpComp key={idx} tamanho={45}>
                                <Tooltip title={e}>
                                    <IconButton onClick={() => handleClick(idx)} sx={{ background: selected === idx ? theme.palette.primary.main : theme.palette.background.default }}>
                                        {getIcon(Icons(idx), selected === idx ? theme.palette.text.whiteText : theme.palette.secondary.main, 30)}
                                    </IconButton>
                                </Tooltip>
                            </UpComp>
                        )
                    })}
                </Box>
            </Box>
        </Box>
    )
}

const Icons = (index) => {
    const x = [67, 66]
    return x[index]
}