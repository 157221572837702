import { useEffect, useState, useContext } from "react";
import { Box, IconButton, Tooltip, useTheme, Typography, Avatar, Collapse } from "@mui/material";
import { ModalInfo } from '../../../components/modalInfoN';
import { getIcon } from "../../../components/icons";
import { UpComp } from "../../../components/upComp";
import { CTextField } from "../../../components/TextField";
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import moment from "moment";
import { onlyInteger } from "../../../components/masks";
import mainApi from "../../../apis/mainApiN";
import { User } from '../../../contexts/user';
import 'moment/locale/pt-br'


export function Filter({ getProdutos, updateData, updateSUser, getMinhasAlterações, getRevisoes, SUser }) {
    const theme = useTheme()
    const user = useContext(User);
    const [data, setData] = useState({
        vendedor: '',
        nota: '',
        date: {
            init: moment().format('DD/MM/YYYY'),
            end: moment().format('DD/MM/YYYY'),
        }
    })
    const [MInfo, setMInfo] = useState({
        visible: false,
        title: '',
        subtitle: '',
        canDesactive: true,
        icon: '',
        funcS: () => null
    });
    const [colOpen, setColOpen] = useState(null)

    function updateModalInfo(visible, canDesactive, title, subtitle, icon, func) {
        const newMInfo = { ...MInfo };
        newMInfo.visible = visible;
        newMInfo.canDesactive = canDesactive;
        newMInfo.title = title;
        newMInfo.subtitle = subtitle;
        newMInfo.icon = icon;
        newMInfo.funcS = func;
        setMInfo(newMInfo);
    }

    function handleClick(index) {
        if (!SUser?.id && index !== 4) {
            updateModalInfo(true, true, 'Atenção', 'Selecione um usuário antes', 'exclamation')
            return
        }
        if (index === 0) {
            setColOpen(null)
            getData('last')
        }
        if ([1, 2, 3].includes(index)) {
            setColOpen(index === colOpen ? null : index)
        }
        if (index === 4) {
            updateSUser(true)
        }
    }
    function handleChangeText(value, type) {
        const newData = { ...data }
        newData[type] = type === 'nota' ? onlyInteger(value) : value
        setData(newData)
    }
    function changeData(date, type) {
        const newData = { ...data }
        newData.date[type] = moment(date).format('DD/MM/YYYY')
        setData(newData)
    }
    function OnKeyPressTF(value, type) {
        if (value.key === 'Enter') {
            getData(type)
        }
    }
    async function getData(type) {
        if (!SUser?.id) {
            updateModalInfo(true, true, 'Atenção', 'Selecione um usuário antes', 'exclamation')
            return
        }
        const body = {
            user: SUser.id,
            type: type,
            init: moment(data.date.init, 'DD/MM/YYYY').format('YYYY-MM-DD'),
            end: moment(data.date.end, 'DD/MM/YYYY').format('YYYY-MM-DD'),
            vendedor: data.vendedor,
            nota: data.nota
        }
        updateModalInfo(true, false, 'Carregando', 'Buscando Dados', 'loading')
        const get = await mainApi('sincronizacao/getSincVend', 'POST', body, user?.data?.token)
        if (get?.status !== 200) {
            const message = get?.apiReturn?.message ? get?.apiReturn?.message : 'Ocorreu um erro ao buscar os dados.'
            updateModalInfo(true, true, 'Atenção', message, 'exclamation')
            return
        }
        updateModalInfo(false)
        updateData(get?.apiReturn?.apiReturn)
    }
    useEffect(() => {
        if (colOpen === 3) {
            getData('date')
        }
    }, [data, colOpen])

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: 3, minHeight: 130, boxShadow: theme.palette.mode === 'dark' ? '0px 4px 10px rgba(155, 155, 155, 0.1)' : '0px 4px 10px rgba(0, 0, 0, 0.1)' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap', rowGap: 2, width: '100%', }}>
                <Box>
                    {SUser ?
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                            {SUser?.avatar ?
                                <Avatar src={SUser?.avatar} sx={{ width: 40, height: 40, marginBottom: '10px' }} />
                                :
                                <UpComp tamanho={40}>
                                    {getIcon(33, theme.palette.text.primary, 30)}
                                </UpComp>
                            }
                            <Box>
                                <Typography sx={{ textAlign: 'left', color: theme.palette.text.primary, fontWeight: 'bold' }}>{SUser.name}</Typography>
                                <Typography sx={{ textAlign: 'left', color: theme.palette.text.primary }}>{SUser.email}</Typography>
                                {SUser.logo ?
                                    <img src={SUser.logo} style={{ objectFit: 'contain', width: 60 }} /> : null}
                            </Box>
                        </Box>
                        : null}
                </Box>
                <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                    {['Última', 'Por Vendedor', 'Por Nota', 'Data', 'Trocar Usuário'].map((e, idx) => {
                        const type = idx === 1 ? 'vendedor' : idx === 2 ? 'nota' : null
                        return (
                            <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                <Collapse orientation='horizontal' in={colOpen === idx ? true : false}>
                                    {[1, 2].includes(idx) ?
                                        <CTextField onKeyPress={OnKeyPressTF} label={e} icon={getIcon(53)} iconPosition={'start'} width={'100%'} value={data[type]} type={type} onChangeText={handleChangeText} />
                                        : idx === 3 ? <ByData date={data.date} changeData={changeData} /> : null}
                                </Collapse>
                                <UpComp key={idx} tamanho={45}>
                                    <Tooltip title={e}>
                                        <IconButton onClick={() => handleClick(idx)} sx={{ background: theme.palette.mode === 'dark' ? theme.palette.primary.main : theme.palette.background.default }}>
                                            {getIcon(Icons(idx), theme.palette.mode === 'dark' ? theme.palette.text.whiteText : theme.palette.secondary.main, 30)}
                                        </IconButton>
                                    </Tooltip>
                                </UpComp>
                            </Box>
                        )
                    })}
                </Box>
            </Box>
            <ModalInfo hideModal={() => updateModalInfo(false)} canDesactive={MInfo.canDesactive} visible={MInfo.visible} title={MInfo.title} subtitle={MInfo.subtitle} icon={MInfo.icon} funcS={MInfo.funcS} />
        </Box>
    )
}
function nomeToName(type) {
    const types = {
        'Por Vendedor': 'vendedor', 'Por Nota': 'nota', 'Data': 'date'
    }
    return types[type]
}

function ByData({ date, changeData }) {
    return (
        <Box sx={{ display: 'flex', gap: 2 }}>
            <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="pt-br" >
                <DemoContainer
                    components={['MobileDatePicker']}
                >
                    <MobileDatePicker disabled={false} label={'Início'} defaultValue={moment()} localeText={{ cancelButtonLabel: 'Cancelar', toolbarTitle: 'Selecionar Data' }}
                        value={moment(date.init, 'DD/MM/YYYY')}
                        onChange={(value) => {
                            changeData(value, 'init')
                        }}
                    />
                </DemoContainer>
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="pt-br" >
                <DemoContainer
                    components={['MobileDatePicker']}
                >
                    <MobileDatePicker disabled={false} label={'Fim'} defaultValue={moment()} localeText={{ cancelButtonLabel: 'Cancelar', toolbarTitle: 'Selecionar Data' }}
                        value={moment(date.end, 'DD/MM/YYYY')}
                        onChange={(value) => {
                            changeData(value, 'end')
                        }}
                    />
                </DemoContainer>
            </LocalizationProvider>
        </Box>
    )
}

const Icons = (index) => {
    const x = [70, 33, 69, 66, 59]
    return x[index]
}