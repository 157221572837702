import React, { useState, useContext } from 'react';
import { useTheme, Box, Modal, Typography, Button, Avatar } from '@mui/material';
import { ModalInfo } from '../../../components/modalInfoN';
import { User } from '../../../contexts/user';
import { getIcon } from '../../../components/icons';
import { CTextField } from '../../../components/TextField';
import mainApi from '../../../apis/mainApiN';
import { UpComp } from '../../../components/upComp';

export function SelectUser({ visible, dismiss, updateSUser }) {
    const theme = useTheme();
    const user = useContext(User);
    //estados
    const [MInfo, setMInfo] = useState({
        visible: false,
        title: '',
        subtitle: '',
        canDesactive: true,
        icon: '',
        funcS: () => null
    });
    const [nome, setNome] = useState('')
    const [data, setData] = useState([])
    function updateModalInfo(visible, canDesactive, title, subtitle, icon, func) {
        const newMInfo = { ...MInfo };
        newMInfo.visible = visible;
        newMInfo.canDesactive = canDesactive;
        newMInfo.title = title;
        newMInfo.subtitle = subtitle;
        newMInfo.icon = icon;
        newMInfo.funcS = func;
        setMInfo(newMInfo);
    }
    function handleDismiss() {
        setNome('')
        setData([])
        updateSUser(false)
    }
    function handleChangeText(value) {
        setNome(value)
    }
    function OnKeyPressTF(value) {
        if (value.key === 'Enter') {
            getDados()
        }
    }

    async function getDados() {
        updateModalInfo(true, false, 'Carregando', 'Buscando usuários', 'loading')
        const get = await mainApi(`sincronizacao/getUser/${nome}`, 'GET', null, user?.data?.token)
        if (get?.status !== 200) {
            const message = get?.apiReturn?.message ? get?.apiReturn?.message : 'Ocorreu um erro ao buscar os usuários'
            updateModalInfo(true, true, 'Atenção', message, 'exclamation')
            return
        }
        updateModalInfo(false)
        setData(get?.apiReturn?.apiReturn)
    }
    function handleSelect(value) {
        setData([])
        setNome('')
        updateSUser(false, value)
    }
    return (
        <Modal open={visible} onClose={handleDismiss} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 2 }} slotProps={{
            backdrop: {
                sx: {
                    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(250,255,255, 0.2)' : 'rgba(0,0,0, 0.4)'
                }
            }
        }}>
            <Box sx={{
                display: 'flex', flexDirection: 'column', height: 500, width: '95%', maxWidth: 600, background: theme.palette.background.default, outline: 'none',
                [theme.breakpoints.down('lg')]: {
                    width: '98%'
                },
            }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: 5, paddingBottom: 0 }}>
                    <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                        {getIcon(68, theme.palette.primary.main, 30)}
                        <Typography sx={{ fontWeight: 'bold', color: theme.palette.text.primary, fontSize: 30 }}>Usuários</Typography>
                    </Box>
                </Box>
                <Box sx={{ padding: 5, paddingTop: 2 }}>
                    <CTextField onKeyPress={OnKeyPressTF} onChangeText={handleChangeText} value={nome} label={'Buscar'} width={'100%'} icon={getIcon(53)} iconPosition={'start'} />
                </Box>
                <Box sx={{ padding: 5, paddingTop: 2, display: 'flex', flexDirection: 'column', gap: 1, overflowY: 'scroll' }}>
                    {data.map((e) => {
                        return (
                            <Button sx={{ textTransform: 'none' }} onClick={() => handleSelect(e)}>
                                <Box sx={{ width: '100%', display: 'flex', gap: 2, boxShadow: theme.palette.mode === 'dark' ? '0px 4px 10px rgba(155, 155, 155, 0.2)' : '0px 4px 10px rgba(0, 0, 0, 0.1)', padding: 2, alignItems: 'center', justifyContent: 'space-between' }}>
                                    <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                                        {e?.avatar ?
                                            <Avatar src={e?.avatar} sx={{ width: 64, height: 64, marginBottom: '10px' }} />
                                            :
                                            <UpComp>
                                                {getIcon(33, theme.palette.text.primary, 40)}
                                            </UpComp>
                                        }
                                        <Box>
                                            <Typography sx={{ textAlign: 'left', color: theme.palette.text.primary, fontWeight: 'bold' }}>{e.name}</Typography>
                                            <Typography sx={{ textAlign: 'left', color: theme.palette.text.primary }}>{e.email}</Typography>
                                        </Box>
                                    </Box>
                                    {e?.logo ?
                                        <img src={e?.logo} style={{ objectFit: 'contain', width: 80 }} /> : null}
                                </Box>
                            </Button>
                        )
                    })}
                </Box>
                <ModalInfo hideModal={() => updateModalInfo(false)} canDesactive={MInfo.canDesactive} visible={MInfo.visible} title={MInfo.title} subtitle={MInfo.subtitle} icon={MInfo.icon} funcS={MInfo.funcS} />
            </Box>
        </Modal>
    );
}