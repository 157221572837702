import React from "react";
import { Box, Typography, Modal, CircularProgress, useTheme } from "@mui/material"

export function ModalInfo({ ative, closeModal, height, title, subtitle, body }) {
    const theme = useTheme()
    return (
        <Modal
            open={[null, undefined].includes(ative) ? false : ative}
            onClose={() => closeModal()}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                height: height ? height : 300,
                width: '25%',
                transform: 'translate(-50%, -50%)',
                display: "flex",
                flexDirection: 'column',
                justifyContent: 'space-evenly',
                alignItems: 'center',
                outline: 'none',
                backgroundColor: theme.palette.background.paper,
                borderRadius: 3,
                [theme.breakpoints.down('md')]: {
                    width: '70%'
                },
                [theme.breakpoints.down('sm')]: {
                    width: '90%'
                },

            }}>
                <Typography fontWeight={'bold'} fontSize={25} textAlign={'center'}>{title}</Typography>
                <Typography textAlign={'center'} fontSize={18}>{subtitle}</Typography>
                {body}
            </Box>
        </Modal>
    )
}

