import React, { useState, useContext, useEffect } from 'react';
import { useTheme, Box, Modal, Typography, Button } from '@mui/material';
import { ModalInfo } from '../../../../components/modalInfoN';
import { User } from '../../../../contexts/user';
import { getIcon } from '../../../../components/icons';
import { ButtonSubmit } from '../../../../components/buttons';
import { UserAvatar } from '../../../../components/avatar';
import mainApi from '../../../../apis/mainApiN';
import { CTextField } from '../../../../components/TextField';
import { ModalRevisão } from './components/modalRevisao';

export function PendProdSImg({ visible, dismiss }) {
    const theme = useTheme();
    const user = useContext(User);
    //estados
    const [MInfo, setMInfo] = useState({
        visible: false,
        title: '',
        subtitle: '',
        canDesactive: true,
        icon: '',
        funcS: () => null
    });
    const [data, setData] = useState({
        cod_barra: '',
        nom_produto: '',
        grupo: '',
        categoria: '',
        image: null
    })
    const [modRevis, setModRevis] = useState({
        produto: null,
        visible: false
    })
    function handleAvatar(base64) {
        const newData = { ...data }
        newData.image = base64
        setData(newData)
    }

    function updateModalInfo(visible, canDesactive, title, subtitle, icon, func) {
        const newMInfo = { ...MInfo };
        newMInfo.visible = visible;
        newMInfo.canDesactive = canDesactive;
        newMInfo.title = title;
        newMInfo.subtitle = subtitle;
        newMInfo.icon = icon;
        newMInfo.funcS = func;
        setMInfo(newMInfo);
    }
    function handleButton(type) {
        if (type === 'Sair') {
            handleDismiss()
            return
        }
        if (type === 'Revisão') {
            updateModRevis(true, data)
            return
        }
        if (type === 'Próximo') {
            getData()
            return
        }
        save()
    }
    function handleDismiss() {
        if (dismiss) {
            dismiss()
        }
    }
    async function save() {
        if (!data.image) {
            updateModalInfo(true, true, 'Atenção', 'Uma imagem precisa ser selecionada', 'exclamation')
            return
        }
        const body = {
            cod_barra: data.cod_barra,
            base64: data.image.includes('data:image/jpeg;base64,') ? data.image.replace('data:image/jpeg;base64,', '') : data.image
        }
        updateModalInfo(true, false, 'Atenção', 'Salvando pendência', 'loading')
        const save = await mainApi('produtosBase/pendencias/savePendProdSImg', 'POST', body, user?.data?.token)
        if (save?.status !== 200) {
            const message = save?.apiReturn?.message ? save?.apiReturn?.message : 'Ocorreu um erro ao salvar pendência'
            updateModalInfo(true, true, 'Atenção', message, 'exclamation')
            return
        }
        getData()
    }

    function updateModRevis(visible, produto) {
        const newMod = { ...modRevis }
        newMod.produto = produto
        newMod.visible = visible ? visible : false
        setModRevis(newMod)
    }

    async function getData() {
        updateModalInfo(true, false, 'Atenção', 'Buscando produto.', 'loading')
        const get = await mainApi('produtosBase/pendencias/prodSImg', 'GET', null, user?.data?.token)
        if (get?.status !== 200) {
            const message = get?.apiReturn?.message ? get?.apiReturn?.message : 'Ocorreu um erro ao buscar o produto.'
            updateModalInfo(true, true, 'Atenção', message, 'exclamation')
            return
        }
        const produto = get?.apiReturn?.apiReturn
        const newData = { ...data }
        newData.cod_barra = produto?.cod_barra
        newData.nom_produto = produto?.name
        newData.image = !produto?.image ? null : produto?.image?.includes('data:image/jpeg;base64,') ? produto?.image : 'data:image/jpeg;base64,' + produto.image
        newData.categoria = produto?.nom_categ
        newData.grupo = produto?.nom_grupo
        setData(newData)
        updateModalInfo(false)
    }
    useEffect(() => {
        getData()
    }, [])
    return (
        <Modal open={visible} onClose={handleDismiss} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 2 }} slotProps={{
            backdrop: {
                sx: {
                    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(250,255,255, 0.2)' : 'rgba(0,0,0, 0.4)'
                }
            }
        }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', maxHeight: '98%', width: 650, background: theme.palette.background.default, outline: 'none' }}>
                <Box sx={{ background: theme.palette.background.paper, padding: 5 }}>
                    <Typography sx={{ fontWeight: 'bold', color: theme.palette.text.primary, fontSize: 30 }}>Pendências de Imagem</Typography>
                    <Typography sx={{ color: theme.palette.text.primary, fontSize: 18 }}>Adicione a imagem e prossiga para salvar.</Typography>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, padding: 5, justifyContent: 'space-between', height: '100%' }}>
                    <Box sx={{ display: 'flex', gap: 2, justifyContent: 'center', alignItems: 'center' }}>
                        <UserAvatar icon={getIcon(47, theme.palette.mode === 'dark' ? theme.palette.text.primary : theme.palette.primary.main, 80)} avatarBase64={data.image} editable={true} funcReturn={handleAvatar} />
                    </Box>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
                        {Object.keys(data).map((e) => {
                            if (e === 'image') return
                            return (
                                <CTextField key={e} disabled={true} label={e} value={data[e]} />
                            )
                        })}
                    </Box>
                    <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap' }}>
                        {['Próximo', 'Salvar', 'Revisão', 'Sair'].map((e) => {
                            const background = e === 'Salvar' ? theme.palette.secondary.main : e === 'Próximo' ? theme.palette.primary.main : theme.palette.background.paper
                            const color = ['Próximo', 'Salvar'].includes(e) ? theme.palette.text.whiteText : theme.palette.text.primary
                            return (
                                <ButtonSubmit key={e} text={e} background={background} color={color} func={handleButton} funcParameters={e} />
                            )
                        })}
                    </Box>
                </Box>
                <ModalRevisão visible={modRevis.visible} dismiss={updateModRevis} produto={modRevis.produto} tipo={2} funcReturn={getData} />
                <ModalInfo hideModal={() => updateModalInfo(false)} canDesactive={MInfo.canDesactive} visible={MInfo.visible} title={MInfo.title} subtitle={MInfo.subtitle} icon={MInfo.icon} funcS={MInfo.funcS} />
            </Box>
        </Modal>
    );
}