import { useTheme, Box, Typography } from '@mui/material';

export function ActionNeed({ data }) {
    const theme = useTheme()
    const text = data.status_nome === 'Devolução Solicitada' ? 'Finalizar Devolução' : data.status_nome === 'Pagamento Aprovado' ? 'Faturar pedido.' : data.status_nome === 'Pedido Faturado' ? 'Preparar para o envio' : data.status_nome === 'Pedido em Separação' ? 'Enviar Pedido' : null
    if (text !== null) {
        return (
            <Box sx={{ display: 'flex', gap: 2 }}>
                <Typography sx={{ color: theme.palette.text.primary, fontWeight: 'bold' }}>Ação Necessária: </Typography>
                <Typography>{text}</Typography>
            </Box>
        )
    }
}