import React, { useState, useContext, useEffect } from 'react';
import { useTheme, Box, Modal, Typography, Button, IconButton, Tooltip } from '@mui/material';
import { ModalInfo } from '../../../../components/modalInfoN';
import { User } from '../../../../contexts/user';
import { getIcon } from '../../../../components/icons';
import { ButtonSubmit } from '../../../../components/buttons';
import { UserAvatar } from '../../../../components/avatar';
import mainApi from '../../../../apis/mainApiN';
import { CTextField } from '../../../../components/TextField';
import { ModalRevisão } from './components/modalRevisao';
import { categToGroups, gruposNames } from '../../../../components/gruposNames';
import { categoriasOBJ } from '../../../../components/categorias';
import { MenuList } from '../../../../components/menu';
import { UpComp } from '../../../../components/upComp';

export function PendPreClass({ visible, dismiss }) {
    const theme = useTheme();
    const user = useContext(User);
    //estados
    const [MInfo, setMInfo] = useState({
        visible: false,
        title: '',
        subtitle: '',
        canDesactive: true,
        icon: '',
        funcS: () => null
    });
    const [data, setData] = useState({
        uName: '',
        uAvatar: '',
        cod_barra: '',
        nom_produto: '',
        grupo: '',
        categoria: '',
        image: null
    })
    const [modRevis, setModRevis] = useState({
        produto: null,
        visible: false
    })
    function handleAvatar(base64) {
        const newData = { ...data }
        newData.image = base64
        setData(newData)
    }

    function updateModalInfo(visible, canDesactive, title, subtitle, icon, func) {
        const newMInfo = { ...MInfo };
        newMInfo.visible = visible;
        newMInfo.canDesactive = canDesactive;
        newMInfo.title = title;
        newMInfo.subtitle = subtitle;
        newMInfo.icon = icon;
        newMInfo.funcS = func;
        setMInfo(newMInfo);
    }
    function handleButton(type) {
        if (type === 'Sair') {
            handleDismiss()
            return
        }
        if (type === 'Revisão') {
            updateModRevis(true, data)
            return
        }
        if (type === 'Próximo') {
            getData()
            return
        }
        save()
    }
    function handleDismiss() {
        if (dismiss) {
            dismiss()
        }
    }
    async function save() {
        if (!data.grupo?.id || !data.categoria?.id) {
            updateModalInfo(true, true, 'Atenção', 'Classificação precisa ser preenchida.', 'exclamation')
            return
        }
        const body = {
            cod_barra: data.cod_barra,
            nome: data.nom_produto,
            grupo: data.grupo.id,
            categoria: data.categoria.id,
            base64: !data.image ? null : data.image.includes('data:image/jpeg;base64,') ? data.image.replace('data:image/jpeg;base64,', '') : data.image
        }
        updateModalInfo(true, false, 'Atenção', 'Salvando pendência', 'loading')
        const save = await mainApi('produtosBase/pendencias/savePreClass', 'POST', body, user?.data?.token)
        if (save?.status !== 200) {
            const message = save?.apiReturn?.message ? save?.apiReturn?.message : 'Ocorreu um erro ao salvar pendência'
            updateModalInfo(true, true, 'Atenção', message, 'exclamation')
            return
        }
        getData()
    }
    async function sincImage() {
        if (!data.cod_barra) {
            updateModalInfo(true, true, 'Atenção', 'Código de barras precisa ser preenchido.', 'exclamation')
            return
        }
        updateModalInfo(true, false, 'Carregando', 'Buscando imagem no servidor.', 'loading')
        const get = await mainApi(`produtosBase/buscarImagem/${data['cod_barra']}`, 'GET', null, user?.data?.token)
        const image = get?.apiReturn?.apiReturn?.base64
        if (get?.status !== 200 || !image) {
            const message = get?.apiReturn?.message ? get?.apiReturn?.message : 'Ocorreu um erro ao buscar a imagem.'
            updateModalInfo(true, true, 'Atenção', message, 'exclamation')
            return
        }
        const newImage = 'data:image/jpeg;base64,' + image
        updateModalInfo(false)
        const newData = { ...data }
        newData['image'] = newImage
        setData(newData)
    }

    function updateModRevis(visible, produto) {
        const newMod = { ...modRevis }
        newMod.produto = produto
        newMod.visible = visible ? visible : false
        setModRevis(newMod)
    }
    function handleChangeMenu(index, type) {
        const newData = { ...data }
        newData.categoria = categoriasOBJ[index]
        const getGrupos = gruposNames.filter((e) => e?.name === categToGroups(categoriasOBJ[index].id))
        newData.grupo = getGrupos[0]
        setData(newData)
    }

    async function getData() {
        updateModalInfo(true, false, 'Atenção', 'Buscando produto.', 'loading')
        const get = await mainApi('produtosBase/pendencias/preClass', 'GET', null, user?.data?.token)
        if (get?.status !== 200) {
            const message = get?.apiReturn?.message ? get?.apiReturn?.message : 'Ocorreu um erro ao buscar o produto.'
            updateModalInfo(true, true, 'Atenção', message, 'exclamation')
            return
        }
        const produto = get?.apiReturn?.apiReturn
        const newData = { ...data }
        newData.uAvatar = produto?.uAvatar
        newData.uName = produto?.uName
        newData.cod_barra = produto?.cod_barra
        newData.nom_produto = produto?.name
        newData.image = !produto?.image ? null : produto?.image?.includes('data:image/jpeg;base64,') ? produto?.image : 'data:image/jpeg;base64,' + produto.image
        newData.categoria = {
            id: produto?.cod_categoria,
            name: produto?.nom_categ,
        }
        newData.grupo = {
            id: produto?.cod_grupo,
            name: produto?.nom_grupo,
        }
        setData(newData)
        updateModalInfo(false)
    }
    function handleChangeText(value, type) {
        const newData = { ...data }
        newData[type] = value
        setData(newData)
    }
    useEffect(() => {
        getData()
    }, [])
    return (
        <Modal open={visible} onClose={handleDismiss} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 2 }} slotProps={{
            backdrop: {
                sx: {
                    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(250,255,255, 0.2)' : 'rgba(0,0,0, 0.4)'
                }
            }
        }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', width: 650, maxHeight: '98%', background: theme.palette.background.default, outline: 'none', gap: 2 }}>
                <Box sx={{ background: theme.palette.background.paper, padding: 5 }}>
                    <Typography sx={{ fontWeight: 'bold', color: theme.palette.text.primary, fontSize: 30 }}>Produtos pré-classificados</Typography>
                    <Typography sx={{ color: theme.palette.text.primary, fontSize: 18 }}>Verifique as informações, atualize se necessário e prossiga para o próximo produto.</Typography>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 5, padding: 5, paddingTop: 2, justifyContent: 'space-between', height: '100%', overflowY: 'scroll', maxHeight: 700 }}>
                    {data.uName ?
                        <Box sx={{ display: 'flex', flexDirection: 'column', background: theme.palette.secondary.main, padding: 2, gap: 2 }}>
                            <Typography sx={{ color: theme.palette.text.whiteText, fontWeight: 'bold' }}>Esse produto foi pré classificado pelo franqueado:</Typography>
                            <Box sx={{ display: 'flex', gap: 1, flexDirection: 'column', alignSelf: 'center' }}>
                                {data.uAvatar ?
                                    <Box sx={{ borderRadius: 25, boxShadow: theme.palette.mode === 'dark' ? '0px 4px 10px rgba(0, 0, 0, 0.3)' : '0px 4px 10px rgba(0, 0, 0, 0.1)', overflow: 'hidden', height: 70, width: 70, alignSelf: 'center' }}>
                                        <img src={data.uAvatar} style={{ objectFit: 'contain', width: '100%', borderRadius: 90 }} />
                                    </Box>
                                    :
                                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 75, width: 75, borderRadius: 25, boxShadow: theme.palette.mode === 'dark' ? '0px 4px 10px rgba(0, 0, 0, 0.3)' : '0px 4px 10px rgba(0, 0, 0, 0.1)', alignSelf: 'center' }}>
                                        {
                                            getIcon(33, theme.palette.text.whiteText, 50)
                                        }
                                    </Box>
                                }
                                <Typography sx={{ color: theme.palette.text.whiteText, textAlign: 'center' }}>{data.uName}</Typography>
                            </Box>
                        </Box>
                        : null}
                    <Box sx={{ display: 'flex', alignSelf: 'center', justifyContent: 'center', alignItems: 'center', gap: 2 }}>
                        <UserAvatar icon={getIcon(47, theme.palette.mode === 'dark' ? theme.palette.text.primary : theme.palette.primary.main, 80)} avatarBase64={data.image} editable={true} funcReturn={handleAvatar} />
                        <UpComp tamanho={40}>
                            <Tooltip title='Buscar Imagem' placement='right'>
                                <IconButton onClick={sincImage}>
                                    {getIcon(59)}
                                </IconButton>
                            </Tooltip>
                        </UpComp>
                    </Box>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
                        {Object.keys(data).map((e) => {
                            if (['image', 'uName', 'uAvatar'].includes(e)) return
                            if (e === 'grupo') return <MenuList disabled={true} width={'100%'} key={e} options={gruposNames} param={'name'} selected={data.grupo} title={e} handleChange={handleChangeMenu} />
                            if (e === 'categoria') return <MenuList width={'100%'} key={e} options={categoriasOBJ} param={'name'} selected={data.categoria} title={e} handleChange={handleChangeMenu} />
                            return (
                                <CTextField width={'48%'} key={e} disabled={e === 'cod_barra'} label={e} value={data[e]} onChangeText={handleChangeText} type={e} />
                            )
                        })}
                    </Box>
                    <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap' }}>
                        {['Próximo', 'Salvar', 'Revisão', 'Sair'].map((e) => {
                            const background = e === 'Salvar' ? theme.palette.secondary.main : e === 'Próximo' ? theme.palette.primary.main : theme.palette.background.paper
                            const color = ['Próximo', 'Salvar'].includes(e) ? theme.palette.text.whiteText : theme.palette.text.primary
                            return (
                                <ButtonSubmit key={e} text={e} background={background} color={color} func={handleButton} funcParameters={e} />
                            )
                        })}
                    </Box>
                </Box>
                <ModalRevisão visible={modRevis.visible} dismiss={updateModRevis} produto={modRevis.produto} tipo={4} funcReturn={getData} />
                <ModalInfo hideModal={() => updateModalInfo(false)} canDesactive={MInfo.canDesactive} visible={MInfo.visible} title={MInfo.title} subtitle={MInfo.subtitle} icon={MInfo.icon} funcS={MInfo.funcS} />
            </Box>
        </Modal>
    );
}