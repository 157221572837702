import React, { useEffect, useState, useContext } from "react";
import { useTheme } from '@mui/material/styles';
import { Box, Typography, Button, List, TextField } from '@mui/material';
import mainApi from "../../apis/mainApi";
import Modal from "@mui/material";
import { ModalLoading } from "../../components/modal";
import { User } from "../../contexts/user";
import { ModalInfo } from "../../components/modalInfo";
import moment from 'moment'
import ListProdutos from "./listProdutos";
import ProdutosInside from "./produtosInside";
import { getEvento } from "../../components/getEvento";
export default props => {
    document.title = 'Produtos - Central de Negócios'
    const theme = useTheme()
    const modalInfoInitial = {
        active: false,
        title: '',
        subtitle: '',
        body: ''
    }
    const user = useContext(User)
    const [modalLoading, setModalLoading] = useState(false)
    const [modalInfo, setModalInfo] = useState(modalInfoInitial)
    const [data, setData] = useState({
        sorted: {
            type: null,
            direction: 1
        },
        content: [],
        dataFiltered: null,
        statusPedidos: [],
        openData: {
            open: false,
            edit: 1,
            content: {}
        }
    })
    function updateModalInfo(value) { setModalInfo(value) }
    function updateData(value) { setData(value) }
    function updateModalLoading(value) { setModalLoading(value) }
    function resetOpenData() {
        const newData = { ...data }
        newData.openData = {
            open: false,
            content: {}
        }
        setData(newData)
    }

    function resetModalInfo() {
        setModalInfo(modalInfoInitial)
    }
    async function getData() {
        if (modalLoading === false) updateModalLoading(true)
        function FCallback(callback) {
            updateModalLoading(false)
            if (callback.status !== 200) {
                const x = { ...modalInfo }
                x.title = 'Erro'
                x.subtitle = callback.apiReturn.message ? callback.apiReturn.message : 'Ocorreu um erro! Tente novamente mais tarde'
                x.body = <Button onClick={() => setModalInfo(modalInfoInitial)} sx={{ mb: 2, backgroundColor: theme.palette.secondary.main, color: theme.palette.text.primary, '&:hover': { backgroundColor: theme.palette.secondary.dark }, borderRadius: 0, padding: 1 }}>
                    <Typography fontWeight={'bold'} sx={{ color: theme.palette.text.whiteText, textAlign: 'center' }}>Voltar</Typography>
                </Button>
                x.active = true
                setModalInfo(x)
            }
            const newData = { ...data }
            newData.content = callback.apiReturn.apiReturn
            setData(newData)
        }
        await mainApi('produtos/byaplicacao', 'GET', null, FCallback, user?.data?.token)
    }
    useEffect(() => {
        if (!getEvento(props.eventos, 'Acesso')) return
        getData()
    }, [])
    return (
        <Box sx={{
            width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 5, [theme.breakpoints.down('md')]: {
                overflow: 'scroll', maxWidth: '100%'
            },
        }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '90%' }}>
                <Typography variant="h2" fontWeight={'bold'}>{props?.nome}</Typography>
                <Typography fontSize={20}>{props?.subtitle}</Typography>
            </Box>
            <Box sx={{
                width: '90%',
                [theme.breakpoints.down('md')]: {
                    width: '98%'
                },
            }}>
                {/* //content */}
                {!getEvento(props.eventos, 'Acesso') ?
                    <Box sx={{ padding: 2, background: theme.palette.warning.main }}>
                        <Typography sx={{ color: theme.palette.text.whiteText, fontWeight: 'bold' }}>Você não possui acesso a esse módulo</Typography>
                    </Box>
                    :
                    <ListProdutos data={data} updateData={updateData} />
                }
            </Box>
            <ProdutosInside resetModalInfo={resetModalInfo} eventos={props?.eventos} active={data.openData.open} updateModalInfo={updateModalInfo} ative={modalLoading} updateModalLoading={updateModalLoading} data={data} updateData={updateData} resetOpenData={resetOpenData} />
            <ModalLoading ative={modalLoading} updateModalLoading={updateModalLoading} />
            <ModalInfo height={modalInfo?.height} ative={modalInfo.active} title={modalInfo.title} subtitle={modalInfo.subtitle} body={modalInfo.body} closeModal={resetModalInfo} />
        </Box>
    )
}