import React, { useEffect, useState, useContext } from "react";
import { useTheme } from '@mui/material/styles';
import { Box, Typography, Button, Modal, MenuItem, TextField, IconButton, FormControl, Select, InputLabel, OutlinedInput, Chip } from '@mui/material';
import mainApi from "../../apis/mainApi";
import { User } from '../../contexts/user'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { getEvento } from "../../components/getEvento";
import { cpfMask, cepMask, celularMask, realMask, onlyInteger, onlyString } from "../../components/masks";
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import { estadosBr } from "../../components/estadosBr";
import DeleteIcon from '@mui/icons-material/Delete';
import AutorenewIcon from '@mui/icons-material/Autorenew';

//value.target.value.replace(/[^a-zA-Z]+/g, '')
export default props => {
    const theme = useTheme()
    const user = useContext(User)
    let data = props?.data
    const newDataInitial = {
        editable: false,
        id: '',
        aplicacao_id: '',
        aplicacao_nome: '',
        nome: '',
        servico: '',
        preco: '',
        prazo: '',
        status: '',
    }
    const [newData, setNewData] = useState(newDataInitial)

    async function updateESaveEDeleteClient(value) {
        props.updateModalLoading(true)
        function FCallback(callback) {
            props.updateModalLoading(false)
            if (callback.status !== 200) {
                const x = {}
                x.title = 'Erro'
                x.subtitle = callback.apiReturn.message ? callback.apiReturn.message : 'Ocorreu um erro! Tente novamente mais tarde'
                x.body = <Button onClick={() => props.resetModalInfo()} sx={{ mb: 2, backgroundColor: theme.palette.secondary.main, color: theme.palette.text.primary, '&:hover': { backgroundColor: theme.palette.secondary.dark }, borderRadius: 0, padding: 1 }}>
                    <Typography fontWeight={'bold'} sx={{ color: theme.palette.text.whiteText, textAlign: 'center' }}>Voltar</Typography>
                </Button>
                x.active = true
                props.updateModalInfo(x)
                return
            }
            const updateData = { ...props.mainData }
            const findIndexEntrega = updateData.content.findIndex((e) => e.id === newData.id)
            if (value) {
                const filterData = updateData.content.filter((e) => e.id !== newData.id)
                updateData.content = filterData
                updateData.openDataInside.open = false
                setNewData(newDataInitial)
                props.updateData(updateData)
                props.resetModalInfo()
                return
            }
            else if (props.type === 2) {
                const findIndex = updateData.content.findIndex((e) => e.id === newData.id)
                updateData.content[findIndex] = newData
            }
            else if (props.type === 1) {
                newData.id = callback?.apiReturn?.apiReturn
                newData.status = 1
                updateData.openDataInside.open = false
                updateData.content.push(newData)
                props.updateData(updateData)
                const x = {}
                x.title = 'Sucesso'
                x.subtitle = callback.apiReturn.message ? callback.apiReturn.message : 'Entrega inserida com sucesso'
                x.body = <Button onClick={() => props.resetModalInfo()} sx={{ mb: 2, backgroundColor: theme.palette.secondary.main, color: theme.palette.text.primary, '&:hover': { backgroundColor: theme.palette.secondary.dark }, borderRadius: 0, padding: 1 }}>
                    <Typography fontWeight={'bold'} sx={{ color: theme.palette.text.whiteText, textAlign: 'center' }}>Voltar</Typography>
                </Button>
                x.active = true
                props.updateModalInfo(x)
                return
            }
        }
        const body = { ...newData }
        body['aplicacao'] = body.aplicacao_id
        delete body.aplicacao_nome
        if (props.type === 2) {
            delete body.aplicacao_id
        }
        delete body.editable
        const method = value ? 'DELETE' : props.type === 2 ? 'PUT' : 'POST'
        const endpoint = value ? 'entregas/' + newData.id : props.type === 2 ? 'entregas' : 'entregas/register'
        const newBody = {
            aplicacao: body.aplicacao_id,
            nome: body.nome,
            prazo: body.prazo,
            preco: body.preco,
            servico: [null, undefined, ''].includes(body.servico) ? null : body.servico
        }
        await mainApi(endpoint, method, props.type === 1 ? newBody : body, FCallback, user.data.token)
    }


    useEffect(() => {
        if (props.active === true && props.type === 2) {
            const formatData = { ...newData }
            const getData = props.data
            formatData.editable = true
            formatData.aplicacao_id = getData.aplicacao_id ? getData.aplicacao_id : ''
            formatData.aplicacao_nome = getData.aplicacao_nome ? getData.aplicacao_nome : ''
            formatData.id = getData.id ? getData.id : ''
            formatData.nome = getData.nome ? getData.nome : ''
            formatData.prazo = getData.prazo ? getData.prazo : ''
            formatData.preco = getData.preco ? getData.preco : '00.00'
            formatData.servico = getData.servico ? getData.servico : ''
            formatData.status = ![null, undefined, ''].includes(getData.status) ? getData.status : ''
            setNewData(formatData)
        }
    }, [props.active])
    return (
        <Modal
            open={props.active}
            onClose={() => {
                props.resetOpenData()
                setNewData(newDataInitial)
            }}
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                [theme.breakpoints.down('lg')]: {
                    alignItems: 'flex-start',
                },
            }}
        >
            <Box sx={{
                width: '45%',
                height: 'auto',
                backgroundColor: theme.palette.background.default,
                border: 1,
                borderColor: theme.palette.secondary.main,
                position: 'absolute',
                [theme.breakpoints.down('lg')]: {
                    width: '70%'
                },
                [theme.breakpoints.down('md')]: {
                    width: '90%'
                },
            }}>
                <Box sx={{ backgroundColor: theme.palette.background.paper, padding: 5, display: 'flex', justifyContent: 'space-between' }}>
                    <Typography sx={{ fontWeight: 'bold', fontSize: 30 }}>{props.type === 1 ? 'Nova Entrega' : 'Entrega #' + newData.nome}</Typography>
                    <Typography sx={{ fontWeight: 'bold', fontSize: 30 }}>{props.type === 1 ? '' : newData.status === 1 ? 'Ativo' : 'Inativo'}</Typography>
                </Box >
                <Box sx={{
                    padding: 5, paddingTop: 1
                }}>
                    <Box sx={{
                        display: 'flex', flexDirection: 'column', width: '100%', gap: 3,
                        [theme.breakpoints.down('md')]: {
                            width: '98%'
                        },
                    }}>
                        <Typography fontWeight={'bold'} fontSize={20}>Informações</Typography>
                        <Box sx={{ display: 'flex', gap: 1, width: '100%', flexWrap: 'wrap' }}>
                            {Object.keys(newData).map((e, idx) => {
                                if (['editable', 'aplicacao_id', 'status'].includes(e)) return
                                if (e === 'aplicacao_nome') {
                                    return (
                                        <FormControl disabled={newData.editable} key={idx} sx={{
                                            backgroundColor: theme.palette.background.paper,
                                            width: '49%'
                                        }}>
                                            <InputLabel id="demo-simple-select-label">{e}</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={newData.aplicacao_id}
                                                label={e}
                                                onChange={(event) => {
                                                    const formatData = { ...newData }
                                                    formatData.aplicacao_id = event.target.value
                                                    const findIndex = props.aplicacoes.findIndex((e) => e.id === parseInt(event.target.value))
                                                    formatData.aplicacao_nome = props.aplicacoes[findIndex].nome
                                                    setNewData(formatData)
                                                }}
                                            >
                                                {props.aplicacoes.map((e, eidx) => (
                                                    <MenuItem key={eidx} value={e.id}>{e.nome}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    )
                                }
                                return (
                                    <TextField
                                        key={idx}
                                        label={e}
                                        margin="none"
                                        value={newData[e]}
                                        disabled={['id', 'data_cadastro'].includes(e) ? true : newData.editable}
                                        id={e}
                                        name={e}
                                        onChange={(value) => {
                                            const x = { ...newData }
                                            x[e] = ['preco'].includes(e) ? realMask(value.target.value) : ['servico', 'prazo'].includes(e) ? onlyInteger(value.target.value) : value.target.value
                                            setNewData(x)
                                        }}
                                        sx={{
                                            width: '49%'
                                        }}
                                        InputLabelProps={{
                                            style: {
                                                color: theme.palette.text.secondary,
                                                borderColor: '#eee'
                                            }
                                        }}
                                        autoComplete=""
                                        inputProps={{
                                            maxLength: e === 'estado' ? 2 : 'unset',
                                            style: {
                                                backgroundColor: theme.palette.background.paper,
                                                borderRadius: 5,
                                                textTransform: e === 'estado' ? 'uppercase' : 'unset',
                                            }
                                        }}
                                    />
                                )
                            })}
                        </Box>
                        <Box sx={{ display: 'flex', gap: 2 }}>
                            {props.type === 2 ?
                                <IconButton
                                    onClick={() => {
                                        if (getEvento(props.eventos, 'Editar Entrega') === false) {
                                            const modalInfo = {
                                                active: true,
                                                title: 'Permissão',
                                                subtitle: 'Você não tem permissão para editar o cliente',
                                                body: <Button onClick={() => props.resetModalInfo()} sx={{ mb: 2, backgroundColor: theme.palette.secondary.main, color: theme.palette.text.primary, '&:hover': { backgroundColor: theme.palette.secondary.dark }, borderRadius: 0, padding: 1 }}>
                                                    <Typography fontWeight={'bold'} sx={{ color: theme.palette.text.whiteText, textAlign: 'center' }}>Voltar</Typography>
                                                </Button>
                                            }
                                            props.updateModalInfo(modalInfo)
                                            return
                                        }
                                        const x = { ...newData }
                                        x.editable = !x.editable
                                        setNewData(x)
                                    }}
                                    sx={{
                                        backgroundColor: theme.palette.primary.main,
                                        borderRadius: 0,
                                        '&:hover': {
                                            backgroundColor: theme.palette.secondary.main,
                                        }
                                    }}>
                                    {props.type === 2 ?
                                        <EditIcon sx={{ color: theme.palette.text.whiteText }} />
                                        : <Typography fontWeight={'bold'} sx={{ color: theme.palette.text.whiteText }}>Salvar</Typography>
                                    }
                                </IconButton>
                                : null
                            }
                            <IconButton
                                onClick={() => {
                                    if (newData.editable === true) return
                                    updateESaveEDeleteClient()
                                }}
                                sx={{
                                    backgroundColor: newData.editable === true ? theme.palette.grey[600] : theme.palette.primary.main,
                                    borderRadius: 0,
                                    '&:hover': {
                                        backgroundColor: newData.editable === false ? theme.palette.secondary.main : theme.palette.grey[600]
                                    }
                                }}>
                                {props.type === 2 ?
                                    <SaveIcon sx={{ color: theme.palette.text.whiteText }} />
                                    : <Typography fontWeight={'bold'} sx={{ color: theme.palette.text.whiteText }}>Salvar</Typography>
                                }
                            </IconButton>
                            {props.type === 2 ?
                                <IconButton
                                    onClick={() => {
                                        if (newData.editable === true) return
                                        if (getEvento(props.eventos, 'Excluir Entrega') === false) {
                                            const modalInfo = {
                                                active: true,
                                                title: 'Permissão',
                                                subtitle: 'Você não tem permissão para excluir entrega',
                                                body: <Button onClick={() => props.resetModalInfo()} sx={{ mb: 2, backgroundColor: theme.palette.secondary.main, color: theme.palette.text.primary, '&:hover': { backgroundColor: theme.palette.secondary.dark }, borderRadius: 0, padding: 1 }}>
                                                    <Typography fontWeight={'bold'} sx={{ color: theme.palette.text.whiteText, textAlign: 'center' }}>Voltar</Typography>
                                                </Button>
                                            }
                                            props.updateModalInfo(modalInfo)
                                            return
                                        }
                                        const modalInfo = {
                                            active: true,
                                            title: 'Exclusão',
                                            subtitle: 'Você tem certeza que deseja excluir a entrega?',
                                            body:
                                                <Box sx={{ display: 'flex', gap: 2 }}>
                                                    <Button onClick={() => props.resetModalInfo()} sx={{ mb: 2, backgroundColor: theme.palette.secondary.main, color: theme.palette.text.primary, '&:hover': { backgroundColor: theme.palette.secondary.dark }, borderRadius: 0, padding: 1 }}>
                                                        <Typography fontWeight={'bold'} sx={{ color: theme.palette.text.whiteText, textAlign: 'center' }}>Voltar</Typography>
                                                    </Button>
                                                    <Button onClick={() => {
                                                        updateESaveEDeleteClient('delete')
                                                    }} sx={{ mb: 2, backgroundColor: theme.palette.secondary.main, color: theme.palette.text.primary, '&:hover': { backgroundColor: theme.palette.secondary.dark }, borderRadius: 0, padding: 1 }}>
                                                        <Typography fontWeight={'bold'} sx={{ color: theme.palette.text.whiteText, textAlign: 'center' }}>Sim</Typography>
                                                    </Button>
                                                </Box>
                                        }
                                        props.updateModalInfo(modalInfo)
                                    }}
                                    sx={{
                                        backgroundColor: newData.editable === true ? theme.palette.grey[600] : theme.palette.primary.main,
                                        borderRadius: 0,
                                        '&:hover': {
                                            backgroundColor: newData.editable === false ? theme.palette.secondary.main : theme.palette.grey[600]
                                        }
                                    }}>
                                    <DeleteIcon sx={{ color: theme.palette.text.whiteText }} />
                                </IconButton>
                                : null}
                            {props.type === 2 ?
                                <IconButton
                                    onClick={() => {
                                        if (newData.editable === true) return
                                        if (getEvento(props.eventos, 'Trocar Status') === false) {
                                            const modalInfo = {
                                                active: true,
                                                title: 'Permissão',
                                                subtitle: 'Você não tem permissão para trocar status',
                                                body: <Button onClick={() => props.resetModalInfo()} sx={{ mb: 2, backgroundColor: theme.palette.secondary.main, color: theme.palette.text.primary, '&:hover': { backgroundColor: theme.palette.secondary.dark }, borderRadius: 0, padding: 1 }}>
                                                    <Typography fontWeight={'bold'} sx={{ color: theme.palette.text.whiteText, textAlign: 'center' }}>Voltar</Typography>
                                                </Button>
                                            }
                                            props.updateModalInfo(modalInfo)
                                            return
                                        }
                                        const formatData = { ...newData }
                                        formatData.status = formatData.status === 1 ? 0 : 1
                                        setNewData(formatData)
                                    }}
                                    sx={{
                                        backgroundColor: newData.editable === true ? theme.palette.grey[600] : theme.palette.primary.main,
                                        borderRadius: 0,
                                        '&:hover': {
                                            backgroundColor: newData.editable === false ? theme.palette.secondary.main : theme.palette.grey[600]
                                        }
                                    }}>
                                    <AutorenewIcon sx={{ color: theme.palette.text.whiteText }} />
                                </IconButton>
                                : null}
                        </Box>
                    </Box >
                </Box >
            </Box >
        </Modal >
    )
}