import React, { useState, useContext } from 'react';
import { useTheme, Box, Typography } from '@mui/material';
import { ModalInfo } from '../../components/modalInfoN';
import { User } from '../../contexts/user';
import { getEvento } from '../../components/getEvento';
import { Filter } from './components/filter';
import { SelectUser } from './components/selectUser';
import { List } from './components/list';

export function Vendas(props) {
    const theme = useTheme();
    const user = useContext(User);
    //estados
    const [SUser, setSUser] = useState({
        visible: true,
        user: null
    })
    const [data, setData] = useState([])
    const [MInfo, setMInfo] = useState({
        visible: false,
        title: '',
        subtitle: '',
        canDesactive: true,
        icon: '',
        funcS: () => null
    });
    function updateData(value) {
        setData(Array.isArray(value) ? value : [])
    }
    function updateSUser(visible, user) {
        const newSUser = { ...SUser }
        newSUser.visible = visible ? visible : false
        newSUser.user = user ? user : null
        setData([])
        setSUser(newSUser)
    }

    function updateModalInfo(visible, canDesactive, title, subtitle, icon, func) {
        const newMInfo = { ...MInfo };
        newMInfo.visible = visible;
        newMInfo.canDesactive = canDesactive;
        newMInfo.title = title;
        newMInfo.subtitle = subtitle;
        newMInfo.icon = icon;
        newMInfo.funcS = func;
        setMInfo(newMInfo);
    }

    return (
        <Box sx={{
            width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 5, [theme.breakpoints.down('md')]: {
                overflow: 'scroll'
            },
        }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '90%' }}>
                <Typography variant="h2" fontWeight={'bold'}>{props?.nome}</Typography>
                <Typography fontSize={20}>{props?.subtitle}</Typography>
            </Box>
            <Box sx={{ width: '90%', display: 'flex', flexDirection: 'column', gap: 2 }}>
                {!getEvento(props.eventos, 'Acesso') ?
                    <Box sx={{ padding: 2, background: theme.palette.warning.main }}>
                        <Typography sx={{ color: theme.palette.text.whiteText, fontWeight: 'bold' }}>Você não possui acesso a esse módulo</Typography>
                    </Box>
                    :
                    <>
                        <Filter SUser={SUser.user} updateSUser={updateSUser} updateData={updateData} />
                        <SelectUser visible={SUser.visible} updateSUser={updateSUser} />
                        <List data={data} />
                    </>
                }
            </Box>
            <ModalInfo hideModal={() => updateModalInfo(false)} canDesactive={MInfo.canDesactive} visible={MInfo.visible} title={MInfo.title} subtitle={MInfo.subtitle} icon={MInfo.icon} funcS={MInfo.funcS} />
        </Box>
    );
}