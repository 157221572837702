

export function getEvento(eventos, value) {
    if (Array.isArray(eventos)) {
        const getEvento = eventos.filter((e) => e.nome_evento === value)
        if (getEvento.length > 0) return getEvento
        return false
    }
    else {
        return false
    }
}