import React, { useState, useContext } from 'react';
import { useTheme, Box, Paper, IconButton } from '@mui/material';
import { ModalInfo } from '../../../components/modalInfoN';
import { User } from '../../../contexts/user';
import { DataGrid, gridClasses } from '@mui/x-data-grid';
import { ptBR } from '@mui/x-data-grid/locales';
import { getIcon } from '../../../components/icons';

export function List({ data, updateInside }) {
    const theme = useTheme();
    const user = useContext(User);
    //estados
    const [MInfo, setMInfo] = useState({
        visible: false,
        title: '',
        subtitle: '',
        canDesactive: true,
        icon: '',
        funcS: () => null
    });
    const [sortModel, setSortModel] = useState([]);

    function updateModalInfo(visible, canDesactive, title, subtitle, icon, func) {
        const newMInfo = { ...MInfo };
        newMInfo.visible = visible;
        newMInfo.canDesactive = canDesactive;
        newMInfo.title = title;
        newMInfo.subtitle = subtitle;
        newMInfo.icon = icon;
        newMInfo.funcS = func;
        setMInfo(newMInfo);
    }
    function handleDelete() {
        return
    }

    const columns = typeof data[0] === 'object' ? Object.keys(data[0]).map((header) => ({
        field: header,
        headerName: nameToNome(header),
        sortable: true,

    })) : null;
    const columnsWithButton = columns ? [
        {
            field: 'actions',
            headerName: 'Mais',
            sortable: false,
            renderCell: (params) => (
                <IconButton onClick={() => updateInside(true, params.row)} sx={{ background: theme.palette.secondary.main, color: theme.palette.text.whiteText, borderRadius: 2 }}>
                    {getIcon(77)}
                </IconButton>
            ),
            width: 10
        },
        ...columns,
    ] : null;
    return (
        <Box sx={{
            flex: 1,
            ...gridStyles
        }}>
            {Array.isArray(data) && data.length > 0 ?
                <div style={{ height: 520, width: '100%' }}>
                    <DataGrid
                        isRowSelectable={false}
                        disableRowSelectionOnClick
                        component={Paper}
                        rows={data}
                        columns={columnsWithButton.map((e) => ({
                            ...e, flex: 1
                        }))}
                        initialState={{
                            pagination: {
                                paginationModel: { page: 0, pageSize: 10 },
                            },
                        }}
                        pageSizeOptions={[10, 15, 20]}
                        sortModel={sortModel}
                        onSortModelChange={(model) => setSortModel(model)}
                        localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                        getCellClassName={(params) => {
                            return params.field === 'statusNome' ? typesTocolor(params.value) : null
                        }}
                    />
                </div>
                : null}
            <ModalInfo hideModal={() => updateModalInfo(false)} canDesactive={MInfo.canDesactive} visible={MInfo.visible} title={MInfo.title} subtitle={MInfo.subtitle} icon={MInfo.icon} funcS={MInfo.funcS} />
        </Box>
    );
}

const nameToNome = (type) => {
    const types = {
        id: 'Pedido',
        order_id: 'Id. pagamento',
        aplicacaoNome: 'Aplicação',
        produtoNome: 'Produto',
        produto_preco: 'Prod. Preço',
        formaPagamento: 'Form. Pagamento',
        total: 'Total',
        data_pedido: 'Data Pedido',
        data_atualizacao: 'Últ. Atualizacao',
        statusNome: 'Status'
    }
    if (types.hasOwnProperty(type)) return types[type]
    return ''
}

const gridStyles = {
    [`.${gridClasses.cell}.orange`]: {
        backgroundColor: '#FFB800',
        color: '#fff',
    },
    [`.${gridClasses.cell}.green`]: {
        backgroundColor: '#1C9E29',
        color: '#fff',
    },
    [`.${gridClasses.cell}.blue`]: {
        backgroundColor: '#1C479E',
        color: '#fff',
    },
    [`.${gridClasses.cell}.red`]: {
        backgroundColor: '#FC0707',
        color: '#fff',
    },
    [`.${gridClasses.cell}.grey`]: {
        backgroundColor: '#BDC2BD',
        color: '#fff',
    },
};

const typesTocolor = (type) => {
    const types = {
        'Aguardando Pagamento': 'orange',
        'Pagamento Aprovado': 'blue',
        'Pedido Faturado': 'blue',
        'Pedido Enviado': 'blue',
        'Entregue': 'green',
        'Pagamento Negado': 'red',
        'Entrega Negada': 'red',
        'Devolução concluída': 'green',
        'Devolução Solicitada': 'blue',
        'Pagamento Expirado': 'grey'
    }
    if (types.hasOwnProperty(type)) return types[type]
    return 'green'
}
