import React, { useState, useContext, useEffect } from 'react';
import { useTheme, Box, Modal, Typography } from '@mui/material';
import { ModalInfo } from '../../../../components/modalInfoN';
import { User } from '../../../../contexts/user';
import { CTextField } from '../../../../components/TextField';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import moment from "moment";
import 'moment/locale/pt-br'
import { ButtonSubmit } from '../../../../components/buttons';
import mainApi from '../../../../apis/mainApiN';

export function New({ visible, campanha, dismiss, pushCampanha, editCampanha }) {
    const theme = useTheme();
    const user = useContext(User);
    const dataInit = {
        nome: '',
        descricao: '',
        init: moment().format('DD/MM/YYYY'),
        end: moment().format('DD/MM/YYYY'),
    }
    //estados

    const [data, setData] = useState(dataInit)

    const [MInfo, setMInfo] = useState({
        visible: false,
        title: '',
        subtitle: '',
        canDesactive: true,
        icon: '',
        funcS: () => null
    });

    function updateModalInfo(visible, canDesactive, title, subtitle, icon, func) {
        const newMInfo = { ...MInfo };
        newMInfo.visible = visible;
        newMInfo.canDesactive = canDesactive;
        newMInfo.title = title;
        newMInfo.subtitle = subtitle;
        newMInfo.icon = icon;
        newMInfo.funcS = func;
        setMInfo(newMInfo);
    }
    function changeData(date, type) {
        const newData = { ...data }
        newData[type] = moment(date).format('DD/MM/YYYY')
        setData(newData)
    }
    function changeText(value, type) {
        const newData = { ...data }
        newData[type] = value
        setData(newData)
    }

    async function saveData() {
        if (!data.nome || !data.descricao) {
            updateModalInfo(true, true, 'Atenção', 'Todos os campos devem ser preenchidos.', 'exclamation')
            return
        }
        updateModalInfo(true, false, 'Salvando', 'Estamos salvando sua campanha.', 'loading')
        const endpoint = campanha?.id ? 'updateCampFranquia' : 'saveCampFranquia'
        const method = campanha?.id ? 'PUT' : 'POST'
        const save = await mainApi(`farmaTouch/${endpoint}`, method, data, user?.data?.token)
        if (save?.status !== 200) {
            const message = save?.apiReturn?.message ? save?.apiReturn?.message : 'Ocorreu um erro ao salvar a campanha.'
            updateModalInfo(true, true, 'Atenção', message, 'exclamation')
            return
        }
        updateModalInfo(false)
        const newData = { ...data }
        newData.init = moment(newData.init, 'DD/MM/YYYY').format('YYYY-MM-DD')
        newData.end = moment(newData.end, 'DD/MM/YYYY').format('YYYY-MM-DD')
        if (!campanha?.id) {
            newData.id = save?.apiReturn?.apiReturn
            newData.status = 1
            pushCampanha(newData)
        }
        else {
            editCampanha(newData)
        }
        handleDismiss()
    }
    function handleButton(type) {
        if (type === 'Salvar') {
            saveData()
            return
        }
        handleDismiss()
    }
    function handleDismiss() {
        setData(dataInit)
        dismiss()
        return
    }

    useEffect(() => {
        if (campanha?.id) {
            const newData = { ...data }
            newData.id = campanha.id
            newData.nome = campanha.nome
            newData.descricao = campanha.descricao
            newData.init = moment(campanha.init).format('DD/MM/YYYY')
            newData.end = moment(campanha.end).format('DD/MM/YYYY')
            newData.status = campanha.status
            setData(newData)
        }
    }, [visible])

    return (
        <Modal open={visible ? visible : false} onClose={handleDismiss} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 2 }} slotProps={{
            backdrop: {
                sx: {
                    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(250,255,255, 0.2)' : 'rgba(0,0,0, 0.4)'
                }
            }
        }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', height: 420, width: 650, background: theme.palette.background.default, outline: 'none' }}>
                <Box sx={{ background: theme.palette.background.default, padding: 5, paddingBottom: 2 }}>
                    <Typography sx={{ fontWeight: 'bold', color: theme.palette.text.primary, fontSize: 30 }}>Campanha</Typography>
                    <Typography sx={{ color: theme.palette.text.primary, fontSize: 18 }}>Preencha as informações a baixo e salve para ajustar a campanha.</Typography>
                </Box>
                <Box sx={{
                    display: 'flex', flexDirection: 'column', flex: 1,
                    padding: 5,
                    paddingTop: 0,
                    gap: 3,
                }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                        <CTextField prevData={data} value={data.nome} label={'Nome'} width={'100%'} onChangeText={changeText} type={'nome'} />
                        <CTextField value={data.descricao} prevData={data} label={'Descrição'} width={'100%'} onChangeText={changeText} type={'descricao'} />
                    </Box>
                    <Box>
                        <ByData date={data} changeData={changeData} />
                    </Box>
                    <Box sx={{ display: 'flex', gap: 2 }}>
                        {['Salvar', 'Sair'].map((e, idx) => {
                            const background = e === 'Salvar' ? theme.palette.primary.main : theme.palette.background.paper
                            const color = e === 'Salvar' ? theme.palette.text.whiteText : theme.palette.text.primary
                            return (
                                <ButtonSubmit key={idx} text={e} background={background} color={color} func={handleButton} funcParameters={e} />
                            )
                        })}
                    </Box>
                </Box>
                < ModalInfo hideModal={() => updateModalInfo(false)} canDesactive={MInfo.canDesactive} visible={MInfo.visible} title={MInfo.title} subtitle={MInfo.subtitle} icon={MInfo.icon} funcS={MInfo.funcS} />
            </Box>
        </Modal>
    );
}


function ByData({ date, changeData }) {
    return (
        <Box sx={{ display: 'flex', gap: 2 }}>
            <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="pt-br" >
                <DemoContainer
                    components={['MobileDatePicker']}
                >
                    <MobileDatePicker disabled={false} label={'Início'} defaultValue={moment()} localeText={{ cancelButtonLabel: 'Cancelar', toolbarTitle: 'Selecionar Data' }}
                        value={moment(date.init, 'DD/MM/YYYY')}
                        onChange={(value) => {
                            changeData(value, 'init')
                        }}
                    />
                </DemoContainer>
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="pt-br" >
                <DemoContainer
                    components={['MobileDatePicker']}
                >
                    <MobileDatePicker disabled={false} label={'Fim'} defaultValue={moment()} localeText={{ cancelButtonLabel: 'Cancelar', toolbarTitle: 'Selecionar Data' }}
                        value={moment(date.end, 'DD/MM/YYYY')}
                        onChange={(value) => {
                            changeData(value, 'end')
                        }}
                    />
                </DemoContainer>
            </LocalizationProvider>
        </Box>
    )
}