import { useTheme, Button } from '@mui/material';

export function ButtonOnList({ status, funcReturn }) {
    const theme = useTheme()
    const title = status === 'Devolução Solicitada' ? 'Finalizar Devolução' : status === 'Pagamento Aprovado' ? 'Faturar pedido' : status === 'Pedido Faturado' ? 'Preparar para o envio' : status === 'Pedido em Separação' ? 'Enviar Pedido' : null
    if (title) {
        return (
            <Button onClick={() => funcReturn(status)} sx={{ background: theme.palette.secondary.main, padding: 1, color: theme.palette.text.whiteText, textTransform: 'none' }}>
                {title}
            </Button>
        )
    }
}