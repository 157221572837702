import React, { useState, useContext, useEffect, useRef } from 'react';
import { useTheme, Box, Modal, Typography, IconButton } from '@mui/material';
import { ModalInfo } from '../../../../components/modalInfoN';
import { User } from '../../../../contexts/user';
import mainApi from '../../../../apis/mainApiN';
import { ButtonSubmit } from '../../../../components/buttons';
import { getIcon } from '../../../../components/icons';
import { CTextField } from '../../../../components/TextField';

export function ModalNfe({ pedido, visible, status, dismissNf, prevData, allData, updatedata }) {
    const theme = useTheme()
    const fileInputRef = useRef(null);
    const user = useContext(User);
    const dataInit = {
        numero: '',
        chave: '',
        link: '',
        base64: ''
    }
    const [data, setData] = useState(dataInit)
    function handleDismiss() {
        setData(dataInit)
        dismissNf()
        return
    }
    const [modalInfo, setModalInfo] = useState({
        visible: false,
        title: '',
        subtitle: '',
        canDesactive: true,
        icon: '',
        funcS: () => null
    });

    function updateModalInfo(visible, canDesactive, title, subtitle, icon, func) {
        setModalInfo({
            visible,
            canDesactive,
            title,
            subtitle,
            icon,
            funcS: func
        });
    }
    function changeData(value, type) {
        const newData = { ...data }
        newData[type] = value
        setData(newData)
    }
    function handleButton(type) {
        if (type === 'Sair') {
            handleDismiss()
            return
        }
        saveData()
        return
    }
    function handleFileInputClick() {
        fileInputRef.current.click();
    }

    function handleFileChange(event) {
        const file = event.target.files[0];
        if (file && file.type === 'application/pdf') {
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64String = reader.result.split(',')[1];
                setData((prevData) => ({
                    ...prevData,
                    base64: base64String,
                    link: `data:application/pdf;base64,${base64String}`
                }));
            };
            reader.readAsDataURL(file);
        } else {
            alert('Por favor, selecione um arquivo PDF.');
        }
    }
    async function saveData() {
        if ([data.numero, data.chave].includes('')) {
            updateModalInfo(true, true, 'Atenção', 'Número e Chave são obrigatórios.', 'exclamation')
            return
        }
        if (!prevData) {
            if (!data.base64) {
                updateModalInfo(true, true, 'Atenção', 'Nota fiscal não foi inserida.', 'exclamation')
                return
            }
        }
        const method = prevData ? 'PUT' : 'POST'
        const body = {
            pedido: pedido,
            numero: data['numero'],
            chave: data['chave'],
            base64: data['base64']
        }
        updateModalInfo(true, false, 'Salvando', 'Tentando salvar os dados.', 'loading')
        const trySave = await mainApi('pedidosecom/saveNFe', method, body, user?.data?.token)
        if (trySave?.status !== 200) {
            const message = trySave?.apiReturn?.message ? trySave?.apiReturn?.message : 'Ocorreu um erro ao salvar os dados.'
            updateModalInfo(true, true, 'Atenção', message, 'exclamation')
            return
        }
        /* 
            nfe_created: '',
    nfe_updated: '',
        */
        updateModalInfo(false)
        const apiReturn = trySave?.apiReturn?.apiReturn
        const newData = { ...allData }
        newData.nfe_chave = !prevData ? apiReturn?.chave_nfe : data['chave']
        newData.nfe_num = !prevData ? apiReturn?.num_nfe : data['numero']
        newData.nfe_link = !prevData ? apiReturn?.link : apiReturn?.link ? apiReturn?.link : data['link']
        newData.nfe_created = !prevData ? apiReturn?.data_created : prevData.nfe_created
        newData.nfe_updated = apiReturn?.date_last_updated
        newData.status_nome = !prevData ? apiReturn?.statusNome : prevData.status_nome
        newData.status = !prevData ? apiReturn?.status : prevData.status
        updatedata(newData)
        const newNfeData = { ...data }
        newNfeData.link = apiReturn?.link ? apiReturn?.link : data['link']
        setData(newNfeData)
        handleDismiss()
    }
    useEffect(() => {
        if (prevData) {
            const newData = { ...data }
            newData.numero = prevData.nfe_num
            newData.chave = prevData?.nfe_chave
            newData.link = prevData?.nfe_link
            setData(newData)
        }
    }, [visible])
    return (
        <Modal open={visible} onClose={handleDismiss} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 2 }} slotProps={{
            backdrop: {
                sx: {
                    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(250,255,255, 0.2)' : 'rgba(0,0,0, 0.4)'
                }
            }
        }}>
            <Box sx={{
                display: 'flex', flexDirection: 'column', height: 450, width: '95%', maxWidth: 600, background: theme.palette.background.default, outline: 'none',
                [theme.breakpoints.down('lg')]: {
                    width: '98%'
                },
            }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: 5, paddingBottom: 0 }}>
                    <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                        {getIcon(68, theme.palette.primary.main, 30)}
                        <Typography sx={{ fontWeight: 'bold', color: theme.palette.text.primary, fontSize: 30 }}>Informações da Nota Fiscal</Typography>
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', padding: 5, paddingTop: 1, paddingBottom: 1 }}>
                    <Typography sx={{ fontWeight: 'bold' }}>Informações da nota</Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                        <CTextField label={'Número da Nota'} width={'100%'} value={data['numero']} onChangeText={changeData} type={'numero'} prevData={data} />
                        <CTextField label={'Chave da Nota'} width={'100%'} value={data['chave']} onChangeText={changeData} type={'chave'} prevData={data} />
                        <CTextField disabled={true} label={'Link Nota'} width={'100%'} value={data['link']} onChangeText={changeData} type={'link'} prevData={data} />
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', padding: 5, paddingTop: 1, paddingBottom: 1 }}>
                    <Typography sx={{ fontWeight: 'bold' }}>Inserir Nota</Typography>
                    <Box>
                        <IconButton onClick={handleFileInputClick}>{getIcon(79, theme.palette.secondary.main, 30)}</IconButton>
                        <input
                            type="file"
                            accept="application/pdf"
                            ref={fileInputRef}
                            style={{ display: 'none' }}
                            onChange={handleFileChange}
                        />
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', gap: 2, padding: 5, paddingTop: 1, paddingBottom: 1 }}>
                    {['Salvar', 'Sair'].map((e) => {
                        const background = e === 'Salvar' ? theme.palette.primary.main : theme.palette.background.paper
                        const color = e === 'Salvar' ? theme.palette.text.whiteText : theme.palette.text.primary
                        return (
                            <ButtonSubmit key={e} text={e} background={background} color={color} func={handleButton} funcParameters={e} />
                        )
                    })}
                </Box>
                <ModalInfo hideModal={() => updateModalInfo(false)} canDesactive={modalInfo.canDesactive} visible={modalInfo.visible} title={modalInfo.title} subtitle={modalInfo.subtitle} icon={modalInfo.icon} funcS={modalInfo.funcS} />
            </Box>
        </Modal>
    )
}