import React, { useState, useContext, useEffect} from 'react';
import { useTheme, Box, Typography, Button, Collapse, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, Pagination } from '@mui/material';
import { ModalInfo } from '../../../components/modalInfoN';
import { User } from '../../../contexts/user';
import moment from 'moment';

export function List({ data }) {
    const theme = useTheme();
    const user = useContext(User);
    //estados
    const [ColAtivo, setColAtivo] = useState(null)
    const [MInfo, setMInfo] = useState({
        visible: false,
        title: '',
        subtitle: '',
        canDesactive: true,
        icon: '',
        funcS: () => null
    });
    const [page, setPage] = useState(1);
    const itemsPerPage = 5;

    function updateModalInfo(visible, canDesactive, title, subtitle, icon, func) {
        const newMInfo = { ...MInfo };
        newMInfo.visible = visible;
        newMInfo.canDesactive = canDesactive;
        newMInfo.title = title;
        newMInfo.subtitle = subtitle;
        newMInfo.icon = icon;
        newMInfo.funcS = func;
        setMInfo(newMInfo);
    }

    function handleColAtivo(num_nota) {
        setColAtivo(num_nota === ColAtivo ? null : num_nota)
    }
    const paginatedProdutos = data.slice((page - 1) * itemsPerPage, page * itemsPerPage);
    const handleChangePage = (event, value) => {
        setPage(value);
    };
    useEffect(() => {
        setPage(1)
    }, [data])

    return (
        <Box sx={{
            flex: 1
        }}>
            <Box sx={{}}>
                {paginatedProdutos.map((e) => {
                    return (
                        <Box>
                            <Button sx={{ textTransform: 'none', textAlign: 'left', width: '100%' }} onClick={() => handleColAtivo(e?.num_nota)}>
                                <Box sx={{ display: 'flex', width: '100%', flexDirection: 'column', gap: 2, padding: 2, boxShadow: theme.palette.mode === 'dark' ? '0px 4px 10px rgba(155, 155, 155, 0.1)' : '0px 4px 10px rgba(0, 0, 0, 0.1)' }}>
                                    <Box sx={{ display: 'flex', gap: 1 }}>
                                        <Typography sx={{ color: theme.palette.text.primary, fontWeight: 'bold', fontSize: 20 }}>Venda:</Typography>
                                        <Typography sx={{ color: theme.palette.text.primary, fontSize: 20, fontWeight: 'bold', }}>{e?.num_nota}</Typography>
                                    </Box>
                                    <Box sx={{ display: 'flex', gap: 20 }}>
                                        <Box>
                                            <Box sx={{ display: 'flex', gap: 1 }}>
                                                <Typography sx={{ color: theme.palette.text.primary, fontWeight: 'bold' }}>Data:</Typography>
                                                <Typography sx={{ color: theme.palette.text.primary }}>{moment(e?.dat_emissao, 'YYYY-MM-DD').format('DD/MM/YYYY')}</Typography>
                                            </Box>
                                            <Box sx={{ display: 'flex', gap: 1 }}>
                                                <Typography sx={{ color: theme.palette.text.primary, fontWeight: 'bold' }}>Hora:</Typography>
                                                <Typography sx={{ color: theme.palette.text.primary }}>{e?.hor_emissao}</Typography>
                                            </Box>
                                        </Box>
                                        <Box>
                                            <Box sx={{ display: 'flex', gap: 1 }}>
                                                <Typography sx={{ color: theme.palette.text.primary, fontWeight: 'bold' }}>Vendedor Código:</Typography>
                                                <Typography sx={{ color: theme.palette.text.primary }}>{e?.cod_vendedor}</Typography>
                                            </Box>
                                            <Box sx={{ display: 'flex', gap: 1 }}>
                                                <Typography sx={{ color: theme.palette.text.primary, fontWeight: 'bold' }}>Vendedor:</Typography>
                                                <Typography sx={{ color: theme.palette.text.primary }}>{e?.nom_vendedor}</Typography>
                                            </Box>
                                        </Box>
                                        {e?.nom_cliente ?
                                            <Box>
                                                <Box sx={{ display: 'flex', gap: 1 }}>
                                                    <Typography sx={{ color: theme.palette.text.primary, fontWeight: 'bold' }}>Cliente:</Typography>
                                                    <Typography sx={{ color: theme.palette.text.primary }}>{e?.nom_cliente}</Typography>
                                                </Box>
                                                <Box sx={{ display: 'flex', gap: 1 }}>
                                                    <Typography sx={{ color: theme.palette.text.primary, fontWeight: 'bold' }}>Cartão:</Typography>
                                                    <Typography sx={{ color: theme.palette.text.primary }}>{e?.cartao ? e?.cartao : ''}</Typography>
                                                </Box>
                                            </Box>
                                            : null}
                                    </Box>
                                </Box>
                            </Button>
                            <Collapse in={ColAtivo === e?.num_nota ? true : false} sx={{ padding: 1 }}>
                                <TableContainer component={Paper} sx={{ background: 'inherit' }}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                {Object.keys(e?.produtos[0]).map((header) => (
                                                    <TableCell key={header}>{header}</TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {e?.produtos.map((row, rowIndex) => (
                                                <TableRow key={rowIndex}>
                                                    {Object.values(row).map((cell, cellIndex) => (
                                                        <TableCell key={cellIndex}>{cell}</TableCell>
                                                    ))}
                                                </TableRow>
                                            ))}
                                            <TableRow>
                                                <TableCell colSpan={2}>Total:</TableCell>
                                                <TableCell colSpan={1}></TableCell>
                                                <TableCell>
                                                    {e?.produtos.reduce((acc, cur) => acc + cur.qtd_produto, 0)}
                                                </TableCell>
                                                <TableCell>
                                                    {e?.produtos.reduce((acc, cur) => acc + (cur.vlr_custo * cur.qtd_produto), 0).toFixed(2)}
                                                </TableCell>
                                                <TableCell>
                                                    {e?.produtos.reduce((acc, cur) => acc + (cur.venda * cur.qtd_produto), 0).toFixed(2)}
                                                </TableCell>
                                                <TableCell>
                                                    {Math.abs(((e?.produtos.reduce((acc, cur) => acc + cur.vlr_final, 0) - e?.produtos.reduce((acc, cur) => acc + (cur.venda * cur.qtd_produto), 0)) / e?.produtos.reduce((acc, cur) => acc + (cur.venda * cur.qtd_produto), 0)) * 100).toFixed(2)}
                                                </TableCell>

                                                <TableCell>
                                                    {e?.produtos.reduce((acc, cur) => acc + cur.vlr_final, 0).toFixed(2)}
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Collapse>
                        </Box>
                    )
                })}
                <Pagination
                    count={Math.ceil(data.length / itemsPerPage)}
                    page={page}
                    onChange={handleChangePage}
                    color="primary"
                    sx={{ marginTop: 2, alignSelf: 'center' }}
                />
            </Box>
            <ModalInfo hideModal={() => updateModalInfo(false)} canDesactive={MInfo.canDesactive} visible={MInfo.visible} title={MInfo.title} subtitle={MInfo.subtitle} icon={MInfo.icon} funcS={MInfo.funcS} />
        </Box>
    );
}
