import React, { useEffect, useState, useContext } from "react";
import { useTheme } from '@mui/material/styles';
import { Box, Typography, Button } from '@mui/material';
import mainApi from "../../apis/mainApi";
import { ModalLoading } from "../../components/modal";
import { User } from "../../contexts/user";
import { ModalInfo } from "../../components/modalInfo";
import ListCupons from "./listCupons";
import CouponFilter from "./cupomFilter";
import moment from "moment";
import { realMask } from "../../components/masks";
import CuponsInside from "./cuponsInside";
import { getEvento } from "../../components/getEvento";
export default props => {
    document.title = 'Cupons - Central de Negócios'
    const theme = useTheme()
    const modalInfoInitial = {
        active: false,
        title: '',
        subtitle: '',
        body: ''
    }
    const user = useContext(User)
    const [modalLoading, setModalLoading] = useState(false)
    const [modalInfo, setModalInfo] = useState(modalInfoInitial)
    //'id', 'email', 'cpf', 'nome', 'sobrenome', 'rua', 'bairro', 'cidade', 'estado', 'celular', 'cep'
    const [data, setData] = useState({
        content: [],
        contentFilter: [],
        aplicacoes: [],
        parceiros: [],
        tipos: [],
        openDataInside: {
            open: false,
            content: {},
            edit: 1
        }
    })
    function updateModalInfo(value) { setModalInfo(value) }
    function updateData(value) { setData(value) }
    function updateModalLoading(value) { setModalLoading(value) }
    function resetOpenData(value) {
        const newData = { ...data }
        newData.openDataInside = {
            open: false,
            content: {}
        }
        newData.openDataCondicao = {
            open: false,
            content: {}
        }
        setData(newData)
    }

    function resetModalInfo() {
        setModalInfo(modalInfoInitial)
    }
    function filterData(filterCriteria) {
        const newData = data.content.filter((coupon) => {
            // Verifica se o campo 'valor' corresponde ao filtro, considerando a máscara de valor
            const filterValue = typeof filterCriteria.valor === 'string' ? filterCriteria.valor.toLowerCase() : filterCriteria.valor;

            // Verifica se o campo 'valor' corresponde ao filtro, considerando a máscara de valor
            if (filterCriteria.campo === "valor") {
                const formattedValue = coupon.valor ? realMask(coupon.valor.toString()) : coupon.valor;
                return coupon.valor && formattedValue.includes(filterValue);
            }
            if (filterCriteria.campo === "quantidade") {
                return coupon.quantidade && coupon.quantidade.toString().includes(filterValue);
            }

            // Outras verificações de acordo com o campo selecionado
            if (filterCriteria.campo === "id") {
                return coupon.id.toString() === filterValue;
            }
            if (filterCriteria.campo === "codigo") {
                return coupon.codigo.includes(filterValue);
            }
            if (filterCriteria.campo === "parceiro") {
                return coupon.parceiro && coupon.parceiro.nome.toLowerCase().includes(filterValue);
            }
            if (filterCriteria.campo === "tipo") {
                return coupon.tipo && coupon.tipo.nome.toLowerCase().includes(filterValue);
            }
            if (filterCriteria.campo === "status") {
                if (filterCriteria.status === "ativo") {
                    return coupon.status === 1;
                }
                if (filterCriteria.status === "inativo") {
                    return coupon.status === 0;
                }
                if (filterCriteria.status === "vencido") {
                    const validade = moment(coupon.validade, "YYYY-MM-DD");
                    return validade.isBefore(moment()); // Verifica se a data de validade está no passado (vencida)
                }
            }
            if (filterCriteria.campo === "validade") {
                const validade = moment(coupon.validade, "YYYY-MM-DD");
                return validade.format("DD/MM/YYYY") === filterValue;
            }

            // Se nenhum filtro se aplicar, retorne true para manter o cupom no resultado
            return true;
        });
        if (Array.isArray(newData)) {
            const formatData = { ...data }
            formatData.contentFilter = newData
            setData(formatData)
        }
    }
    function openInside(value, idx, type, newClient) {
        const newData = { ...data }
        if (type === 'info') {
            newData.openDataInside.open = true
            newData.openDataInside.content = newClient ? newData.openDataInside.content : value
            newData.openDataInside.edit = newClient ? 1 : 2
        }
        if (type === 'inside') {
            newData.openDataInside.open = true
            newData.openDataInside.content = value
            newData.openDataInside.edit = newClient ? 1 : 2
        }
        setData(newData)
    }
    async function getData() {
        if (modalLoading === false) updateModalLoading(true)
        function FCallback(callback) {
            updateModalLoading(false)
            if (callback.status !== 200) {
                const x = { ...modalInfo }
                x.title = 'Erro'
                x.subtitle = callback.apiReturn.message ? callback.apiReturn.message : 'Ocorreu um erro! Tente novamente mais tarde'
                x.body = <Button onClick={() => setModalInfo(modalInfoInitial)} sx={{ mb: 2, backgroundColor: theme.palette.secondary.main, color: theme.palette.text.primary, '&:hover': { backgroundColor: theme.palette.secondary.dark }, borderRadius: 0, padding: 1 }}>
                    <Typography fontWeight={'bold'} sx={{ color: theme.palette.text.whiteText, textAlign: 'center' }}>Voltar</Typography>
                </Button>
                x.active = true
                setModalInfo(x)
                return
            }
            const newData = { ...data }
            newData.content = callback.apiReturn.apiReturn.cupons
            newData.tipos = callback.apiReturn.apiReturn.tipos
            newData.aplicacoes = callback.apiReturn.apiReturn.aplicacoes
            newData.parceiros = callback.apiReturn.apiReturn.parceiros
            newData.parceiros.unshift({
                id: '',
                nome: '',
            })
            setData(newData)
        }
        await mainApi('cupom/inicio', 'GET', null, FCallback, user?.data?.token)
    }
    useEffect(() => {
        if (!getEvento(props.eventos, 'Acesso')) return
        getData()
    }, [])
    return (
        <Box sx={{
            width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 5, [theme.breakpoints.down('md')]: {
                overflow: 'scroll', maxWidth: '100%'
            },
        }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '90%' }}>
                <Typography variant="h2" fontWeight={'bold'}>{props?.nome}</Typography>
                <Typography fontSize={20}>{props?.subtitle}</Typography>
            </Box>
            <Box sx={{
                width: '90%',
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                [theme.breakpoints.down('md')]: {
                    width: '98%'
                },
            }}>
                {/* //content */}
                {!getEvento(props.eventos, 'Acesso') ?
                    <Box sx={{ padding: 2, background: theme.palette.warning.main }}>
                        <Typography sx={{ color: theme.palette.text.whiteText, fontWeight: 'bold' }}>Você não possui acesso a esse módulo</Typography>
                    </Box>
                    :
                    <>
                        <CouponFilter filterData={filterData} openInside={openInside} />
                        <ListCupons data={data.contentFilter.length > 0 ? data.contentFilter : data.content} openInside={openInside} />
                    </>
                }
            </Box>
            <CuponsInside tipos={data.tipos} parceiros={data.parceiros} aplicacoes={data.aplicacoes} updateModalLoading={updateModalLoading} eventos={props.eventos} resetModalInfo={resetModalInfo} updateModalInfo={updateModalInfo} active={data.openDataInside.open} data={data.openDataInside.content} resetOpenData={resetOpenData} mainData={data} type={data.openDataInside.edit} updateData={updateData} />
            <ModalLoading ative={modalLoading} updateModalLoading={updateModalLoading} />
            <ModalInfo height={modalInfo?.height} ative={modalInfo.active} title={modalInfo.title} subtitle={modalInfo.subtitle} body={modalInfo.body} closeModal={resetModalInfo} />
        </Box>
    )
}