import React, { useState, useContext, useEffect } from 'react';
import { useTheme, Box, Typography, Button, Badge, Pagination } from '@mui/material';
import { ModalInfo } from '../../../../../components/modalInfoN';
import { User } from '../../../../../contexts/user';
import mainApi from '../../../../../apis/mainApiN';
import { UpComp } from '../../../../../components/upComp';
import { getIcon } from '../../../../../components/icons';
import { CTextField } from '../../../../../components/TextField';

export function ListCond({ prevData, updateData, activeIndex }) {
    const theme = useTheme();
    const user = useContext(User)
    const [data, setData] = useState([]);
    const [typeCtrl, setTypeCtrl] = useState(null)
    const [filter, setFilter] = useState('');
    const [MInfo, setMInfo] = useState({
        visible: false,
        title: '',
        subtitle: '',
        canDesactive: true,
        icon: '',
        funcS: () => null
    })
    function updateModalInfo(visible, canDesactive, title, subtitle, icon, func) {
        setMInfo({
            visible: visible,
            canDesactive: canDesactive,
            title: title,
            subtitle: subtitle,
            icon: icon,
            funcS: func
        });
    }
    async function getData() {
        updateModalInfo(true, false, 'Carregando', 'Buscando parâmetros de condições.', 'loading');
        try {
            const get = await mainApi(`farmaTouch/getCampFranquiaParams/${prevData?.type?.id}`, 'GET', null, user?.data?.token);
            if (get?.status !== 200) {
                const message = get?.apiReturn?.message ? get?.apiReturn?.message : 'Ocorreu um erro ao buscar os tipos.';
                updateModalInfo(true, true, 'Atenção', message, 'exclamation');
                return;
            }
            const getCond = get?.apiReturn?.apiReturn;
            if (!Array.isArray(getCond) || getCond.length === 0) {
                updateModalInfo(true, true, 'Atenção', 'Não foram encontrados tipos.', 'exclamation');
                return;
            }
            updateModalInfo(false);
            if (!prevData?.condicoes) {
                updateData([], 'condicoes');
            }
            setData(getCond);
        } catch (error) {
            console.error('Erro ao buscar os tipos:', error);
            updateModalInfo(true, true, 'Erro', 'Ocorreu um erro ao buscar os tipos.', 'exclamation');
        }
    }
    function handleSubmit(type) {
        const finIndex = prevData?.condicoes.findIndex((e) => e.id === type.id);
        const newData = finIndex === -1 ? [...prevData?.condicoes, type] : prevData?.condicoes.filter((e) => e.id !== type.id);
        updateData(newData, 'condicoes');
    }
    function onChangeText(value) {
        setFilter(value);
        setPage(1);
    }
    const [page, setPage] = useState(1);
    const itemsPerPage = 3;
    const filteredData = data.filter(e => e.nome.includes(filter));
    const pageCount = Math.ceil(filteredData.length / itemsPerPage);
    const paginatedFilteredData = filteredData.slice((page - 1) * itemsPerPage, page * itemsPerPage);
    const handleChangePage = (event, value) => {
        setPage(value);
    };
    useEffect(() => {
        if (activeIndex === 1 && typeCtrl !== prevData?.type?.id) {
            setPage(1);
            setTypeCtrl(prevData?.type?.id)
            getData();
        }
    }, [activeIndex]);

    return (
        <Box sx={{
            flex: 1,
            padding: 2,
            flexGrow: 1,
            paddingBottom: 0
        }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                    <CTextField label={'Buscar'} onChangeText={onChangeText} value={filter} width={'50%'} />
                </Box>
                {paginatedFilteredData.map((e, index) => (
                    <Button key={index} sx={{ width: '100%' }} onClick={() => handleSubmit(e)} >
                        <Badge sx={{ width: '100%' }} badgeContent={prevData?.condicoes.find(b => b.id === e.id) ? (
                            <Box sx={{ background: theme.palette.secondary.main, borderRadius: 5 }}>
                                {getIcon(82, theme.palette.text.whiteText)}
                            </Box>
                        ) : null} anchorOrigin={{ vertical: 'top', horizontal: 'left' }}>
                            <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 2 }}>
                                <Box sx={{ display: 'flex', background: theme.palette.background.default, boxShadow: theme.palette.mode === 'dark' ? '0px 4px 10px rgba(155, 155, 155, 0.3)' : '0px 4px 10px rgba(0, 0, 0, 0.1)', padding: 1, alignItems: 'center', gap: 3 }}>
                                    <UpComp>
                                        {getIcon(e.icon, theme.palette.mode === 'dark' ? theme.palette.text.primary : theme.palette.primary.main, 25)}
                                    </UpComp>
                                    <Box>
                                        <Typography sx={{ color: theme.palette.text.primary, textTransform: 'none', fontWeight: 'bold', textAlign: 'left' }}>{e.nome}</Typography>
                                        <Typography sx={{ color: theme.palette.text.primary, textTransform: 'none' }}>{e.descricao}</Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Badge>
                    </Button>
                ))}
            </Box>
            <Pagination
                count={pageCount}
                page={page}
                onChange={handleChangePage}
                color="primary"
                sx={{ marginTop: 2, alignSelf: 'center' }}
            />
            <ModalInfo hideModal={() => updateModalInfo(false)} canDesactive={MInfo.canDesactive} visible={MInfo.visible} title={MInfo.title} subtitle={MInfo.subtitle} icon={MInfo.icon} funcS={MInfo.funcS} />
        </Box>
    );
}
